import { CurrencyResponse, UserOrderResponse } from "@ns/api";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { usePagination } from "../../../hooks/use-pagination.ts";
import useScreenSize from "../../../hooks/use-screen-size.ts";
import { api } from "../../api/client-api.ts";
import BidList from "../../components/bid-list.tsx";
import DropdownSelect from "../../components/dropdown-select.tsx";
import ControlledForm from "../../components/form/controlled-form.tsx";
import { CardDataType } from "../../components/ui/card-data.tsx";
import CardList from "../../components/ui/card-list";
import FooterBidMenu from "../../components/ui/footer-bid-menu.tsx";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { profileSchema } from "../../schemas/form/profile-schema.ts";
import { useDialogContext } from "../../store/dialog-store.ts";
import Loader from "../../components/loader.tsx";

import giftSvg from "/svg/gift.svg";
import bellSvg from "/svg/bell.svg";

export enum BidTab {
  MY_BIDS = "ALL",
  WINNING_BIDS = "WINNING",
}

export enum ReferralTab {
  MY_REFERRALS = "MY_REFERRALS",
  WINNING_REFERRALS = "WINNING_REFERRALS",
}

const ProfileTabs = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/bids", title: "User Page" });
  }, []);

  const { width } = useScreenSize();

  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const {
    setIsGiftDialogOpen,
    toggleNotificationOpen,
    selectedTab,
    setSelectedTab,
  } = useDialogContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>(
    undefined,
  );

  const [bidTab, setBidTab] = useState<BidTab.MY_BIDS | BidTab.WINNING_BIDS>(
    BidTab.MY_BIDS,
  );

  useEffect(() => {
    if (selectedTab) {
      setBidTab(selectedTab as BidTab);
      setSelectedTab(undefined);
    }
  }, [selectedTab, setBidTab, setSelectedTab]);

  const { data: analytics } = useQuery({
    queryKey: ["analytics", currencyFilter],
    queryFn: () => api.analytics.getUserAnalytics(currencyFilter),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: myBidsData,
    isLoading: myBidsLoad,
    isFetchingNextPage: isFetchingNextBidPage,
    lastItemRef,
  } = usePagination<UserOrderResponse>({
    queryKey: ["myBids", "ALL", currencyFilter || ""],
    queryFn: (offset: number, limit: number) =>
      api.orders.getAllOrders("ALL", currencyFilter || "", limit, offset),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: winningBids,
    isFetchingNextPage: isFetchingNextWinningBidPage,
    lastItemRef: winningBidsRef,
  } = usePagination<UserOrderResponse>({
    queryFn: (offset: number, limit: number) =>
      api.orders.getAllOrders("WINNING", currencyFilter || "", limit, offset),
    queryKey: ["winningBids", "WINNING", currencyFilter || ""],
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: currencies } = useQuery<{ items: CurrencyResponse[] }>({
    queryKey: ["currency"],
    queryFn: () => api.resources.getCurrencies(),
  });

  const formatCurrencies = useMemo(
    () =>
      !currencies?.items
        ? []
        : currencies?.items.map((currency) => ({
            label: currency.code.toUpperCase(),
            value: currency.id,
            icon: CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE],
          })),
    [currencies],
  );

  useEffect(() => {
    if (formatCurrencies.length !== 0) {
      setValue("currencyId", formatCurrencies[0].value);
    }
  }, [setValue, formatCurrencies]);

  const onFilterSelect = useCallback(
    (value: string | undefined) => {
      setCurrencyFilter(value);
    },
    [setCurrencyFilter],
  );

  const formatFilterValues = useMemo(() => {
    const formatedCurrencies = currencies?.items?.map((currency) => ({
      id: currency.code,
      label: currency.code,
      svg: CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE],
    }));
    return [
      { id: undefined, label: t("common.all") },
      ...(formatedCurrencies || []),
    ];
  }, [currencies, t]);

  const onBidsTypeChange = (type: BidTab) => {
    setBidTab(type);
  };

  const stats: CardDataType[] = useMemo(
    () =>
      analytics?.totalWinnings !== 0
        ? [
            {
              title: t("common.totalWinnings"),
              value: `${Number(analytics?.totalWinnings).toFixed(2) ?? 0} ${environmentVariables.fiatCurrency}`,
              svg: () => <img src="/svg/money.svg" />,
            },
            {
              title: t("common.totalBids"),
              value: analytics?.totalBids ?? 0,
              svg: () => <img src="/svg/status.svg" />,
            },
            {
              title: t("common.totalRounds"),
              value: analytics?.roundsPlayed ?? 0,
              svg: () => <img src="/svg/money-pie.svg" />,
            },
          ]
        : [
            {
              title: t("common.totalWinnings"),
              value: analytics?.totalBids ?? 0,
              svg: () => <img src="/svg/status.svg" />,
            },
            {
              title: t("common.totalBids"),
              value: analytics?.roundsPlayed ?? 0,
              svg: () => <img src="/svg/money-pie.svg" />,
            },
          ],
    [analytics, t],
  );

  if (myBidsLoad) return <Loader />;

  return (
    <div className="flex flex-col w-full sm:ml-auto">
      <div className={"relative z-30 flex w-full items-center justify-evenly"}>
        <img
          onClick={() => setIsGiftDialogOpen(true)}
          src={giftSvg}
          alt="gift"
          className={"sm:hidden"}
        />
        <div className={"min-w-56 sm:hidden"} />
        <div className="hidden sm:block absolute right-[4rem] lg:right-[-2rem] top-0 w-[142px] h-[40px]">
          <div className="absolute left-0 w-[142px] top-0">
            <DropdownSelect
              values={formatFilterValues}
              onSelect={onFilterSelect}
              selected={currencyFilter}
            />
          </div>
        </div>
        <img
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleNotificationOpen();
          }}
          src={bellSvg}
          alt="bell"
          className={"sm:hidden"}
        />
      </div>
      <div className={"pt-[1.25rem] sm:pt-0"}>
        <CardList data={stats} />
      </div>
      <div className="flex flex-col gap-[20px] sm:gap-[32px] h-full wifull mt-8 sm:mt-[60px]">
        <div
          className={
            "w-full xl:w-[860px] flex flex-col mx-auto sm:pl-[128px] sm:pr-12 lg:pl-[192px] xl:p-0"
          }
        >
          <div className="hidden mb-2 sm:flex items-center justify-between text-white avenir-semibold text-[16px]">
            <div className="flex items-center gap-[30px]">
              <span
                className={`cursor-pointer ${
                  bidTab === BidTab.MY_BIDS
                    ? "text-white"
                    : "text-disabledSecondaryText"
                }`}
                onClick={() => onBidsTypeChange(BidTab.MY_BIDS)}
              >
                {t("profile.myBids")}
              </span>
              <span
                className={`cursor-pointer ${
                  bidTab === BidTab.WINNING_BIDS
                    ? "text-white"
                    : "text-disabledSecondaryText"
                }`}
                onClick={() => onBidsTypeChange(BidTab.WINNING_BIDS)}
              >
                {t("userPage.myWinningBids")}
              </span>
            </div>
          </div>
          <div className="hidden sm:flex hide-scrollbar overflow-y-auto w-full flex flex-col gap-[30px] items-center h-[calc(60vh-150px)]">
            <BidList
              data={bidTab === BidTab.MY_BIDS ? myBidsData : winningBids}
              isFetching={
                bidTab === BidTab.MY_BIDS
                  ? isFetchingNextBidPage
                  : isFetchingNextWinningBidPage
              }
              lastItemRef={
                width > 640
                  ? bidTab === BidTab.MY_BIDS
                    ? lastItemRef
                    : winningBidsRef
                  : () => {}
              }
              bidTab={bidTab}
            />
          </div>
        </div>
      </div>
      <>
        <FooterBidMenu
          renderData={bidTab === BidTab.MY_BIDS ? myBidsData : winningBids}
          isFetching={
            bidTab === BidTab.MY_BIDS
              ? isFetchingNextBidPage
              : isFetchingNextWinningBidPage
          }
          lastItemRef={
            width < 640
              ? bidTab === BidTab.MY_BIDS && width < 640
                ? lastItemRef
                : winningBidsRef
              : () => {}
          }
          setTab={setBidTab}
          currencyFilter={currencyFilter!}
          bidTab={bidTab}
          setCurrencyFilter={setCurrencyFilter}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </>
    </div>
  );
};

const UserPage = () => {
  return (
    <>
      <div
        className="flex xl:pt-[2rem] justify-center w-full xl:w-userPageWidth"
        style={{
          height: "var(--dashboard-height)",
        }}
      >
        <div className="w-full xl:w-[calc(100%-20rem)]">
          <ControlledForm schema={profileSchema}>
            <ProfileTabs />
          </ControlledForm>
        </div>
      </div>
    </>
  );
};

export default UserPage;
