/* eslint-disable @typescript-eslint/naming-convention */
import { Input } from "@ns/client-ui";
import { ChangeEvent, MouseEventHandler, forwardRef } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";

import exclamationSvg from "/svg/exclamation.svg";

export type ControlledInputProps = {
  type?: "text" | "password" | "number" | "email";
  label?: string;
  placeholder?: string;
  name: string;
  className?: string;
  id?: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errors?: FieldErrors<FieldValues>;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
  isError?: boolean;
  icon?: string;
  iconText?: string;
  defaultValue?: string;
  isOutlineShown?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  currency: string;
};

const ControlledPlaceABidInput = forwardRef<
  HTMLInputElement,
  ControlledInputProps
>(
  (
    {
      placeholder,
      name = "",
      className,
      disabled = false,
      autoFocus,
      id,
      onClick,
      icon,
      defaultValue = "",
      isOutlineShown = true,
      iconText,
      currency,
    },
    ref,
  ) => {
    const { control, trigger } = useFormContext();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputChange = (event: any, field: any) => {
      const text = event.target.value;
      if (!text) {
        field.onChange("");
        return;
      }

      let sanitizedValue = text.replace(/[^0-9.,]/g, "");

      sanitizedValue = sanitizedValue.replace(/,/g, ".");

      const parts = sanitizedValue.split(".");
      if (parts.length > 2) {
        sanitizedValue = `${parts[0]}.${parts[1]}`;
      }

      if (sanitizedValue.length > 5) {
        field.onChange(sanitizedValue.slice(0, 5));
      } else {
        field.onChange(sanitizedValue);
      }
    };

    const getCurrencyLimit = (currency: string) =>
      currency === "BTC" ? 100 : 10;

    const validateBidValue = (value: string) => {
      if (!value) {
        return "This field is required";
      }

      const sanitizedValue = value.replace(/,/g, ".");
      const currencyLimit = getCurrencyLimit(currency);

      const patternsAndErrors = [
        {
          pattern: /^0\d+$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. You are missing "." and two decimals`,
        },
        {
          pattern: /^\d+$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. You are missing two decimals`,
        },
        {
          pattern: /^\d+\.\d$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. You are missing second decimal number`,
        },
        {
          pattern: /^\.\d{2}$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. You are missing value in front of decimal point`,
        },
        {
          pattern: /^\.\d$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. You are missing value in front of decimal and second decimal number`,
        },
        {
          pattern: /^0\.\d$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. You are missing second decimal number`,
        },
        {
          pattern: /^\d+\.$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT. Your bid can't end with "."`,
        },
        {
          pattern: /^\d+\.\d\d(?!\d)$/,
          error: `Bid should be between 0.01-${currencyLimit} USDT.`, // Adjust this if needed
        },
      ];

      for (const { pattern, error } of patternsAndErrors) {
        if (pattern.test(sanitizedValue)) {
          return error;
        }
      }

      const numericValue = parseFloat(sanitizedValue);
      if (
        isNaN(numericValue) ||
        numericValue < 0.01 ||
        numericValue > currencyLimit
      ) {
        return `Bid should be between 0.01-${currencyLimit} USDT.`;
      }
      return true;
    };

    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{
          validate: (value) => validateBidValue(value),
        }}
        render={({ field, fieldState: { error } }) => (
          <div className="flex flex-col gap-[8px] w-full relative h-[55px]">
            <div className={"relative"}>
              <Input
                id={id}
                value={field.value ?? ""}
                onChange={(event) => handleInputChange(event, field)}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
                onClick={onClick}
                className={`${className} ${error && "border-destructive"} px-[25%] text-center ${field.value && "text-center"}`}
                isOutlineShown={error && !!field.value ? false : isOutlineShown}
                icon={icon}
                iconText={iconText}
                ref={ref}
                onBlur={async () => await trigger(name)}
                type={"text"}
              />
            </div>
            {error && (
              <div className="absolute top-[40px] flex justify-start gap-[3px]">
                <img src={exclamationSvg} alt="exclamation" />
                <span className="text-red-600 text-[12px]">
                  {error.message}
                </span>
              </div>
            )}
          </div>
        )}
      />
    );
  },
);

ControlledPlaceABidInput.displayName = "ControlledPlaceABidInput";

export default ControlledPlaceABidInput;
