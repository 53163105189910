const EyeOpen = ({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <g clipPath="url(#clip0_3581_2117)">
        <path
          d="M0.666626 8.00002C0.666626 8.00002 3.33329 2.66669 7.99996 2.66669C12.6666 2.66669 15.3333 8.00002 15.3333 8.00002C15.3333 8.00002 12.6666 13.3334 7.99996 13.3334C3.33329 13.3334 0.666626 8.00002 0.666626 8.00002Z"
          stroke="#CBCBCB"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99996 10C9.10453 10 9.99996 9.10459 9.99996 8.00002C9.99996 6.89545 9.10453 6.00002 7.99996 6.00002C6.89539 6.00002 5.99996 6.89545 5.99996 8.00002C5.99996 9.10459 6.89539 10 7.99996 10Z"
          stroke="#CBCBCB"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3581_2117">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeOpen;
