import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const codeValidationSchema = (t: TFunction) =>
  z.object({
    pin: z
      .string()
      .min(4, { message: t("form.authentication.minCodeCharacters") }),
  });

const schema = codeValidationSchema(staticTranslationFunction);

export type CodeData = z.infer<typeof schema>;

export const codeValidation: FormNames<CodeData> = {
  pin: "pin",
};
