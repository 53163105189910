import CountryDialog from "../components/dialogs/country-dialog.tsx";
import CreateAccountDialog from "../components/dialogs/create-account-dialog.tsx";
import VerifyCodeDialog from "../components/dialogs/verify-code.tsx";

interface ComponentProps {
  toggleAuthDialog: () => void;
}

interface Step {
  component: React.ComponentType<ComponentProps>;
  props?: ComponentProps;
}

export const RegistrationSteps: Step[] = [
  { component: CreateAccountDialog },
  { component: CountryDialog },
  { component: VerifyCodeDialog },
];
