import type { CheckPinCodeRequest } from "@ns/api";
import { AuthCard, AuthCardContent, Button, Modal } from "@ns/client-ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { api } from "../../api/client-api.ts";
import { removeCodeVerified, setCodeVerified } from "../../api/token-helper.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  CodeData,
  codeValidationSchema,
} from "../../schemas/form/code-schema.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import useUserStore from "../../store/user-store.ts";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledOtp from "../form/controlled-otp.tsx";
import Lock from "../svg-components/lock.tsx";

const EnterPinCodeDialogForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
    setError,
  } = useFormContext<CodeData>();

  const { setActiveForgotPinStep, setIsForgotPinModalOpen } = useAuthContext();

  const { setUser } = useUserStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (data: CheckPinCodeRequest) =>
      api.pinCode.validatePinCode(data),
    onSuccess: (response) => {
      if (response.isValid) {
        reset();
        setCodeVerified(true);
        onClose();
      } else {
        setError("pin", { message: t("screenLock.invalidPin") });
      }
    },
  });

  const onSubmitHandler = handleSubmit((data) => {
    mutate(data);
  });

  const onCancel = async () => {
    try {
      await axios
        .put(
          `${environmentVariables.authServiceUrl}/session/logout`,
          {},
          { withCredentials: true },
        )
        .then(() => {
          setUser(null);
          removeCodeVerified();
          navigate("/", { replace: true });
          queryClient.invalidateQueries();
          queryClient.removeQueries();
          onClose();
          reset();
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthCard className="min-h-[31.5rem] max-w-[37.5rem] items-center">
      <AuthCardContent>
        <div
          className={
            "text-white flex flex-col gap-4 justify-center items-center mb-[35px]"
          }
        >
          <Lock />
          <div className="text-white text-2xl">{t("screenLock.enterPin")}</div>
        </div>
        <div>
          <ControlledOtp name={"pin"} />
          <div className={"text-xs flex mt-8 justify-end"}>
            <div
              onClick={() => {
                setIsForgotPinModalOpen(true);
                setActiveForgotPinStep(1);
                onClose();
                reset();
              }}
              className={"cursor-pointer text-textGray"}
            >
              {t("screenLock.forgotPin")}?
            </div>
          </div>
        </div>
        <div className={"flex flex-col w-full gap-[1rem] mt-[2rem]"}>
          <Button disabled={!isValid} onClick={onSubmitHandler}>
            {t("common.continue")}
          </Button>
          <Button variant={"secondary"} onClick={onCancel}>
            {t("common.cancel")}
          </Button>
        </div>
      </AuthCardContent>
    </AuthCard>
  );
};

const EnterPinCodeDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={codeValidationSchema}>
      <Modal isOpen={isOpen}>
        <EnterPinCodeDialogForm onClose={onClose} />
      </Modal>
    </ControlledForm>
  );
};

export default EnterPinCodeDialog;
