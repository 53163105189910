const BellButton = ({ className }: { className?: string }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.6367 8.65C18.6367 6.95261 18.0046 5.32475 16.8793 4.12452C15.7541 2.92428 14.228 2.25 12.6367 2.25C11.0454 2.25 9.5193 2.92428 8.39408 4.12452C7.26886 5.32475 6.63672 6.95261 6.63672 8.65C6.63672 16.1167 3.63672 18.25 3.63672 18.25H21.6367C21.6367 18.25 18.6367 16.1167 18.6367 8.65Z"
        stroke="url(#paint0_linear_1321_65690)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3662 21.25C14.1904 21.5531 13.9381 21.8047 13.6344 21.9795C13.3308 22.1544 12.9866 22.2465 12.6362 22.2465C12.2858 22.2465 11.9416 22.1544 11.638 21.9795C11.3344 21.8047 11.082 21.5531 10.9062 21.25"
        stroke="url(#paint1_linear_1321_65690)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1321_65690"
          x1="3.63672"
          y1="10.25"
          x2="21.6367"
          y2="10.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.75" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1321_65690"
          x1="10.9062"
          y1="21.7483"
          x2="14.3662"
          y2="21.7482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.75" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BellButton;
