const AddReferralButton = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1831 8.92578H8.18311V14.9258H6.18311V8.92578H0.183105V6.92578H6.18311V0.925781H8.18311V6.92578H14.1831V8.92578Z"
        fill="url(#paint0_linear_3936_9149)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3936_9149"
          x1="0.183105"
          y1="7.92578"
          x2="14.1831"
          y2="7.92578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.75" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AddReferralButton;
