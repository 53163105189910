import { FC } from "react";

import logoButton from "../../../public/assets/logo-button.png";
import maintenance from "../../../public/assets/maintenance.png";

const Maintenance: FC = () => {
  return (
    <>
      <div
        className={
          "bg-background h-full w-screen flex flex-col items-center overflow-hidden relative"
        }
      >
        <div className={"pt-8 flex flex-col items-center gap-8 max-w-[268px]"}>
          <img
            src={logoButton}
            alt="logo button"
            className=" w-14 h-14 lg:w-[89px] lg:h-[87px] "
          />
          <img
            src={maintenance}
            alt="maintenance"
            className="maintenance-mobile"
          />
          <h1 className="text-3xl font-semibold text-white text-center">
            We’ll Be Right Back
          </h1>
          <p className="text-1xl text-white text-center">
            Our website is currently under maintenance as we work on improving
            your user experience.
          </p>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
