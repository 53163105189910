const OnboardingBtc = () => {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="41.75"
        cy="41.75"
        r="39"
        fill="url(#paint0_linear_4271_52420)"
        fillOpacity="0.7"
      />
      <path
        opacity="0.39"
        d="M41.25 71.75C57.8185 71.75 71.25 58.3185 71.25 41.75C71.25 25.1815 57.8185 11.75 41.25 11.75C24.6815 11.75 11.25 25.1815 11.25 41.75C11.25 58.3185 24.6815 71.75 41.25 71.75Z"
        fill="url(#paint1_radial_4271_52420)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.0972 38.3328C53.6166 34.7947 50.9692 32.8927 47.3492 31.6238L48.5234 26.831L45.6555 26.1039L44.5123 30.7705C43.7594 30.5793 42.9853 30.399 42.216 30.2204L43.3674 25.5231L40.502 24.796L39.3269 29.5872C38.703 29.4426 38.0906 29.2997 37.4961 29.1493L37.4993 29.1343L33.5454 28.1297L32.7827 31.2457C32.7827 31.2457 34.9099 31.7418 34.865 31.7726C36.0262 32.0675 36.2369 32.8495 36.201 33.4693L34.8634 38.9294C34.9434 38.9502 35.0471 38.9801 35.1614 39.0266L34.8593 38.9502L32.9836 46.599C32.8415 46.9579 32.4813 47.4964 31.6696 47.292C31.6982 47.3343 29.5857 46.7627 29.5857 46.7627L28.1624 50.103L31.8942 51.0495C32.3155 51.1569 32.7316 51.2674 33.1432 51.3766L33.1434 51.3767L33.1439 51.3768C33.4103 51.4475 33.6747 51.5177 33.9373 51.5862L32.7508 56.4348L35.6146 57.1618L36.7905 52.3657C37.572 52.5817 38.3315 52.7811 39.0746 52.9689L37.9036 57.7427L40.7706 58.4697L41.9572 53.6312C46.8461 54.5726 50.5233 54.1929 52.0691 49.6942C53.3161 46.0713 52.0079 43.9815 49.4356 42.6179C51.3089 42.1767 52.7199 40.9228 53.0964 38.3328H53.0972ZM46.5456 47.68C45.7317 51.0051 40.6157 49.6293 38.277 49.0004C38.0676 48.944 37.8804 48.8937 37.7206 48.8533L39.295 42.431C39.4903 42.4806 39.729 42.5351 39.9993 42.5968C42.4188 43.1493 47.3772 44.2814 46.5464 47.68H46.5456ZM40.4855 39.6166C42.4369 40.1463 46.6905 41.3011 47.4315 38.2804C48.1891 35.1903 44.0529 34.2589 42.0328 33.804C41.8057 33.7529 41.6054 33.7077 41.4417 33.6663L40.0143 39.4911C40.1494 39.5254 40.3079 39.5684 40.4855 39.6166Z"
        fill="#F7931A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.0972 38.3328C53.6166 34.7947 50.9692 32.8927 47.3492 31.6238L48.5234 26.831L45.6555 26.1039L44.5123 30.7705C43.7594 30.5793 42.9853 30.399 42.216 30.2204L43.3674 25.5231L40.502 24.796L39.3269 29.5872C38.703 29.4426 38.0906 29.2997 37.4961 29.1493L37.4993 29.1343L33.5454 28.1297L32.7827 31.2457C32.7827 31.2457 34.9099 31.7418 34.865 31.7726C36.0262 32.0675 36.2369 32.8495 36.201 33.4693L34.8634 38.9294C34.9434 38.9502 35.0471 38.9801 35.1614 39.0266L34.8593 38.9502L32.9836 46.599C32.8415 46.9579 32.4813 47.4964 31.6696 47.292C31.6982 47.3343 29.5857 46.7627 29.5857 46.7627L28.1624 50.103L31.8942 51.0495C32.3155 51.1569 32.7316 51.2674 33.1432 51.3766L33.1434 51.3767L33.1439 51.3768C33.4103 51.4475 33.6747 51.5177 33.9373 51.5862L32.7508 56.4348L35.6146 57.1618L36.7905 52.3657C37.572 52.5817 38.3315 52.7811 39.0746 52.9689L37.9036 57.7427L40.7706 58.4697L41.9572 53.6312C46.8461 54.5726 50.5233 54.1929 52.0691 49.6942C53.3161 46.0713 52.0079 43.9815 49.4356 42.6179C51.3089 42.1767 52.7199 40.9228 53.0964 38.3328H53.0972ZM46.5456 47.68C45.7317 51.0051 40.6157 49.6293 38.277 49.0004C38.0676 48.944 37.8804 48.8937 37.7206 48.8533L39.295 42.431C39.4903 42.4806 39.729 42.5351 39.9993 42.5968C42.4188 43.1493 47.3772 44.2814 46.5464 47.68H46.5456ZM40.4855 39.6166C42.4369 40.1463 46.6905 41.3011 47.4315 38.2804C48.1891 35.1903 44.0529 34.2589 42.0328 33.804C41.8057 33.7529 41.6054 33.7077 41.4417 33.6663L40.0143 39.4911C40.1494 39.5254 40.3079 39.5684 40.4855 39.6166Z"
        fill="url(#paint2_linear_4271_52420)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4271_52420"
          x1="2.75"
          y1="45.2926"
          x2="80.75"
          y2="45.2926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07252A" />
          <stop offset="0.5" stopColor="#26616C" />
          <stop offset="1" stopColor="#07252A" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_4271_52420"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.2568 41.7438) scale(37.2774)"
        >
          <stop stopColor="#11639D" />
          <stop offset="0.99" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_4271_52420"
          x1="28.1624"
          y1="24.796"
          x2="55.0184"
          y2="26.5299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.745543" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OnboardingBtc;
