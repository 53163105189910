const Lock = () => {
  return (
    <svg
      width="25"
      height="35"
      viewBox="0 0 25 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5096 14.2505V9.71895C22.4359 4.31053 18.1254 0 12.7906 0C7.45589 0 3.14904 4.31053 3.14904 9.64526V14.1768C1.83378 14.1768 0.80957 15.2011 0.80957 16.5163V32.6642C0.80957 33.9795 1.83378 35.0037 3.14904 35.0037H22.5133C23.8285 35.0037 24.8527 33.9795 24.8527 32.6642V16.5863C24.779 15.2711 23.7548 14.2505 22.5133 14.2505H22.5096ZM13.7964 28.9358H11.829C11.1843 28.9358 10.6906 28.3574 10.7975 27.72L11.258 24.9163C10.5985 24.4226 10.219 23.5532 10.3259 22.6284C10.4475 21.6079 11.2064 20.7458 12.1975 20.4768C13.8554 20.0237 15.4212 21.3389 15.4212 22.9453C15.4212 23.7484 15.0564 24.4779 14.4706 24.92L14.8317 27.7605C14.909 28.3832 14.4227 28.9358 13.7927 28.9358H13.7964ZM19.3669 14.2505H6.21799V9.71895C6.21799 6.13789 9.13957 3.14263 12.7943 3.14263C16.449 3.14263 19.3706 6.06421 19.3706 9.71895V14.2505H19.3669Z"
        fill="#26616C"
      />
    </svg>
  );
};

export default Lock;
