import { Switch } from "@ns/client-ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { getNotificationsByUserId } from "../../graphql/api.ts";
import { useOutsideClick } from "../../hooks/use-outside-click.ts";
import { usePagination } from "../../hooks/use-pagination.ts";
import { api } from "../api/client-api.ts";
import {
  groupNotificationsByDate,
  GroupNotificationsType,
} from "../helpers/group-notifications-helper.ts";
import { useDialogContext } from "../store/dialog-store.ts";
import useUserStore from "../store/user-store.ts";

import { NotificationList } from "./notification-list.tsx";
import BackButton from "./svg-components/back-button.tsx";

import stealDealPng from "/assets/no-data.jpeg";

type NotificationPopupProps = {
  className?: string;
};

export type NotificationResponse = {
  userId: string;
  createdAt: string;
  notification: string;
  title: string;
  type: string;
};

const NotificationPopup = ({ className }: NotificationPopupProps) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { user } = useUserStore();
  const { t } = useTranslation();
  const { toggleNotificationOpen, isNotificationOpen, bellRef } =
    useDialogContext();

  const { data, lastItemRef, isFetchingNextPage } =
    usePagination<NotificationResponse>({
      queryKey: ["getNotificationsByUserId"],
      queryFn: (offset, limit) =>
        getNotificationsByUserId({ userId: user!.id!, offset, limit }),
      enabled: !!user?.id,
      limit: 30,
      isGraphql: true,
    });

  const { data: setup, refetch } = useQuery({
    queryKey: ["setup"],
    queryFn: () => api.notificationsSetup.getNotificationsSetup(),
    enabled: !!user?.id,
  });

  const { mutate } = useMutation({
    mutationFn: () =>
      api.notificationsSetup.updateNotificationsSetup({
        sendNotification: !setup?.sendNotification,
      }),
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (isNotificationOpen) {
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: "instant" });
      }
    }
  }, [isNotificationOpen]);

  const handleCloseNotification = (): void => {
    if (isNotificationOpen) {
      toggleNotificationOpen();
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: "instant" });
      }
    }
  };

  // @ts-ignore
  useOutsideClick([popupRef, bellRef], () => {
    if (isNotificationOpen) {
      toggleNotificationOpen();
    }
  });

  const groupedNotifications = useMemo(() => {
    return groupNotificationsByDate(data) as GroupNotificationsType;
  }, [data]);

  return (
    <div
      className={`w-full h-full top-0 right-0 sm:top-[7rem] xl:top-[6.5rem] sm:right-4 bg-backgroundDeepTeal absolute z-[1000000001] transform sm:w-64 md:w-30 transition-transform duration-200 ease-in-out ${
        isNotificationOpen ? "scale-100 opacity-100" : "scale-0 opacity-0"
      } flex-1 text-white p-[16px] overflow-hidden shadow-2xl sm:h-[calc(100vh-7.5rem)] ${className}`}
      ref={popupRef}
    >
      <div className="flex justify-around items-center p-4">
        <BackButton onClick={handleCloseNotification} />
        <span className="avenir-semibold text-[24px] text-center">
          {t("common.notifications")}
        </span>
        <Switch
          onClick={() => mutate()}
          checked={setup?.sendNotification}
          className={setup?.sendNotification ? `bg-[#00F0FF]` : "bg-secondary"}
        />
      </div>
      <div
        ref={containerRef}
        className={`flex flex-col items-center gap-4 pb-[5rem] ${
          data.length ? "overflow-auto" : ""
        } h-full`}
      >
        {data.length === 0 && (
          <div
            className={"mt-[120px] flex flex-col justify-center items-center"}
          >
            <img className="w-[200px] h-auto" src={stealDealPng} alt="logo" />
            {setup?.sendNotification ? (
              <div className={"text-lg text-textGray7"}>
                {t("notificationPopup.thereAreNo")}
              </div>
            ) : (
              <div
                className={
                  "text-center flex flex-col justify-center items-center"
                }
              >
                <div className={"text-lg text-textGray7 mb-8"}>
                  {t("notificationPopup.notificationsDisabled")}
                </div>
                <div className={"max-w-[170px]"}>
                  {t("notificationPopup.turnOn")}
                </div>
              </div>
            )}
          </div>
        )}
        {data && groupedNotifications && (
          <>
            <NotificationList
              items={groupedNotifications.today}
              title={t("notification.today")}
              lastItemRef={lastItemRef}
              handleCloseNotification={handleCloseNotification}
              isFetchingNextPage={isFetchingNextPage}
            />
            <NotificationList
              items={groupedNotifications.yesterday}
              title={t("notification.yesterday")}
              lastItemRef={lastItemRef}
              handleCloseNotification={handleCloseNotification}
              isFetchingNextPage={isFetchingNextPage}
            />
            <NotificationList
              items={groupedNotifications.thisWeek}
              title={t("notification.thisWeek")}
              lastItemRef={lastItemRef}
              handleCloseNotification={handleCloseNotification}
              isFetchingNextPage={isFetchingNextPage}
            />
            <NotificationList
              items={groupedNotifications.lastWeek}
              title={t("notification.lastWeek")}
              lastItemRef={lastItemRef}
              handleCloseNotification={handleCloseNotification}
              isFetchingNextPage={isFetchingNextPage}
            />
            <NotificationList
              items={groupedNotifications.thisMonth}
              title={t("notification.thisMonth")}
              lastItemRef={lastItemRef}
              handleCloseNotification={handleCloseNotification}
              isFetchingNextPage={isFetchingNextPage}
            />
            <NotificationList
              items={groupedNotifications.older}
              title={t("notification.older")}
              lastItemRef={lastItemRef}
              handleCloseNotification={handleCloseNotification}
              isFetchingNextPage={isFetchingNextPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationPopup;
