import CardData, { CardDataType } from "./card-data";

const CardList = ({ data }: { data: CardDataType[] }) => {
  return (
    <div className="flex flex-col lg:flex-row lg:flex-wrap lg:w-full lg:pl-0 lg:justify-center items-center justify-center gap-[20px] lg:gap-[32px]">
      {data.map((element, i) => (
        <CardData
          title={element.title}
          value={element.value}
          svg={element.svg}
          key={i}
        />
      ))}
    </div>
  );
};

export default CardList;
