import { FC } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

import { IconPicture } from './icon-picture';

type Props = {
  dragAndDropText: string;
  browseText: string;
  maxFileSizeText: string;
  errorText: string;
  maxFileSize: string;
  errors: FieldErrors<FieldValues>;
  isUploadError: boolean;
};

export const UploadImageIdle: FC<Props> = ({
  dragAndDropText,
  browseText,
  maxFileSizeText,
  errorText,
  maxFileSize,
  isUploadError,
}) => {
  return (
    <div className={'text-center flex flex-col px-0 py-2'}>
      <div className={'flex flex-col items-center gap-4'}>
        <IconPicture />
        <div className={'flex flex-col items-center gap-1'}>
          <div className={'flex flex-row gap-[0.4rem]'}>
            <span className={'text-[16px] text-white'}>{dragAndDropText}</span>
            <span className={'gold-text-clear text-[16px] cursor-pointer'}>{browseText}</span>
          </div>
          {isUploadError ? (
            <div className={'flex flex-row gap-[0.4rem]'}>
              <span className={'text-red-600'}>{errorText}</span>
              <span className={'text-red-600'}>{maxFileSize}</span>
            </div>
          ) : (
            <span className={'text-textGray text-[14px]'}>{maxFileSizeText}</span>
          )}
        </div>
      </div>
    </div>
  );
};
