import React, { FC } from 'react';

export interface TextareaProps {
  value: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  label?: string;
  color?: string;
  errors?: any;
  disabled?: boolean;
  placeholder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const Textarea: FC<TextareaProps> = ({
  onChange,
  onKeyDown,
  placeholder,
  value,
  className,
  disabled = false,
}) => {
  return (
    <textarea
      onChange={onChange}
      value={value}
      className={`${className} focus:outline-none focus:border-transparent resize-none p-4 text-white w-full h-full `}
      disabled={disabled}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
    />
  );
};
