const SendMessageIcon = ({
  onClick,
  isDisabled,
  className,
}: {
  onClick: () => void;
  isDisabled: boolean;
  className?: string;
}) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect
        y="0.882812"
        width="40"
        height="40"
        rx="20"
        fill="url(#paint0_linear_2265_9875)"
      />
      <g clipPath="url(#clip0_2265_9875)">
        <path
          d="M26.5 14.3828L11.5 19.3828L18.6875 22.1953M26.5 14.3828L21.5 29.3828L18.6875 22.1953M26.5 14.3828L18.6875 22.1953"
          stroke={isDisabled ? "#BDBDBD" : "black"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2265_9875"
          x1="-1.49012e-07"
          y1="20.8828"
          x2="40"
          y2="20.8828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.75" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
        <clipPath id="clip0_2265_9875">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(10 12.8828)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendMessageIcon;
