import Cookies from "js-cookie";

const SESSION_TOKEN = "session";
const CODE_VERIFIED = "code";

export function removeSession() {
  return Cookies.remove(SESSION_TOKEN);
}

export function getSessionToken() {
  return Cookies.get(SESSION_TOKEN);
}

export function setCodeVerified(isVerified: boolean) {
  Cookies.set(CODE_VERIFIED, isVerified.toString(), {
    sameSite: "none",
    secure: true,
  });
}

export function getCodeVerified() {
  return Cookies.get(CODE_VERIFIED);
}

export function removeCodeVerified() {
  Cookies.remove(CODE_VERIFIED);
}
