import { ReactNode } from 'react';

import { Card } from './card';

const CardGradient = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div className={'relative sm:max-w-[200px] w-60 md:max-w-[300px] rounded-lg'}>
      <div className="rounded-lg absolute card-border z-10" />
      <Card
        className={`w-full h-full rounded-lg max-w-[300px] border-[1px] border-solid border-tabBorderColor flex-1 text-white p-[16px] relative bg-backgroundDeepTeal overflow-hidden z-20 ${className}`}
      >
        <div className="blur-[50px] w-14 h-14 absolute right-0 top-0" />
        {children}
      </Card>
    </div>
  );
};

export default CardGradient;
