import { Badge, Card, CardContent } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { environmentVariables } from "../../env/enviroment-variables.ts";

export type MyBidData = {
  roundNumber: string;
  roundStatus: string;
  bid: string;
  paymentStatus: string;
  currency: "BTC" | "ETH";
};

const MyBidsCard = ({
  roundNumber,
  roundStatus,
  bid,
  paymentStatus,
  currency,
}: MyBidData) => {
  const { t } = useTranslation();

  const handleBadgeVariant = () => {
    switch (paymentStatus) {
      case "CONFIRMED":
        return "success";
      case "FAILED":
        return "destructive";
      case "PENDING":
        return "secondary";
      default:
        return "default";
    }
  };

  return (
    <Card className="h-full bg-transparent w-full p-[16px] border-[1px] border-solid border-tabBorderColor rounded text-bidCardText">
      <CardContent className="flex flex-col p-0">
        <div className={"hidden sm:block"}>
          <div className="flex justify-between text-[16px]">
            <span className="w-1/5 text-center">
              {`${t("bidCard.round")} ${roundNumber}`}
            </span>
            <span className="w-1/5 text-center">
              {t("myBidsCard.roundStatus")}
            </span>
            <span className="w-1/5 text-center">{t("myBidsCard.myBid")}</span>
            <span className="w-1/5 text-center">{t("myBidsCard.reward")}</span>
            <span className="w-1/5 text-center">{t("myBidsCard.payment")}</span>
          </div>
          <div className="border-b border-tabBorderColor my-4"></div>
          <div className="flex justify-between text-[14px]">
            <div className="w-1/5 flex justify-center items-center gap-2">
              <img
                src={CURRENCY_IMAGE[currency as keyof typeof CURRENCY_IMAGE]}
                alt="Currency"
                className="w-[18px]"
              />
              <span className="w-1/5 text-center">{currency}</span>
            </div>
            <span className="w-1/5 text-center">
              {t(`common.status.${roundStatus}`)}
            </span>
            <span className="w-1/5 text-center">
              {bid} {environmentVariables.fiatCurrency}
            </span>
            <span className="w-1/5 text-center">{`1 ${currency}`}</span>
            <div className="w-1/5 text-center">
              <Badge variant={handleBadgeVariant()}>
                {t(`common.${paymentStatus}`)}
              </Badge>
            </div>
            {/*<span*/}
            {/*  className="w-1/5 text-center cursor-pointer"*/}
            {/*  onClick={invoiceUrl ? () => window.open(invoiceUrl) : undefined}*/}
            {/*>*/}
            {/*  {invoiceUrl ? t("myBidsCard.invoice") : "/"}*/}
            {/*</span>*/}
          </div>
        </div>

        {/*MOBILE CARD*/}
        <div className={"h-full w-full sm:hidden"}>
          <div className="flex w-full flex-col justify-between text-[16px]">
            <div className={"flex justify-between"}>
              <span>{`${t("bidCard.round")} ${roundNumber}`}</span>
              <div className="flex justify-center items-center gap-2">
                <img
                  src={CURRENCY_IMAGE[currency as keyof typeof CURRENCY_IMAGE]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span>{currency}</span>
              </div>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.roundStatus")}</span>
              <span>{t(`common.status.${roundStatus}`)}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.myBid")}</span>
              <span>
                {bid} {environmentVariables.fiatCurrency}
              </span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.reward")}</span>
              <span>{`1 ${currency}`}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span className="w-1/5 text-center">
                {t("myBidsCard.payment")}
              </span>
              <span>
                <Badge variant={handleBadgeVariant()}>
                  {t(`common.${paymentStatus}`)}
                </Badge>
              </span>
              {/*<span*/}
              {/*  className="cursor-pointer"*/}
              {/*  onClick={invoiceUrl ? () => window.open(invoiceUrl) : undefined}*/}
              {/*>*/}
              {/*  {invoiceUrl ? t("myBidsCard.invoice") : "/"}*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MyBidsCard;
