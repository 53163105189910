import { TFunction } from "i18next";

export const getCurrencyLimit = (currency: string) =>
  currency === "BTC" ? 100 : 10;

export const validateBidValue = (
  value: string,
  currency: "BTC" | "ETH",
  t: TFunction,
) => {
  if (!value || value === "") {
    return t("common.errors.required");
  }

  const sanitizedValue = value.replace(/,/g, ".");
  const currencyLimit = getCurrencyLimit(currency);

  const patternsAndErrors = [
    { pattern: /^0\d+$/, error: t("common.errors.missingDot") },
    { pattern: /^\d+$/, error: t("common.errors.missingTwoDecimals") },
    { pattern: /^\d+\.\d$/, error: t("common.errors.missingSecondDecimal") },
    {
      pattern: /^\.\d{2}$/,
      error: t("common.errors.missingFrontValue"),
    },
    { pattern: /^\.\d$/, error: t("common.errors.missingFrontValue") },
    { pattern: /^0\.\d$/, error: t("common.errors.missingSecondDecimal") },
    { pattern: /^\d+\.$/, error: t("common.errors.cantBid") },
    {
      pattern: /^\d+\.\d{3,}$/,
      error: t("common.errors.maximumDecimals"),
    },
  ];

  if (
    !isNaN(parseFloat(sanitizedValue)) &&
    parseFloat(sanitizedValue) >= currencyLimit
  ) {
    return `${t("common.errors.bidBetween")}${currencyLimit} USDT.`;
  }

  for (const { pattern, error } of patternsAndErrors) {
    if (pattern.test(sanitizedValue)) {
      return error;
    }
  }

  const numericValue = parseFloat(sanitizedValue);
  if (
    isNaN(numericValue) ||
    numericValue < 0.01 ||
    numericValue > currencyLimit
  ) {
    return `${t("common.errors.bidBetween")}${currencyLimit} USDT.`;
  }

  return true;
};
