import btcNotification from "/svg/btc-notification.svg";
import ethNotification from "/svg/eth-notification.svg";
import strategyNotification from "/svg/strategy-notification.svg";
import winnerNotification from "/svg/winner-notification.svg";
import referralNotification from "/svg/referral-notification.svg";
import btcClosed from "/svg/btc-closed.svg";
import ethClosed from "/svg/eth-closed.svg";

import { NotificationType } from "../enums/notification-type.enum";

import giftNotification from "/svg/gift-notification.svg";
import networkNotification from "/svg/network-notification.svg";
import pricesNotification from "/svg/prices-notification.svg";

export const notificationIconHelper = (type: string) => {
  switch (type) {
    case NotificationType.ROUND_START_BTC:
      return btcNotification;
    case NotificationType.ROUND_FINISH_BTC:
      return btcClosed;
    case NotificationType.ROUND_START_ETH:
      return ethNotification;
    case NotificationType.ROUND_FINISH_ETH:
      return ethClosed;
    case NotificationType.ROUND_WINNER_BTC:
      return winnerNotification;
    case NotificationType.ROUND_WINNER_ETH:
      return winnerNotification;
    case NotificationType.ROUND_WINNER_REFERRAL_BTC:
      return winnerNotification;
    case NotificationType.ROUND_WINNER_REFERRAL_ETH:
      return winnerNotification;
    case NotificationType.STRATEGY:
      return strategyNotification;
    case NotificationType.REFERRAL_PROGRAM:
      return referralNotification;
    case NotificationType.FREE_ROUNDS:
      return giftNotification;
    case NotificationType.PAYMENT_NETWORKS:
      return networkNotification;
    case NotificationType.PRICES:
      return pricesNotification;
    case NotificationType.PAYMENT_SUCCESSFUL:
      return networkNotification;
    default:
      return strategyNotification;
  }
};
