import { TFunction } from "i18next";
import * as z from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";

export const sendSupportMessageSchema = (t: TFunction) =>
  z.object({
    message: z.string(),
    email: z.string().email({ message: t("form.authentication.invalidEmail") }),
    name: z.string(),
  });

const schema = sendSupportMessageSchema(staticTranslationFunction);

export type SendSupportMessageSchema = z.infer<typeof schema>;
