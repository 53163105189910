import { Tabs, TabsContent, TabsList, TabsTrigger } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import btcSvg from "/svg/btc-svg.svg";
import ethSvg from "/svg/eth-svg.svg";

import { VideoPlayer } from "../../components/video-player.tsx";

export const WatchDemo = () => {
  const { t } = useTranslation();

  return (
    <Tabs
      className="overflow-y-auto flex flex-col w-full p-4 sm:p-[4rem] overflow-video-mobile"
      defaultValue={"BTC"}
    >
      <div className={"flex flex-col w-full items-center justify-around"}>
        <TabsList className="self-center w-full">
          <TabsTrigger
            value={"BTC"}
            className="rounded-tl-[8px] rounded-bl-[8px]"
          >
            <img src={btcSvg} alt={`btc-icon`} />
            <span>{"BTC"}</span>
          </TabsTrigger>
          <TabsTrigger
            value={"ETH"}
            className="rounded-tr-[8px] rounded-br-[8px]"
          >
            <img src={ethSvg} alt={`eth-icon`} />
            <span>{"ETH"}</span>
          </TabsTrigger>
        </TabsList>
        <TabsContent
          value={"BTC"}
          className="flex flex-col gap-[32px] h-full wifull mt-[60px]"
        >
          <div className="flex flex-col gap-[1.25rem] items-center">
            <span
              className={"text-white avenir-semibold text-[24px] text-center"}
            >
              {t("common.howTo")}
            </span>
            <span className={"text-textGray w-[21rem] text-[14px] text-center"}>
              {t("common.quickGuide")}
            </span>
            <VideoPlayer
              className={"w-[32rem] video-mobile"}
              videoUrl={
                "https://prod-steal-deal.s3.eu-central-1.amazonaws.com/A+Steal+Deal+_+BTC.mp4"
              }
            />
          </div>
        </TabsContent>
        <TabsContent
          value={"ETH"}
          className="flex flex-col gap-[32px] h-full wifull mt-[0px]"
        >
          <div className="flex flex-col gap-[1.25rem] items-center">
            <span
              className={"text-white avenir-semibold text-[24px] text-center"}
            >
              {t("common.howTo")}
            </span>
            <span className={"text-textGray w-[21rem] text-[14px] text-center"}>
              {t("common.quickGuide")}
            </span>
            <VideoPlayer
              className={"w-[32rem] video-mobile"}
              videoUrl={
                "https://prod-steal-deal.s3.eu-central-1.amazonaws.com/A+Steal+Deal+_+ETH.mp4"
              }
            />
          </div>
        </TabsContent>
      </div>
    </Tabs>
  );
};
