import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import PopupOverlay from './popup-overlay';

type Props = {
  isOpen: boolean;
  children: ReactNode | ReactNode[];
};

const Modal = ({ isOpen, children }: Props) => {
  return createPortal(<>{isOpen && <PopupOverlay>{children}</PopupOverlay>}</>, document.body);
};

export default Modal;
