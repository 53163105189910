import { Ref } from "react";

import FlatList from "./flat-list.tsx";
import { NotificationCard } from "./notification-card.tsx";
import { NotificationResponse } from "./notification-popup.tsx";

export const NotificationList = ({
  items,
  title,
  lastItemRef,
  handleCloseNotification,
  isFetchingNextPage,
}: {
  items: NotificationResponse[];
  title: string;
  isFetchingNextPage: boolean;
  lastItemRef: Ref<HTMLDivElement>;
  handleCloseNotification: () => void;
}) => {
  return (
    <>
      {items.length > 0 && (
        <div className="w-full">
          <div className="pb-3">
            <span className="text-white text-[14px] avenir-semibold">
              {title}
            </span>
          </div>
          <FlatList<NotificationResponse>
            data={items}
            isFetching={isFetchingNextPage}
            keyExtractor={(item: NotificationResponse) => item.createdAt}
            ref={lastItemRef}
            renderItem={(notification: NotificationResponse) => (
              <NotificationCard
                item={notification}
                handleCloseNotification={handleCloseNotification}
              />
            )}
          />
        </div>
      )}
    </>
  );
};
