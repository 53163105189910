const WizzardTabs = ({
  tabsNumber,
  index,
}: {
  tabsNumber: number;
  index: number;
}) => {
  return (
    <div className="flex gap-[12px] items-center justify-center">
      {Array.from({ length: tabsNumber }, (_, tabIndex) => (
        <div
          key={tabIndex}
          className={`h-[2px] ${
            index === tabIndex ? "bg-gradientGold" : "bg-slate-500"
          } w-full rounded-[3px]`}
        />
      ))}
    </div>
  );
};

export default WizzardTabs;
