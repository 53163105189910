import { Switch } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import chevron from "/svg/chevron.svg";
import profile from "/svg/profile.svg";
import myBids from "/svg/my-bids.svg";
import allRounds from "/svg/all-rounds.svg";
import myReferral from "/svg/my-referral.svg";
import settings from "/svg/settings.svg";
import addWallet from "/svg/add-wallet.svg";
import help from "/svg/help.svg";
import logout from "/svg/logout.svg";
import logo from "/svg/small-logo.svg";
import trash from "/svg/trash.svg";

import { useLocation, useNavigate } from "react-router-dom";

import { useOutsideClick } from "../../hooks/use-outside-click.ts";
import { api } from "../api/client-api";
import { removeCodeVerified } from "../api/token-helper.ts";
import { environmentVariables } from "../env/enviroment-variables.ts";
import { setLocalstorageItem } from "../helpers/localstorage.ts";
import { uploadImageSchema } from "../schemas/form/upload-image-schema";
import { useAuthContext } from "../store/auth-store.ts";
import { useDialogContext } from "../store/dialog-store.ts";
import useUserStore from "../store/user-store";
import { featureWallet } from "../utils/feature-flags/feature-flags.ts";

import AddReferralDialog from "./dialogs/add-referral-dialog";
import ChangeLanguageDialog from "./dialogs/change-language-dialog";
import ChangePasswordDialog from "./dialogs/change-password-dialog.tsx";
import ChangePinDialog from "./dialogs/change-pin-dialog.tsx";
import { DeleteAccountDialog } from "./dialogs/delete-account-dialog.tsx";
import LockScreenDialog from "./dialogs/lock-screen-dialog.tsx";
import NewPinDialog from "./dialogs/new-pin-dialog.tsx";
import PinChangeSuccessDialog from "./dialogs/pin-change-success-dialog.tsx";
import PinSetSuccessDialog from "./dialogs/pin-set-success-dialog.tsx";
import UploadImageDialog from "./dialogs/upload-image-dialog";
import ExpandableItem from "./expandable-item";
import ExpandableSimpleMenu from "./expandable-simple-menu.tsx";
import ExpandableUserProfile from "./expandable-user-profile";
import ControlledForm from "./form/controlled-form";

const SideMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { setUser, user, selectedItem, setSelectedItem } = useUserStore();
  const { imageUrl, username } = user || {};

  const [passwordDialog, setPasswordDialog] = useState<boolean>(false);
  const [settingsMenu, setSettingsMenu] = useState<boolean>(false);
  const [profileMenu, setProfileMenu] = useState<boolean>(false);
  const [supportMenu, setSupportMenu] = useState<boolean>(false);
  const [languageDialog, setLanguageDialog] = useState<boolean>(false);
  const [addReferralDialog, setAddReferralDialog] = useState<boolean>(false);
  const [uploadImageDialog, setUploadImageDialog] = useState<boolean>(false);
  const [isLockScreenDialogOpen, setIsLockScreenDialogOpen] =
    useState<boolean>(false);
  const [isChangePinDialogOpen, setIsChangePinDialogOpen] =
    useState<boolean>(false);
  const [isNewPinDialogOpen, setIsNewPinDialogOpen] = useState<boolean>(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] =
    useState<boolean>(false);
  const [isSuccessSetDialogOpen, setIsSuccessSetDialogOpen] =
    useState<boolean>(false);

  const containerRef = useRef(null);
  const location = useLocation();

  const { setIsDeleteAccountDialogOpen } = useDialogContext();

  const [previousItem, setPreviousItem] = useState<string | undefined | null>(
    null,
  );

  useOutsideClick([containerRef], () => {
    setIsOpen(false);
    setSettingsMenu(false);
    setProfileMenu(false);
    if (selectedItem === "profile") {
      setSelectedItem(previousItem);
    }
  });

  const { data: activePinCode, refetch: refetchPinCode } = useQuery({
    queryKey: ["pinCode"],
    queryFn: () => api.pinCode.getPinCode(),
    enabled: !!user?.id,
  });

  const { mutate: togglePinCode } = useMutation({
    mutationFn: () => api.pinCode.togglePinCodeActivity(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pinCode"] });
      refetchPinCode();
    },
  });

  const { setIsLoginModalOpen } = useAuthContext();

  const togglePasswordDialog = (): void => {
    setPasswordDialog((password) => !password);
  };

  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
    settingsMenu && setSettingsMenu(false);
    profileMenu && setProfileMenu(false);
    if (selectedItem === "profile" && location.pathname !== "/referral") {
      setSelectedItem(previousItem);
    }
  };

  const toggleLanguageDialog = () => {
    setLanguageDialog((dialog) => !dialog);
  };

  const toggleSettingsDropdown = () => {
    !isOpen && toggleMenu();
    if (selectedItem === "settings") {
      setSelectedItem(previousItem);
    } else {
      setPreviousItem(selectedItem);
      setSelectedItem("settings");
    }
    setSettingsMenu((prev) => !prev);
  };

  const toggleSupportDropdown = () => {
    !isOpen && toggleMenu();
    setSupportMenu((prev) => !prev);
  };

  const toggleProfileDropdown = () => {
    !isOpen && toggleMenu();
    if (selectedItem === "profile") {
      setSelectedItem(previousItem);
    } else {
      setPreviousItem(selectedItem);
      setSelectedItem("profile");
    }
    setProfileMenu((prev) => !prev);
  };

  const toggleAddReferralDialog = () => {
    if (selectedItem === "referral") {
      setSelectedItem(previousItem);
    } else {
      setPreviousItem(selectedItem);
      setSelectedItem("referral");
    }
    setAddReferralDialog((dialog) => !dialog);
  };

  const toggleUploadImageDialog = () => {
    setUploadImageDialog((dialog) => !dialog);
  };

  const onLogout = async () => {
    try {
      await axios
        .put(
          `${environmentVariables.authServiceUrl}/session/logout`,
          {},
          { withCredentials: true },
        )
        .then(() => {
          setUser(null);
          removeCodeVerified();
          navigate("/", { replace: true });
          queryClient.invalidateQueries();
          queryClient.removeQueries();
          setLocalstorageItem("betaModal", "true");
          setSelectedItem(undefined);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const { data: setup, refetch } = useQuery({
    queryKey: ["setup"],
    queryFn: () => api.notificationsSetup.getNotificationsSetup(),
    enabled: !!user?.id,
  });

  const { mutate } = useMutation({
    mutationFn: () =>
      api.notificationsSetup.updateNotificationsSetup({
        sendNotification: !setup?.sendNotification,
      }),
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      Sentry.withScope((scope) => {
        scope.setTag("update-notifications-setup", "basic");
        Sentry.captureException(error);
      });
    },
  });

  const onLockScreenHandler = () => {
    if (activePinCode) {
      togglePinCode();
    } else {
      setIsLockScreenDialogOpen((val: boolean) => !val);
    }
  };

  const toggleChangePinDialog = () => {
    if (!activePinCode) {
      setIsLockScreenDialogOpen((val: boolean) => !val);
    } else {
      setIsSuccessSetDialogOpen((value) => !value);
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        className={`hidden sm:block absolute left-0 bottom-0 transform z-30 transition-all duration-300 ease-in-out`}
        style={{ height: "calc(100% - 200px)" }}
      >
        <div
          onClick={toggleMenu}
          className="absolute right-[-10px] top-[60px] transform -translate-y-1/2 cursor-pointer z-50 rounded-full w-[24px] h-[24px] flex justify-center items-center bg-gray-600"
        >
          <img
            src={chevron}
            className={`absolute right-0 top-1/2 transform -translate-y-1/2 transition-transform duration-300 ease-in-out ${
              !isOpen ? "rotate-[-90deg]" : "rotate-[90deg]"
            }`}
            alt="Toggle Chevrons"
          />
        </div>
        <div
          className={`overflow-y-auto relative flex h-full text-white text-base flex-col gap-5 justify-starrt items-center bg-sideMenuBg z-10 rounded-tr-[20px] w-full sm:p-2 lg:p-8`}
        >
          <div className="flex flex-col w-full items-center justify-start">
            {user && (
              <ExpandableUserProfile
                imgSrc={imageUrl || ""}
                shouldExpand={isOpen}
                username={username || ""}
                onClick={toggleMenu}
              />
            )}
            {user ? (
              <>
                <div className="w-fit">
                  <ExpandableItem
                    text={t("links.profile")}
                    imgSrc={profile}
                    shouldExpand={isOpen}
                    isActive={profileMenu}
                    onClick={toggleProfileDropdown}
                    isSelected={selectedItem === "profile"}
                    hasMenu={true}
                  />

                  {profileMenu && (
                    <div className="w-full flex flex-col bg-collapsibleBg rounded-[8px] p-[16px] gap-[12px]">
                      <span
                        className="text-white text-[14px] cursor-pointer"
                        onClick={toggleUploadImageDialog}
                      >
                        {t("common.uploadImage")}
                      </span>
                      <span
                        className="text-white text-[14px] cursor-pointer"
                        onClick={togglePasswordDialog}
                      >
                        {t("common.changePassword")}
                      </span>
                      <span
                        className="text-white text-[14px] cursor-pointer"
                        onClick={toggleChangePinDialog}
                      >
                        {t("common.changeScreenLock")}
                      </span>
                      <span
                        className="text-white text-[14px] cursor-pointer"
                        onClick={() => {
                          navigate("/referral");
                          setSelectedItem("profile");
                        }}
                      >
                        {t("links.addReferral")}
                      </span>
                      <div
                        onClick={() => setIsDeleteAccountDialogOpen(true)}
                        className="flex items-center cursor-pointer justify-between w-full"
                      >
                        <span className="text-red-600 text-[14px]">
                          {t("common.deleteAccount")}
                        </span>
                        <img src={trash} alt="Delete" />
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <ExpandableItem
                shouldExpand={isOpen}
                text={t("common.signIn")}
                imgSrc={logo}
                onClick={() => {
                  setIsLoginModalOpen(true);
                }}
              />
            )}
            {user && (
              <ExpandableItem
                text={t("links.myBids")}
                imgSrc={myBids}
                shouldExpand={isOpen}
                onClick={() => {
                  setSelectedItem("myBids");
                  navigate("/bids");
                }}
                isSelected={selectedItem === "myBids"}
              />
            )}

            {user && (
              <ExpandableItem
                text={t("links.myReferrals")}
                imgSrc={myReferral}
                shouldExpand={isOpen}
                onClick={() => {
                  setSelectedItem("myReferrals");
                  navigate("/referrals");
                }}
                isSelected={selectedItem === "myReferrals"}
              />
            )}

            <ExpandableItem
              text={t("links.winners")}
              imgSrc={allRounds}
              shouldExpand={isOpen}
              onClick={() => {
                setSelectedItem("winners");
                navigate("/winners");
              }}
              isSelected={selectedItem === "winners"}
            />

            {user && featureWallet && (
              <ExpandableItem
                text={t("links.addWallet")}
                imgSrc={addWallet}
                shouldExpand={isOpen}
                onClick={() => {
                  navigate("/wallet");
                  setSelectedItem("wallet");
                }}
                isSelected={selectedItem === "wallet"}
              />
            )}

            <div className="w-fit">
              <ExpandableItem
                text={t("links.settings")}
                imgSrc={settings}
                shouldExpand={isOpen}
                isActive={settingsMenu}
                onClick={toggleSettingsDropdown}
                isSelected={selectedItem === "settings"}
                hasMenu={true}
              />
              {settingsMenu && (
                <div className="w-full flex flex-col bg-collapsibleBg rounded-[8px] p-[16px] gap-[12px]">
                  {user && (
                    <div className="flex items-center justify-between w-full">
                      <span className="text-white text-[14px]">
                        {t("common.notifications")}
                      </span>
                      <Switch
                        onClick={() => mutate()}
                        checked={setup?.sendNotification}
                        className={
                          setup?.sendNotification
                            ? `bg-[#00F0FF]`
                            : "bg-secondary"
                        }
                      />
                    </div>
                  )}
                  {user && (
                    <div className="text-white flex items-center justify-between w-full">
                      <span className="text-white text-[14px]">
                        {t("screenLock.screenLock")}
                      </span>
                      <Switch
                        onClick={onLockScreenHandler}
                        checked={activePinCode ? activePinCode.isActive : false}
                        className={
                          activePinCode?.isActive
                            ? `bg-[#00F0FF]`
                            : "bg-secondary"
                        }
                      />
                    </div>
                  )}
                  {environmentVariables.languagesFlag && (
                    <span
                      className="text-white text-[14px] cursor-pointer"
                      onClick={toggleLanguageDialog}
                    >
                      {t("common.languages")}
                    </span>
                  )}
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={() => {
                      navigate("/about-us");
                      setSelectedItem("settings");
                    }}
                  >
                    {t("common.aboutUs")}
                  </span>
                  <ExpandableSimpleMenu
                    text={t("links.support")}
                    shouldExpand={isOpen}
                    onClick={toggleSupportDropdown}
                    isActive={supportMenu}
                  />
                  {supportMenu && (
                    <div className="w-full flex flex-col rounded-[8px] pl-[16px] gap-[12px]">
                      <div className="flex flex-col gap-[0.5rem] justify-between w-full">
                        <span
                          className="text-white text-[14px] cursor-pointer"
                          onClick={() => {
                            navigate("/demo");
                            setSelectedItem("settings");
                          }}
                        >
                          {t("common.demo")}
                        </span>
                        <span
                          className="text-white text-[14px] cursor-pointer"
                          onClick={() => {
                            navigate("/support");
                            setSelectedItem("settings");
                          }}
                        >
                          {t("common.help")}
                        </span>
                      </div>
                    </div>
                  )}
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={() =>
                      window.open("https://www.astealdeal.com/privacypolicy")
                    }
                  >
                    {t("common.privacyPolicy")}
                  </span>
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={() =>
                      window.open("https://www.astealdeal.com/terms-conditions")
                    }
                  >
                    {t("common.termsConditions")}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full items-center justify-end flex-1">
            <ExpandableItem
              text={t("links.help")}
              imgSrc={help}
              shouldExpand={isOpen}
              onClick={() => {
                navigate("/support");
                setSelectedItem("help");
              }}
              isSelected={selectedItem === "help"}
            />

            {user && (
              <ExpandableItem
                text={t("common.logout")}
                imgSrc={logout}
                shouldExpand={isOpen}
                onClick={onLogout}
                isLogout={true}
              />
            )}
          </div>
        </div>
      </div>
      <LockScreenDialog
        isOpen={isLockScreenDialogOpen}
        onClose={() => setIsLockScreenDialogOpen(false)}
        onSuccess={() => {
          setIsSuccessSetDialogOpen(true);
          setIsLockScreenDialogOpen(false);
        }}
      />
      <ChangePinDialog
        isOpen={isChangePinDialogOpen}
        onClose={() => setIsChangePinDialogOpen(false)}
        action={() => {
          setIsChangePinDialogOpen(false);
          setIsNewPinDialogOpen(true);
        }}
      />
      <NewPinDialog
        isOpen={isNewPinDialogOpen}
        onClose={() => setIsNewPinDialogOpen(false)}
        onSuccess={() => {
          setIsSuccessDialogOpen(true);
          setIsNewPinDialogOpen(false);
        }}
      />
      <PinChangeSuccessDialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
      />
      <PinSetSuccessDialog
        isOpen={isSuccessSetDialogOpen}
        onClose={() => setIsSuccessSetDialogOpen(false)}
        openChangePinDialog={() => setIsNewPinDialogOpen(true)}
      />
      <ChangeLanguageDialog
        isOpen={languageDialog}
        onClose={toggleLanguageDialog}
      />
      <AddReferralDialog
        isOpen={addReferralDialog}
        onClose={toggleAddReferralDialog}
      />
      <DeleteAccountDialog />
      <ControlledForm schema={uploadImageSchema}>
        <UploadImageDialog
          isOpen={uploadImageDialog}
          onClose={toggleUploadImageDialog}
        />
      </ControlledForm>
      <ChangePasswordDialog
        isOpen={passwordDialog}
        onClose={togglePasswordDialog}
      />
    </>
  );
};

export default SideMenu;
