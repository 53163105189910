const Lightbulb = () => {
  return (
    <svg
      width="17"
      height="23"
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31641 22.1807C7.84081 22.1807 7.42029 22.0554 7.05486 21.8047C6.68945 21.554 6.4338 21.225 6.28792 20.8178H5.94603C5.46132 20.8178 5.04386 20.6427 4.69364 20.2924C4.34339 19.9422 4.16827 19.5248 4.16827 19.0401V15.1517C2.97246 14.427 2.03152 13.4552 1.34548 12.2366C0.659429 11.018 0.316406 9.71355 0.316406 8.32324C0.316406 6.09268 1.09209 4.2017 2.64347 2.6503C4.19486 1.09893 6.08584 0.323242 8.31641 0.323242C10.547 0.323242 12.438 1.09893 13.9893 2.6503C15.5407 4.2017 16.3164 6.09268 16.3164 8.32324C16.3164 9.73786 15.9734 11.0484 15.2873 12.2549C14.6013 13.4613 13.6604 14.427 12.4645 15.1517V19.0401C12.4645 19.5248 12.2894 19.9422 11.9392 20.2924C11.589 20.6427 11.1715 20.8178 10.6868 20.8178H10.3449C10.199 21.225 9.94337 21.554 9.57795 21.8047C9.21252 22.0554 8.792 22.1807 8.31641 22.1807ZM5.94603 19.0401H10.6868V17.9278H5.94603V19.0401ZM5.94603 16.8794H10.6868V15.7306H5.94603V16.8794ZM5.70899 13.9529H7.60985V10.5022L5.07082 7.96312L6.06455 6.9694L8.31641 9.22126L10.5683 6.9694L11.562 7.96312L9.02296 10.5022V13.9529H10.9238C11.9905 13.4393 12.8596 12.6838 13.5312 11.6862C14.2028 10.6887 14.5387 9.56769 14.5387 8.32324C14.5387 6.58496 13.9362 5.11335 12.7312 3.90841C11.5263 2.70347 10.0547 2.101 8.31641 2.101C6.57813 2.101 5.10652 2.70347 3.90157 3.90841C2.69663 5.11335 2.09416 6.58496 2.09416 8.32324C2.09416 9.56769 2.42996 10.6887 3.10157 11.6862C3.77318 12.6838 4.64232 13.4393 5.70899 13.9529Z"
        fill="url(#paint0_linear_2188_21419)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2188_21419"
          x1="0.866406"
          y1="0.323244"
          x2="18.1449"
          y2="2.2079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.75" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Lightbulb;
