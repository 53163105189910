const SecurityIcon = () => {
  return (
    <svg
      width="43"
      height="50"
      viewBox="0 0 43 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 48.2465C21.5 48.2465 1.5 43.2538 1.5 8.52666C8.16527 7.98725 14.6967 7.2304 21.5 1C28.3074 7.2304 34.8347 7.98725 41.5 8.52666C41.5 43.2538 21.5 48.2465 21.5 48.2465Z"
        fill="url(#paint0_linear_4727_675)"
        fillOpacity="0.2"
        stroke="#07EAD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6403 33.925L22.8548 20.653C23.7371 20.1721 24.3393 19.2355 24.3393 18.1567C24.3393 16.551 23.0054 15.2547 21.3829 15.3174C19.9194 15.376 18.7277 16.5635 18.665 18.027C18.6148 19.156 19.2253 20.1512 20.1452 20.653L18.3597 33.925H24.6403Z"
        stroke="#07EAD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4727_675"
          x1="1.49582"
          y1="24.6212"
          x2="41.5"
          y2="24.6212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07EAD3" stopOpacity="0.2" />
          <stop offset="0.07" stopColor="#07EAD3" stopOpacity="0.28" />
          <stop offset="0.2" stopColor="#07EAD3" stopOpacity="0.49" />
          <stop offset="0.4" stopColor="#07EAD3" />
          <stop offset="0.49" stopColor="#07EAD3" />
          <stop offset="0.76" stopColor="#07EAD3" stopOpacity="0.827451" />
          <stop offset="0.82" stopColor="#07EAD3" stopOpacity="0.81" />
          <stop offset="0.87" stopColor="#07EAD3" stopOpacity="0.68" />
          <stop offset="0.92" stopColor="#07EAD3" stopOpacity="0.58" />
          <stop offset="0.96" stopColor="#07EAD3" stopOpacity="0.52" />
          <stop offset="1" stopColor="#5707EA" stopOpacity="0.827451" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SecurityIcon;
