import { Input } from "@ns/client-ui";

import searchSvg from "/svg/search.svg";

const SearchInput = ({
  onChange,
  placeholder,
}: {
  onChange: (value: string) => void;
  placeholder: string;
}) => {
  return (
    <div className="relative">
      <img
        src={searchSvg}
        alt="search"
        className="absolute top-[16px] left-[16px]"
      />
      <Input
        onChange={(e) => onChange(e.target.value.toLowerCase())}
        placeholder={placeholder}
        className="h-[56px] pl-[45px]"
      />
    </div>
  );
};

export default SearchInput;
