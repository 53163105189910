const StealdealLoader = ({
  isMobile,
  className,
}: {
  isMobile?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={`bg-background w-full h-full absolute top-0 left-0 flex justify-center items-center z-[100] ${className}`}
    >
      <svg
        width={`${isMobile ? "103" : "309"}`}
        height={`${isMobile ? "108" : "325"}`}
        viewBox="0 0 309 325"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={"steal-deal-loader"}
      >
        <path
          d="M14.9 276L154.5 33.7L294.1 276H231.8L154.5 140.3L77.2 276H14.9Z"
          stroke="#00FDF6"
          strokeWidth="2"
          strokeMiterlimit="10"
          className="loader-path"
        />
        <path
          d="M127.9 231.6C127.9 246.3 139.8 258.2 154.5 258.2C169.2 258.2 181.1 246.3 181.1 231.6C181.1 216.9 169.2 205 154.5 205C139.8 205 127.9 216.9 127.9 231.6Z"
          fill="url(#paint0_linear_2884_1162)"
          fillOpacity="0.8"
        />
        <path
          d="M169.8 215.8C173.7 219.8 176.1 225.2 176.1 231.2C176.1 243.3 166.3 253.2 154.1 253.2C148.1 253.2 142.7 250.8 138.7 246.9C142.7 251 148.3 253.5 154.4 253.5C166.5 253.5 176.4 243.7 176.4 231.5C176.4 225.4 173.9 219.8 169.8 215.8Z"
          fill="url(#paint1_linear_2884_1162)"
        />
        <path
          d="M132.8 232C132.8 219.9 142.6 210 154.8 210C160.8 210 166.2 212.4 170.2 216.3C166.2 212.2 160.6 209.7 154.5 209.7C142.4 209.7 132.5 219.5 132.5 231.7C132.5 237.9 135 243.4 139.1 247.4C135.2 243.4 132.8 237.9 132.8 232Z"
          fill="url(#paint2_linear_2884_1162)"
        />
        <path
          d="M162.8 231.8C162.1 231.2 161.1 230.7 160.1 230.3C159 229.9 157.8 229.5 156.5 229.2L155.5 229V222.4C156.2 222.5 156.9 222.6 157.6 222.8C158.3 223 158.9 223.3 159.5 223.5C160.1 223.8 160.6 224 161 224.3C161.4 224.6 161.7 224.8 161.9 224.9L164.2 220.6C163.1 219.9 161.9 219.2 160.5 218.7C159.1 218.1 157.6 217.8 155.9 217.7V213.9H153.6V217.8C152.4 217.9 151.2 218.2 150.1 218.6C149 219 148.1 219.6 147.3 220.3C146.5 221 145.9 221.8 145.5 222.9C145.1 223.9 144.8 225.1 144.8 226.3C144.8 227.3 145 228.2 145.3 229C145.6 229.8 146.1 230.4 146.7 231C147.3 231.6 148.1 232 149.1 232.5C150.1 232.9 151.2 233.3 152.5 233.7L153.9 234.1V240.6C153 240.5 152.1 240.3 151.3 240C150.4 239.7 149.7 239.4 149 239.1C148.3 238.8 147.7 238.4 147.2 238.1C146.7 237.8 146.4 237.5 146.2 237.3L143.9 241.8C145.3 242.8 146.8 243.5 148.5 244.1C150.2 244.7 151.9 245.1 153.7 245.2V249.4H156V245.2C157.2 245.1 158.4 245 159.5 244.6C160.6 244.3 161.6 243.8 162.4 243.2C163.2 242.6 163.9 241.8 164.4 240.8C164.9 239.8 165.1 238.6 165.1 237.2C165.1 236 164.9 234.9 164.5 234.1C164.2 233.2 163.6 232.4 162.8 231.8ZM154 228.5C152.8 228.1 151.9 227.7 151.3 227.3C150.7 226.9 150.4 226.2 150.4 225.3C150.4 223.4 151.6 222.4 154.1 222.3V228.5H154ZM155.5 240.5V234.3C156.8 234.7 157.8 235.2 158.5 235.6C159.2 236.1 159.6 236.8 159.6 237.7C159.6 239.5 158.2 240.4 155.5 240.5Z"
          fill="url(#paint3_linear_2884_1162)"
          fillOpacity="0.8"
        />
        <path
          d="M155.9 217.7L156.3 217.3V213.5L155.9 213.8V217.7Z"
          fill="url(#paint4_linear_2884_1162)"
        />
        <path
          d="M155.9 213.8L156.3 213.5H153.3L153.6 213.8H155.9Z"
          fill="#FFFCAF"
        />
        <path
          d="M160.5 218.7C161.9 219.3 163.1 219.9 164.2 220.6L164.7 220.5L164.4 220.3C163.3 219.6 162 218.9 160.6 218.3C159.3 217.8 157.8 217.4 156.3 217.3L155.9 217.7C157.6 217.8 159.1 218.2 160.5 218.7Z"
          fill="url(#paint5_linear_2884_1162)"
        />
        <path
          d="M153.6 213.8L153.3 213.5V217.4L153.6 217.7V213.8Z"
          fill="#F9FFC3"
        />
        <path
          d="M161 224.3C160.6 224 160.1 223.8 159.5 223.5C158.9 223.2 158.3 223 157.6 222.8C156.9 222.6 156.2 222.4 155.5 222.4L155.9 222.8C156.5 222.9 157 223 157.5 223.2C158.2 223.4 158.8 223.6 159.3 223.9C159.9 224.2 160.3 224.4 160.7 224.7C161.2 225 161.4 225.2 161.5 225.3L161.9 225.7L161.8 225C161.8 224.7 161.5 224.5 161 224.3Z"
          fill="url(#paint6_linear_2884_1162)"
        />
        <path
          d="M161.9 224.9L162 225.6L164.7 220.5L164.2 220.7L161.9 224.9Z"
          fill="url(#paint7_linear_2884_1162)"
        />
        <path
          d="M155.5 228.9L155.9 228.6V222.8L155.5 222.3V228.9Z"
          fill="#623809"
        />
        <path
          d="M153.6 249.3L153.3 249.7H156.3L155.9 249.3H153.6Z"
          fill="#623809"
        />
        <path
          d="M153.6 245.1L153.3 245.5V249.7L153.6 249.3V245.1Z"
          fill="url(#paint8_linear_2884_1162)"
        />
        <path
          d="M164.9 233.8C164.5 232.9 163.9 232.1 163.1 231.5C162.3 230.9 161.4 230.3 160.3 229.9C159.2 229.5 158 229.1 156.7 228.7L156 228.5L155.6 228.8L156.6 229C157.9 229.4 159.1 229.7 160.2 230.1C161.3 230.5 162.2 231 162.9 231.6C163.6 232.2 164.2 232.9 164.6 233.8C165 234.7 165.2 235.7 165.2 236.9C165.2 238.3 165 239.5 164.5 240.5C164 241.5 163.3 242.3 162.5 242.9C161.6 243.5 160.7 244 159.6 244.3C158.5 244.6 157.3 244.8 156.1 244.9L156.5 245.3C157.6 245.2 158.7 245 159.8 244.8C161 244.5 162 244 162.9 243.3C163.8 242.6 164.5 241.8 165 240.7C165.5 239.7 165.8 238.4 165.8 237C165.6 235.8 165.3 234.7 164.9 233.8Z"
          fill="url(#paint9_linear_2884_1162)"
        />
        <path
          d="M152.6 233.5C151.3 233.1 150.2 232.8 149.2 232.3C148.2 231.9 147.5 231.4 146.8 230.8C146.2 230.2 145.7 229.6 145.4 228.8C145.1 228 144.9 227.1 144.9 226.1C144.9 224.8 145.1 223.7 145.6 222.7C146 221.7 146.7 220.8 147.4 220.1C148.2 219.4 149.1 218.9 150.2 218.4C151.3 218 152.4 217.7 153.7 217.6L153.3 217.3C152.2 217.4 151.1 217.7 150.1 218.1C149 218.5 148 219.1 147.2 219.8C146.4 220.5 145.7 221.4 145.3 222.5C144.8 223.5 144.6 224.8 144.6 226.1C144.6 227.2 144.8 228.1 145.1 228.9C145.4 229.7 146 230.4 146.6 231C147.3 231.6 148.1 232.1 149.1 232.5C150.1 232.9 151.2 233.3 152.5 233.7L153.6 234L154 233.7L152.6 233.5Z"
          fill="url(#paint10_linear_2884_1162)"
        />
        <path
          d="M147.2 237.9C147.7 238.2 148.3 238.6 149 238.9C149.7 239.2 150.5 239.5 151.3 239.8C152.2 240.1 153 240.3 153.9 240.4L153.5 240C152.8 239.9 152 239.7 151.4 239.5C150.6 239.2 149.8 238.9 149.1 238.6C148.4 238.3 147.8 238 147.3 237.6C146.8 237.3 146.5 237 146.3 236.8L145.9 236.4L146 237C146.3 237.3 146.7 237.6 147.2 237.9Z"
          fill="#FFFC90"
        />
        <path
          d="M148.4 244C146.7 243.4 145.2 242.6 143.8 241.7L143.3 241.8L143.6 242C145 243 146.6 243.8 148.3 244.4C149.9 245 151.6 245.3 153.3 245.5L153.7 245.1C151.9 245 150.1 244.6 148.4 244Z"
          fill="#C39422"
        />
        <path
          d="M155.9 249.3L156.3 249.7V245.5L155.9 245.1V249.3Z"
          fill="#623809"
        />
        <path
          d="M154 233.8L153.6 234.1V239.9L154 240.4V233.8Z"
          fill="#F9FFC6"
        />
        <path
          d="M146.1 237.1L146 236.5L143.3 241.8L143.8 241.7L146.1 237.1Z"
          fill="#F9FFC6"
        />
        <path
          d="M150.3 225.3C150.3 226.2 150.6 226.8 151.2 227.3C151.8 227.7 152.7 228.1 153.9 228.5L153.5 228C152.6 227.7 151.9 227.4 151.4 227C150.9 226.6 150.6 226.1 150.6 225.3C150.6 223.8 151.5 222.9 153.5 222.7L153.9 222.3C151.6 222.4 150.3 223.5 150.3 225.3Z"
          fill="url(#paint11_linear_2884_1162)"
        />
        <path
          d="M153.6 227.9L154 228.5V222.3L153.6 222.7V227.9Z"
          fill="#F9FFC3"
        />
        <path
          d="M155.9 234.8L155.5 234.3V240.5L155.9 240.1V234.8Z"
          fill="#623809"
        />
        <path
          d="M155.9 234.8C156.9 235.1 157.7 235.5 158.3 235.9C158.9 236.3 159.2 236.9 159.2 237.6C159.2 238.1 159.2 239.8 155.9 240L155.5 240.4C158.2 240.3 159.5 239.4 159.5 237.6C159.5 236.7 159.1 236 158.4 235.5C157.7 235 156.7 234.6 155.4 234.2L155.9 234.8Z"
          fill="url(#paint12_linear_2884_1162)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2884_1162"
            x1="127.9"
            y1="205"
            x2="184.857"
            y2="209.953"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C9A548" />
            <stop offset="0.5" stopColor="#F9E690" />
            <stop offset="0.745543" stopColor="#C39C3E" />
            <stop offset="1" stopColor="#A87715" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2884_1162"
            x1="177.677"
            y1="223.714"
            x2="146.574"
            y2="254.817"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B67B11" />
            <stop offset="0.4001" stopColor="#FFFC90" />
            <stop offset="0.8522" stopColor="#FFFC90" />
            <stop offset="1" stopColor="#B4811A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2884_1162"
            x1="162.316"
            y1="208.353"
            x2="131.213"
            y2="239.456"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B4811A" />
            <stop offset="0.1478" stopColor="#FFFC90" />
            <stop offset="0.9007" stopColor="#804400" />
            <stop offset="1" stopColor="#B67B11" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2884_1162"
            x1="143.9"
            y1="213.9"
            x2="166.707"
            y2="215.084"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C9A548" />
            <stop offset="0.5" stopColor="#F9E690" />
            <stop offset="0.745543" stopColor="#C39C3E" />
            <stop offset="1" stopColor="#A87715" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2884_1162"
            x1="155.102"
            y1="214.562"
            x2="157.097"
            y2="216.557"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFFC90" />
            <stop offset="0.3999" stopColor="#FFFC90" />
            <stop offset="0.4191" stopColor="#FBF588" />
            <stop offset="0.54" stopColor="#E3CB5C" />
            <stop offset="0.6483" stopColor="#D1AD3C" />
            <stop offset="0.7392" stopColor="#C79B29" />
            <stop offset="0.8016" stopColor="#C39422" />
            <stop offset="1" stopColor="#B67B11" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2884_1162"
            x1="152.381"
            y1="209.409"
            x2="174.798"
            y2="236.786"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFFC90" />
            <stop offset="0.1541" stopColor="#F9FFA9" />
            <stop offset="0.1756" stopColor="#F8FCA4" />
            <stop offset="0.2019" stopColor="#F4F295" />
            <stop offset="0.2306" stopColor="#EDE17C" />
            <stop offset="0.2609" stopColor="#E4CA5A" />
            <stop offset="0.278" stopColor="#DEBB43" />
            <stop offset="0.2999" stopColor="#C69F34" />
            <stop offset="0.338" stopColor="#A0741E" />
            <stop offset="0.3678" stopColor="#895A10" />
            <stop offset="0.3854" stopColor="#80500B" />
            <stop offset="0.5609" stopColor="#FFFC90" />
            <stop offset="0.5724" stopColor="#FBF588" />
            <stop offset="0.6448" stopColor="#E3CB5C" />
            <stop offset="0.7098" stopColor="#D1AD3C" />
            <stop offset="0.7642" stopColor="#C79B29" />
            <stop offset="0.8016" stopColor="#C39422" />
            <stop offset="0.8745" stopColor="#7C5110" />
            <stop offset="0.9007" stopColor="#623809" />
            <stop offset="1" stopColor="#B67B11" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2884_1162"
            x1="149.208"
            y1="212.007"
            x2="171.625"
            y2="239.384"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFFC90" />
            <stop offset="0.1541" stopColor="#F9FFA9" />
            <stop offset="0.1756" stopColor="#F8FCA4" />
            <stop offset="0.2019" stopColor="#F4F295" />
            <stop offset="0.2306" stopColor="#EDE17C" />
            <stop offset="0.2609" stopColor="#E4CA5A" />
            <stop offset="0.278" stopColor="#DEBB43" />
            <stop offset="0.2999" stopColor="#C69F34" />
            <stop offset="0.338" stopColor="#A0741E" />
            <stop offset="0.3678" stopColor="#895A10" />
            <stop offset="0.3854" stopColor="#80500B" />
            <stop offset="0.5609" stopColor="#FFFC90" />
            <stop offset="0.5724" stopColor="#FBF588" />
            <stop offset="0.6448" stopColor="#E3CB5C" />
            <stop offset="0.7098" stopColor="#D1AD3C" />
            <stop offset="0.7642" stopColor="#C79B29" />
            <stop offset="0.8016" stopColor="#C39422" />
            <stop offset="0.8745" stopColor="#7C5110" />
            <stop offset="0.9007" stopColor="#623809" />
            <stop offset="1" stopColor="#B67B11" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2884_1162"
            x1="152.254"
            y1="209.513"
            x2="174.672"
            y2="236.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C39422" />
            <stop offset="0.7358" stopColor="#7C5110" />
            <stop offset="1" stopColor="#623809" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_2884_1162"
            x1="134.399"
            y1="224.133"
            x2="156.816"
            y2="251.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFFC90" />
            <stop offset="0.1541" stopColor="#F9FFA9" />
            <stop offset="0.1756" stopColor="#F8FCA4" />
            <stop offset="0.2019" stopColor="#F4F295" />
            <stop offset="0.2306" stopColor="#EDE17C" />
            <stop offset="0.2609" stopColor="#E4CA5A" />
            <stop offset="0.278" stopColor="#DEBB43" />
            <stop offset="0.2999" stopColor="#C69F34" />
            <stop offset="0.338" stopColor="#A0741E" />
            <stop offset="0.3678" stopColor="#895A10" />
            <stop offset="0.3854" stopColor="#80500B" />
            <stop offset="0.5609" stopColor="#FFFC90" />
            <stop offset="0.5724" stopColor="#FBF588" />
            <stop offset="0.6448" stopColor="#E3CB5C" />
            <stop offset="0.7098" stopColor="#D1AD3C" />
            <stop offset="0.7642" stopColor="#C79B29" />
            <stop offset="0.8016" stopColor="#C39422" />
            <stop offset="0.8745" stopColor="#7C5110" />
            <stop offset="0.9007" stopColor="#623809" />
            <stop offset="1" stopColor="#B67B11" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_2884_1162"
            x1="142.524"
            y1="217.48"
            x2="164.942"
            y2="244.857"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFFC90" />
            <stop offset="0.1541" stopColor="#F9FFA9" />
            <stop offset="0.2044" stopColor="#F8FCA4" />
            <stop offset="0.2658" stopColor="#F4F295" />
            <stop offset="0.3329" stopColor="#EDE17C" />
            <stop offset="0.4038" stopColor="#E4CA5A" />
            <stop offset="0.4439" stopColor="#DEBB43" />
            <stop offset="0.5095" stopColor="#EFDD6C" />
            <stop offset="0.5633" stopColor="#FBF486" />
            <stop offset="0.5951" stopColor="#FFFC90" />
            <stop offset="0.6491" stopColor="#FDF98D" />
            <stop offset="0.6866" stopColor="#F8F184" />
            <stop offset="0.7192" stopColor="#F0E275" />
            <stop offset="0.7489" stopColor="#E4CD5F" />
            <stop offset="0.7764" stopColor="#D5B343" />
            <stop offset="0.8016" stopColor="#C39422" />
            <stop offset="0.8745" stopColor="#7C5110" />
            <stop offset="0.9007" stopColor="#623809" />
            <stop offset="1" stopColor="#B67B11" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_2884_1162"
            x1="143.632"
            y1="216.572"
            x2="166.05"
            y2="243.949"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFFC90" />
            <stop offset="0.1541" stopColor="#F9FFCA" />
            <stop offset="0.1824" stopColor="#F8FCC5" />
            <stop offset="0.2169" stopColor="#F5F5B6" />
            <stop offset="0.2547" stopColor="#F0E89D" />
            <stop offset="0.2949" stopColor="#E9D77B" />
            <stop offset="0.3364" stopColor="#E0C14F" />
            <stop offset="0.3463" stopColor="#DEBB43" />
            <stop offset="0.3822" stopColor="#C69F34" />
            <stop offset="0.4444" stopColor="#A0741E" />
            <stop offset="0.4932" stopColor="#895A10" />
            <stop offset="0.522" stopColor="#80500B" />
            <stop offset="0.7121" stopColor="#FFFC90" />
            <stop offset="0.7164" stopColor="#FBF588" />
            <stop offset="0.7433" stopColor="#E3CB5C" />
            <stop offset="0.7674" stopColor="#D1AD3C" />
            <stop offset="0.7877" stopColor="#C79B29" />
            <stop offset="0.8016" stopColor="#C39422" />
            <stop offset="0.8745" stopColor="#7C5110" />
            <stop offset="0.9007" stopColor="#623809" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_2884_1162"
            x1="150.332"
            y1="225.381"
            x2="153.986"
            y2="225.381"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C39422" />
            <stop offset="0.7358" stopColor="#7C5110" />
            <stop offset="1" stopColor="#623809" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_2884_1162"
            x1="155.547"
            y1="237.37"
            x2="159.581"
            y2="237.37"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#623809" />
            <stop offset="0.0649392" stopColor="#7C501A" />
            <stop offset="0.2396" stopColor="#BD8C45" />
            <stop offset="0.322" stopColor="#D7A456" />
            <stop offset="0.5922" stopColor="#E9CA84" />
            <stop offset="0.8608" stopColor="#F9EBAC" />
            <stop offset="1" stopColor="#FFF8BB" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default StealdealLoader;
