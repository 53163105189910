import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { useDialogContext } from "../../store/dialog-store.ts";
import useUserStore from "../../store/user-store.ts";
import CloseBtn from "../svg-components/close-btn.tsx";

const DeleteAccountDialogContext = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { user } = useUserStore();

  const { mutate: deleteAccount } = useMutation({
    mutationFn: () => api.user.deleteUser({ email: user!.email }),
    onSuccess: () => {
      queryClient.invalidateQueries(["auth"]);
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen}>
      <AuthCard>
        <AuthCardContent>
          <FormLayout
            subTitle={t("deleteAccount.deleteTitle")}
            description={t("deleteAccount.question")}
          >
            <CloseBtn
              className="absolute top-[30px] right-[16px]"
              onClick={onClose}
            />
            <div className="flex flex-col items-center justify-center gap-[1rem] w-full mt-[2rem]">
              <Button onClick={() => deleteAccount()} className={"w-full"}>
                {t("deleteAccount.delete")}
              </Button>
              <Button
                onClick={onClose}
                variant={"secondary"}
                className={"w-full"}
              >
                {t("deleteAccount.cancel")}
              </Button>
            </div>
          </FormLayout>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};

export const DeleteAccountDialog = () => {
  const { isDeleteAccountDialogOpen, toggleIsDeleteAccountDialogOpen } =
    useDialogContext();

  return (
    <DeleteAccountDialogContext
      isOpen={isDeleteAccountDialogOpen}
      onClose={toggleIsDeleteAccountDialogOpen}
    />
  );
};
