import { RoundResponse } from "@ns/api";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@ns/client-ui";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { usePagination } from "../../../hooks/use-pagination.ts";
import { api } from "../../api/client-api";
import DropdownSelect from "../../components/dropdown-select.tsx";
import FlatList from "../../components/flat-list.tsx";
import FooterRoundsMenu from "../../components/footer-rounds-menu.tsx";
import FooterWinnerMenu from "../../components/footer-winner-menu.tsx";
import Loader from "../../components/loader.tsx";
import RoundCarousel from "../../components/round-carousel";
import AllRoundsCard from "../../components/ui/all-rounds-card";
import { CardDataType } from "../../components/ui/card-data.tsx";
import CardList from "../../components/ui/card-list.tsx";
import WinnerPreview from "../../components/ui/winner-preview";
import { CURRENCY_IMAGE } from "../../constants/currency-images";

import noBidsPng from "/assets/no-active-bids.png";
import giftSvg from "/svg/gift.svg";
import bellSvg from "/svg/bell.svg";

import { environmentVariables } from "../../env/enviroment-variables.ts";
import { useDialogContext } from "../../store/dialog-store.ts";
import useUserStore from "../../store/user-store.ts";

const WinnersTabs = ({
  index,
  setIndex,
}: {
  index: number;
  setIndex: (val: number | ((prevIndex: number) => number)) => void;
}) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/winners", title: "Winners" });
  }, []);

  const { user } = useUserStore();

  const {
    setIsGiftDialogOpen,
    toggleNotificationOpen,
    selectedTab,
    setSelectedTab,
  } = useDialogContext();

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("rounds");

  useEffect(() => {
    if (selectedTab) {
      setActiveTab(selectedTab);
      setSelectedTab(undefined);
    }
  }, [selectedTab, setSelectedTab, setActiveTab]);

  const { data: currencies } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => api.resources.getCurrencies(),
  });

  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>(
    undefined,
  );

  const onFilterSelect = useCallback(
    async (value: string | undefined) => {
      setCurrencyFilter(value);
    },
    [setCurrencyFilter],
  );

  const {
    data: allRoundsData,
    isFetchingNextPage: isFetchingNextRoundsPage,
    isLoading,
    lastItemRef: lastRoundsItemRef,
  } = usePagination<RoundResponse>({
    queryKey: ["allRounds", "ALL", currencyFilter || ""],
    queryFn: (offset: number, limit: number) =>
      api.round.getRounds("ALL", "", currencyFilter, limit, offset),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const formatFilterValues = useMemo(() => {
    const formatedCurrencies = currencies?.items?.map((currency) => ({
      id: currency.id,
      label: currency.code,
      svg: CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE],
    }));
    return [
      { id: undefined, label: t(`winners.all${activeTab.toLowerCase()}`) },
      ...(formatedCurrencies || []),
    ];
  }, [currencies, t, activeTab]);

  const { data: allWinners } = useQuery({
    queryFn: () => api.winners.getWinners("", currencyFilter),
    queryKey: ["allWinners", currencyFilter],
  });

  const { data: analytics } = useQuery({
    queryKey: ["roundsAnalytics", currencyFilter],
    queryFn: () => api.analytics.getRoundsAnalytics(currencyFilter),
  });

  const stats: CardDataType[] = useMemo(() => {
    return [
      {
        title: t("common.totalWinnings"),
        value: `${Number(analytics?.totalWinnings).toFixed(2) ?? 0} ${environmentVariables.fiatCurrency}`,
        svg: () => <img src="/svg/money.svg" />,
      },
      {
        title: t("common.roundsPlayed"),
        value: analytics?.totalRounds ?? 0,
        svg: () => <img src="/svg/money-pie.svg" />,
      },
    ];
  }, [analytics, t]);

  const renderData = useMemo(() => {
    if (!allRoundsData?.length) {
      return (
        <div className="flex flex-col items-center justify-center">
          <span className="text-[24px] avenir-semibold text-white">
            {"No Rounds"}
          </span>
          <img src={noBidsPng} alt="no bids img" />
        </div>
      );
    }

    return (
      <FlatList<RoundResponse>
        data={allRoundsData}
        isFetching={isFetchingNextRoundsPage}
        keyExtractor={(item: RoundResponse) => item.id}
        ref={lastRoundsItemRef}
        renderItem={(round: RoundResponse) => (
          <AllRoundsCard
            key={round.id}
            roundNumber={round.number}
            roundStatus={round.status}
            winningBid={round?.winningBid}
            currency={round.currency.code}
            date={round.createdAt}
            roundClosed={round?.roundClosed}
          />
        )}
      />
    );
  }, [allRoundsData]);

  const changeSlide = (direction: number): void => {
    setIndex(
      (prevIndex: number) =>
        (prevIndex + direction + slideData.length) % slideData.length,
    );
  };

  const slideData = useMemo(() => {
    if (!allWinners?.items.length) {
      return [];
    }

    const items = allWinners.items;

    const extendedItems =
      items.length === 1
        ? [...items, ...items, ...items]
        : items.length === 2
          ? [...items, ...items]
          : items;

    return extendedItems.map((data, i) => ({
      key: i,
      content: (
        <WinnerPreview
          profileImage={data?.winnerImageUrl ?? ""}
          selectedCurrency={data.currencyCode.toUpperCase()}
          userName={data.name}
          isSelected={i === index}
          countryFlag={data.winnerCountryFlag}
          roundNumber={data.roundNumber}
          createdAt={data.createdAt}
          bid={data.bid}
        />
      ),
    }));
  }, [allWinners, index]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Tabs
        value={activeTab}
        onValueChange={(value) => setActiveTab(value)}
        className={
          "h-full w-full flex flex-col justify-center items-center xl:pt-8"
        }
      >
        <div className={"w-full lg:ml-0 flex items-center justify-center"}>
          <div
            className={
              "relative z-[1] w-full text-center flex items-center justify-evenly"
            }
          >
            {user && (
              <img
                onClick={() => setIsGiftDialogOpen(true)}
                src={giftSvg}
                alt="gift"
                className={"sm:hidden"}
              />
            )}
            <TabsList className={"relative self-center"}>
              <TabsTrigger
                value={"rounds"}
                className="rounded-tl-[8px] rounded-bl-[8px]"
              >
                {t("winners.rounds")}
              </TabsTrigger>
              <TabsTrigger
                value={"winners"}
                className="rounded-tr-[8px] rounded-br-[8px]"
              >
                {t("winners.winners")}
              </TabsTrigger>
              <div className="hidden sm:block absolute right-[-10rem] top-0 w-[142px] h-[40px]">
                <div className="absolute left-0 w-[142px] top-0">
                  <DropdownSelect
                    values={formatFilterValues}
                    onSelect={onFilterSelect}
                    selected={currencyFilter}
                  />
                </div>
              </div>
            </TabsList>
            {user && (
              <img
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleNotificationOpen();
                }}
                src={bellSvg}
                alt="bell"
                className={"sm:hidden"}
              />
            )}
          </div>
        </div>
        {activeTab === "rounds" && (
          <TabsContent
            value="rounds"
            className="flex flex-col gap-[32px] h-full w-full max-w-full relative sm:self-end lg:pl-0 lg:pr-0 xl:max-w-[860px] xl:mx-auto mt-[60px]"
          >
            <CardList data={stats} />
            <div className={"pr-12 xl:pr-0"}>
              <div className="sm:pl-[128px] hide-scrollbar lg:pl-[192px] xl:pl-0 hidden sm:flex overflow-y-auto scrollbar w-full flex flex-col gap-[30px] items-center h-[calc(60vh-150px)]">
                {renderData}
              </div>
            </div>
            <FooterRoundsMenu />
          </TabsContent>
        )}
        {activeTab === "winners" && (
          <TabsContent
            value="winners"
            className="w-full flex justify-center relative sm:w-[calc(100%-144px)] h-full"
          >
            {!slideData.length ? (
              <span className="text-white text-[30px]">
                {t("winners.noWinners")}
              </span>
            ) : (
              <div className="w-full h-full flex flex-col items-center">
                <RoundCarousel
                  slideData={slideData}
                  index={index}
                  onClick={changeSlide}
                  className={"carousel-data"}
                />
              </div>
            )}
            <FooterWinnerMenu />
          </TabsContent>
        )}
      </Tabs>
    </>
  );
};

const WinnersPage = () => {
  const [index, setIndex] = useState<number>(0);

  return (
    <>
      <div className="h-full w-full flex items-center flex-col h-[40px] gap-10 max-w-full lg:dashboardHeight lg:w-dashboardWidth lg:self-end lg:max-w-full">
        <div className="w-full h-full">
          <WinnersTabs index={index} setIndex={setIndex} />
        </div>
      </div>
    </>
  );
};

export default WinnersPage;
