import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const verificationCodeSchema = (t: TFunction) =>
  z.object({
    code: z
      .string({ message: t("form.required", { name: "form.code" }) })
      .min(4, { message: t("form.authentication.minCodeCharacters") }),
  });

const schema = verificationCodeSchema(staticTranslationFunction);

export type VerificationCodeSchema = z.infer<typeof schema>;

export const verificationCodeForm: FormNames<VerificationCodeSchema> = {
  code: "code",
};
