import { AuthCard, AuthCardContent, Button, Modal } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import CloseBtn from "../svg-components/close-btn.tsx";
import Lock from "../svg-components/lock.tsx";

const PinChangeSuccessDialog = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen}>
      <AuthCard className="min-h-[31.5rem] max-w-[37.5rem] items-center">
        <AuthCardContent>
          <CloseBtn
            onClick={onClose}
            className="absolute right-[32px] top-[32px] w-6 h-6"
          />
          <div
            className={
              "text-white flex flex-col gap-4 justify-center items-center mb-[35px]"
            }
          >
            <Lock />
            <div className="text-white text-2xl text-center">
              {t("screenLock.successfullyChanged")}
            </div>
          </div>
          <div className={"flex flex-col gap-4 w-full mt-8"}>
            <Button onClick={onClose}>{t("common.close")}</Button>
          </div>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};

export default PinChangeSuccessDialog;
