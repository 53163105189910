export const capitalizeFirstLetter = (string: string): string => {
  const stringWithSpaces = string.replace(/-/g, " ");
  return (
    stringWithSpaces.charAt(0).toUpperCase() +
    stringWithSpaces.slice(1).toLowerCase()
  );
};

export const insertValueInString = (
  string: string,
  placeholder: string,
  value: string,
) => {
  const regex = new RegExp(`{${placeholder}}`, "g");
  return string.replace(regex, value);
};

export const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);

  return `${day}/${month}/${year}`;
};

export const getCapitalizedFirstLetter = (
  input: string | undefined,
): string => {
  if (!input) {
    return "";
  }

  return input.charAt(0).toUpperCase();
};
