import { Card, CardContent } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { BidData } from "../footer-menu";

const BidCard = ({ roundNumber, value, entryFee, currency }: BidData) => {
  const { t } = useTranslation();
  return (
    <Card className="bg-transparent h-fit rounded-md w-full lg:w-full p-[16px] border-[1px] border-solid border-tabBorderColor text-bidCardText">
      <CardContent className="flex flex-col p-0">
        <div className="flex justify-between mb-2 text-[16px]">
          <span className="w-1/3 text-left">
            {`${t("bidCard.round")} ${roundNumber}`}
          </span>
          <span className="w-1/3 text-center">{t("bidCard.bid")}</span>
          <span className="w-1/3 text-right">{t("bidCard.entryFee")}</span>
        </div>
        <div className="border-b border-tabBorderColor my-2"></div>
        <div className="flex justify-between text-[14px]">
          <div className="w-1/3 text-left flex gap-2">
            <img
              // @ts-ignore
              src={CURRENCY_IMAGE[currency]}
              alt="Currency"
              className="w-[18px]"
            />
            <span className="w-1/3 text-center">{currency}</span>
          </div>
          <span className="w-1/3 text-center">{value}</span>
          <span className="w-1/3 text-right">
            {entryFee} {environmentVariables.fiatCurrency}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default BidCard;
