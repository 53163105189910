import { OTPInput, OTPInputContext } from 'input-otp';
import { Dot } from 'lucide-react';
import * as React from 'react';

import { cn } from './../../lib/utils';

const InputOtp = React.forwardRef<React.ElementRef<typeof OTPInput>, React.ComponentPropsWithoutRef<typeof OTPInput>>(
  ({ className, containerClassName, ...props }, ref) => (
    <OTPInput
      ref={ref}
      containerClassName={cn('flex items-center gap-2 has-[:disabled]:opacity-50', containerClassName)}
      className={cn('disabled:cursor-not-allowed', className)}
      {...props}
    />
  )
);
InputOtp.displayName = 'InputOtp';

const InputOtpGroup = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center justify-center gap-[10px] w-full', className)} {...props} />
  )
);
InputOtpGroup.displayName = 'InputOtpGroup';

const InputOtpSlot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, className, ...props }, ref) => {
  const InputOtpContext = React.useContext(OTPInputContext);
  const { char, hasFakeCaret, isActive } = InputOtpContext.slots[index];

  return (
    <div
      ref={ref}
      className={cn(
        'relative bg-otpBg flex h-[59px] w-[59px] items-center justify-center border-[2px] border-otpBorder text-white text-[24px] rounded-[8px] transition-all',
        isActive && 'z-10 ring-2 ring-white ring-offset-background',
        className
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="h-4 w-px animate-caret-blink bg-foreground duration-1000" />
        </div>
      )}
    </div>
  );
});
InputOtpSlot.displayName = 'InputOtpSlot';

const InputOtpSeparator = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ ...props }, ref) => (
    <div ref={ref} role="separator" {...props}>
      <Dot />
    </div>
  )
);
InputOtpSeparator.displayName = 'InputOtpSeparator';

export { InputOtp, InputOtpGroup, InputOtpSlot, InputOtpSeparator };
