import { TFunction } from "i18next";
import * as z from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";

export const sendMessageSchema = (translate: TFunction) =>
  z.object({
    message: z.string().min(10, {
      message: translate("form.messageLength", {
        minLength: 10,
        name: translate("form.message"),
      }),
    }),
  });

export const schema = sendMessageSchema(staticTranslationFunction);

export type SendMessageSchema = z.infer<typeof schema>;
