import plus from "/svg/plus.svg";
import minus from "/svg/minus.svg";

const ExpandableSimpleMenu = ({
  shouldExpand,
  text,
  onClick,
  isActive,
}: {
  shouldExpand: boolean;
  text: string;
  isActive: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`group relative inline-flex items-center py-[0rem] justify-between rounded-md cursor-pointer`}
      onClick={onClick}
    >
      <span
        className={`overflow-hidden text-nowrap text-white text-[14px] cursor-pointer ${
          shouldExpand ? "w-52" : "w-0"
        }`}
      >
        {text}
      </span>
      {!isActive ? (
        <img src={plus} className={`w-[1.5rem] h-[1.5rem]`} />
      ) : (
        <img src={minus} className={`w-[1.5rem] h-[1.5rem]`} />
      )}
    </div>
  );
};

export default ExpandableSimpleMenu;
