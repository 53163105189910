import { CheckReferralCodeResponse, CreateReferralRequest } from "@ns/api";
import { Dialog } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { addReferralSchema } from "../../schemas/form/add-referral-schema.ts";
import ControlledForm from "../form/controlled-form";
import ControlledInput from "../form/controlled-input";

const AddReferralForm = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, setError } = useFormContext();

  const { mutate: checkReferralCodeMutation, data: checkReferralCode } =
    useMutation({
      mutationFn: (data: string) => api.referralCode.checkReferralCode(data),
      onSuccess: (response: CheckReferralCodeResponse) => {
        if (!response.isValid) {
          setError("code", {
            message: t("profile.dialog.referral.invalidCode"),
          });
        }
      },
      onError: () => {
        setError("code", { message: t("profile.dialog.referral.invalidCode") });
      },
    });

  const { mutate: addReferralCodeMutation } = useMutation({
    mutationFn: (data: CreateReferralRequest) =>
      api.referral.createReferral(data),
    onSuccess: () => {
      setValue("code", "");
      onClose();
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("add-referral-code", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const referralCode = useWatch({ name: "code" });

  useEffect(() => {
    if (!referralCode) {
      return;
    }

    const timeout = setTimeout(() => {
      checkReferralCodeMutation(referralCode);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [referralCode, checkReferralCodeMutation]);

  const onSubmit = handleSubmit((data) =>
    addReferralCodeMutation({ code: data.code }),
  );

  return (
    <Dialog
      title={t("profile.addReferral")}
      subTitle={t("profile.dialog.referral.enterReferralCode")}
      isOpen={isOpen}
      successBtnText={t("profile.addReferral")}
      closeBtnText={t("common.cancel")}
      onClose={onClose}
      onSuccess={onSubmit}
      isError={!checkReferralCode?.isValid}
      showCancel
    >
      <div className="flex flex-col gap-[16px] w-[343px] mb-[32px]">
        <ControlledInput
          name="code"
          placeholder={t("profile.dialog.referral.referralCode")}
        />
      </div>
    </Dialog>
  );
};

const AddReferralDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={addReferralSchema}>
      <AddReferralForm isOpen={isOpen} onClose={onClose} />
    </ControlledForm>
  );
};

export default AddReferralDialog;
