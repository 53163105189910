import { ApiError, CancelablePromise, OpenAPIConfig } from "@ns/api";
import { ApiRequestOptions } from "@ns/api/src/api/core/ApiRequestOptions.ts";
import { ApiResult } from "@ns/api/src/api/core/ApiResult.ts";
import { OnCancel } from "@ns/api/src/api/core/CancelablePromise.ts";
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axiosStatic from "axios";
import FormData from "form-data";

import { useAuthContext } from "../store/auth-store.ts";
import useUserStore from "../store/user-store.ts";

import { removeCodeVerified, removeSession } from "./token-helper.ts";

const isDefined = <T>(
  value: T | null | undefined,
): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isBlob = (value: any): value is Blob => {
  return (
    typeof value === "object" &&
    typeof value.type === "string" &&
    typeof value.stream === "function" &&
    typeof value.arrayBuffer === "function" &&
    typeof value.constructor === "function" &&
    typeof value.constructor.name === "string" &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  );
};

const isFormData = (value: unknown): value is FormData => {
  return value instanceof FormData;
};

const isSuccess = (status: number): boolean => {
  return status >= 200 && status < 300;
};

const getQueryString = (params: Record<string, unknown>): string => {
  const qs: string[] = [];

  const append = (key: string, value: unknown) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: unknown) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v);
        });
      } else if (typeof value === "object" && !!value) {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v);
        });
      } else {
        append(key, value);
      }
    }
  };

  Object.entries(params).forEach(([key, value]) => {
    process(key, value);
  });

  if (qs.length > 0) {
    return `?${qs.join("&")}`;
  }

  return "";
};

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI;

  const path = options.url
    .replace("{api-version}", config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (
        !!options.path &&
        Object.prototype.hasOwnProperty.call(options.path, group)
      ) {
        return encoder(String(options.path[group]));
      }
      return substring;
    });

  const url = `${config.BASE}${path}`;
  if (options.query) {
    return `${url}${getQueryString(options.query)}`;
  }
  return url;
};

const getFormData = (options: ApiRequestOptions): FormData | undefined => {
  if (options.formData) {
    const formData = new FormData();

    const process = (key: string, value: unknown) => {
      if (isString(value) || isBlob(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    };

    Object.entries(options.formData)
      .filter(([, value]) => isDefined(value))
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => process(key, v));
        } else {
          process(key, value);
        }
      });

    return formData;
  }
  return undefined;
};

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

const resolve = async <T>(
  options: ApiRequestOptions,
  resolver?: T | Resolver<T>,
): Promise<T | undefined> => {
  if (typeof resolver === "function") {
    return (resolver as Resolver<T>)(options);
  }
  return resolver;
};

const getHeaders = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  formData?: FormData,
): Promise<Record<string, string>> => {
  const additionalHeaders = await resolve(options, config.HEADERS);
  const formHeaders =
    (typeof formData?.getHeaders === "function" && formData?.getHeaders()) ||
    {};
  const headers = Object.entries({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Accept: "application/json",
    ...additionalHeaders,
    ...options.headers,
    ...formHeaders,
  })
    .filter(([, value]) => isDefined(value))
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
      }),
      {} as Record<string, string>,
    );
  if (options.body) {
    if (options.mediaType) {
      headers["Content-Type"] = options.mediaType;
    } else if (isBlob(options.body)) {
      headers["Content-Type"] = options.body.type || "application/octet-stream";
    } else if (isString(options.body)) {
      headers["Content-Type"] = "text/plain";
    } else if (!isFormData(options.body)) {
      headers["Content-Type"] = "application/json";
    }
  }

  return headers;
};

const getRequestBody = (options: ApiRequestOptions): unknown => {
  if (options.body) {
    return options.body;
  }
  return undefined;
};

const sendRequest = async <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: unknown,
  formData: FormData | undefined,
  headers: Record<string, string>,
  onCancel: OnCancel,
  axiosInstance: AxiosInstance,
): Promise<AxiosResponse<T>> => {
  const source = axiosStatic.CancelToken.source();

  const requestConfig: AxiosRequestConfig = {
    url,
    headers,
    data: body ?? formData,
    method: options.method,
    withCredentials: config.WITH_CREDENTIALS,
    cancelToken: source.token,
  };

  onCancel(() => source.cancel("The user aborted a request."));
  try {
    return await axiosInstance.request(requestConfig);
  } catch (error) {
    const axiosError = error as AxiosError<T>;
    if (axiosError.response) {
      return axiosError.response;
    }
    throw error;
  }
};

const getResponseHeader = (
  response: AxiosResponse<unknown>,
  responseHeader?: string,
): string | undefined => {
  if (responseHeader) {
    const content = response.headers[responseHeader];
    if (isString(content)) {
      return content;
    }
  }
  return undefined;
};

const getResponseBody = (response: AxiosResponse<unknown>): unknown => {
  if (response.status !== 204) {
    return response.data;
  }
  return undefined;
};

const checkIfUserIsLoggedIn = (result: ApiResult): void => {
  if (result.status === 401) {
    useUserStore.getState().setUser(null);
    removeSession();
    removeCodeVerified();
    return;
  }

  if (!result.url.includes("login") && !result.url.includes("/auth/who-am-i")) {
    return;
  }

  return;
};

const catchErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult,
): void => {
  const errors: Record<number, string> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    400: "Bad Request",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    401: "Unauthorized",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    403: "Forbidden",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    404: "Not Found",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    500: "Internal Server Error",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    502: "Bad Gateway",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    503: "Service Unavailable",
    ...options.errors,
  };

  const error = errors[result.status];

  if (result.status === 503) {
    useAuthContext.getState().setIsMaintenanceMode(true);
    throw new ApiError(options, result, error);
  }
  useAuthContext.getState().setIsMaintenanceMode(false);

  if (error) {
    throw new ApiError(options, result, error);
  }

  if (!result.ok) {
    throw new ApiError(options, result, "Generic Error");
  }
};

/**
 * Request method
 * @param config - The OpenAPI configuration object
 * @param options - The request options from the service
 * @param axiosInstance
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  axiosInstance: AxiosInstance,
): CancelablePromise<T> => {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = getUrl(config, options);
      const formData = getFormData(options);
      const body = getRequestBody(options);
      const headers = await getHeaders(config, options, formData);

      if (onCancel.isCancelled) {
        return;
      }

      const response = await sendRequest<T>(
        config,
        options,
        url,
        body,
        formData,
        headers,
        onCancel,
        axiosInstance,
      );
      const responseBody = getResponseBody(response);
      const responseHeader = getResponseHeader(
        response,
        options.responseHeader,
      );
      const successBody = responseHeader ?? responseBody;

      const result: ApiResult = {
        url,
        ok: isSuccess(response.status),
        status: response.status,
        statusText: response.statusText,
        body: isSuccess(response.status) ? successBody : response.data,
      };

      checkIfUserIsLoggedIn(result);

      catchErrorCodes(options, result);

      resolve(result.body);
    } catch (error) {
      reject(error);
    }
  });
};
