import { WhoAmIClientResponse } from "@ns/api";
import { useQuery } from "@tanstack/react-query";
import { FC, useMemo } from "react";

import { api } from "../api/client-api";
import Loader from "../components/loader.tsx";
import { removeLocalstorageItem } from "../helpers/localstorage.ts";
import useUserStore from "../store/user-store.ts";

import SubHeaderControls from "./sub-header-controls";

interface Props {
  openMobileMenu: () => void;
}

const Header: FC<Props> = ({ openMobileMenu }) => {
  const { setUser } = useUserStore();
  const { data, isLoading: isLoadingBanner } = useQuery({
    queryKey: ["banner"],
    queryFn: () => api.banner.getBanner(),
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingUser } = useQuery({
    queryKey: ["auth"],
    queryFn: () => api.auth.getAuthWhoAmI(),
    onSuccess: (data: WhoAmIClientResponse) => {
      setUser(data);
    },
    onError: () => {
      removeLocalstorageItem("betaModal");
      setUser(null);
    },
    retry: false,
  });

  const isLoading = useMemo(
    () => isLoadingUser || isLoadingBanner,
    [isLoadingBanner, isLoadingUser],
  );

  const { message } = data || {};

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex w-full items-center justify-center flex-col gap-2 md:gap-[30px]">
      {message && (
        <div className="w-full h-[27px] bg-[#65A8AD99] flex items-center justify-center text-white text-[14px]">
          <span>{message}</span>
        </div>
      )}
      <SubHeaderControls openMobileMenu={openMobileMenu} />
    </div>
  );
};

export default Header;
