import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const registerSchema = (t: TFunction) =>
  z
    .object({
      email: z
        .string({ message: t("form.authentication.requiredEmail") })
        .email({ message: t("form.authentication.invalidEmail") }),
      username: z.string({ message: t("common.required") }),
      password: z
        .string({ message: t("form.authentication.passwordRequired") })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
      confirmPassword: z
        .string({
          message: t("form.authentication.passwordRequired"),
        })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
      privacy: z.boolean({ message: t("common.required") }),
      terms: z.boolean({ message: t("common.required") }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: t("form.authentication.passwordsDoNotMatch"),
      path: ["confirmPassword"],
    });

const schema = registerSchema(staticTranslationFunction);

export type RegisterFormData = z.infer<typeof schema>;

export const registerFieldNames: FormNames<RegisterFormData> = {
  email: "email",
  username: "username",
  password: "password",
  confirmPassword: "confirmPassword",
  privacy: "privacy",
  terms: "terms",
};
