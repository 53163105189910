import { ReactNode, useEffect, useRef, useState } from 'react';

import { Card, CardContent } from '../..';
import flareBig from '../../assets/flare-big.png';
import flare from '../../assets/flare.png';
import logo from '../../assets/logo.png';
import simpleLogo from '/svg/small-logo.svg';

type Socials = {
  src: string;
  onClick: () => void;
};

type Form = {
  children: ReactNode;
  isSignUp?: boolean;
  title?: string;
  subTitle?: string;
  description?: string;
};

type AccountNavigation = {
  description: string;
  actionText: string;
  action: () => void;
  className?: string;
};

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-background min-h-screen w-screen relative flex justify-center items-center">
      <div className="bg-inherit flex lg:flex-row flex-col lg:px-[75px] px-0 py-[75px] min-h-screen ">
        <div className="flex-1 flex justify-center items-center z-10">{children}</div>
        <div className="flex-1">
          <img src={logo} alt="steal deal logo" className="object-contain w-full h-full hidden lg:block" />
        </div>
      </div>
      <img src={flare} className="absolute bottom-0 left-0" alt="flare" />
      <img src={flareBig} className="absolute top-0 right-0" alt="flare" />
    </div>
  );
};

export default AuthLayout;

export const AuthCard = ({ children, className }: { children: ReactNode; className?: string }) => {
  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight,
  });
  const childRef = useRef<HTMLDivElement>(null);
  const [childHeight, setChildHeight] = useState<string | number>('auto');

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (childRef.current) {
      setChildHeight(childRef.current.offsetHeight);
    }
  }, [children, screenSize.height]);

  return (
    <div
      className={`flex justify-center items-center relative w-full h-full md:h-fit md:max-w-[560px] ${screenSize.height < 760 ? 'sm:h-[90%]' : 'sm:h-fit'} sm:max-w-[40rem]`}
    >
      <div className={`relative h-full w-full ${screenSize.height < 760 ? 'sm:h-[90%]' : 'sm:h-auto'}`}>
        <div
          className={`rounded-lg sm:rounded-[30px] absolute inset-0 card-border z-10 w-full hidden sm:block h-[${childHeight}]`}
        />
        <Card
          className={`hide-scrollbar overflow-y-auto relative h-full z-[20] w-full md:w-full md:max-w-[560px] flex justify-center sm:rounded-[30px] sm:max-w-[40rem] py-[3rem] shadow-custom border-none ${className}`}
          ref={childRef}
        >
          {children}
        </Card>
      </div>
    </div>
  );
};

export const AuthCardContent = ({ children }: { children: ReactNode }) => {
  return (
    <CardContent className="overflow-y-auto overflow-x-hidden w-full max-w-[340px] flex flex-col gap-[25px] hide-scrollbar p-4 sm:p-0">
      {children}
    </CardContent>
  );
};

export const Divider = ({ text }: { text: string }) => {
  return <div className="text-descriptionText text-center divider w-[90px] self-center">{text}</div>;
};

export const SocialsLayout = ({ socialArr }: { socialArr: Socials[] }) => {
  return (
    <div className="flex justify-center cursor-pointer w-full flex-row gap-[16px] max-h-12">
      {socialArr.map((social, index) => (
        <img
          src={social.src}
          className="w-[3rem] h-[3rem]"
          onClick={() => social.onClick()}
          alt={`social-${index}`}
          key={index}
        />
      ))}
    </div>
  );
};

export const FormLayout = ({ children, isSignUp = false, title, subTitle, description }: Form) => {
  return (
    <div className="flex flex-col gap-[0.75rem] text-center">
      <div className="flex flex-col gap-[0.25rem] text-center items-center">
        {isSignUp && <img src={simpleLogo} alt={'logo'} className="w-[3.75rem] h-[3.75rem]" />}
        <span className={`text-white text-[1.75rem] ${title ? '' : 'hidden'}`}>{title}</span>
        <span className={`text-white text-[1.5rem] ${subTitle ? '' : 'hidden'}`}>{subTitle}</span>
        <span className="text-descriptionText text-[1rem] font-normal">{description}</span>
      </div>
      {children}
    </div>
  );
};

export const AccountNav = ({ description, actionText, action, className }: AccountNavigation) => {
  return (
    <div className={`${className} flex flex-col text-white items-center`}>
      <span>{description}</span>
      <span className="gold-text cursor-pointer" onClick={action}>
        {actionText}
      </span>
    </div>
  );
};
