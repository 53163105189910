const BtcToast = () => {
  return (
    <svg
      width="71"
      height="70"
      viewBox="0 0 71 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"h-full w-full"}
    >
      <rect
        x="0.413574"
        width="70"
        height="70"
        rx="35"
        fill="url(#paint0_linear_4842_10587)"
        fillOpacity="0.8"
      />
      <g clipPath="url(#clip0_4842_10587)">
        <path
          d="M62.9136 35C62.9136 40.439 61.3007 45.7558 58.279 50.2782C55.2573 54.8005 50.9623 58.3253 45.9374 60.4067C40.9124 62.4881 35.3831 63.0327 30.0486 61.9716C24.7141 60.9105 19.8141 58.2914 15.9682 54.4454C12.1222 50.5995 9.50308 45.6995 8.44199 40.365C7.3809 35.0305 7.92549 29.5012 10.0069 24.4762C12.0883 19.4512 15.6131 15.1563 20.1354 12.1346C24.6578 9.11285 29.9746 7.5 35.4136 7.5C42.707 7.5 49.7018 10.3973 54.859 15.5546C60.0163 20.7118 62.9136 27.7065 62.9136 35Z"
          fill="#F7931A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.4585 20.0575L33.7785 21.75L35.191 16.485L38.351 17.3425L36.9935 22.39L39.571 23.0825L40.931 17.98L44.146 18.84L42.761 23.97C42.761 23.97 48.011 25.1325 49.246 29.4025C50.481 33.6725 46.531 35.915 45.311 36C45.311 36 49.911 38.5225 48.331 43.485C46.751 48.4475 41.901 49.335 36.7985 48.1975L35.4135 53.5175L32.1985 52.6575L33.611 47.42L31.061 46.725L29.6485 52L26.4585 51.1425L27.8735 45.8925L21.386 44.1425L23.021 40.5125C23.021 40.5125 24.851 41.0125 25.5435 41.1775C26.236 41.3425 26.681 40.6225 26.876 39.9025C27.071 39.1825 30.0085 27.25 30.286 26.2675C30.5635 25.285 30.451 24.5175 29.286 24.215C28.121 23.9125 26.536 23.44 26.536 23.44L27.4585 20.0575ZM33.8335 35.86L32.0835 42.8175C32.0835 42.8175 40.761 45.95 41.871 41.5425C42.981 37.135 33.8335 35.86 33.8335 35.86ZM34.6385 32.56L36.356 26.185C36.356 26.185 43.786 27.515 42.871 31.0625C41.956 34.61 37.576 33.25 34.6385 32.56Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4842_10587"
          x1="0.413574"
          y1="0"
          x2="75.3572"
          y2="6.51713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.745543" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
        <clipPath id="clip0_4842_10587">
          <rect
            width="55"
            height="55"
            fill="white"
            transform="translate(7.91357 7.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BtcToast;
