import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useEffect, useMemo, useRef } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import btcAnimation from "../../assets/animations/gold-coin-btc.json";
import ethAnimation from "../../assets/animations/gold-coin-eth.json";
import { entryFee } from "../../constants/entry-fee";
import { environmentVariables } from "../../env/enviroment-variables.ts";

type Currency = keyof typeof entryFee;

const RewardCard = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const currency = useWatch({ name: "currency" }) as Currency;
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const animationData = useMemo(() => {
    return currency === "BTC" ? btcAnimation : ethAnimation;
  }, [currency]);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(3);
    }
  }, [lottieRef, lottieRef.current, animationData]);

  return (
    <div className="w-full relative h-full">
      <div className="border-gradient-container">
        <div
          className={`w-full lg:w-[165px] pt-2 lg:pt-[22px] pb-[10px] flex rounded-[20px] relative rewardCard text-white px-[20px] items-center justify-center flex-col gap-[15px] avenir-semibold bg-backgroundDeepTeal ${className}`}
        >
          <div
            className={
              "mt-6 flex w-full gap-4 lg:flex-col lg:justify-center lg:items-center"
            }
          >
            <Lottie
              animationData={animationData}
              lottieRef={lottieRef}
              loop
              autoPlay
              style={{
                width: "52px",
                height: "52px",
                position: "absolute",
                top: "-25px",
                zIndex: "2",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
            <div
              className={
                "w-[50px] h-[50px] bg-background rounded-[50%] absolute top-[-25px] z-[1] left-1/2 transform translate-x-[-50%]"
              }
            />
            <div
              className={
                "w-full flex gap-4 flew-row justify-between md:text-center md:flex-col"
              }
            >
              <span className="text-gray-400 text-sm">
                {t("common.entryFee")}
              </span>
              <span className={"text-sm text-right"}>
                {t("rewardCard.upTo")} {entryFee[currency]}{" "}
                {environmentVariables.fiatCurrency}
              </span>
            </div>
          </div>
          <div className="hidden lg:block bg-gradientGoldShade h-[1px] w-full" />
          <div
            className={
              "flex justify-between w-full gap-[10px] justify-center items-center reward-container-padding"
            }
          >
            <span className="text-sm text-gray-400">{t("common.reward")}</span>
            <div className="flex items-center gap-[6px]">
              <span className="text-sm">1</span>
              <span className="text-sm">{currency}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardCard;
