import { Button } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import ControlledForm from "../../components/form/controlled-form.tsx";
import ControlledInput from "../../components/form/controlled-input.tsx";

import btcSvg from "/svg/btc-svg.svg";
import ethSvg from "/svg/eth-svg.svg";

import WalletCard from "../../components/ui/wallet-card.tsx";
import { getCapitalizedFirstLetter } from "../../helpers/helpers.ts";
import {
  AddWalletData,
  addWalletSchema,
} from "../../schemas/form/add-wallet-schema.ts";
import useUserStore from "../../store/user-store.ts";

const WalletPage = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    setValue,
  } = useFormContext<AddWalletData>();

  useEffect(() => {
    setValue("btcWallet", user?.btcWallet);
    setValue("ethWallet", user?.ethWallet);
  }, [user, setValue]);

  const { mutate } = useMutation({
    mutationFn: (data: AddWalletData) => api.user.updateUser(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["auth"]);
      reset();
      setValue("btcWallet", user?.btcWallet);
      setValue("ethWallet", user?.ethWallet);
      setIsEditMode(false);
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("update-wallet", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const { mutate: deleteWallet } = useMutation({
    mutationFn: (currency: "BTC" | "ETH") =>
      api.user.deleteUserWallet(currency),
    onSuccess: () => {
      queryClient.invalidateQueries(["auth"]);
      reset();
      setValue("btcWallet", user?.btcWallet);
      setValue("ethWallet", user?.ethWallet);
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("delete-wallet", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const onSubmit = handleSubmit((data) => mutate(data));

  const onDelete = (currency: "BTC" | "ETH") => {
    deleteWallet(currency);
  };

  return (
    <div className="overflow-y-auto flex flex-col w-full h-full p-[0rem] lg:p-[3rem] container-90">
      <div className="flex flex-col w-full items-center pt-4 sm:pt-12 sm:pt-[3rem] justify-around">
        <div className="flex flex-col items-center w-[21.5rem] justify-around container-90">
          {user?.imageUrl ? (
            <img
              src={user.imageUrl}
              className={"w-[3.5rem] h-[3.5rem] mb-[0.75rem] rounded-full"}
            />
          ) : (
            <div className="rounded-full bg-placeholderBg mb-[0.75rem] w-[3.5rem] h-[3.5rem] flex justify-center items-center">
              <span className="text-black text-[24px]">
                {getCapitalizedFirstLetter(user?.username)}
              </span>
            </div>
          )}
          <div className="flex flex-col items-center w-full">
            <span
              className={`overflow-hidden font-bold text-center text-nowrap transition-all text-[16px] text-white w-52`}
            >
              {user?.username}
            </span>
          </div>
          <span className={"text-white text-[24px] pt-[30px] avenir-semibold"}>
            {t("profile.dialog.wallet.connectWallet")}
          </span>
          <span className={"text-textGray text-[16px] text-center pt-[20px]"}>
            {t("profile.dialog.wallet.subtitle")}
          </span>
          <div className="flex flex-col gap-[1rem] w-[21.5rem] mt-[1.90rem] mb-[1rem] container-90">
            <div className="sm:w-full flex flex-row items-center w-full gap-[1rem]">
              {!isEditMode && user && user.btcWallet ? (
                <div className={"sm:w-full w-[85%]"}>
                  <WalletCard
                    text={t("wallet.btcWallet")}
                    currency={"BTC"}
                    setIsEditMode={setIsEditMode}
                    onDelete={() => onDelete("BTC")}
                  />
                </div>
              ) : (
                <ControlledInput
                  icon={btcSvg}
                  name={"btcWallet"}
                  placeholder={t("profile.dialog.wallet.btcWallet")}
                  className={"w-[85%] sm:w-full width-100"}
                />
              )}
            </div>
            <div className="sm:w-full flex flex-row items-center w-full gap-[1rem]">
              {!isEditMode && user && user.ethWallet ? (
                <div className={"w-[85%] sm:w-full"}>
                  <WalletCard
                    text={t("wallet.ethWallet")}
                    currency={"ETH"}
                    setIsEditMode={setIsEditMode}
                    onDelete={() => onDelete("ETH")}
                  />
                </div>
              ) : (
                <ControlledInput
                  icon={ethSvg}
                  name={"ethWallet"}
                  placeholder={t("profile.dialog.wallet.ethWallet")}
                  className={"sm:w-full w-[85%] width-100"}
                />
              )}
            </div>
            <div
              className={
                "mt-8 flex flex-row w-[21.5rem] gap-[1rem] container-90"
              }
            >
              <Button
                className={"w-full"}
                variant={"primary"}
                disabled={!isDirty || !isValid}
                onClick={onSubmit}
              >
                {t("common.save")}
              </Button>
              <Button
                className={"w-full"}
                variant={"secondary"}
                onClick={() => {
                  reset();
                  setValue("btcWallet", user?.btcWallet);
                  setValue("ethWallet", user?.ethWallet);
                  setIsEditMode(false);
                }}
              >
                {t("common.cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WalletForm = () => {
  return (
    <ControlledForm schema={addWalletSchema}>
      <WalletPage />
    </ControlledForm>
  );
};
