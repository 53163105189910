const EyeClosed = ({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      <path
        d="M14.5137 10.1202C15.4948 8.99389 16.0137 7.99512 16.0137 7.99512C16.0137 7.99512 13.2864 2.74512 8.51367 2.74512C8.25777 2.74512 8.00772 2.76021 7.76367 2.78878C7.50702 2.81883 7.25697 2.86378 7.01367 2.92176M8.51367 5.74512C8.77662 5.74512 9.02907 5.79023 9.26367 5.87315C9.90297 6.09911 10.4097 6.60582 10.6356 7.24512C10.7185 7.47972 10.7637 7.73217 10.7637 7.99512M1.76367 1.24512L15.2637 14.7451M8.51367 10.2451C8.25072 10.2451 7.99827 10.2 7.76367 10.1171C7.12437 9.89112 6.61766 9.38442 6.3917 8.74512C6.34907 8.62452 6.31643 8.49919 6.29477 8.37012M2.62393 5.74512C2.39275 6.0035 2.18543 6.25692 2.00265 6.49512C1.35332 7.34127 1.01367 7.99512 1.01367 7.99512C1.01367 7.99512 3.74094 13.2451 8.51367 13.2451C8.76957 13.2451 9.01962 13.23 9.26367 13.2015"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeClosed;
