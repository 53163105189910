import { Button } from "@ns/client-ui";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@ns/styles";
import { ChevronDown } from "lucide-react";
import { FC, ReactNode, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ControlledDropdownProps = {
  name: string;
  options: { value: string; label?: string; icon?: string | ReactNode }[];
  disabled?: boolean;
  resetDropdown?: () => void;
  showInput?: (val: boolean) => void;
};
const ControlledDropdown: FC<ControlledDropdownProps> = ({
  name = "",
  options,
  disabled,
  resetDropdown,
  showInput,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [buttonWidth, setButtonWidth] = useState<number>();

  useEffect(() => {
    const updateButtonWidth = () => {
      const buttonElement = document.getElementById("dropdown-button");
      if (buttonElement) {
        const width = buttonElement.offsetWidth;
        setButtonWidth(width);
      }
    };

    updateButtonWidth();

    window.addEventListener("resize", updateButtonWidth);

    return () => {
      window.removeEventListener("resize", updateButtonWidth);
    };
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const selectedOption = options.find(
          (option) => option.value === field.value,
        );
        return (
          <DropdownMenu>
            <DropdownMenuTrigger
              id={"dropdown-button"}
              asChild
              className={"w-full"}
            >
              <Button
                variant={"secondary"}
                className="outline-none bg-none font-normal"
                disabled={disabled}
              >
                <div className={"flex w-full items-center"}>
                  {selectedOption?.icon &&
                    (typeof selectedOption?.icon === "string" ? (
                      <img
                        src={selectedOption.icon}
                        alt={`dropdown icon`}
                        className="w-[1.625rem] h-[1.625rem] mr-2"
                      />
                    ) : (
                      selectedOption?.icon
                    ))}
                  <span className="text-[16px]">
                    {selectedOption?.label || t("common.select")}
                  </span>
                </div>
                <ChevronDown />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              style={{ width: buttonWidth }}
              className={`z-[10000000001] max-h-60 overflow-y-auto text-white bg-[#001214] p-4 border-0`}
            >
              {options.length === 0 ? (
                <div>{t("form.noCountries")}</div>
              ) : (
                options.map((option) => (
                  <DropdownMenuItem
                    key={option.value}
                    onClick={() => {
                      if (resetDropdown) {
                        resetDropdown();
                      }
                      if (option.value === "1" && showInput) {
                        showInput(true);
                      }
                      field.onChange(option.value);
                    }}
                    className={
                      "cursor-pointer flex justify-start items-center gap-2 pt-2 pb-2"
                    }
                  >
                    {option?.icon &&
                      (typeof option.icon === "string" ? (
                        <img src={option.icon} alt="icon" />
                      ) : (
                        option?.icon
                      ))}
                    <div className={"self-end flex-1"}>
                      <span>{option.label}</span>
                      <div className={"fade-away w-full mt-1"}></div>
                    </div>
                  </DropdownMenuItem>
                ))
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      }}
    />
  );
};

export default ControlledDropdown;
