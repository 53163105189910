import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@ns/styles";
import { ReactNode } from "react";

interface Props {
  trigger: ReactNode;
  content: ReactNode;
  side?: "right" | "top" | "bottom" | "left" | undefined;
}

export const TooltipComponent = ({
  trigger,
  content,
  side = "right",
}: Props) => {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger>{trigger}</TooltipTrigger>
        <TooltipContent side={side}>
          <p>{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipComponent;
