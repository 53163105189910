import { TFunction } from "i18next";
import { z } from "zod";

import { validateBidValue } from "../../helpers/bid.validation.ts";
import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const placeBidValidationSchema = (t: TFunction) =>
  z
    .object({
      roundId: z.string(),
      currency: z.string(),
      bid: z.string(),
      promoCode: z.string().optional(),
      referralId: z.string().optional(),
    })
    .superRefine((data, context) => {
      const { bid, currency } = data;
      const validationResult = validateBidValue(
        bid,
        currency as "BTC" | "ETH",
        t,
      );

      if (validationResult !== true) {
        context.addIssue({
          path: ["bid"],
          code: z.ZodIssueCode.custom,
          message: validationResult,
        });
      }
    });

const schema = placeBidValidationSchema(staticTranslationFunction);

export type PlaceBidData = z.infer<typeof schema>;

export const placeBidFormNames: FormNames<PlaceBidData> = {
  roundId: "roundId",
  currency: "currency",
  bid: "bid",
  promoCode: "promoCode",
  referralId: "referralId",
};
