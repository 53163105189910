import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

type UsePaginationProps = {
  queryKey: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryFn: (offset: number, limit: number) => any;
  onError?: ((err: unknown) => void) | undefined;
  enabled?: boolean;
  limit?: number;
  retry?: boolean;
  isGraphql?: boolean;
  refetchOnWindowFocus?: boolean;
};

type Page<T> = {
  items: T[];
  totalItems: number;
  totalPages: number;
  page: number;
};

export const usePagination = <T>({
  queryFn,
  enabled,
  queryKey,
  limit = 12,
  onError,
  retry = true,
  isGraphql = false,
  refetchOnWindowFocus,
}: UsePaginationProps) => {
  const [fetchingPoints, setFetchingPoints] = useState<boolean[]>([]);
  const [ref, inView, entry] = useInView({
    threshold: 0,
  });

  const {
    fetchNextPage,
    remove,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    data,
  } = useInfiniteQuery<Page<T>, unknown, Page<T>, string[]>({
    queryKey,
    queryFn: ({ pageParam = 0 }) => queryFn(pageParam * limit, limit),
    getNextPageParam: (lastPage) =>
      lastPage.page !== lastPage.totalPages ? lastPage.page : undefined,
    enabled,
    onError,
    retry: retry,
    refetchOnWindowFocus,
  });

  useEffect(() => {
    if (entry) {
      setFetchingPoints((prev) => [...prev, false]);
    }
  }, [entry]);

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      fetchingPoints.length &&
      // @ts-ignore
      !fetchingPoints.at(-1)
    ) {
      setFetchingPoints((prev) => [...prev.slice(0, -1), true]);
      fetchNextPage();
    }
  }, [fetchNextPage, fetchingPoints, hasNextPage, inView, isFetchingNextPage]);

  useEffect(() => () => remove(), [remove]);

  const preparedData = useMemo(
    () => ({
      items:
        data?.pages?.reduce((acc: T[], page) => {
          return isGraphql
            ? // @ts-ignore
              acc.concat(page[queryKey].items)
            : acc.concat(page.items);
        }, []) || [],
      totalItems: data?.pages?.[data.pages.length - 1]?.totalItems || 0,
    }),
    [isGraphql, queryKey, data?.pages],
  );

  return {
    data: preparedData.items,
    totalItems: preparedData.totalItems,
    isLoading,
    isError,
    limit,
    isFetchingNextPage,
    lastItemRef: ref,
    fetchNextPage,
    refetch,
  };
};
