import { Avatar, AvatarFallback, AvatarImage } from "@ns/styles";
import { FC } from "react";

import { getCapitalizedFirstLetter } from "../../helpers/helpers.ts";
import useUserStore from "../../store/user-store.ts";

interface Props {
  className?: string;
}

const ProfileAvatar: FC<Props> = ({ className }) => {
  const { user } = useUserStore();

  if (!user) return;
  return (
    <Avatar className={`w-8 h-8 bg-borderBlue rounded-full ${className}`}>
      <AvatarImage src={`${user?.imageUrl}`} />
      <AvatarFallback className="text-base ">
        {getCapitalizedFirstLetter(user?.username)}
      </AvatarFallback>
    </Avatar>
  );
};

export default ProfileAvatar;
