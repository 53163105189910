import { FC } from 'react';

import { IconPicture } from './icon-picture';
import { CloseCircle } from './close-circle';

type Props = {
  fileTitle: string;
  fileSize: number;
  progressPercentage: number;
  onDelete: (name: string) => void;
};

export const UploadProgress: FC<Props> = ({ fileTitle, fileSize, progressPercentage, onDelete }) => {
  return (
    <div className={'w-full h-fit flex flex-row gap-4 pt-12'}>
      <IconPicture />
      <div className={'flex flex-col w-full'}>
        <div className={'flex flex-row justify-between pb-8'}>
          <span className="text-white text-[14px]">{fileTitle}</span>
          <CloseCircle onClick={onDelete as never} />
        </div>
        <div className={'flex flex-row justify-between'}>
          <span className={'text-textGray'}>{`${fileSize}MB`}</span>
          <span>{`${progressPercentage}%`}</span>
        </div>
        <>
          <div className={'w-full h-[0.3rem] rounded-[0.15rem] bg-gray-600'} />
          <div
            style={{ width: `${progressPercentage}%` }}
            className={'h-[0.3rem] relative rounded-[0.15rem] bottom-[0.3rem] bg-gradientGold'}
          />
        </>
      </div>
    </div>
  );
};
