import { LanguageResponse } from "@ns/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../api/client-api.ts";
import useUserStore from "../store/user-store.ts";

import DropdownSelect from "./dropdown-select.tsx";

export const LanguagePicker = () => {
  const queryClient = useQueryClient();

  const { data: languages } = useQuery<{ items: LanguageResponse[] }>({
    queryKey: ["languages"],
    queryFn: () => api.resources.getLanguages(),
  });

  const { i18n } = useTranslation();
  const { setLanguageId, user, languageId } = useUserStore();
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    undefined,
  );

  const { mutate } = useMutation({
    mutationFn: (languageId: string) => api.user.updateUserLanguage(languageId),
    onSuccess: () => {
      queryClient.invalidateQueries(["auth"]);
    },
  });

  const formatLanguages = useMemo(
    () =>
      !languages?.items
        ? []
        : languages?.items.map((language) => ({
            label: language.code.toUpperCase(),
            id: language.id,
            svg: language.flag,
          })),
    [languages],
  );

  useEffect(() => {
    if (formatLanguages?.length > 0) {
      if (languageId) {
        const userLanguage = formatLanguages.find(
          (item) => item.id === languageId,
        );
        if (userLanguage) {
          setSelectedLanguage(userLanguage.id);
          i18n.changeLanguage(userLanguage.label.toLowerCase());
        } else {
          setSelectedLanguage(formatLanguages[0].id);
        }
      } else {
        setSelectedLanguage(formatLanguages[0].id);
      }
    }
  }, [formatLanguages, languageId, i18n]);

  const onFilterSelect = useCallback(
    (value: string | undefined) => {
      setSelectedLanguage(value);

      const selected = languages?.items?.find((item) => item.id === value);

      if (selected) {
        i18n.changeLanguage(selected.code);
        setLanguageId(selected.id);

        if (user) {
          mutate(selected.id);
        }
      }
    },
    [setSelectedLanguage, i18n, languages, setLanguageId, mutate, user],
  );

  return (
    <div className="hidden sm:block right-[8rem] top-0 w-[120px] h-[40px] z-[1000000]">
      <DropdownSelect
        selected={selectedLanguage}
        values={formatLanguages}
        onSelect={onFilterSelect}
        languages={true}
      />
    </div>
  );
};
