import * as React from 'react';
import { useState } from 'react';

import { cn } from '../../lib/utils';
import EyeOpen from '../../../../../apps/steal-deal-frontend/src/components/svg-components/eye-open';
import EyeClosed from '../../../../../apps/steal-deal-frontend/src/components/svg-components/eye-closed';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: string;
  iconText?: string;
  isOutlineShown?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, iconText, isOutlineShown, onKeyDown, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [revealPassword, setRevealPassword] = useState<boolean>(false);
    const isPassword = type === 'password';

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    return (
      <div className="relative w-full">
        <input
          type={revealPassword ? 'text' : type}
          className={cn(
            `w-full flex h-10 pl-10 pr-3 rounded-md border border-inputBorderIdle bg-transparent px-3 py-2 text-sm ring-offset-background file:bg-transparent file:text-sm file:font-medium placeholder-inputPlaceholder focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-inputText ${icon && 'pl-12'} ${icon && iconText ? 'pl-[88px]' : ''}`,
            className
          )}
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          {...props}
        />
        {icon && (
          <div className={'absolute -translate-y-2/4 left-4 top-2/4 flex gap-2 items-center'}>
            <img src={icon} alt={`dropdown icon`} className="w-[1.625rem] h-[1.625rem]" />
            {iconText && <div className={'text-textGray4 text-sm'}>{iconText}</div>}
          </div>
        )}
        {isOutlineShown && isFocused && (
          <div className="absolute inset-0 border-gradient-gold pointer-events-none rounded-md" />
        )}
        {isPassword && (
          <div className={'absolute top-1/2 transform translate-y-[-50%] right-[10px]'}>
            {revealPassword ? (
              <EyeOpen onClick={() => setRevealPassword(false)} />
            ) : (
              <EyeClosed onClick={() => setRevealPassword(true)} />
            )}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
