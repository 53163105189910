import { NotificationResponse } from "../components/notification-popup";

export type GroupNotificationsType = {
  today: NotificationResponse[];
  yesterday: NotificationResponse[];
  thisWeek: NotificationResponse[];
  lastWeek: NotificationResponse[];
  thisMonth: NotificationResponse[];
  older: NotificationResponse[];
};

export const groupNotificationsByDate = (
  notifications: NotificationResponse[],
): GroupNotificationsType => {
  const grouped = {
    today: [] as NotificationResponse[],
    yesterday: [] as NotificationResponse[],
    thisWeek: [] as NotificationResponse[],
    lastWeek: [] as NotificationResponse[],
    thisMonth: [] as NotificationResponse[],
    older: [] as NotificationResponse[],
  };

  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
  );
  const startOfYesterday = new Date(startOfToday);
  startOfYesterday.setDate(startOfToday.getDate() - 1);

  const startOfWeek = new Date(startOfToday);
  startOfWeek.setDate(startOfToday.getDate() - startOfToday.getDay());

  const startOfLastWeek = new Date(startOfWeek);
  startOfLastWeek.setDate(startOfWeek.getDate() - 7);

  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  notifications.forEach((notification) => {
    const createdAt = new Date(notification.createdAt);

    if (createdAt >= startOfToday) {
      grouped.today.push(notification);
    } else if (createdAt >= startOfYesterday && createdAt < startOfToday) {
      grouped.yesterday.push(notification);
    } else if (createdAt >= startOfWeek) {
      grouped.thisWeek.push(notification);
    } else if (createdAt >= startOfLastWeek && createdAt < startOfWeek) {
      grouped.lastWeek.push(notification);
    } else if (createdAt >= startOfMonth) {
      grouped.thisMonth.push(notification);
    } else {
      grouped.older.push(notification);
    }
  });

  return grouped;
};
