import { UserOrderResponse } from "@ns/api";
import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

import chevrons from "/svg/chevrons.svg";

import { useOutsideClick } from "../../../hooks/use-outside-click.ts";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import { BidTab } from "../../pages/auth/user-page.tsx";
import BidList from "../bid-list.tsx";

interface Props {
  renderData: UserOrderResponse[];
  isFetching: boolean;
  lastItemRef: (node?: Element | null) => void;
  setTab: (tab: BidTab) => void;
  currencyFilter: string;
  bidTab: BidTab;
  setCurrencyFilter: (curr: string | undefined) => void;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

const FooterBidMenu: FC<Props> = ({
  renderData,
  setTab,
  isFetching,
  lastItemRef,
  currencyFilter,
  bidTab,
  setCurrencyFilter,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  useOutsideClick([containerRef], () => setIsOpen(false));
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCurrencySelectedColor = (currency: string) => {
    if (currencyFilter === currency) return "text-white";
  };

  const handleTabSelectedColor = (tab: BidTab) => {
    if (bidTab === tab) return "text-white";
  };

  return (
    <div
      ref={containerRef}
      className={`${isOpen && "h-full"} h-[70%] flex flex-1 flex-col items-end justify-end absolute bottom-0 max-w-[1096px] w-full sm:hidden`}
    >
      <div className="h-[500px] flex w-full absolute bottom-0">
        <div
          className={`flex flex-1 w-full flex-col footer-menu ${
            isOpen ? "footer-menu-active" : "footer-menu"
          } gap-[30px] rounded-tr-[30px] transition-transform duration-300 ease-in-out z-[100]`}
          style={{ transform: isOpen ? "translateY(0)" : "translateY(440px)" }}
        >
          <div
            onClick={toggleMenu}
            className={`absolute cursor-pointer flex justify-center items-center w-[40px] h-[40px] top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full ${
              isOpen
                ? "bg-backgroundSecondary  border border-gray-700"
                : "bg-borderBlue"
            } z-20`}
          >
            <img
              src={chevrons}
              className={`transition-transform duration-300 ease-in-out ${
                isOpen ? "rotate-[0deg]" : "rotate-[180deg]"
              }`}
            />
          </div>

          <div className="h-full flex items-center overflow-hidden gap-[16px] mt-5 p-2 flex-col z-10">
            <div className={"self-baseline flex gap-4"}>
              <span
                onClick={() => setTab(BidTab.MY_BIDS)}
                className={`text-base avenir-semibold self-start text-textGray3 ${handleTabSelectedColor(BidTab.MY_BIDS)}`}
              >
                {t("userPage.myBids")}
              </span>
              <span
                onClick={() => setTab(BidTab.WINNING_BIDS)}
                className={`text-base avenir-semibold self-start text-textGray3 ${handleTabSelectedColor(BidTab.WINNING_BIDS)}`}
              >
                {t("userPage.myWinningBids")}
              </span>
            </div>
            <div className={"flex gap-4 text-sm w-full text-textGray3"}>
              <div
                onClick={() => setCurrencyFilter("BTC")}
                className={"flex gap-2"}
              >
                <img
                  src={CURRENCY_IMAGE["BTC"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleCurrencySelectedColor("BTC")}>BTC</span>
              </div>
              <div
                className={"flex gap-2"}
                onClick={() => setCurrencyFilter("ETH")}
              >
                <img
                  src={CURRENCY_IMAGE["ETH"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleCurrencySelectedColor("ETH")}>ETH</span>
              </div>
              <div onClick={() => setCurrencyFilter(undefined)}>
                <span
                  className={`${currencyFilter === undefined && "text-white"}`}
                >
                  ALL
                </span>
              </div>
            </div>
            <div className="h-full flex justify-start overflow-auto gap-[16px] flex-col h-auto items-center w-full h-auto">
              <BidList
                bidTab={bidTab}
                data={renderData}
                isFetching={isFetching}
                lastItemRef={lastItemRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBidMenu;
