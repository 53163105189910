const EthToast = () => {
  return (
    <svg
      width="71"
      height="70"
      viewBox="0 0 71 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"h-full w-full"}
    >
      <rect
        x="0.413574"
        y="0.00854492"
        width="70"
        height="70"
        rx="35"
        fill="url(#paint0_linear_4842_10629)"
        fillOpacity="0.8"
      />
      <g clipPath="url(#clip0_4842_10629)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 8.00049C40.7451 8.00049 46.062 9.61334 50.5843 12.6351C55.1067 15.6568 58.6314 19.9517 60.7128 24.9767C62.7942 30.0017 63.3388 35.531 62.2777 40.8655C61.2167 46.1999 58.5975 51.1 54.7516 54.9459C50.9056 58.7919 46.0056 61.411 40.6711 62.4721C35.3367 63.5332 29.8073 62.9886 24.7824 60.9072C19.7574 58.8258 15.4625 55.301 12.4407 50.7787C9.419 46.2563 7.80615 40.9395 7.80615 35.5005C7.80615 28.207 10.7035 21.2123 15.8607 16.0551C21.018 10.8978 28.0127 8.00049 35.3062 8.00049V8.00049Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 13.9055L42.5412 24.7155L49.7762 35.528L35.3062 42.818L20.8362 35.5005L28.0712 24.688L35.3062 13.9055ZM35.3062 45.7305L21.6387 38.438L35.3062 57.0955L48.9737 38.438L35.3062 45.7305Z"
          fill="#343434"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 13.9055L42.5412 24.688L49.7762 35.5005L35.3062 30.038V13.9055Z"
          fill="#343434"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 45.7305L49.0012 38.438L35.3062 57.123V45.7305Z"
          fill="#313131"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 30.038L49.7762 35.5005L35.3062 42.7905V30.038Z"
          fill="#151515"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 13.9055L28.0712 24.688L20.8362 35.5005L35.3062 30.038V13.9055Z"
          fill="#8C8C8C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 45.7305L21.6387 38.438L35.3062 57.123V45.7305Z"
          fill="#8A8A8A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.3062 30.038L20.8362 35.5005L35.3062 42.7905V30.038Z"
          fill="#353535"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4842_10629"
          x1="0.413574"
          y1="0.00854492"
          x2="75.3572"
          y2="6.52568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9A548" />
          <stop offset="0.5" stop-color="#F9E690" />
          <stop offset="0.745543" stop-color="#C39C3E" />
          <stop offset="1" stop-color="#A87715" />
        </linearGradient>
        <clipPath id="clip0_4842_10629">
          <rect
            width="55"
            height="55"
            fill="white"
            transform="translate(7.91357 8.00049)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EthToast;
