import { z } from "zod";

import { FormNames } from "../name-generator";

export const profileSchema = z.object({
  currencyId: z.string(),
});

export type ProfileSchema = z.infer<typeof profileSchema>;

export const profileForm: FormNames<ProfileSchema> = {
  currencyId: "currencyId",
};
