import { ReactNode } from 'react';

import { BlueCircle } from '../../../../../apps/steal-deal-frontend/src/components/ui/bluecircle';

const WinnerFrame = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex items-center justify-center w-[200px] h-[200px] relative">
      <div className="absolute">
        <BlueCircle />
      </div>
      <div className="flex justify-center items-center rounded-full sm:p-6 relative winner-gradient w-24 h-24 sm:w-36 sm:h-36">
        {children}
      </div>
    </div>
  );
};

export default WinnerFrame;
