import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";

export const addReferralSchema = (t: TFunction) =>
  z.object({
    code: z
      .string({ message: t("form.code") })
      .min(6, { message: t("form.authentication.referral") }),
  });

const schema = addReferralSchema(staticTranslationFunction);

export type AddReferralSchema = z.infer<typeof schema>;
