export const EthClosed = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.730591"
        y="0.791992"
        width="48"
        height="48"
        rx="24"
        fill="#001214"
      />
      <g clip-path="url(#clip0_4876_9363)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 7.29199C28.1234 7.29199 31.5068 8.31835 34.3847 10.2413C37.2626 12.1642 39.5056 14.8973 40.8301 18.095C42.1547 21.2927 42.5012 24.8114 41.826 28.2061C41.1507 31.6007 39.484 34.7189 37.0366 37.1664C34.5892 39.6138 31.471 41.2805 28.0763 41.9557C24.6816 42.631 21.163 42.2844 17.9653 40.9599C14.7676 39.6353 12.0344 37.3923 10.1115 34.5145C8.18859 31.6366 7.16223 28.2532 7.16223 24.792C7.16223 20.1507 9.00598 15.6995 12.2879 12.4176C15.5697 9.13574 20.0209 7.29199 24.6622 7.29199V7.29199Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 11.0498L29.2663 17.9289L33.8703 24.8096L24.6622 29.4487L15.454 24.7921L20.0581 17.9114L24.6622 11.0498ZM24.6622 31.3021L15.9647 26.6614L24.6622 38.5344L33.3597 26.6614L24.6622 31.3021Z"
          fill="#343434"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 11.0498L29.2663 17.9114L33.8704 24.7921L24.6622 21.3159V11.0498Z"
          fill="#343434"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 31.3018L33.3772 26.6611L24.6622 38.5516V31.3018Z"
          fill="#313131"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 21.3154L33.8704 24.7916L24.6622 29.4307V21.3154Z"
          fill="#151515"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 11.0498L20.0581 17.9114L15.454 24.7921L24.6622 21.3159V11.0498Z"
          fill="#8C8C8C"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 31.3018L15.9647 26.6611L24.6622 38.5516V31.3018Z"
          fill="#8A8A8A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.6622 21.3154L15.454 24.7916L24.6622 29.4307V21.3154Z"
          fill="#353535"
        />
      </g>
      <defs>
        <clipPath id="clip0_4876_9363">
          <rect
            width="35"
            height="35"
            fill="white"
            transform="translate(7.23059 7.29199)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
