import plus from "/svg/plus.svg";
import minus from "/svg/minus.svg";
import icon from "/svg/tooltip.svg";

import TooltipComponent from "./tooltip.tsx";

const ExpandableItem = ({
  shouldExpand,
  text,
  imgSrc,
  isActive = false,
  onClick,
  hasMenu = false,
  isSelected = false,
  isLogout = false,
}: {
  shouldExpand: boolean;
  text: string;
  isActive?: boolean;
  imgSrc?: string;
  hasMenu?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  isLogout?: boolean;
}) => {
  const trigger = (
    <div
      className={`hover:bg-gray-500 group relative inline-flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors ${isSelected && "bg-gray-500"}`}
      onClick={onClick}
    >
      {imgSrc && <img src={imgSrc} className={`w-[1.5rem] h-[1.5rem]`} />}
      <span
        className={`overflow-hidden text-nowrap transition-all text-[16px] ${
          shouldExpand ? "w-52 ml-3" : "w-0"
        } ${isLogout && "text-[#CC8889]"}`}
      >
        {text}
      </span>
      {shouldExpand && hasMenu ? (
        !isActive ? (
          <img src={plus} className="w-[1.5rem] h-[1.5rem]" />
        ) : (
          <img src={minus} className="w-[1.5rem] h-[1.5rem]" />
        )
      ) : (
        shouldExpand && <div className="w-[1.5rem] h-[1.5rem]"></div>
      )}
    </div>
  );

  return (
    <>
      {(shouldExpand || isSelected) && trigger}
      {!isSelected && !shouldExpand && (
        <TooltipComponent
          trigger={trigger}
          content={
            <>
              <img src={icon} className="absolute top-[25%] left-[-0.5rem]" />
              <span>{text}</span>
            </>
          }
        />
      )}
    </>
  );
};

export default ExpandableItem;
