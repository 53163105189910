import { useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import logoButton from "/assets/logo-button.png";

import { PulseLoader } from "react-spinners";

import { getAllMessages, sendMessage } from "../../../graphql/api.ts";
import { useChatPagination } from "../../../hooks/chat-use-pagination.ts";
import { useOutsideClick } from "../../../hooks/use-outside-click.ts";
import { useUpload } from "../../../hooks/use-upload.ts";
import { api } from "../../api/client-api.ts";
import { getCapitalizedFirstLetter } from "../../helpers/helpers.ts";
import useUserStore from "../../store/user-store.ts";
import { useChat } from "../context/chat.context.tsx";
import ControlledTextarea from "../form/controlled-textarea.tsx";
import { TextWithLinks } from "../linkify-text.tsx";
import CloseBtn from "../svg-components/close-btn.tsx";
import { GalleryIcon } from "../svg-components/gallery-icon.tsx";
import SendMessageIcon from "../svg-components/send-message-icon.tsx";

type Props = {
  onClose: () => void;
};

type Message = {
  message: string;
  imageUrl?: string;
  time: string;
  user: {
    id: string;
    displayName: string;
    imageUrl: string | undefined;
  };
};

const formatTime = (timestamp: string) => {
  return format(new Date(parseInt(timestamp, 10)), "hh:mm a");
};

const Chat: FC<Props> = ({ onClose }) => {
  const chatContainerRef = useRef(null);
  const queryClient = useQueryClient();
  const { user, chatImageUrl, setChatImageUrl } = useUserStore();
  const { t } = useTranslation();
  const hasInitializedChat = useRef<number>(0);
  const initialScroll = useRef<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useOutsideClick([chatContainerRef], () => onClose());

  const { handleSubmit, reset, watch } = useFormContext<{
    message: string;
  }>();

  const message = watch("message");

  const { state, dispatch } = useChat();

  const {
    data: messagesData,
    containerRef,
    lastItemRef,
    page,
    isFetchingNextPage,
  } = useChatPagination<Message>({
    queryKey: [`getAllMessages`],
    queryFn: (offset, limit) => getAllMessages({ offset, limit }),
    enabled: !!user?.id,
    limit: 10,
  });

  const [mappedMessages, setMappedMessages] = useState<Message[]>([]);

  const scrollToBottom = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [containerRef]);

  useEffect(() => {
    if (messagesData?.length && hasInitializedChat.current !== page) {
      dispatch({
        type: "initChat",
        payload: {
          messages: messagesData ?? [],
        },
      });
      hasInitializedChat.current = page;
    }
  }, [messagesData, dispatch, page]);

  const formatMessages = useCallback(async () => {
    try {
      if (!state?.global?.messages || state.global.messages.length === 0) {
        setMappedMessages([]);
        return;
      }
      const array = state.global.messages;

      const messagesWithImage = await Promise.all(
        array.map(async (message: Message) => {
          let userData;

          if (message?.user?.id) {
            userData = await queryClient.fetchQuery(
              ["getUserByIdImage", message.user.id],
              () => api.user.getUserById(message.user.id),
            );
          }

          return {
            ...message,
            time: formatTime(message.time),
            user: { ...message.user, imageUrl: userData?.imageUrl },
          };
        }),
      );
      setMappedMessages(messagesWithImage);
    } catch (error) {
      console.error(error);
    }
  }, [state, queryClient]);

  useEffect(() => {
    formatMessages();
  }, [formatMessages]);

  useEffect(() => {
    if (mappedMessages?.length > 0 && !initialScroll.current) {
      scrollToBottom();
      initialScroll.current = true;
    }
  }, [mappedMessages, scrollToBottom]);

  const isValidForm = useMemo(
    () => !!message || !!chatImageUrl,
    [message, chatImageUrl],
  );

  const sendMessageHandler = async () => {
    if (isValidForm) {
      await handleSubmit((data) => {
        sendMessage({
          chatData: {
            message: data.message,
            imageUrl: chatImageUrl,
            user: {
              id: user?.id,
              displayName: user?.username,
            },
          },
        });
      })();
      reset();
      setChatImageUrl(undefined);
      scrollToBottom();
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      await sendMessageHandler();
    }
  };

  const { upload, fileUrl } = useUpload("chat");

  useEffect(() => {
    setChatImageUrl(fileUrl);
  }, [fileUrl, setChatImageUrl]);

  const handleUpload = async (file: File) => {
    const formData: FormData = new FormData();
    formData.append("file", file);
    await upload(formData);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles[0]) {
      return;
    }

    await handleUpload(acceptedFiles[0]);
  }, []);

  const { getInputProps } = useDropzone({
    accept:
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { "image/png": [".png"], "image/jpg": [".jpg"], "image/jpeg": [".jpeg"] },
    maxFiles: 1,
    maxSize: 26214400,
    onDrop,
  });

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (!isFetchingNextPage) {
      setTimeout(() => {
        setShowSpinner(false);
      }, 5000);

      return;
    }
    setShowSpinner(true);
  }, [isFetchingNextPage]);

  if (!user) return <></>;

  const isSameMessage = (message: Message, index: number) => {
    if (!message || !message.time) return false;

    const nextMessage = mappedMessages[index];
    const isSameUser = nextMessage?.user?.id === message?.user?.id;

    if (!message?.time || !nextMessage?.time) return false;

    const currentMessageTime = parse(message.time, "hh:mm a", new Date());
    const nextMessageTime = parse(nextMessage.time, "hh:mm a", new Date());

    const isSameMinute =
      currentMessageTime.getMinutes() === nextMessageTime.getMinutes();

    return isSameUser && isSameMinute;
  };

  return (
    <div
      className={
        "fixed bottom-0 right-0 w-full h-full sm:h-full sm:absolute flex flex-col sm:max-h-[80%] sm:bottom-[50px] sm:right-[74px] sm:w-[450px] z-[1000000001] sm:rounded-[20px] full-height"
      }
      ref={chatContainerRef}
    >
      <div
        className={
          "chat-heading h-fit max-h-[100px] sm:max-h-[160px] flex flex-col justify-center sm:rounded-tl-[20px] sm:rounded-tr-[20px] w-full relative pl-8 pr-8 pt-4 pb-4 full-height"
        }
      >
        <div className={"flex items-end gap-4"}>
          <img
            src={logoButton}
            alt="logo button"
            className={"w-[40px] h-[40px] sm:w-[60px] sm:h-[60px]"}
          />
          <div className={"text-2xl text-white"}>{t("chat.liveChat")}</div>
        </div>
        <div className={"text-white"}>{t("chat.interactWithOthers")}</div>
        <CloseBtn
          className={"absolute top-[15px] right-[15px]"}
          onClick={onClose}
        />
      </div>
      <div
        className={
          "flex flex-col bg-[#001214] p-8 flex-1 overflow-scroll full-height"
        }
        ref={containerRef}
      >
        {showSpinner && (
          <div className={"h-32 w-full flex items-center justify-center"}>
            <PulseLoader loading={showSpinner} size={10} color={"#07252a"} />
          </div>
        )}
        {mappedMessages.map((message: Message, index: number) => {
          const isLastItem = mappedMessages.length === index + 10 * page;

          const isNextMessageFromSameSenderAndTime = isSameMessage(
            message,
            index + 1,
          );

          const isPreviousMessageFromSameSenderAndTime = isSameMessage(
            message,
            index - 1,
          );

          return (
            <div
              className={"w-full"}
              id={message.time}
              ref={isLastItem ? lastItemRef : undefined}
              key={`${message.time}-${index}`}
            >
              {message.user.id !== user?.id ? (
                <div className={"flex gap-2"}>
                  {!isPreviousMessageFromSameSenderAndTime ? (
                    <>
                      {message.user.imageUrl ? (
                        <img
                          src={message.user.imageUrl}
                          className="w-[40px] h-[40px] rounded-full bg-white"
                          alt="Profile image"
                        />
                      ) : (
                        <span className="text-2xl flex items-center justify-center w-[40px] h-[40px] rounded-full bg-white">
                          {getCapitalizedFirstLetter(message.user.displayName)}
                        </span>
                      )}
                    </>
                  ) : (
                    <div className="invisible w-[40px] h-[40px] sm:rounded-full bg-white" />
                  )}
                  <div className={"text-white"}>
                    {!isPreviousMessageFromSameSenderAndTime && (
                      <div>{message.user.displayName}</div>
                    )}
                    <div
                      className={
                        "mt-2 pt-2 pb-2 pl-2 pr-2 bg-[#07252A] sm:rounded-tr-[10px] sm:rounded-br-[10px] sm:rounded-bl-[10px] max-w-72 w-fit"
                      }
                    >
                      <TextWithLinks text={message.message}></TextWithLinks>
                      {message?.imageUrl && (
                        <img
                          className={"w-16 h-16 cursor-pointer"}
                          src={message?.imageUrl}
                          onClick={() => window.open(message!.imageUrl)}
                        />
                      )}
                    </div>
                    {!isNextMessageFromSameSenderAndTime && (
                      <div className={"text-right text-sm opacity-40"}>
                        {message.time}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={
                    "flex flex-col text-white items-end w-full self-end"
                  }
                >
                  <div
                    className={
                      "mt-2 pt-2 pb-2 pl-2 pr-2 bg-[#26616C] sm:rounded-tl-[10px] sm:rounded-br-[10px] sm:rounded-bl-[10px] max-w-72"
                    }
                  >
                    <TextWithLinks text={message.message}></TextWithLinks>
                    {message?.imageUrl && (
                      <img
                        className={"w-16 h-16 cursor-pointer"}
                        src={message?.imageUrl}
                        onClick={() => window.open(message!.imageUrl)}
                      />
                    )}
                  </div>
                  {!isNextMessageFromSameSenderAndTime && (
                    <div className={"w-full flex flex-row justify-end"}>
                      <div
                        className={"text-left text-sm opacity-40 self-baseline"}
                      >
                        {message.time}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div
        className={`relative chat-input w-full sm:rounded-bl-[20px] sm:rounded-br-[20px] h-[55px]`}
      >
        {chatImageUrl && (
          <div
            className={
              "absolute w-full pl-8 pt-2 chat-input top-[-85%] -translate-y-1/2 bg-transparent"
            }
          >
            <img
              className={"w-24 h-24"}
              src={chatImageUrl}
              alt={"chat-image"}
            ></img>
            <div className="absolute top-[0.5rem] left-[8.5rem]">
              <CloseBtn
                className={"h-[1rem] w-[1rem]"}
                onClick={() => setChatImageUrl(undefined)}
              />
            </div>
          </div>
        )}
        <ControlledTextarea
          className={"pl-8 bg-transparent sm:rounded-bl-[20px] max-w-[75%]"}
          label={"send message"}
          name={"message"}
          placeholder={t("chat.reply")}
          onKeyDown={handleKeyDown}
        />
        <input {...getInputProps()} ref={fileInputRef} />
        <GalleryIcon
          onClick={() => (!chatImageUrl ? fileInputRef?.current?.click() : {})}
          className={
            "absolute right-14 h-6 w-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
          }
        />
        <SendMessageIcon
          onClick={async () => {
            await sendMessageHandler();
          }}
          isDisabled={!isValidForm}
          className={`absolute right-4 h-8 w-8 top-1/2 transform -translate-y-1/2 ${isValidForm ? "cursor-pointer" : "cursor-not-allowed"}`}
        />
      </div>
    </div>
  );
};

export default Chat;
