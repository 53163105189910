const CoinPaymentIcon = () => {
  return (
    <svg
      width="160"
      height="45"
      viewBox="0 0 203 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4727_702)">
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.173096 20.7467L1.54408 15.5338L11.1996 9.97964V16.9385L0.173096 23.8447V20.7467Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.74268 20.9298L15.6156 14.2122V19.7289C15.6156 19.7289 15.4503 21.5498 14.6555 21.8889C14.0678 22.1272 4.74559 27.608 4.74559 27.608L4.74268 20.9298Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6643 24.1888L20.33 18.5289V24.0448C20.33 24.0448 20.33 25.5698 19.3698 26.1062C18.7503 26.4353 10.6664 31.1101 10.6664 31.1101L10.6643 24.1888Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6702 8.00887L19.9845 4.60468V11.56L14.6752 14.7884L14.6702 8.00887Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9975 12.1531L24.338 9.01892V16.2189L18.9954 19.302L18.9975 12.1531Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.0918 2.74925L26.7287 0.538116V5.33477C26.7287 5.33477 26.7287 7.73455 25.7685 8.21481C25.5837 8.31201 23.1114 9.74194 23.1114 9.74194L23.0918 2.74925Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.1881 16.7953L26.8112 14.6353L26.7279 20.1469C26.7279 20.1469 26.8112 21.6005 25.7677 22.3069C25.5272 22.5193 23.0599 23.7131 23.1034 23.7368C23.1468 23.7606 23.1737 16.6002 23.1881 16.7953Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.4534 7.25361L30.352 5.34705C30.352 5.34705 30.381 8.20691 30.352 10.1444C30.3307 10.733 30.1925 11.3115 29.9453 11.8467C29.6982 12.382 29.3472 12.8635 28.912 13.2635C28.912 13.2635 27.4129 14.1908 27.4585 14.2168C27.5042 14.2427 27.4534 7.25361 27.4534 7.25361Z"
          fill="#1C60DD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1998 9.97449C11.1549 10.0681 2.33559 14.4169 0.998668 15.9088C1.68054 15.3371 0.418974 16.4732 0.274048 18.1847L0.693603 34.2689C0.693603 34.2689 0.0197072 36.4136 3.33266 38.3058C5.69494 39.655 19.3583 48.0015 21.0358 48.7857C22.7133 49.5698 23.146 48.3307 23.146 48.3307V32.7186C23.1097 32.6763 23.6467 30.252 20.7714 28.4238C20.2192 27.9472 2.51457 19.9343 1.76241 17.4114C0.954466 14.702 11.1998 10.675 11.1998 9.97449Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3215 32.5782L23.1835 31.9582V48.5903C23.1835 48.5903 22.7677 49.422 22.3215 49.5012C22.2685 49.4738 22.3215 32.5782 22.3215 32.5782Z"
          fill="#C0C9D7"
        />
        <path
          d="M1.15522 17.9585C1.27773 18.1518 1.41819 18.3333 1.57478 18.5007C1.39762 18.3062 1.24883 18.0881 1.13277 17.8527C0.0893132 15.1785 7.65435 12.1717 10.8064 10.1629C10.8064 10.1629 3.15517 13.9379 1.63854 15.2549C0.298004 16.4183 0.201629 17.1218 0.201629 17.1218L0.0400391 18.1046C0.0733717 17.628 0.084241 17.2024 0.201629 17.1218C0.187137 17.2046 1.14291 17.8338 1.15522 17.9585Z"
          fill="#1C60DD"
        />
        <path
          d="M19.9647 29.0851C19.3429 28.5631 11.4083 24.9581 5.92151 21.6979C6.24759 21.9024 6.53815 22.0817 6.7664 22.2314L19.7632 48.2005C20.0371 48.3445 20.2436 48.4604 20.4016 48.534L22.3218 49.4872V32.8053C22.2979 32.7867 22.5131 30.4833 19.9647 29.0851Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.3"
          d="M0.592588 36.4325C0.29555 35.8244 0.0956256 35.1739 0 34.5044C0.0291694 35.1868 0.233139 35.8505 0.592588 36.4325Z"
          fill="url(#paint0_linear_4727_702)"
        />
        <path
          d="M5.9216 21.6975C3.97962 20.5556 2.37097 19.431 1.57389 18.5014C1.41751 18.3338 1.27729 18.1522 1.15505 17.9586C1.14056 17.834 0.213049 17.1169 0.237686 17.0312C0.120298 17.1125 0.0456616 17.6173 0.0123291 18.0968L0.0376909 34.5007C0.131891 35.7155 0.226092 35.7621 0.550722 36.4325C1.02535 37.1632 1.26013 37.4404 2.10648 38.1908C4.09339 39.3967 16.396 46.3886 19.7807 48.1988L6.76649 22.2239L5.9216 21.6975Z"
          fill="#1C60DD"
        />
        <path
          opacity="0.2"
          d="M5.89986 21.6862C3.95788 20.5443 2.34923 19.4196 1.55215 18.4901C1.40271 18.3219 1.26975 18.1398 1.15505 17.9465C1.14056 17.8226 0.209426 17.1056 0.237686 17.0198C0.179717 17.0753 0.0717481 17.7175 0.0384154 18.1964L0.0123291 34.5492C0.0594293 35.5708 0.260148 35.8537 0.555794 36.4629C0.978971 37.1633 1.52316 37.6868 2.13692 38.1793C4.12455 39.3852 16.375 46.3774 19.7596 48.1874L6.74476 22.2125L5.89986 21.6862Z"
          fill="url(#paint1_linear_4727_702)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5237 35.8435L16.8231 34.1874C16.3434 33.9109 15.881 34.0896 16.118 34.5785V39.0346C16.2332 39.1996 16.4658 39.7328 16.3579 40.0023C15.9709 39.9835 17.894 41.5861 18.981 42.1903C19.2977 42.3666 19.8882 42.4402 19.8426 41.7136V36.4201C19.8439 36.305 19.8149 36.1912 19.7585 36.0905C19.7027 35.9895 19.6219 35.9044 19.5237 35.8435Z"
          fill="#B0B5BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4081 36.1107C18.9878 35.5908 17.5835 34.7925 16.6306 34.1093C16.3908 33.935 15.9734 34.0589 15.9784 34.3475C16.0386 37.7193 16.0509 39.2708 16.2473 39.9311C16.3922 40.4352 17.9827 41.4942 18.887 42.0911C19.1559 42.272 19.5189 42.3151 19.5305 41.9883C19.5813 40.5705 19.5305 37.2816 19.5305 36.4406C19.5264 36.3195 19.4826 36.2033 19.4059 36.1094L19.4081 36.1107Z"
          fill="white"
        />
        <path
          d="M31.8331 25.0428C31.8331 30.1322 35.7261 33.6878 40.525 33.6878C44.0754 33.6878 47.1359 31.9344 48.4335 28.6225H45.0793C44.1732 30.4247 42.5575 31.277 40.525 31.277C37.1462 31.277 34.6978 28.8661 34.6978 25.0428C34.6978 21.2195 37.1462 18.833 40.525 18.833C42.5575 18.833 44.1732 19.6852 45.0793 21.4874H48.4335C47.1359 18.1754 44.0754 16.3977 40.525 16.3977C35.7261 16.3977 31.8331 19.9775 31.8331 25.0428Z"
          fill="white"
        />
        <path
          d="M63.8204 26.8205C63.8204 22.6075 60.7845 19.9044 56.9403 19.9044C53.0964 19.9044 50.0602 22.6075 50.0602 26.8205C50.0602 31.0333 52.9739 33.7609 56.818 33.7609C60.6864 33.7609 63.8204 31.0333 63.8204 26.8205ZM52.9005 26.8205C52.9005 23.7764 54.7859 22.3153 56.8916 22.3153C58.9725 22.3153 60.9559 23.7764 60.9559 26.8205C60.9559 29.8645 58.8991 31.35 56.818 31.35C54.7123 31.35 52.9005 29.8645 52.9005 26.8205Z"
          fill="white"
        />
        <path
          d="M65.8451 33.5416H68.6364V20.1235H65.8451V33.5416ZM67.2653 18.3458C68.2447 18.3458 69.0282 17.5665 69.0282 16.5681C69.0282 15.5696 68.2447 14.7904 67.2653 14.7904C66.2615 14.7904 65.4781 15.5696 65.4781 16.5681C65.4781 17.5665 66.2615 18.3458 67.2653 18.3458Z"
          fill="white"
        />
        <path
          d="M81.14 33.5417H83.9067V25.6271C83.9067 21.8769 81.5318 19.9044 78.3732 19.9044C76.7574 19.9044 75.2637 20.5619 74.3579 21.6577V20.1236H71.5667V33.5417H74.3579V26.0412C74.3579 23.5816 75.7046 22.3153 77.7612 22.3153C79.7934 22.3153 81.14 23.5816 81.14 26.0412V33.5417Z"
          fill="white"
        />
        <path
          d="M89.5239 24.4095V18.8817H92.6089C94.7879 18.8817 95.7672 19.9044 95.7672 21.6578C95.7672 23.3625 94.7879 24.4095 92.6089 24.4095H89.5239ZM98.6318 21.6578C98.6318 18.906 96.6487 16.6169 92.6089 16.6169H86.7325V33.5417H89.5239V26.6743H92.6089C97.0405 26.6743 98.6318 24.0687 98.6318 21.6578Z"
          fill="white"
        />
        <path
          d="M99.6293 26.7717C99.6293 30.9115 102.445 33.7609 105.946 33.7609C108.199 33.7609 109.815 32.6894 110.672 31.5446V33.5417H113.488V20.1236H110.672V22.0718C109.839 20.9759 108.272 19.9044 105.996 19.9044C102.445 19.9044 99.6293 22.6318 99.6293 26.7717ZM110.672 26.8205C110.672 29.7184 108.689 31.35 106.582 31.35C104.501 31.35 102.494 29.6697 102.494 26.7717C102.494 23.8738 104.501 22.3153 106.582 22.3153C108.689 22.3153 110.672 23.9469 110.672 26.8205Z"
          fill="white"
        />
        <path
          d="M121.732 30.3516L117.937 20.1236H114.827L120.14 33.3225L117.398 39.8487H120.287L128.563 20.1236H125.674L121.732 30.3516Z"
          fill="white"
        />
        <path
          d="M149.057 33.5417H151.824V25.6271C151.824 21.8769 149.425 19.9044 146.266 19.9044C144.308 19.9044 142.397 20.9272 141.565 22.5587C140.635 20.8054 138.848 19.9044 136.717 19.9044C135.102 19.9044 133.632 20.5619 132.726 21.6577V20.1236H129.935V33.5417H132.726V26.0412C132.726 23.5816 134.073 22.3153 136.129 22.3153C138.162 22.3153 139.509 23.5816 139.509 26.0412V33.5417H142.276V26.0412C142.276 23.5816 143.621 22.3153 145.679 22.3153C147.711 22.3153 149.057 23.5816 149.057 26.0412V33.5417Z"
          fill="white"
        />
        <path
          d="M160.321 22.2422C162.403 22.2422 164.043 23.5573 164.092 25.6271H156.624C156.918 23.5085 158.436 22.2422 160.321 22.2422ZM166.639 29.5478H163.628C163.113 30.595 162.158 31.4231 160.444 31.4231C158.388 31.4231 156.796 30.0837 156.6 27.8674H166.908C166.981 27.405 167.006 26.9666 167.006 26.5038C167.006 22.5588 164.288 19.9044 160.444 19.9044C156.453 19.9044 153.711 22.6075 153.711 26.8205C153.711 31.0333 156.575 33.7609 160.444 33.7609C163.749 33.7609 165.88 31.8855 166.639 29.5478Z"
          fill="white"
        />
        <path
          d="M178.591 33.5417H181.358V25.6271C181.358 21.8769 178.984 19.9044 175.824 19.9044C174.209 19.9044 172.715 20.5619 171.81 21.6577V20.1236H169.019V33.5417H171.81V26.0412C171.81 23.5816 173.156 22.3153 175.212 22.3153C177.245 22.3153 178.591 23.5816 178.591 26.0412V33.5417Z"
          fill="white"
        />
        <path
          d="M184.625 29.8157C184.625 32.5432 186.118 33.5416 188.518 33.5416H190.722V31.2281H189.008C187.832 31.2281 187.441 30.8141 187.441 29.8157V22.3882H190.722V20.1235H187.441V16.7873H184.625V20.1235H183.033V22.3882H184.625V29.8157Z"
          fill="white"
        />
        <path
          d="M202.623 29.7915C202.476 24.9453 194.861 26.4551 194.861 23.752C194.861 22.851 195.644 22.2422 197.138 22.2422C198.657 22.2422 199.562 23.0458 199.66 24.2147H202.451C202.304 21.536 200.297 19.9044 197.236 19.9044C194.053 19.9044 192.045 21.6334 192.045 23.7764C192.045 28.6955 199.881 27.1858 199.881 29.8158C199.881 30.7411 199.024 31.4231 197.408 31.4231C195.865 31.4231 194.812 30.522 194.714 29.426H191.826C191.947 31.8613 194.224 33.7609 197.457 33.7609C200.639 33.7609 202.623 32.0562 202.623 29.7915Z"
          fill="white"
        />
        <path
          d="M196.877 43.8999C196.907 43.1266 197.16 42.5303 197.636 42.1111C198.113 41.6919 198.76 41.4824 199.576 41.4824C200.119 41.4824 200.582 41.5762 200.965 41.7642C201.355 41.9521 201.648 42.2087 201.842 42.5339C202.045 42.8592 202.146 43.2241 202.146 43.6289C202.146 44.1058 202.027 44.4962 201.788 44.7997C201.549 45.096 201.271 45.2983 200.954 45.4068V45.4502C201.365 45.5875 201.691 45.8151 201.929 46.1331C202.168 46.4511 202.287 46.8594 202.287 47.3582C202.287 47.8062 202.183 48.2038 201.972 48.5507C201.77 48.8904 201.47 49.1578 201.073 49.3528C200.682 49.548 200.216 49.6456 199.675 49.6456C198.807 49.6456 198.113 49.4323 197.593 49.0059C197.08 48.5795 196.809 47.9363 196.78 47.0763H198.579C198.587 47.3943 198.677 47.6472 198.85 47.8351C199.024 48.0158 199.277 48.1062 199.609 48.1062C199.892 48.1062 200.108 48.0267 200.26 47.8677C200.419 47.7015 200.498 47.4846 200.498 47.2172C200.498 46.8703 200.386 46.621 200.162 46.4692C199.945 46.3102 199.594 46.2307 199.11 46.2307H198.763V44.7238H199.11C199.479 44.7238 199.775 44.6624 199.999 44.5395C200.231 44.4094 200.346 44.1817 200.346 43.8565C200.346 43.5963 200.274 43.3939 200.129 43.2494C199.986 43.1049 199.787 43.0326 199.533 43.0326C199.259 43.0326 199.053 43.1157 198.916 43.282C198.786 43.4482 198.71 43.6541 198.688 43.8999H196.877Z"
          fill="white"
        />
        <path
          d="M192.541 43.3904V41.6667H195.544V49.5806H193.614V43.3904H192.541Z"
          fill="white"
        />
        <path
          d="M185.665 45.5152C185.665 44.2722 185.903 43.2928 186.38 42.5773C186.865 41.8618 187.641 41.5041 188.711 41.5041C189.781 41.5041 190.554 41.8618 191.03 42.5773C191.515 43.2928 191.757 44.2722 191.757 45.5152C191.757 46.7728 191.515 47.7593 191.03 48.4748C190.554 49.1903 189.781 49.548 188.711 49.548C187.641 49.548 186.865 49.1903 186.38 48.4748C185.903 47.7593 185.665 46.7728 185.665 45.5152ZM189.936 45.5152C189.936 44.7853 189.856 44.2251 189.697 43.8349C189.539 43.4373 189.21 43.2386 188.711 43.2386C188.212 43.2386 187.883 43.4373 187.724 43.8349C187.566 44.2251 187.485 44.7853 187.485 45.5152C187.485 46.0066 187.515 46.415 187.572 46.7402C187.63 47.0582 187.745 47.3184 187.92 47.5208C188.1 47.716 188.364 47.8134 188.711 47.8134C189.058 47.8134 189.318 47.716 189.491 47.5208C189.672 47.3184 189.791 47.0582 189.849 46.7402C189.907 46.415 189.936 46.0066 189.936 45.5152Z"
          fill="white"
        />
        <path
          d="M179.407 48.0953C179.652 47.9002 179.764 47.8098 179.742 47.8243C180.45 47.2389 181.007 46.7583 181.411 46.3825C181.824 46.0066 182.17 45.6128 182.453 45.2008C182.734 44.7888 182.876 44.3877 182.876 43.9975C182.876 43.7011 182.807 43.4699 182.669 43.3037C182.532 43.1375 182.326 43.0543 182.051 43.0543C181.777 43.0543 181.56 43.1591 181.401 43.3687C181.249 43.5711 181.173 43.8601 181.173 44.236H179.384C179.399 43.6216 179.529 43.1085 179.774 42.6966C180.027 42.2845 180.356 41.981 180.761 41.7859C181.173 41.5907 181.629 41.4932 182.127 41.4932C182.988 41.4932 183.634 41.7136 184.068 42.1545C184.509 42.5954 184.729 43.1699 184.729 43.8782C184.729 44.6515 184.466 45.3707 183.938 46.0356C183.41 46.6932 182.738 47.3365 181.921 47.9653H184.848V49.4721H179.407V48.0953Z"
          fill="white"
        />
        <path
          d="M175.932 46.3826C175.932 46.5705 175.922 46.7693 175.901 46.9788H171.152C171.188 47.5642 171.387 48.0232 171.748 48.3556C172.117 48.6809 172.561 48.8435 173.082 48.8435C173.508 48.8435 173.862 48.7459 174.143 48.5508C174.433 48.3484 174.635 48.0809 174.751 47.7485H175.814C175.654 48.3195 175.336 48.7856 174.859 49.147C174.382 49.5011 173.79 49.6782 173.082 49.6782C172.517 49.6782 172.012 49.5517 171.564 49.2987C171.123 49.0458 170.776 48.688 170.523 48.2255C170.271 47.7557 170.144 47.2138 170.144 46.5994C170.144 45.9851 170.266 45.4466 170.513 44.984C170.758 44.5216 171.102 44.1674 171.542 43.9217C171.99 43.6688 172.503 43.5422 173.082 43.5422C173.645 43.5422 174.143 43.6651 174.578 43.9108C175.011 44.1566 175.343 44.4962 175.575 44.9299C175.814 45.3563 175.932 45.8405 175.932 46.3826ZM174.914 46.1766C174.914 45.8008 174.831 45.4792 174.665 45.2118C174.498 44.9371 174.27 44.7311 173.982 44.5938C173.699 44.4493 173.385 44.3769 173.038 44.3769C172.539 44.3769 172.113 44.536 171.759 44.854C171.412 45.172 171.214 45.6129 171.163 46.1766H174.914Z"
          fill="#90A0E6"
        />
        <path
          d="M163.559 46.5994C163.559 45.9851 163.681 45.4502 163.927 44.9949C164.173 44.5324 164.512 44.1747 164.946 43.9217C165.387 43.6688 165.889 43.5422 166.453 43.5422C167.183 43.5422 167.783 43.7193 168.253 44.0735C168.73 44.4276 169.044 44.919 169.196 45.5478H168.134C168.032 45.1864 167.833 44.9009 167.537 44.6914C167.248 44.4818 166.887 44.3769 166.453 44.3769C165.889 44.3769 165.434 44.5721 165.087 44.9624C164.74 45.3454 164.566 45.8911 164.566 46.5994C164.566 47.3149 164.74 47.8678 165.087 48.258C165.434 48.6483 165.889 48.8435 166.453 48.8435C166.887 48.8435 167.248 48.7423 167.537 48.5399C167.826 48.3376 168.025 48.0485 168.134 47.6726H169.196C169.037 48.2797 168.718 48.7676 168.242 49.1361C167.765 49.4975 167.168 49.6782 166.453 49.6782C165.889 49.6782 165.387 49.5517 164.946 49.2987C164.512 49.0458 164.173 48.688 163.927 48.2255C163.681 47.763 163.559 47.2209 163.559 46.5994Z"
          fill="#90A0E6"
        />
        <path
          d="M159.887 43.5314C160.61 43.5314 161.196 43.7518 161.643 44.1926C162.091 44.6263 162.315 45.2551 162.315 46.0791V49.5806H161.34V46.2199C161.34 45.6273 161.191 45.1756 160.895 44.8649C160.599 44.5468 160.194 44.3878 159.682 44.3878C159.16 44.3878 158.745 44.5504 158.434 44.8756C158.131 45.2009 157.979 45.6743 157.979 46.2958V49.5806H156.993V43.6398H157.979V44.4854C158.174 44.1818 158.438 43.9469 158.77 43.7807C159.111 43.6145 159.483 43.5314 159.887 43.5314Z"
          fill="#90A0E6"
        />
        <path
          d="M154.834 42.6749C154.647 42.6749 154.487 42.6098 154.357 42.4797C154.227 42.3497 154.163 42.1906 154.163 42.0027C154.163 41.8148 154.227 41.6558 154.357 41.5257C154.487 41.3957 154.647 41.3305 154.834 41.3305C155.015 41.3305 155.167 41.3957 155.29 41.5257C155.42 41.6558 155.485 41.8148 155.485 42.0027C155.485 42.1906 155.42 42.3497 155.29 42.4797C155.167 42.6098 155.015 42.6749 154.834 42.6749ZM155.311 43.6397V49.5806H154.324V43.6397H155.311Z"
          fill="#90A0E6"
        />
        <path
          d="M150.357 49.6565C149.859 49.6565 149.411 49.5697 149.014 49.3963C148.623 49.2156 148.316 48.9699 148.092 48.659C147.869 48.3411 147.752 47.9761 147.745 47.5641H148.797C148.833 47.9183 148.978 48.2183 149.23 48.464C149.49 48.7024 149.867 48.8217 150.357 48.8217C150.828 48.8217 151.196 48.7061 151.463 48.4748C151.738 48.2362 151.876 47.9327 151.876 47.5641C151.876 47.2751 151.796 47.0401 151.637 46.8594C151.478 46.6788 151.279 46.5415 151.04 46.4475C150.803 46.3536 150.481 46.2524 150.076 46.144C149.577 46.0139 149.176 45.8838 148.873 45.7537C148.576 45.6236 148.32 45.4212 148.103 45.1466C147.894 44.8647 147.788 44.4889 147.788 44.0191C147.788 43.6072 147.894 43.2422 148.103 42.9242C148.313 42.6062 148.606 42.3604 148.981 42.1871C149.365 42.0135 149.801 41.9268 150.293 41.9268C151.001 41.9268 151.58 42.1039 152.028 42.458C152.483 42.8122 152.74 43.282 152.798 43.8674H151.713C151.677 43.5782 151.525 43.3253 151.258 43.1085C150.991 42.8844 150.637 42.7725 150.196 42.7725C149.783 42.7725 149.447 42.8808 149.187 43.0977C148.927 43.3072 148.797 43.6036 148.797 43.9867C148.797 44.2612 148.873 44.4853 149.024 44.6588C149.184 44.8322 149.375 44.9659 149.599 45.0599C149.83 45.1466 150.152 45.2478 150.564 45.3635C151.063 45.5007 151.463 45.638 151.767 45.7754C152.071 45.9054 152.331 46.1115 152.548 46.3933C152.765 46.668 152.873 47.0438 152.873 47.5208C152.873 47.8893 152.775 48.2362 152.58 48.5616C152.385 48.8868 152.096 49.1506 151.713 49.353C151.33 49.5553 150.879 49.6565 150.357 49.6565Z"
          fill="#90A0E6"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4727_702"
          x1="8.9265"
          y1="25.5944"
          x2="0.452942"
          y2="35.4091"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopColor="#1C60DD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4727_702"
          x1="13.0315"
          y1="29.1369"
          x2="4.55837"
          y2="38.9536"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" />
          <stop offset="1" stopColor="#1C60DD" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_4727_702">
          <rect
            width="203"
            height="54.9792"
            fill="white"
            transform="translate(0 0.538116)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoinPaymentIcon;
