import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const giftValidationSchema = (t: TFunction) =>
  z.object({
    email: z
      .string()
      .min(1, { message: t("form.authentication.requiredEmail") })
      .email({ message: t("form.authentication.invalidEmail") }),
    currencyId: z.string().min(1, { message: "form.currencyRequired" }),
  });

const schema = giftValidationSchema(staticTranslationFunction);

export type GiftData = z.infer<typeof schema>;

export const giftFieldNames: FormNames<GiftData> = {
  email: "email",
  currencyId: "currencyId",
};
