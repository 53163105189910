import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldErrors, FieldValues } from 'react-hook-form';

import { SuccessfullyUploadedImage } from './successfully-uploaded-image';
import { UploadImageIdle } from './upload-image-idle';
import { UploadProgress } from './upload-progress';

type Props = {
  id?: string;
  uploadFile: (file: File) => void;
  fileSrc?: string | null;
  isSuccess: boolean;
  isProgress: boolean;
  progressPercentage: number;
  errors: FieldErrors<FieldValues>;
  maxFileSizeText: string;
  browseText: string;
  errorText: string;
  maxFileSize: string;
  dragAndDropText: string;
  fileSize: number;
  onDelete: (name: string) => void;
  uploadAgainText: string;
};

export const UploadImage: FC<Props> = ({
  id,
  uploadFile,
  fileSrc,
  isSuccess,
  isProgress,
  progressPercentage,
  errors,
  maxFileSizeText,
  browseText,
  errorText,
  maxFileSize,
  dragAndDropText,
  fileSize,
  onDelete,
  uploadAgainText,
}) => {
  const [fileTitle, setFileTitle] = useState<string | undefined>(undefined);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles[0]) {
      setIsUploadError(true);
      return;
    }

    uploadFile(acceptedFiles[0]);
    setFileTitle(acceptedFiles[0].name);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] },
    maxFiles: 1,
    maxSize: 26214400,
    onDrop,
  });

  return (
    <div
      id={id}
      className={classNames(
        'w-full h-56 hover:cursor-pointer rounded',
        { ['border border-dashed border-primary rounded-2xl']: !isSuccess && !isProgress },
        { ['border-solid']: isDragActive }
      )}
    >
      <div {...getRootProps()} className={'h-[inherit] w-[inherit] justify-center flex flex-col items-center'}>
        {isSuccess || isProgress ? (
          isSuccess ? (
            <SuccessfullyUploadedImage
              fileTitle={fileTitle ?? ''}
              uploadAgainText={uploadAgainText}
              onDelete={onDelete}
              fileSrc={fileSrc ?? ''}
            />
          ) : (
            <UploadProgress
              fileTitle={fileTitle ?? ''}
              fileSize={fileSize}
              progressPercentage={progressPercentage}
              onDelete={onDelete}
            />
          )
        ) : (
          <>
            <input {...getInputProps()} />
            <UploadImageIdle
              dragAndDropText={dragAndDropText}
              browseText={browseText}
              maxFileSizeText={maxFileSizeText}
              errorText={errorText}
              maxFileSize={maxFileSize}
              errors={errors}
              isUploadError={isUploadError}
            />
          </>
        )}
      </div>
    </div>
  );
};
