import { LanguageResponse } from "@ns/api";
import { Dialog } from "@ns/client-ui";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import useUserStore from "../../store/user-store";
import SearchInput from "../search-input";

const ChangeLanguageDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { setLanguageId, user } = useUserStore();
  const { data: languagesData } = useQuery<{ items: LanguageResponse[] }>({
    queryKey: ["languages"],
    queryFn: () => api.resources.getLanguages(),
  });

  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState<LanguageResponse[] | []>([]);

  const handleSearch = (value: string) => {
    const results = languagesData?.items.filter((language) =>
      language.code.toLowerCase().includes(value),
    );
    setLanguages(results || []);
  };

  const { mutate } = useMutation({
    mutationFn: (languageId: string) => api.user.updateUserLanguage(languageId),
    onSuccess: () => {
      queryClient.invalidateQueries(["auth"]);
    },
  });

  const handleChangeLanguage = (code: string, id: string) => {
    i18n.changeLanguage(code);
    setLanguageId(id);
    if (user) {
      mutate(id);
    }
  };

  const renderLanguages = useMemo(() => {
    return languages.map((language) => (
      <div
        className="p-[16px] pl-0 border-b border-solid border-bidCardBorder text-white cursor-pointer"
        onClick={() => handleChangeLanguage(language.code, language.id)}
        key={language.id}
      >
        <div className="flex items-center justify-start gap-[10px]">
          <img src={language.flag} className="w-[24px]" />
          <span className="text-[16px]">{t(`languages.${language.code}`)}</span>
        </div>
      </div>
    ));
  }, [languages, t]);

  useEffect(() => {
    if (languagesData) {
      setLanguages(languagesData.items || []);
    }
  }, [languagesData]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("profile.dialog.changeLanguage.changeLanguage")}
    >
      <div className="flex flex-col w-[343px] gap-[24px] h-[456px]">
        <SearchInput placeholder={t("common.search")} onChange={handleSearch} />
        {renderLanguages}
      </div>
    </Dialog>
  );
};

export default ChangeLanguageDialog;
