import { RoundWinnerResponse } from "@ns/api";
import { forwardRef, useEffect } from "react";
import Carousel from "react-spring-3d-carousel";

import chevrons from "/svg/chevrons.svg";

import useScreenSize from "../../hooks/use-screen-size.ts";

export type CarouselData = {
  key: number | string;
  content: JSX.Element;
};

interface RoundCarouselProps {
  slideData: CarouselData[] | RoundWinnerResponse[];
  index: number;
  onClick: (index: number) => void;
  className?: string;
}

const RoundCarousel = forwardRef<Carousel, RoundCarouselProps>(
  ({ slideData, index, onClick, className }, ref) => {
    const offsetFn = (offsetFromCenter: number): { left: string } => {
      return {
        left: `${50 + offsetFromCenter * -14}%`,
      };
    };

    const { height, width } = useScreenSize();

    useEffect(() => {
      // @ts-ignore
      ref?.current?.setState({
        goToSlide: index,
      });
    }, [index]);

    return (
      <div
        style={{
          height: `calc(${height}px - ${width < 640 ? "440px" : "420px"})`,
        }}
        className="h-fit carousel-wrapper max-h-[500px] flex items-center w-full max-w-[30rem] lg:max-w-[45rem] relative"
      >
        <img
          src={chevrons}
          onClick={() => onClick(1)}
          className="absolute left-4 lg:left-16 xl:left-0 rotate-[90deg] cursor-pointer z-10"
          alt="toggle-left"
        />
        <div
          className={`max-h-[250px] xl:max-h-[300px] xl:max-h-[400px] 2xl:max-h-[500px] h-full w-full carousel-wrapper ${className}`}
        >
          <Carousel
            ref={ref}
            slides={slideData}
            goToSlide={index}
            offsetRadius={1}
            showNavigation={false}
            offsetFn={offsetFn}
          />
        </div>
        <img
          src={chevrons}
          onClick={() => onClick(-1)}
          className="absolute right-4 lg:right-16 xl:right-0 rotate-[-90deg] cursor-pointer z-10"
          alt="toggle-right"
        />
      </div>
    );
  },
);

export default RoundCarousel;
