const CloseBtn = ({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={`${className} bg-closeBtn rounded-full cursor-pointer hover:bg-gray-400 close-btn`}
    >
      <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.2" />
      <path
        d="M26 14L14 26"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14L26 26"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <style>
        {`
          @media screen and (max-width: 1024px) {
            .close-btn {
              width: 2rem;
              height: 2rem;
            }
          }
        `}
      </style>
    </svg>
  );
};

export default CloseBtn;
