import { Button, Dialog } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import bitCoinPayment from "../../../public/svg/bitcoin-payment.svg";
import { usePaymentStore } from "../../store/payment-store.ts";

export const PaymentProcessingDialog = () => {
  const { t } = useTranslation();

  const { isPaymentProcessingOpen, setIsPaymentProcessingOpen } =
    usePaymentStore();

  const onClose = () => {
    setIsPaymentProcessingOpen(false);
  };

  return (
    <Dialog
      className={"px-4"}
      isOpen={isPaymentProcessingOpen}
      onClose={onClose}
      smallDeviceHeight={"h-fit"}
    >
      <div
        className={
          "w-full text-center flex flex-col justify-center items-center text-white gap-[1rem] px-8 padding-0"
        }
      >
        <span className={"font-semibold text-white text-[18px]"}>
          {t("payment.paymentProcessing")}
        </span>
        <div className={"w-full divider-gold"} />
        <img
          src={bitCoinPayment}
          className={"w-[58px] h-[58px]"}
          alt={"bitcoin-payment"}
        />
        <span className={"text-white text-[20px]"}>
          {t("payment.thankYou")}
        </span>
        <span className={"text-white text-[16px] text-center"}>
          {t("payment.paymentIsBeingProcessed")}
        </span>
        <Button variant={"primary"} className={"w-full"} onClick={onClose}>
          {t("common.continue")}
        </Button>
      </div>
    </Dialog>
  );
};
