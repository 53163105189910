import type { CheckUnclaimedDealResponse } from "@ns/api";
import { Dialog, WinnerFrame } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { sendComment, sendMessage } from "../../../graphql/api.ts";

import flareLeft from "/svg/winner-flare-left.svg";
import flareRight from "/svg/winner-flare-right.svg";

import { useUpload } from "../../../hooks/use-upload.ts";
import { api } from "../../api/client-api.ts";
import confettiAnimation from "../../assets/animations/confetti-animation.json";
import btcAnimation from "../../assets/animations/gold-coin-btc.json";
import ethAnimation from "../../assets/animations/gold-coin-eth.json";
import { formatDate } from "../../helpers/helpers.ts";
import { SendMessageSchema } from "../../schemas/form/send-message-schema.ts";
import useUserStore from "../../store/user-store.ts";
import InputMessage from "../input-message.tsx";
import ProfileAvatar from "../ui/profile-avatar.tsx";

const WinnerComment = ({
  isOpen,
  onClose,
  unclaimedDealResponse,
}: {
  isOpen: boolean;
  onClose: () => void;
  unclaimedDealResponse: CheckUnclaimedDealResponse;
}) => {
  const { t } = useTranslation();
  const { handleSubmit, reset } = useFormContext<SendMessageSchema>();
  const { user, chatImageUrl, setChatImageUrl } = useUserStore();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  const { text, isWinner } = useMemo(() => {
    if (
      unclaimedDealResponse.hasUnclaimedWinnerDeal &&
      !unclaimedDealResponse.hasUnclaimedReferralDeal
    ) {
      return { text: t("dashboard.dialog.toClaimWinner"), isWinner: true };
    }

    if (
      !unclaimedDealResponse.hasUnclaimedWinnerDeal &&
      unclaimedDealResponse.hasUnclaimedReferralDeal
    ) {
      return { text: t("dashboard.dialog.toClaimReferral"), isWinner: false };
    }

    return { text: t("dashboard.dialog.toClaimWinner"), isWinner: true };
  }, [unclaimedDealResponse, t]);

  const { mutate } = useMutation({
    mutationFn: (isWinner: boolean) =>
      api.winners.updateUnclaimedDeals({
        isWinner,
        roundNumber: Number(unclaimedDealResponse.roundNumber),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["checkUnclaimed"],
      });
      reset();
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("winner-comment", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Promise.all([
        sendMessage({
          chatData: {
            message: data.message,
            imageUrl: chatImageUrl,
            user: {
              id: user!.id,
              displayName: user!.username!,
            },
          },
        }),
        sendComment({
          comment: {
            comment: data.message,
            imageUrl: chatImageUrl,
            user: {
              id: user!.id,
              username: user!.username!,
              isInfluencer: false,
            },
          },
        }),
      ]);

      mutate(isWinner);
      queryClient.invalidateQueries(["checkUnclaimed"]);
      setChatImageUrl(null);
      onClose();
    } catch (error) {
      Sentry.withScope((scope) => {
        const errorContext = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "winner-comment-send-message": "basic",
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "winner-comment-send-comment": "basic",
          data,
        };
        Object.entries(errorContext).forEach(([tag, extra]) => {
          scope.setTag(tag, "basic");
          scope.setExtra("data", extra);
        });
        Sentry.captureException(error);
      });
    }
  });

  const { upload, fileUrl } = useUpload("chat");

  useEffect(() => {
    setChatImageUrl(fileUrl);
  }, [fileUrl, setChatImageUrl]);

  const handleUpload = async (file: File) => {
    const formData: FormData = new FormData();
    formData.append("file", file);

    await upload(formData);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles[0]) {
      return;
    }

    await handleUpload(acceptedFiles[0]);
  }, []);

  const { getInputProps } = useDropzone({
    accept:
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { "image/png": [".png"], "image/jpg": [".jpg"], "image/jpeg": [".jpeg"] },
    maxFiles: 1,
    maxSize: 26214400,
    onDrop,
  });

  const animationData = useMemo(() => {
    return unclaimedDealResponse.roundCurrency === "BTC"
      ? btcAnimation
      : ethAnimation;
  }, [unclaimedDealResponse.roundCurrency]);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(3);
    }
  }, [lottieRef, lottieRef.current, animationData]);

  return (
    <Dialog
      isOpen={isOpen}
      title={t("dashboard.dialog.pleaseLeaveComment")}
      subTitle="Test"
      hideHeaderBtn={true}
      isTitleBold={true}
      disableHeader
    >
      <Lottie
        animationData={confettiAnimation}
        loop
        autoPlay
        className="absolute top-0"
      />
      <div className="flex flex-col w-[19.5rem] gap-[20px] items-center py-[32px] relative">
        <div className="items-center justify-center flex flex-col">
          {!isWinner && (
            <span className="text-white text-[14px]">
              {t("winnerCard.referralWinner")}
            </span>
          )}
          <span className="text-white">{`${unclaimedDealResponse.roundCurrency} ${t("winnerCard.round")} ${unclaimedDealResponse.roundNumber}`}</span>
          <span className="text-[9px] text-gray-400">
            {unclaimedDealResponse?.date &&
              formatDate(new Date(unclaimedDealResponse.date))}
          </span>
        </div>
        <img
          src={unclaimedDealResponse.countryFlag}
          alt={"flag"}
          className="w-[30px] absolute top-[28px] left-0"
        />
        <div className="flex items-center justify-center relative w-full">
          <img src={flareLeft} className={`absolute top-0 left-0`} />
          <WinnerFrame>
            <ProfileAvatar className="w-full h-full" />
          </WinnerFrame>
          <img src={flareRight} className="absolute bottom-0 right-0" />
        </div>

        <div className="flex justify-center items-center flex-col">
          <div className="flex gap-[5px]">
            <span className="text-white text-[14px]">{`${t("winnerCard.congratulations")},`}</span>
            <span className="gold-text-clear text-[14px]">
              @{user?.username}
            </span>
          </div>
          {isWinner ? (
            <span className="text-white text-center text-[14px]">
              {t("winnerCard.yourBidWon")} <br /> 1{" "}
              {unclaimedDealResponse.roundCurrency} {t("winnerCard.for")}{" "}
              {unclaimedDealResponse.winningBid} USDT
            </span>
          ) : (
            <span className="text-white text-center text-[14px]">
              {t("winnerCard.youGotReferral")} <br /> 1{" "}
              {unclaimedDealResponse.roundCurrency} from{" "}
              {unclaimedDealResponse.username}
            </span>
          )}
        </div>
        <div className="flex items-center min-h-[110px] bg-backgroundDeepTeal rounded-[12px] mt-[16px] relative">
          <div className="absolute left-1/2 top-[-25px] bg-background transform -translate-x-1/2 rounded-full">
            <Lottie
              animationData={animationData}
              lottieRef={lottieRef}
              loop
              autoPlay
              style={{
                width: "52px",
                height: "52px",
                zIndex: "2",
              }}
            />
          </div>

          <span className="text-white text-[14px] text-center">{text}</span>
        </div>

        <div
          className={`w-full flex flex-col gap-[12px] ${chatImageUrl && "mt-[3.5rem]"}`}
        >
          <InputMessage
            name={"message"}
            placeholder={t("dashboard.dialog.message")}
            onClick={onSubmit}
            fileInputRef={fileInputRef}
            chatImageUrl={chatImageUrl}
            getInputProps={getInputProps}
            setChatImageUrl={setChatImageUrl}
          />
          <div className="text-disabledSecondaryText text-[12px] text-center">
            {t("dashboard.dialog.messageValidation")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WinnerComment;
