import React, { useMemo, useRef } from "react";

import chevronSvg from "/svg/chevron.svg";

import { useOutsideClick } from "../../hooks/use-outside-click.ts";

const DropdownSelect = ({
  selected,
  onSelect,
  values,
  languages = false,
}: {
  selected: string | undefined; // Controlled selected state
  onSelect: (valueId: string | undefined) => void;
  values: { svg?: string; label: string; id: string | undefined }[];
  languages?: boolean;
}) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick([dropdownRef], () => {
    setTimeout(() => setOpenMenu(false), 100);
  });

  const renderOptions = useMemo(() => {
    if (!values.length) return [];
    return values
      .filter((value) => value.id !== selected)
      .map((value) => (
        <div
          key={value.id}
          className="flex items-center justify-start gap-[10px] z-[100000] cursor-pointer"
          onClick={() => {
            onSelect(value.id);
            setOpenMenu(false);
          }}
        >
          {value.svg && (
            <img className={"w-[26px]"} src={value.svg} alt="Option" />
          )}
          <span>{value.label}</span>
        </div>
      ));
  }, [selected, values, onSelect]);

  const selectedValue = useMemo(
    () => values.find((value) => value.id === selected),
    [selected, values],
  );

  return (
    <div
      ref={dropdownRef}
      className={`flex flex-col gap-[16px] ${languages ? "z-[1000000000]" : "z-[100000]"} border border-tabBorderColor py-[0.375rem] px-[0.5rem] bg-background rounded-[8px] text-white overflow-hidden w-full h-fit`}
    >
      <div
        className={`flex justify-between items-center cursor-pointer`}
        onClick={() => setOpenMenu((openMenu) => !openMenu)}
      >
        <div className="flex items-center justify-start gap-[10px]">
          {selectedValue?.svg && (
            <img src={selectedValue?.svg} className={"w-[26px]"} alt="icon" />
          )}
          <span>{selectedValue?.label}</span>
        </div>
        <img src={chevronSvg} alt="chevron" />
      </div>
      <div
        className={`${
          openMenu ? "flex" : "hidden"
        } flex-col gap-[16px] items-start`}
      >
        {renderOptions}
      </div>
    </div>
  );
};

export default DropdownSelect;
