const AnimatedButton = ({
  text,
  onClick,
  className,
  animatedButtonClass = "animated-button",
}: {
  text: string;
  onClick: () => void;
  className?: string;
  animatedButtonClass?: string;
}) => {
  return (
    <div className={`w-full relative ${className}`}>
      <div className="absolute card-border" style={{ zIndex: "0" }}></div>
      <button
        onClick={onClick}
        className={`max-w-[220px] ${animatedButtonClass} text-white text-center bg-backgroundDeepTeal w-full rounded-[100px]`}
      >
        <div className="inner p-2 lg:p-4 bg-backgroundDeepTeal rounded-[100px]">
          {text}
        </div>
      </button>
    </div>
  );
};

export default AnimatedButton;
