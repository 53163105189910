import { Textarea } from "@ns/client-ui";
import { FC } from "react";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const ControlledTextarea: FC<Props> = ({
  name,
  label,
  className,
  disabled,
  placeholder,
  onKeyDown,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <>
          <Textarea
            onChange={onChange}
            value={value}
            label={label}
            name={name}
            errors={errors}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
          />
        </>
      )}
    />
  );
};

export default ControlledTextarea;
