import { FC } from 'react';

type Props = {
  fileTitle: string;
  onDelete: (name: string) => void;
  fileSrc: string;
  uploadAgainText: string;
};

export const SuccessfullyUploadedImage: FC<Props> = ({ fileTitle, fileSrc, onDelete, uploadAgainText }) => {
  return (
    <div className={'flex flex-col items-center gap-4'}>
      <span className="text-white text-[24px] text-center">{fileTitle}</span>
      <img src={fileSrc} className="w-[100px] h-[100px] rounded-full bg-white" alt="Profile image" />
      <span className="text-[14px] text-descriptionText" onClick={onDelete as never}>
        {uploadAgainText}
      </span>
    </div>
  );
};
