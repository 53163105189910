// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { GraphQLClient } from "graphql-request";
import { QueryClient } from "@tanstack/query-core";
import { getSdk } from "./generated/gql-api.generated";
import { environmentVariables } from "../src/env/enviroment-variables";

const gqlClient = new GraphQLClient(environmentVariables.graphqlUrl!, {
  headers: {
    "x-api-key": environmentVariables.graphqlApiKey!,
  },
  responseMiddleware: (response: any) => {
    if (!(response instanceof Error) && response.status === 200) {
      return response.data;
    }

    return response;
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export const {
  sendMessage,
  onSendMessage,
  getAllMessages,
  getNotificationsByUserId,
  sendComment,
  onSendComment,
  getAllComments,
  getUnreadNotifications,
  readNotifications,
} = getSdk(gqlClient);

/*
 * TODO: We should be using `React.cache()` to make a request-scoped singleton out of queryClient. Unfortunately,
 * currently it's not supported because we get a "Not implemented" error during build time.
 */
export const getQueryClient = () => queryClient;
