import { Button } from "@ns/client-ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useDialogContext } from "../../store/dialog-store.ts";
import CloseBtnMobile from "../svg-components/close-btn-mobile.tsx";
import Lightbulb from "../svg-components/lightbulb.tsx";

interface Props {
  onClose: () => void;
  className?: string;
}

const ReferralInvitePopup: FC<Props> = ({ onClose, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsGiftDialogOpen } = useDialogContext();

  const placeBidHandler = (e: React.MouseEvent | React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsGiftDialogOpen(false);
    onClose();
    navigate("/");
  };

  return (
    <div
      className={`bg-background absolute right-0 bottom-16 rounded-lg h-fit ${className}`}
    >
      <div className="absolute inset-0 border-gradient-blue rounded-lg"></div>
      <div className={"flex flex-col gap-4 relative p-4"}>
        <div className={"flex items-center text-white"}>
          <Lightbulb />
          <div className={"ml-4 text-lg"}>{t("giftDialog.referralInvite")}</div>
          <CloseBtnMobile
            className={"cursor-pointer ml-auto"}
            onClick={onClose}
          />
        </div>
        <div className={"text-white"}>{t("giftDialog.toGetACode")}</div>
        <Button
          onClick={placeBidHandler}
          variant={"primary"}
          className="flex-1 w-full"
        >
          <span>{t("common.placeBid")}</span>
        </Button>
      </div>
    </div>
  );
};

export default ReferralInvitePopup;
