import { Tabs, TabsList, TabsTrigger } from "@ns/client-ui";
import { Controller, useFormContext, useWatch } from "react-hook-form";

type Tab = {
  label: string;
  value: string;
  icon?: string;
};

type ControlledTabProps = {
  name: string;
  tabs: Tab[];
  callback?: (value: string) => void;
  className?: string;
};

const ControlledTab = ({
  name,
  tabs,
  callback,
  className,
}: ControlledTabProps) => {
  const { control } = useFormContext();
  const value = useWatch({ name: name });

  const handleBorderRadius = (index: number): string => {
    if (index === 0) {
      return "rounded-tl-radius rounded-bl-radius";
    } else if (index === tabs.length - 1) {
      return "rounded-tr-radius rounded-br-radius";
    }
    return "";
  };

  return (
    <Tabs
      value={value}
      className="z-30 w-full flex items-center justify-center"
    >
      <TabsList className={className}>
        {tabs.map((tab, index) => (
          <Controller
            key={tab.value}
            name={name}
            control={control}
            render={({ field }) => (
              <TabsTrigger
                value={tab.value}
                className={`${handleBorderRadius(index)}`}
                onClick={() => {
                  callback && callback(tab.value);
                  field.onChange(tab.value);
                }}
              >
                {tab.icon && <img src={tab.icon} alt={`${tab.value}-icon`} />}
                <span>{tab.label}</span>
              </TabsTrigger>
            )}
          />
        ))}
      </TabsList>
    </Tabs>
  );
};

export default ControlledTab;
