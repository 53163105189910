import { CreateChargeRequest } from "@ns/api";
import { Dialog } from "@ns/client-ui";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { PaymentPlatformEnum } from "../../enums/payment-platform.enum.ts";
import { usePaymentStore } from "../../store/payment-store.ts";
import CoinPaymentIcon from "../svg-components/coin-payment-icon.tsx";
import Coinbase from "../svg-components/coinbase.tsx";
import SecurityIcon from "../svg-components/security-icon.tsx";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  placeBid: (data: CreateChargeRequest) => void;
}

interface CardProps {
  icon: ReactNode;
  text: string;
  onClick?: () => void;
}

const PaymentCard = ({ icon, text, onClick }: CardProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      className={"min-h-[125px] max-w-[400px] relative w-full cursor-pointer"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div
        className={`z-[1] ${isHovered && "border-[1px] border-gradient-gold"} inset-0 scale-x-[1.005] scale-y-[1.02] absolute top-0 left-0 min-h-[125px] w-full max-w-[400px] rounded-2xl`}
      />
      <div
        className={`absolute top-0 left-0 bg-backgroundSecondary z-[2] w-full h-full py-2 rounded-2xl flex flex-col gap-2 justify-center items-center ${!isHovered && "border-[1px] border-white"}`}
      >
        {icon}
        <div
          className={"max-w-[180px] text-[0.75rem] bidCardText opacity-[0.7]"}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

const ChoosePaymentDialog = ({ isOpen, onClose, placeBid }: Props) => {
  const { t } = useTranslation();

  const {
    setPaymentTypeHandler,
    setIsCoinPaymentOpen,
    bidData,
    setIsPaymentProcessingOpen,
  } = usePaymentStore();

  const { data: round } = useQuery({
    queryKey: ["round", bidData.roundId],
    queryFn: () => api.round.getRoundById(bidData.roundId!),
    enabled: !!bidData.roundId,
  });

  const { data: config } = useQuery({
    queryKey: ["coinPaymentsConfig", round?.fiatCurrency?.id],
    queryFn: () =>
      api.coinPaymentsConfiguration.getCoinPaymentsActiveConfigurationByFiatCurrencyId(
        round!.fiatCurrency!.id!,
      ),
    enabled: !!round?.fiatCurrency?.id,
  });

  const onClickHandler = (type: PaymentPlatformEnum) => {
    setPaymentTypeHandler(type);
    onClose();
    let bidRequest;
    if (type === PaymentPlatformEnum.COIN_PAYMENT) {
      bidRequest = {
        ...bidData,
        paymentPlatform: type,
        receiveCurrency: config!.code,
      };
    } else {
      bidRequest = { ...bidData, paymentPlatform: type };
    }
    placeBid(bidRequest);
    if (type === PaymentPlatformEnum.COIN_PAYMENT) {
      setIsCoinPaymentOpen(true);
    }
    if (type === PaymentPlatformEnum.COIN_BASE) {
      setIsPaymentProcessingOpen(true);
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div
        className={
          "text-center flex flex-col justify-center items-center text-white"
        }
      >
        <SecurityIcon />
        <div className={"text-[28px] font-semibold mt-8 mb-4"}>
          {t("payment.choosePayment")}
        </div>
        <div className={"text-sm max-w-[400px] bidCardText opacity-[0.7] mb-8"}>
          {t("payment.selectingPayment")}
        </div>
        <div
          className={"w-full flex flex-col gap-8 justify-center items-center"}
        >
          <PaymentCard
            onClick={() => onClickHandler(PaymentPlatformEnum.COIN_BASE)}
            text={t("payment.isTop")}
            icon={<Coinbase />}
          />
          <PaymentCard
            text={t("payment.supported")}
            icon={<CoinPaymentIcon />}
            onClick={() => onClickHandler(PaymentPlatformEnum.COIN_PAYMENT)}
          />
        </div>
        <div className={"mt-8 font-semibold"}>
          {t("payment.transactionFee")}
        </div>
      </div>
    </Dialog>
  );
};

export default ChoosePaymentDialog;
