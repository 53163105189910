const CopyButton = ({
  onClick,
  className,
}: {
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.16668 7.29818V18.9648C7.16668 19.7384 7.47397 20.4803 8.02095 21.0272C8.56793 21.5742 9.30979 21.8815 10.0833 21.8815H18.8333M7.16668 7.29818V4.38151C7.16668 3.60796 7.47397 2.8661 8.02095 2.31912C8.56793 1.77213 9.30979 1.46484 10.0833 1.46484H16.7713C17.158 1.46493 17.5289 1.61863 17.8023 1.89214L24.2394 8.32922C24.5129 8.60265 24.6666 8.97352 24.6667 9.36026V18.9648C24.6667 19.7384 24.3594 20.4803 23.8124 21.0272C23.2654 21.5742 22.5236 21.8815 21.75 21.8815H18.8333M7.16668 7.29818H4.25001C3.47646 7.29818 2.7346 7.60547 2.18762 8.15245C1.64063 8.69943 1.33334 9.4413 1.33334 10.2148V24.7982C1.33334 25.5717 1.64063 26.3136 2.18762 26.8606C2.7346 27.4076 3.47646 27.7148 4.25001 27.7148H15.9167C16.6902 27.7148 17.4321 27.4076 17.9791 26.8606C18.5261 26.3136 18.8333 25.5717 18.8333 24.7982V21.8815"
        stroke="url(#paint0_linear_3886_16017)"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3886_16017"
          x1="1.33334"
          y1="1.46484"
          x2="26.354"
          y2="3.39889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9A548" />
          <stop offset="0.5" stopColor="#F9E690" />
          <stop offset="0.745543" stopColor="#C39C3E" />
          <stop offset="1" stopColor="#A87715" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CopyButton;
