import { AuthCard, AuthCardContent, Button, Modal } from "@ns/client-ui";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { getSessionToken } from "../../api/token-helper.ts";
import CloseBtn from "../svg-components/close-btn.tsx";
import Lock from "../svg-components/lock.tsx";

const PinChangeSuccessDialog = ({
  onClose,
  isOpen,
  openChangePinDialog,
}: {
  onClose: () => void;
  isOpen: boolean;
  openChangePinDialog: () => void;
}) => {
  const { t } = useTranslation();

  const { data: activePinCode } = useQuery({
    queryKey: ["pinCode"],
    queryFn: () => api.pinCode.getPinCode(),
    enabled: !!getSessionToken(),
  });

  return (
    <Modal isOpen={isOpen}>
      <AuthCard className="min-h-[31.5rem] max-w-[37.5rem] items-center">
        <AuthCardContent>
          <CloseBtn
            onClick={onClose}
            className="absolute right-[32px] top-[32px] w-6 h-6"
          />
          <div
            className={
              "text-white flex flex-col gap-4 justify-center items-center mb-[35px]"
            }
          >
            <Lock />
            <div className="text-white text-2xl text-center">
              {t("screenLock.successfullySet")}
            </div>
            <div className={"text-textGray max-w-[220px] text-center"}>
              {activePinCode?.canBeChanged
                ? t("screenLock.youCanChangeNow")
                : t("screenLock.youCanChange")}
            </div>
          </div>
          <div className={"flex flex-col gap-4 w-full mt-8"}>
            <Button
              onClick={() => {
                if (activePinCode?.canBeChanged) {
                  openChangePinDialog();
                }
                onClose();
              }}
            >
              {activePinCode?.canBeChanged
                ? t("screenLock.changePin")
                : t("common.close")}
            </Button>
          </div>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};

export default PinChangeSuccessDialog;
