import { create } from "zustand";

export type UserDetails = {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  countryId: string;
  languageId: string;
  authUserId?: string;
};

export type Auth = {
  userDetails: UserDetails | null;
  verificationId?: string;
  setUserDetails: (authUser: Partial<UserDetails>) => void;
  activeRegistrationStep: number;
  isLoginModalOpen: boolean;
  setActiveRegistrationStep: (step: number) => void;
  isAuthModalOpen: boolean;
  toggleAuthModal: () => void;
  isPinModalOpen: boolean;
  activeForgotPinStep: number;
  setActiveForgotPinStep: (step: number) => void;
  isForgotPinModalOpen: boolean;
  setIsForgotPinModalOpen: (isForgotPinModalOpen: boolean) => void;
  setIsPinModalOpen: (isPinModalOpen: boolean) => void;
  setNextStep: () => void;
  checkedEmail?: string;
  setCheckedEmail: (value?: string) => void;
  setIsLoginModalOpen: (value: boolean) => void;
  setVerificationId: (value?: string) => void;
  isSuccessModalOpen: boolean;
  toggleSuccessModal: () => void;
  isMaintenanceMode: boolean;
  setIsMaintenanceMode: (isMaintainanceMode: boolean) => void;
};

export const useAuthContext = create<Auth>((set) => ({
  userDetails: null,
  isAuthModalOpen: false,
  isLoginModalOpen: false,
  checkedEmail: undefined,
  verificationId: undefined,
  isSuccessModalOpen: false,
  activeRegistrationStep: 0,
  isPinModalOpen: false,
  activeForgotPinStep: 0,
  isForgotPinModalOpen: false,
  isMaintenanceMode: false,
  setUserDetails: (authUser: Partial<UserDetails>) =>
    set((state) => ({
      userDetails: state.userDetails
        ? { ...state.userDetails, ...authUser }
        : (authUser as UserDetails),
    })),
  setActiveRegistrationStep: (step: number) =>
    set(() => ({ activeRegistrationStep: step })),
  setActiveForgotPinStep: (step: number) =>
    set(() => ({ activeForgotPinStep: step })),
  toggleAuthModal: () =>
    set((state) => ({ isAuthModalOpen: !state.isAuthModalOpen })),
  setIsPinModalOpen: (isPinModalOpen: boolean) =>
    set(() => ({ isPinModalOpen: isPinModalOpen })),
  setNextStep: () =>
    set((state) => ({
      activeRegistrationStep: state.activeRegistrationStep + 1,
    })),
  setCheckedEmail: (value?: string) => set(() => ({ checkedEmail: value })),
  setIsLoginModalOpen: (value: boolean) =>
    set(() => ({ isLoginModalOpen: value })),
  setIsForgotPinModalOpen: (value: boolean) =>
    set(() => ({ isForgotPinModalOpen: value })),
  setVerificationId: (value?: string) => set(() => ({ verificationId: value })),
  toggleSuccessModal: () =>
    set((state) => ({ isSuccessModalOpen: !state.isSuccessModalOpen })),
  setIsMaintenanceMode: (isMaintenanceMode: boolean) =>
    set(() => ({
      isMaintenanceMode: isMaintenanceMode,
    })),
}));
