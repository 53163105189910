const OnboardingEth = () => {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="41.25"
        cy="41.75"
        r="39"
        fill="url(#paint0_linear_4271_52444)"
        fillOpacity="0.7"
      />
      <path
        opacity="0.39"
        d="M41.25 71.75C57.8185 71.75 71.25 58.3185 71.25 41.75C71.25 25.1815 57.8185 11.75 41.25 11.75C24.6815 11.75 11.25 25.1815 11.25 41.75C11.25 58.3185 24.6815 71.75 41.25 71.75Z"
        fill="url(#paint1_radial_4271_52444)"
      />
      <path
        d="M41.6629 21.3575L41.3608 22.2638V49.2272L41.6629 49.5293L54.125 42.1276L41.6629 21.3575Z"
        fill="#26BAD8"
      />
      <path
        d="M41.6626 21.3575L29.125 42.1276L41.6626 49.5293V36.463V21.3575Z"
        fill="#32FDE8"
      />
      <path
        d="M41.6629 51.8708L41.4363 52.0973V61.6893L41.6629 62.1425L54.1249 44.4691L41.6629 51.8708Z"
        fill="#26BAD8"
      />
      <path
        d="M41.6626 62.1425V51.8707L29.125 44.469L41.6626 62.1425Z"
        fill="#32FDE8"
      />
      <path
        d="M54.1244 42.1276L41.6624 49.5293V36.463L54.1244 42.1276Z"
        fill="#32FDE8"
      />
      <path
        d="M41.6626 36.463V49.5293L29.125 42.1276L41.6626 36.463Z"
        fill="#26BAD8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4271_52444"
          x1="2.25"
          y1="45.2926"
          x2="80.25"
          y2="45.2926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07252A" />
          <stop offset="0.5" stopColor="#26616C" />
          <stop offset="1" stopColor="#07252A" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_4271_52444"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.2568 41.7438) scale(37.2774)"
        >
          <stop stopColor="#11639D" />
          <stop offset="0.99" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default OnboardingEth;
