import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  VerificationCodeSchema,
  verificationCodeSchema,
} from "../../schemas/form/verification-code.schema.ts";
import useUserStore from "../../store/user-store.ts";
import ControlledForm from "../form/controlled-form";
import ControlledOtp from "../form/controlled-otp";

const EnterCodeForm = ({
  onClose,
  openNewPassword,
}: {
  isOpen: boolean;
  onClose: () => void;
  openNewPassword: () => void;
}) => {
  const { t } = useTranslation();
  const {
    reset,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    clearErrors,
  } = useFormContext<VerificationCodeSchema>();
  const { resetId, resetEmail, setResetId } = useUserStore();

  const [seconds, setSeconds] = useState<number>(60 * 10);
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (errors.code) {
        clearErrors(name);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, errors, clearErrors]);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [seconds]);

  const closeModal = () => {
    onClose();
    reset();
  };

  const handleModalChange = (setterFn: () => void) => {
    closeModal();
    setterFn();
  };

  const submit = handleSubmit((data) => {
    axios
      .post(
        `${environmentVariables.authServiceUrl}/password/forgot/code/${resetId}`,
        {
          code: data.code,
        },
        { withCredentials: true },
      )
      .then(() => {
        handleModalChange(openNewPassword);
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setTag("enter-code-dialog", "basic");
          scope.setExtra("data", data);
          Sentry.captureException(error);
        });
        setError("code", { message: t("form.code") });
      });
  });

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const resendCode = () =>
    axios
      .post(`${environmentVariables.authServiceUrl}/password/forgot/code`, {
        email: resetEmail,
      })
      .then((response) => {
        setResetId(response.data.reset_id);
        setIsCodeResent(true);
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setTag("forgot-password", "basic");
          Sentry.captureException(error);
        });
      });

  const renderAction = useCallback(() => {
    if (seconds > 0) {
      return (
        <div className={"flex flex-row gap-[1.5rem] justify-center"}>
          <span className={"text-[14px] avenir-semibold text-textGray"}>
            {t("enterCodeDialog.sendCode")}
          </span>
          <span className={"text-[14px] avenir-semibold text-textGray"}>
            {formatTime(seconds)}
          </span>
        </div>
      );
    }

    if (isCodeResent) {
      return (
        <div className={"flex flex-row gap-[1.5rem] justify-center"}>
          <span
            className={
              "text-[14px] avenir-semibold cursor-pointer text-textGray"
            }
          >
            {t("enterCodeDialog.codeResent")}
          </span>
        </div>
      );
    }
    return (
      <div className={"flex flex-row gap-[1.5rem] justify-center"}>
        <span
          className={"text-[14px] avenir-semibold cursor-pointer text-textGray"}
        >
          {t("enterCodeDialog.didntreceive")}
        </span>
        <span
          className={"text-[14px] avenir-semibold cursor-pointer text-white"}
          onClick={resendCode}
        >
          {t("enterCodeDialog.resend")}
        </span>
      </div>
    );
  }, [seconds, t, isCodeResent]);

  return (
    <AuthCard className="max-w-[37.5rem]">
      <AuthCardContent>
        <FormLayout
          subTitle={t("enterCodeDialog.enterCode")}
          description={`${t(
            "enterCodeDialog.fourLetterCodeText",
          )} ${resetEmail}`}
        >
          <ControlledOtp name={"code"} />
          <Button className={"mt-[2rem]"} disabled={!isValid} onClick={submit}>
            {t("common.submit")}
          </Button>
          <Button onClick={closeModal} variant={"secondary"}>
            {t("common.cancel")}
          </Button>
          {renderAction()}
        </FormLayout>
      </AuthCardContent>
    </AuthCard>
  );
};

const EnterCodeDialog = ({
  isOpen,
  openNewPassword,
  onClose,
}: {
  isOpen: boolean;
  openNewPassword: () => void;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={verificationCodeSchema}>
      <Modal isOpen={isOpen}>
        <EnterCodeForm
          isOpen={isOpen}
          onClose={onClose}
          openNewPassword={openNewPassword}
        />
      </Modal>
    </ControlledForm>
  );
};

export default EnterCodeDialog;
