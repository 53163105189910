import { useTranslation } from "react-i18next";
import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";

import CloseBtn from "../svg-components/close-btn.tsx";

import tickCircleSvg from "/svg/tick-circle.svg";

export const SuccessDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen}>
      <AuthCard>
        <AuthCardContent>
          <FormLayout
            subTitle={t("common.success")}
            description={t("common.hasRegistered")}
          >
            <CloseBtn
              className="absolute top-[30px] right-[16px]"
              onClick={onClose}
            />
            <div className="flex flex-col items-center justify-center w-full">
              <img src={tickCircleSvg} alt={"tick-circle"} />
            </div>
            <div className="flex flex-col items-center justify-center gap-[1rem] w-full mt-[2rem]">
              <Button onClick={onClose} className={"w-full"}>
                {t("signInDialog.signIn")}
              </Button>
            </div>
          </FormLayout>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};
