import { useTranslation } from "react-i18next";
import { Dialog } from "@ns/client-ui";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { environmentVariables } from "../../env/enviroment-variables.ts";

import { useNavigate } from "react-router-dom";

import tickCircleSvg from "/svg/tick-circle.svg";
import exclamationSvg from "/svg/exclamation.svg";

const VerifyDialog = ({
  firstParam,
  secondParam,
  onClose,
  isOpen,
}: {
  firstParam: string;
  secondParam: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, isError } = useQuery({
    queryKey: ["verify", firstParam, secondParam],
    queryFn: () =>
      axios.get(
        `${environmentVariables.authServiceUrl}/verification/email/${firstParam}/${secondParam}`,
        {
          withCredentials: true,
        },
      ),
    enabled: !!firstParam && !!secondParam,
    retry: false,
    refetchOnMount: false,
  });

  const closeAction = () => {
    navigate("/");
    onClose();
  };

  return (
    <Dialog
      title={t("profile.dialog.verify.verify")}
      isOpen={isOpen}
      onClose={() => closeAction()}
      hideHeaderBtn={true}
      showCancel={!isLoading}
      closeBtnText={t("common.close")}
    >
      <div className="flex flex-col gap-[2rem] w-[25rem] min-h-[10rem] mb-[1rem]">
        {isLoading && (
          <span className="text-textGray text-center text-[16px]">
            {t("profile.dialog.verify.loading")}
          </span>
        )}
        {!isLoading && isError && (
          <div className={"flex flex-col items-center gap-[2rem]"}>
            <span className="text-textGray text-center text-[16px]">
              {t("profile.dialog.verify.error")}
            </span>
            <img
              src={exclamationSvg}
              className={"w-[3rem] h-[3rem]"}
              alt={"exclamation"}
            />
          </div>
        )}
        {!isLoading && !isError && (
          <div className={"flex flex-col items-center gap-[2rem]"}>
            <span className="text-textGray text-center text-[16px]">
              {t("profile.dialog.verify.success")}
            </span>
            <img
              src={tickCircleSvg}
              className={"w-[3rem] h-[3rem]"}
              alt={"tick circle"}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default VerifyDialog;
