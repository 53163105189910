import {
  CurrencyResponse,
  ReferralResponse,
  WinningReferralResponse,
} from "@ns/api";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useOutsideClick } from "../../../hooks/use-outside-click.ts";
import { usePagination } from "../../../hooks/use-pagination.ts";
import useScreenSize from "../../../hooks/use-screen-size.ts";
import { api } from "../../api/client-api.ts";
import DropdownSelect from "../../components/dropdown-select.tsx";
import FooterReferralMenu from "../../components/footer-referral-menu.tsx";
import ControlledForm from "../../components/form/controlled-form.tsx";
import Loader from "../../components/loader.tsx";
import AddReferralButton from "../../components/svg-components/add-referral-button.tsx";
import CardList from "../../components/ui/card-list.tsx";
import ReferralList from "../../components/ui/referral-list.tsx";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { profileSchema } from "../../schemas/form/profile-schema.ts";
import { useDialogContext } from "../../store/dialog-store.ts";

import giftSvg from "/svg/gift.svg";
import bellSvg from "/svg/bell.svg";

import { ReferralTab } from "./user-page.tsx";

const Referrals = () => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/referrals",
      title: "User Page",
    });
  }, []);

  const { width } = useScreenSize();
  const { setValue, watch } = useFormContext();
  const {
    setIsGiftDialogOpen,
    toggleNotificationOpen,
    setIsAddReferralDialogOpen,
    selectedTab,
    setSelectedTab,
  } = useDialogContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isReferralInviteOpen, setIsReferralInviteOpen] =
    useState<boolean>(true);
  const [isTextCopied, setIsTextCopied] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick([ref], () => setIsReferralInviteOpen(false));

  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>(
    undefined,
  );

  const [referralTab, setReferralTab] = useState<ReferralTab>(
    ReferralTab.MY_REFERRALS,
  );

  useEffect(() => {
    if (selectedTab) {
      setReferralTab(selectedTab as ReferralTab);
      setSelectedTab(undefined);
    }
  }, [selectedTab, setSelectedTab, setReferralTab]);

  const {
    data: referrals,
    isFetchingNextPage: isFetchingNextReferralPage,
    lastItemRef: referralsRef,
    isLoading: referralLoad,
  } = usePagination<ReferralResponse>({
    queryKey: ["referrals", currencyFilter || ""],
    queryFn: (offset: number, limit: number) =>
      api.referral.getReferrals(currencyFilter, limit, offset),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: winningReferrals,
    isFetchingNextPage: isFetchingNextWinningReferralPage,
    lastItemRef: winningReferralsRef,
  } = usePagination<WinningReferralResponse>({
    queryKey: ["winningReferrals", currencyFilter || ""],
    queryFn: (offset: number, limit: number) =>
      api.referral.getWinningReferrals(currencyFilter, limit, offset),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: referralAnalytics } = useQuery({
    queryKey: ["referralAnalytics", currencyFilter],
    queryFn: () => api.analytics.getReferralAnalytics(currencyFilter),
  });

  const { data: currencies } = useQuery<{ items: CurrencyResponse[] }>({
    queryKey: ["currency"],
    queryFn: () => api.resources.getCurrencies(),
  });

  const formatCurrencies = useMemo(
    () =>
      !currencies?.items
        ? []
        : currencies?.items.map((currency) => ({
            label: currency.code.toUpperCase(),
            value: currency.id,
            icon: CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE],
          })),
    [currencies],
  );

  const currencyId = watch("currencyId");

  const { data: referralCode, refetch: refetchReferralCode } = useQuery({
    queryKey: ["referralCode", currencyId],
    queryFn: () => api.referralCode.getReferralCodeByCurrencyId(currencyId!),
    enabled: !!currencyId,
  });

  const onTabChange = useCallback(
    async (newCurrencyId: string) => {
      if (isTextCopied) {
        setIsTextCopied(false);
      }
      if (isReferralInviteOpen) {
        setIsReferralInviteOpen(false);
      }
      if (currencyId === newCurrencyId) return;
      setValue("currencyId", newCurrencyId);
      await refetchReferralCode();
    },

    [
      refetchReferralCode,
      currencyId,
      setValue,
      isReferralInviteOpen,
      isTextCopied,
    ],
  );

  useEffect(() => {
    if (formatCurrencies.length !== 0) {
      setValue("currencyId", formatCurrencies[0].value);
    }
  }, [setValue, formatCurrencies]);

  const onFilterSelect = useCallback(
    (value: string | undefined) => {
      setCurrencyFilter(value);
    },
    [setCurrencyFilter],
  );

  const formatFilterValues = useMemo(() => {
    const formatedCurrencies = currencies?.items?.map((currency) => ({
      id: currency.code,
      label: currency.code,
      svg: CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE],
    }));
    return [
      { id: undefined, label: t("common.all") },
      ...(formatedCurrencies || []),
    ];
  }, [currencies, t]);

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode!.code!);
    setIsTextCopied(true);
    setTimeout(() => {
      setIsTextCopied(false);
    }, 1000);
  };

  const referralInviteHandler = async () => {
    if (!referralCode?.code && !isReferralInviteOpen) {
      setIsReferralInviteOpen(true);
    }
  };

  const referralStatistics = useMemo(
    () => [
      {
        title: t("userPage.totalAccepted"),
        value: referralAnalytics?.totalInvited ?? "-",
        svg: () => <img src="/svg/money.svg" />,
      },
      {
        title: t("userPage.totalReferralBids"),
        value: referralAnalytics?.totalReferralBids ?? "-",
        svg: () => <img src="/svg/status.svg" />,
      },
      {
        title: t("userPage.totalBonuses"),
        value:
          referralAnalytics?.totalBonus && referralAnalytics?.totalBonus != "0"
            ? `${Number(referralAnalytics?.totalBonus).toFixed(2)} ${environmentVariables.fiatCurrency}`
            : "-",
        svg: () => <img src="/svg/money-pie.svg" />,
      },
    ],
    [referralAnalytics, t],
  );

  const onReferralTabChange = (referralTab: ReferralTab) => {
    setReferralTab(referralTab);
  };

  if (referralLoad) return <Loader />;

  return (
    <div className="flex flex-col w-full sm:ml-auto">
      <div className={"relative z-30 flex w-full items-center justify-evenly"}>
        <img
          onClick={() => setIsGiftDialogOpen(true)}
          src={giftSvg}
          alt="gift"
          className={"sm:hidden"}
        />
        <div className={"min-w-56 sm:hidden"} />
        <div className="hidden sm:block absolute top-0 right-[4rem] lg:right-[-2rem] w-[142px] h-[40px]">
          <div className="absolute left-0 top-0 w-[142px]">
            <DropdownSelect
              values={formatFilterValues}
              onSelect={onFilterSelect}
              selected={currencyFilter}
            />
          </div>
        </div>
        <img
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleNotificationOpen();
          }}
          src={bellSvg}
          alt="bell"
          className={"sm:hidden"}
        />
      </div>
      <div className={"pt-[1.25rem] sm:pt-0"}>
        <CardList data={referralStatistics} />
      </div>
      <div className="flex flex-col gap-[20px] sm:gap-[32px] h-full wifull mt-8 sm:mt-[60px]">
        <div
          className={
            "w-full xl:w-[860px] flex flex-col mx-auto sm:pl-[128px] sm:pr-12 lg:pl-[192px] xl:p-0"
          }
        >
          <div className="hidden mb-2 sm:flex items-center justify-between text-white avenir-semibold text-[16px]">
            <div className="flex items-center gap-[30px] w-full">
              <span
                className={`cursor-pointer ${
                  referralTab === ReferralTab.MY_REFERRALS
                    ? "text-white"
                    : "text-disabledSecondaryText"
                }`}
                onClick={() => onReferralTabChange(ReferralTab.MY_REFERRALS)}
              >
                {t("profile.invitedFriends")}
              </span>
              <span
                className={`cursor-pointer ${
                  referralTab === ReferralTab.WINNING_REFERRALS
                    ? "text-white"
                    : "text-disabledSecondaryText"
                }`}
                onClick={() =>
                  onReferralTabChange(ReferralTab.WINNING_REFERRALS)
                }
              >
                {t("profile.referralWinning")}
              </span>
              {referralCode?.code && (
                <div
                  onClick={() => setIsAddReferralDialogOpen(true)}
                  className={"flex gap-2 items-center ml-auto cursor-pointer"}
                >
                  <AddReferralButton />
                  <span>{t("userPage.addNewReferral")}</span>
                </div>
              )}
            </div>
          </div>
          <div
            ref={ref}
            className="hidden sm:flex overflow-y-auto scrollbar w-full flex flex-col gap-[30px] items-center h-[calc(60vh-150px)]"
          >
            <ReferralList
              referralTab={referralTab}
              referrals={referrals}
              winningReferrals={winningReferrals}
              isFetching={
                referralTab === ReferralTab.MY_REFERRALS
                  ? isFetchingNextReferralPage
                  : isFetchingNextWinningReferralPage
              }
              lastItemRef={
                width > 640
                  ? referralTab === ReferralTab.MY_REFERRALS
                    ? referralsRef
                    : winningReferralsRef
                  : () => {}
              }
              referralInviteHandler={referralInviteHandler}
              referralCode={referralCode}
              isReferralInviteOpen={isReferralInviteOpen}
              handleCopy={handleCopy}
              isTextCopied={isTextCopied}
              onTabChange={onTabChange}
              formatCurrencies={formatCurrencies}
              setIsReferralInviteOpen={setIsReferralInviteOpen}
            />
          </div>
        </div>
        <FooterReferralMenu
          referralTab={referralTab}
          referrals={referrals}
          setTab={setReferralTab}
          currencyFilter={currencyFilter!}
          winningReferrals={winningReferrals}
          isFetching={
            referralTab === ReferralTab.MY_REFERRALS
              ? isFetchingNextReferralPage
              : isFetchingNextWinningReferralPage
          }
          lastItemRef={
            width < 640
              ? referralTab === ReferralTab.MY_REFERRALS
                ? referralsRef
                : winningReferralsRef
              : () => {}
          }
          referralInviteHandler={referralInviteHandler}
          referralCode={referralCode}
          isReferralInviteOpen={isReferralInviteOpen}
          handleCopy={handleCopy}
          isTextCopied={isTextCopied}
          onTabChange={onTabChange}
          formatCurrencies={formatCurrencies}
          setIsReferralInviteOpen={setIsReferralInviteOpen}
          setCurrencyFilter={setCurrencyFilter}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  );
};

const ReferralsPage = () => {
  return (
    <>
      <div
        className="flex xl:pt-[2rem] justify-center w-full xl:w-userPageWidth"
        style={{
          height: "var(--dashboard-height)",
        }}
      >
        <div className="w-full xl:w-[calc(100%-20rem)]">
          <ControlledForm schema={profileSchema}>
            <Referrals />
          </ControlledForm>
        </div>
      </div>
    </>
  );
};

export default ReferralsPage;
