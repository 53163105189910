import { UploadImage } from "@ns/client-ui";
import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { useUpload } from "../../../hooks/use-upload.ts";
import useUserStore from "../../store/user-store.ts";

type Props = {
  name: string;
  id?: string;
  uploadFileHeading: string;
  maxFileSizeText: string;
  browseText: string;
  errorText: string;
  maxFileSize: string;
  dragAndDropText: string;
  uploadAgainText: string;
  folder: "users" | "chat";
};

export const ControlledImageUpload: FC<Props> = ({
  name,
  id,
  maxFileSizeText,
  browseText,
  errorText,
  maxFileSize,
  dragAndDropText,
  uploadAgainText,
  folder,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { setImageUrl, imageUrl } = useUserStore();

  const {
    isSuccess,
    upload,
    progress,
    setIsSuccess,
    setProgress,
    cancelToken,
    fileUrl,
  } = useUpload(folder);

  const [fileSize, setFileSize] = useState<number>(0);

  useEffect(() => {
    setImageUrl(fileUrl);
  }, [fileUrl, setImageUrl]);

  const handleUpload = async (file: File) => {
    const formData: FormData = new FormData();
    formData.append("file", file);

    setFileSize(Number((file.size / (1024 * 1024)).toFixed(2)));
    upload(formData)
      .then(() => {
        setValue(name, {}, { shouldValidate: true, shouldDirty: true });
      })
      .catch((error: unknown) => {
        console.log((error as Error).message);
        setValue(name, "", { shouldValidate: true, shouldDirty: true });
      });
  };

  const onDelete = (name: string) => {
    setValue(name, "", { shouldDirty: true, shouldValidate: true });
    if (!isSuccess) {
      cancelToken?.cancel();
      setProgress(-1);
    }
    setIsSuccess(false);
    setProgress(-1);
  };

  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      render={() => (
        <UploadImage
          id={id}
          uploadFile={(file) => handleUpload(file)}
          maxFileSizeText={maxFileSizeText}
          browseText={browseText}
          errorText={errorText}
          errors={errors}
          fileSrc={imageUrl}
          maxFileSize={maxFileSize}
          dragAndDropText={dragAndDropText}
          isSuccess={isSuccess}
          isProgress={progress !== -1}
          fileSize={fileSize}
          progressPercentage={progress}
          onDelete={() => onDelete(name)}
          uploadAgainText={uploadAgainText}
        />
      )}
    />
  );
};
