/* eslint-disable @typescript-eslint/naming-convention */
import {
  AccountNav,
  AuthCard,
  AuthCardContent,
  Button,
  Divider,
  FormLayout,
  Modal,
  SocialsLayout,
} from "@ns/client-ui";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  registerFieldNames,
  RegisterFormData,
  registerSchema,
} from "../../schemas/form/register-schema";
import { useAuthContext, UserDetails } from "../../store/auth-store.ts";
import useUserStore from "../../store/user-store";
import CloseBtn from "../svg-components/close-btn.tsx";
import ControlledCheckbox from "../form/controlled-checkbox.tsx";
import ControlledForm from "../form/controlled-form";
import ControlledInput from "../form/controlled-input";

import googleSvg from "/svg/google.svg";
import facebookSvg from "/svg/facebook.svg";

const CreateAccountForm = () => {
  const {
    toggleAuthModal,
    setNextStep,
    setUserDetails,
    setIsLoginModalOpen,
    userDetails,
  } = useAuthContext((state) => state);

  const { t } = useTranslation();

  const socials = useMemo(
    () => [
      {
        onClick: () =>
          axios
            .post(
              `${environmentVariables.authServiceUrl}/oidc/google`,
              {
                redirect_url: environmentVariables.baseUrl,
                server: true,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              },
            )
            .then((res) => {
              if (res.data) {
                window.location.href = res.data;
              } else {
                console.error("No redirect URL in response");
              }
            })
            .catch((error: unknown) =>
              console.error("Error:", (error as Error).message),
            ),
        src: googleSvg,
      },
      {
        onClick: () =>
          axios
            .post(
              `${environmentVariables.authServiceUrl}/oidc/facebook`,
              {
                redirect_url: environmentVariables.baseUrl,
                server: true,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              },
            )
            .then((res) => {
              if (res.data) {
                window.location.href = res.data;
              } else {
                console.error("No redirect URL in response");
              }
            })
            .catch((error: unknown) =>
              console.error("Error:", (error as Error).message),
            ),
        src: facebookSvg,
      },
    ],
    [],
  );

  const { languageId } = useUserStore();
  const {
    handleSubmit,
    setError,
    watch,
    trigger,
    clearErrors,
    setValue,
    formState: { errors, isValid },
  } = useFormContext<RegisterFormData>();

  useEffect(() => {
    const formFields = [
      "email",
      "username",
      "password",
      "confirmPassword",
    ] as const;

    formFields.forEach((field) => {
      if (userDetails?.[field]) {
        setValue(field, userDetails[field as keyof UserDetails] || "");
      }
    });
  }, [userDetails, setValue]);

  const password = useWatch({ name: "password" });
  const confirmPassword = useWatch({ name: "confirmPassword" });

  useEffect(() => {
    const validateConfirmPassword = async () => {
      if (confirmPassword) {
        const result = await trigger("confirmPassword");

        if (result) {
          if (password !== confirmPassword) {
            setError("confirmPassword", {
              message: t("form.authentication.passwordsDoNotMatch"),
            });
          } else {
            clearErrors("confirmPassword");
          }
        }
      }
    };

    validateConfirmPassword();
  }, [confirmPassword, password, trigger, setError, clearErrors]);

  const email = useWatch({ name: "email" });
  const username = useWatch({ name: "username" });
  const checkedPrivacy = watch("privacy");
  const checkedTerms = watch("terms");

  const { mutate: checkEmailExist } = useMutation({
    mutationFn: (email: string) =>
      axios.post(`${environmentVariables.authServiceUrl}/basic/email/check`, {
        email: email.toLowerCase(),
      }),
    onSuccess: (response) => {
      if (!response.data.valid) {
        setError("email", { message: t("form.emailExist") });
      }
    },
    onError: () => {
      setError("email", { message: t("form.emailExist") });
    },
  });

  const { mutate: checkUsernameExist } = useMutation({
    mutationFn: (username: string) =>
      axios.post(
        `${environmentVariables.authServiceUrl}/basic/username/check`,
        {
          username,
        },
      ),
    onSuccess: (response) => {
      if (!response.data.valid) {
        setError("username", { message: t("form.usernameExist") });
      }
    },
    onError: () => {
      setError("username", { message: t("form.usernameExist") });
    },
  });

  useEffect(() => {
    if (!email) {
      return;
    }

    const timeout = setTimeout(() => {
      checkEmailExist(email);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [email, checkEmailExist]);

  useEffect(() => {
    if (!username) {
      return;
    }

    const timeout = setTimeout(() => {
      checkUsernameExist(username);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [username, checkUsernameExist]);

  useEffect(() => {
    if (userDetails?.email) {
      setValue("username", userDetails?.username);
      setValue("email", userDetails?.email);
    }
  }, [userDetails]);

  const nextStepHandler = handleSubmit((data) => {
    setUserDetails({
      email: data.email,
      password: data.password,
      username: data.username,
      confirmPassword: data.confirmPassword,
      languageId,
    });
    setNextStep();
  });

  const openLoginHandler = useCallback(() => {
    setIsLoginModalOpen(true);
    toggleAuthModal();
  }, [toggleAuthModal, setIsLoginModalOpen]);

  const renderContent = useMemo(() => {
    return (
      <>
        <ControlledInput
          name={registerFieldNames.email}
          placeholder={t("createAccountDialog.enterEmailAddress")}
        />
        <ControlledInput
          name={registerFieldNames.username}
          placeholder={t("createAccountDialog.username")}
        />
        <ControlledInput
          name={registerFieldNames.password}
          placeholder={t("createAccountDialog.password")}
          type={"password"}
        />
        <ControlledInput
          name={registerFieldNames.confirmPassword}
          placeholder={t("createAccountDialog.confirmPassword")}
          type={"password"}
        />
        <ControlledCheckbox
          name="privacy"
          label={t("createAccountDialog.readPrivacy")}
          link={"https://www.astealdeal.com/privacypolicy"}
        />
        <ControlledCheckbox
          name="terms"
          label={t("createAccountDialog.agreeWithTerms")}
          link={"https://www.astealdeal.com/terms-conditions"}
        />
        <Button
          onClick={nextStepHandler}
          disabled={
            Object.keys(errors).length !== 0 ||
            !checkedPrivacy ||
            !checkedTerms ||
            !isValid
          }
          variant={"primary"}
        >
          {t("common.continue")}
        </Button>
        {environmentVariables.socialLogin === "true" && (
          <>
            <Divider text={t("common.or")} />
            <SocialsLayout socialArr={socials} />
          </>
        )}
        <AccountNav
          description={t("createAccountDialog.alreadyHaveAccount")}
          actionText={t("createAccountDialog.signIn")}
          action={openLoginHandler}
        />
      </>
    );
  }, [
    errors,
    socials,
    nextStepHandler,
    openLoginHandler,
    t,
    checkedPrivacy,
    checkedTerms,
  ]);

  return (
    <AuthCard className="lg:min-h-[90%]">
      <CloseBtn
        className="absolute top-[30px] right-[16px]"
        onClick={toggleAuthModal}
      />
      <AuthCardContent>
        <FormLayout
          isSignUp
          title={t("createAccountDialog.welcome")}
          subTitle={t("createAccountDialog.createAccount")}
          description={t("createAccountDialog.inOrderToPlay")}
        >
          {renderContent}
        </FormLayout>
      </AuthCardContent>
    </AuthCard>
  );
};

const CreateAccountDialog = () => {
  const isOpen = useAuthContext((state) => state.isAuthModalOpen);
  return (
    <ControlledForm schema={registerSchema}>
      <Modal isOpen={isOpen}>
        <CreateAccountForm />
      </Modal>
    </ControlledForm>
  );
};

export default CreateAccountDialog;
