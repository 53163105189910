import { Switch } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import logoButton from "/assets/logo-button.png";
import profile from "/svg/profile.svg";
import myBids from "/svg/my-bids.svg";
import allRounds from "/svg/all-rounds.svg";
import myReferral from "/svg/my-referral.svg";
import settings from "/svg/settings.svg";
import addWallet from "/svg/add-wallet.svg";
import help from "/svg/help.svg";
import logout from "/svg/logout.svg";
import logo from "/svg/small-logo.svg";
import trash from "/svg/trash.svg";

import { api } from "../../api/client-api.ts";
import { removeCodeVerified } from "../../api/token-helper.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import { useDialogContext } from "../../store/dialog-store.ts";
import useUserStore from "../../store/user-store.ts";
import { featureWallet } from "../../utils/feature-flags/feature-flags.ts";
import { DeleteAccountDialog } from "../dialogs/delete-account-dialog.tsx";
import ExpandableItem from "../expandable-item.tsx";
import ExpandableSimpleMenu from "../expandable-simple-menu.tsx";
import CloseBtnMobile from "../svg-components/close-btn-mobile.tsx";

import ProfileAvatar from "./profile-avatar.tsx";

interface Props {
  closeMenu: () => void;
  className?: string;
}

const MobileMenu: FC<Props> = ({ className, closeMenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setUser, user, selectedItem, setSelectedItem } = useUserStore();
  const {
    setIsLanguageDialogOpen,
    setIsUploadImageDialogOpen,
    setIsChangePasswordDialogOpen,
    setIsLockScreenDialogOpen,
    setIsSuccessSetDialogOpen,
    setIsDeleteAccountDialogOpen,
    isLockScreenDialogOpen,
    isSuccessSetDialogOpen,
  } = useDialogContext();

  const { setIsLoginModalOpen } = useAuthContext();

  const [settingsMenu, setSettingsMenu] = useState<boolean>(false);
  const [profileMenu, setProfileMenu] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [supportMenu, setSupportMenu] = useState<boolean>(false);
  const [previousItem, setPreviousItem] = useState<string | undefined | null>(
    null,
  );

  const location = useLocation();

  const { data: setup, refetch } = useQuery({
    queryKey: ["setup"],
    queryFn: () => api.notificationsSetup.getNotificationsSetup(),
    enabled: !!user?.id,
  });

  const { data: activePinCode } = useQuery({
    queryKey: ["pinCode"],
    queryFn: () => api.pinCode.getPinCode(),
    enabled: !!user?.id,
  });

  const { mutate: togglePinCode } = useMutation({
    mutationFn: () => api.pinCode.togglePinCodeActivity(),
    onSuccess: () => {
      queryClient.invalidateQueries(["pinCode"]);
    },
  });

  const { mutate } = useMutation({
    mutationFn: () =>
      api.notificationsSetup.updateNotificationsSetup({
        sendNotification: !setup?.sendNotification,
      }),
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      Sentry.withScope((scope) => {
        scope.setTag("mobile-update-notifications", "basic");
        Sentry.captureException(error);
      });
    },
  });

  const onLogout = async () => {
    try {
      await axios
        .put(
          `${environmentVariables.authServiceUrl}/session/logout`,
          {},
          { withCredentials: true },
        )
        .then(() => {
          setUser(null);
          removeCodeVerified();
          navigate("/", { replace: true });
          queryClient.invalidateQueries();
          queryClient.removeQueries();
          closeMenu();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
    settingsMenu && setSettingsMenu(false);
    profileMenu && setProfileMenu(false);
    if (selectedItem === "profile" && location.pathname !== "/referral") {
      setSelectedItem(previousItem);
    }
  };

  const onLockScreenHandler = () => {
    if (activePinCode) {
      togglePinCode();
    } else {
      setIsLockScreenDialogOpen(!isLockScreenDialogOpen);
      closeMenu();
    }
  };

  const toggleChangePinDialog = () => {
    if (!activePinCode) {
      setIsLockScreenDialogOpen(!isLockScreenDialogOpen);
    } else {
      setIsSuccessSetDialogOpen(!isSuccessSetDialogOpen);
    }
    closeMenu();
  };

  const toggleProfileDropdown = () => {
    !isOpen && toggleMenu();
    if (selectedItem === "profile") {
      setSelectedItem(previousItem);
    } else {
      setPreviousItem(selectedItem);
      setSelectedItem("profile");
    }
    setProfileMenu((prev) => !prev);
  };

  const toggleUploadImageDialog = () => {
    setIsUploadImageDialogOpen(true);
    closeMenu();
  };

  const togglePasswordDialog = (): void => {
    setIsChangePasswordDialogOpen(true);
    closeMenu();
  };

  const toggleSettingsDropdown = () => {
    !isOpen && toggleMenu();
    if (selectedItem === "settings") {
      setSelectedItem(previousItem);
    } else {
      setPreviousItem(selectedItem);
      setSelectedItem("settings");
    }
    setSettingsMenu((prev) => !prev);
  };

  const toggleSupportDropdown = () => {
    !isOpen && toggleMenu();
    setSupportMenu((prev) => !prev);
  };

  const navigateHandler = (route: string) => {
    navigate(route);
    closeMenu();
  };

  const toggleLanguageDialog = () => {
    closeMenu();
    setIsLanguageDialogOpen(true);
  };

  const toggleAuthDialog = () => {
    setIsLoginModalOpen(true);
    closeMenu();
  };

  return (
    <div
      className={`lg:hidden overflow-y-auto bg-sideMenuBg p-4 ${className} flex flex-col items-center h-screen fixed z-[1000000002] text-white w-hull w-full`}
    >
      <div className={"flex items-center justify-around gap-4 w-full"}>
        {user ? <ProfileAvatar /> : <div></div>}
        <img
          src={logoButton}
          alt="logo button"
          className="z-10 w-16 h-16 cursor-pointer"
        />
        <CloseBtnMobile onClick={closeMenu} />
      </div>
      <div className={"flex items-center flex-col gap-2 mt-6 mb-6"}>
        <div>{user?.username}</div>
      </div>
      <div className={"flex flex-col justify-center w-full items-center"}>
        {user && (
          <>
            <div className="w-fit">
              <ExpandableItem
                text={t("links.profile")}
                imgSrc={profile}
                shouldExpand={isOpen}
                onClick={toggleProfileDropdown}
                isSelected={selectedItem === "profile"}
              />
              {profileMenu && (
                <div className="w-full flex flex-col bg-collapsibleBg rounded-[8px] p-[16px] gap-[12px]">
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={toggleUploadImageDialog}
                  >
                    {t("common.uploadImage")}
                  </span>
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={togglePasswordDialog}
                  >
                    {t("common.changePassword")}
                  </span>
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={toggleChangePinDialog}
                  >
                    {t("common.changeScreenLock")}
                  </span>
                  <span
                    className="text-white text-[14px] cursor-pointer"
                    onClick={() => {
                      setSelectedItem("profile");
                      navigateHandler("/referral");
                    }}
                  >
                    {t("links.addReferral")}
                  </span>
                  <div
                    onClick={() => {
                      setIsDeleteAccountDialogOpen(true);
                      closeMenu();
                    }}
                    className="flex cursor-pointer items-center justify-between w-full"
                  >
                    <span className="text-red-600 text-[14px]">
                      {t("common.deleteAccount")}
                    </span>
                    <img src={trash} alt="Delete" />
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {user ? (
          <>
            <ExpandableItem
              text={t("links.myBids")}
              imgSrc={myBids}
              shouldExpand={isOpen}
              onClick={() => {
                setSelectedItem("myBids");
                navigateHandler("/bids");
              }}
              isSelected={selectedItem === "myBids"}
            />
            <ExpandableItem
              text={t("links.myReferrals")}
              imgSrc={myReferral}
              shouldExpand={isOpen}
              onClick={() => {
                setSelectedItem("myReferrals");
                navigateHandler("/referrals");
              }}
              isSelected={selectedItem === "myReferrals"}
            />
          </>
        ) : (
          <ExpandableItem
            shouldExpand={isOpen}
            text={t("common.signIn")}
            imgSrc={logo}
            onClick={toggleAuthDialog}
          />
        )}

        <ExpandableItem
          text={t("links.winners")}
          imgSrc={allRounds}
          shouldExpand={isOpen}
          onClick={() => {
            setSelectedItem("winners");
            navigateHandler("/winners");
          }}
          isSelected={selectedItem === "winners"}
        />

        {user && featureWallet && (
          <ExpandableItem
            text={t("links.addWallet")}
            imgSrc={addWallet}
            shouldExpand={isOpen}
            onClick={() => {
              setSelectedItem("wallet");
              navigateHandler("/wallet");
            }}
            isSelected={selectedItem === "wallet"}
          />
        )}

        <div className="w-fit">
          <ExpandableItem
            text={t("links.settings")}
            imgSrc={settings}
            shouldExpand={isOpen}
            onClick={toggleSettingsDropdown}
            isSelected={selectedItem === "settings"}
          />
          {settingsMenu && (
            <div className="w-full flex flex-col bg-collapsibleBg rounded-[8px] p-[16px] gap-[12px]">
              {user && (
                <div className="flex items-center justify-between w-full">
                  <span className="text-white text-[14px]">
                    {t("common.notifications")}
                  </span>
                  <Switch
                    onClick={() => mutate()}
                    checked={setup?.sendNotification}
                    className={
                      setup?.sendNotification ? `bg-[#00F0FF]` : "bg-secondary"
                    }
                  />
                </div>
              )}
              {user && (
                <div className="text-white flex items-center justify-between w-full">
                  <span className="text-white text-[14px]">
                    {t("screenLock.screenLock")}
                  </span>
                  <Switch
                    onClick={onLockScreenHandler}
                    checked={activePinCode ? activePinCode.isActive : false}
                    className={
                      activePinCode?.isActive ? `bg-[#00F0FF]` : "bg-secondary"
                    }
                  />
                </div>
              )}
              {environmentVariables.languagesFlag && (
                <span
                  className="text-white text-[14px] cursor-pointer"
                  onClick={toggleLanguageDialog}
                >
                  {t("common.languages")}
                </span>
              )}
              <span
                className="text-white text-[14px] cursor-pointer"
                onClick={() => navigateHandler("/about-us")}
              >
                {t("common.aboutUs")}
              </span>
              <ExpandableSimpleMenu
                text={t("links.support")}
                shouldExpand={isOpen}
                onClick={toggleSupportDropdown}
                isActive={supportMenu}
              />
              {supportMenu && (
                <div className="w-full flex flex-col rounded-[8px] pl-[16px] gap-[12px]">
                  <div className="flex flex-col gap-[0.5rem] justify-between w-full">
                    <span
                      className="text-white text-[14px] cursor-pointer"
                      onClick={() => {
                        navigateHandler("/demo");
                      }}
                    >
                      {t("common.demo")}
                    </span>
                    <span
                      className="text-white text-[14px] cursor-pointer"
                      onClick={() => {
                        navigateHandler("/support");
                      }}
                    >
                      {t("common.help")}
                    </span>
                  </div>
                </div>
              )}
              <span
                className="text-white text-[14px] cursor-pointer"
                onClick={() =>
                  window.open("https://www.astealdeal.com/privacypolicy")
                }
              >
                {t("common.privacyPolicy")}
              </span>
              <span
                className="text-white text-[14px] cursor-pointer"
                onClick={() =>
                  window.open("https://www.astealdeal.com/terms-conditions")
                }
              >
                {t("common.termsConditions")}
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-col w-full items-center flex-1">
          <ExpandableItem
            text={t("links.help")}
            imgSrc={help}
            shouldExpand={isOpen}
            onClick={() => {
              navigateHandler("/support");
            }}
          />

          {user && (
            <ExpandableItem
              text={t("common.logout")}
              imgSrc={logout}
              shouldExpand={isOpen}
              onClick={onLogout}
              isLogout={true}
            />
          )}
        </div>
      </div>
      <DeleteAccountDialog />
    </div>
  );
};

export default MobileMenu;
