import type { RoundWinnerResponse } from "@ns/api";
import { useMemo, useRef, useState } from "react";

import chevrons from "/svg/chevrons.svg";
import stealDealPng from "/assets/no-data.jpeg";

import { useTranslation } from "react-i18next";

import { useOutsideClick } from "../../hooks/use-outside-click.ts";
import { usePagination } from "../../hooks/use-pagination.ts";
import { api } from "../api/client-api.ts";
import { CURRENCY_IMAGE } from "../constants/currency-images.ts";

import FlatList from "./flat-list.tsx";
import WinnersCard from "./ui/winners-card.tsx";

const FooterWinnerMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>("");
  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const containerRef = useRef(null);
  useOutsideClick([containerRef], () => setIsOpen(false));

  const {
    data: winnersData,
    lastItemRef,
    isFetchingNextPage,
  } = usePagination<RoundWinnerResponse>({
    queryKey: ["winners", currencyFilter || ""],
    queryFn: (offset: number, limit: number) =>
      api.winners.getWinners(currencyFilter, "", limit, offset),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const renderData = useMemo(() => {
    let allWinners = winnersData;
    if (currencyFilter) {
      allWinners = winnersData.filter(
        (item) => item.currencyCode === currencyFilter,
      );
    }

    if (!allWinners?.length) {
      return (
        <div className="h-full flex w-full flex-col items-center justify-center flex-1">
          <span className="text-[24px] avenir-semibold text-white">
            {t("footerMenu.noWinners")}
          </span>
          <img
            className={"w-[150px] h-auto"}
            src={stealDealPng}
            alt="no bids img"
          />
        </div>
      );
    }

    return (
      <FlatList<RoundWinnerResponse>
        data={winnersData}
        isFetching={isFetchingNextPage}
        keyExtractor={(item: RoundWinnerResponse) => item.roundNumber}
        ref={lastItemRef}
        renderItem={(bid: RoundWinnerResponse) => (
          <WinnersCard
            name={bid.name}
            bid={bid.bid}
            currencyCode={bid.currencyCode}
            createdAt={bid.createdAt}
            roundNumber={bid.roundNumber}
            winnerUserId={bid.winnerUserId}
            reward={bid.reward}
            email={bid.email}
            winnerCountryFlag={bid.winnerCountryFlag}
            winnerImageUrl={bid.winnerImageUrl}
          />
        )}
      />
    );
  }, [t, winnersData, currencyFilter]);

  const handleSelectedColor = (currency: string) => {
    if (currencyFilter === currency) return "text-white";
  };

  return (
    <div
      ref={containerRef}
      className="flex h-[500px] absolute bottom-0 flex-1 flex-col items-end justify-end max-w-[1096px] w-full"
    >
      <div className="flex w-full absolute bottom-0 h-full">
        <div
          className={`flex flex-1 w-full flex-col footer-menu ${
            isOpen
              ? "footer-menu-active translateY(0)"
              : "footer-menu translate-y-[440px]"
          } gap-[30px] rounded-tr-[30px] transition-transform duration-300 ease-in-out z-[100]`}
        >
          <div
            onClick={toggleMenu}
            className={`absolute cursor-pointer flex justify-center items-center w-[40px] h-[40px] top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full ${
              isOpen
                ? "bg-backgroundSecondary  border border-gray-700"
                : "bg-borderBlue"
            } z-20`}
          >
            <img
              src={chevrons}
              className={`transition-transform duration-300 ease-in-out ${
                isOpen ? "rotate-[0deg]" : "rotate-[180deg]"
              }`}
            />
          </div>

          <div className="h-full flex items-center overflow-hidden gap-[16px] mt-5 p-2 flex-col z-10">
            <span className="text-base text-white avenir-semibold self-start px-[16px]">
              {t("footerMenu.allWinners")}
            </span>
            <div className={"flex gap-4 text-sm w-full text-textGray3"}>
              <div
                onClick={() => setCurrencyFilter("BTC")}
                className={"flex gap-2 cursor-pointer"}
              >
                <img
                  src={CURRENCY_IMAGE["BTC"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleSelectedColor("BTC")}>BTC</span>
              </div>
              <div
                className={"flex gap-2 cursor-pointer"}
                onClick={() => setCurrencyFilter("ETH")}
              >
                <img
                  src={CURRENCY_IMAGE["ETH"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleSelectedColor("ETH")}>ETH</span>
              </div>
              <div
                className={"cursor-pointer"}
                onClick={() => setCurrencyFilter("")}
              >
                <span className={`${!currencyFilter && "text-white"}`}>
                  ALL
                </span>
              </div>
            </div>
            <div className="h-full flex justify-start overflow-auto gap-[16px] flex-col h-[260px] items-start w-full h-auto">
              {renderData}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterWinnerMenu;
