import { CardGradient } from "@ns/client-ui";

export interface CardDataType {
  title: string;
  value: string | number;
  svg: React.FC;
}

const CardData = ({ title, value, svg: Svg }: CardDataType) => {
  return (
    <CardGradient>
      <div className="flex flex-col gap-[12px] sm:gap-[24px]">
        <div className="flex flex-row items-center">
          <span className="text-sm flex-1 lg:text-[18px] font-medium">
            {title}
          </span>
          <Svg />
        </div>
        <div>
          <span className="text-md lg:text-[24px] font-bold">{value}</span>
        </div>
      </div>
    </CardGradient>
  );
};

export default CardData;
