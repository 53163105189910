import { Input } from "@ns/client-ui";
import { ChangeEvent, MouseEventHandler, forwardRef, useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";

import exclamationSvg from "/svg/exclamation.svg";

import CopyButton from "../svg-components/copy-button.tsx";
import SuccessButton from "../svg-components/success-button.tsx";

export type ControlledInputProps = {
  type?: "text" | "password" | "number" | "email";
  label?: string;
  placeholder?: string;
  name: string;
  className?: string;
  id?: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errors?: FieldErrors<FieldValues>;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
  isError?: boolean;
  icon?: string;
  iconText?: string;
  defaultValue?: string;
  isOutlineShown?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  hasCopyIcon?: boolean;
};

const ControlledInput = forwardRef<HTMLInputElement, ControlledInputProps>(
  (
    {
      placeholder,
      name = "",
      className,
      disabled = false,
      autoFocus,
      id,
      type = "text",
      onClick,
      icon,
      defaultValue = "",
      isOutlineShown = true,
      onKeyDown,
      iconText,
      hasCopyIcon = false,
    },
    ref,
  ) => {
    const { control, getValues } = useFormContext();
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const onCopyHandler = () => {
      if (hasCopyIcon && placeholder) {
        navigator.clipboard.writeText(getValues(name));
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }
    };

    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <div className={`flex flex-col gap-[8px] w-full relative h-[55px]`}>
            <div className={"relative"}>
              <Input
                id={id}
                value={field.value ?? ""}
                onChange={field.onChange}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                autoFocus={autoFocus}
                onClick={onClick}
                className={`${className} ${error && "border-destructive"} ${hasCopyIcon && "pr-10 placeholder-inputPlaceholderWhite disabled:opacity-100"}`}
                isOutlineShown={isOutlineShown}
                icon={icon}
                onKeyDown={onKeyDown}
                iconText={iconText}
                ref={ref}
              />
              <div
                className={
                  "absolute right-2 top-[50%] transform -translate-y-1/2"
                }
              >
                {hasCopyIcon &&
                  (!isCopied ? (
                    <CopyButton onClick={onCopyHandler} className="w-6 h-6" />
                  ) : (
                    <SuccessButton className="w-6 h-6" />
                  ))}
              </div>
            </div>
            {error?.message && (
              <div className="absolute top-[40px] flex justify-start gap-[3px]">
                <img
                  className={"self-baseline mt-[3px]"}
                  src={exclamationSvg}
                  alt="exclamation"
                />
                <span className="text-left text-red-600 text-[12px]">
                  {error.message}
                </span>
              </div>
            )}
          </div>
        )}
      />
    );
  },
);

ControlledInput.displayName = "ControlledInput";

export default ControlledInput;
