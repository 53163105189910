/* eslint-disable @typescript-eslint/naming-convention */
import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  NewPasswordData,
  newPasswordValidationSchema,
} from "../../schemas/form/new-password-schema";
import useUserStore from "../../store/user-store.ts";
import ControlledForm from "../form/controlled-form";
import ControlledInput from "../form/controlled-input";

import tickCircleSvg from "/svg/tick-circle.svg";

const NewPasswordForm = ({
  onClose,
  openSignIn,
}: {
  isOpen: boolean;
  onClose: () => void;
  openSignIn: () => void;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { isValid },
  } = useFormContext<NewPasswordData>();
  const { setResetId, setResetEmail } = useUserStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  const closeModal = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const submit = handleSubmit((data) => {
    setLoading(true);

    axios
      .post(
        `${environmentVariables.authServiceUrl}/password/forgot/submit`,
        {
          new_password: data.newPassword,
          repeated_new_password: data.confirmNewPassword,
        },
        { withCredentials: true },
      )
      .then(() => {
        setSuccess(true);
        setLoading(false);
        setResetId(undefined);
        setResetEmail(undefined);
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        Sentry.withScope((scope) => {
          scope.setTag("new-password-dialog", "basic");
          scope.setExtra("data", data);
          Sentry.captureException(error);
        });
      });
  });

  const password = useWatch({ name: "newPassword" });
  const confirmNewPassword = useWatch({ name: "confirmNewPassword" });

  useEffect(() => {
    const validateConfirmPassword = async () => {
      if (confirmNewPassword) {
        const result = await trigger("confirmNewPassword");

        if (result) {
          if (password !== confirmNewPassword) {
            setError("confirmNewPassword", {
              message: "Passwords do not match",
            });
          } else {
            clearErrors("confirmNewPassword");
          }
        }
      }
    };

    validateConfirmPassword();
  }, [confirmNewPassword, password, trigger, setError, clearErrors]);

  const renderContent = useMemo(() => {
    if (!success) {
      return (
        <div className="flex flex-col gap-[2rem] w-[340px] mb-[1rem] width-100">
          <ControlledInput
            name={"newPassword"}
            type={"password"}
            placeholder={t("newPasswordDialog.newPassword")}
          />
          <ControlledInput
            name={"confirmNewPassword"}
            type={"password"}
            placeholder={t("newPasswordDialog.confirmNewPassword")}
          />
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <img src={tickCircleSvg} />
      </div>
    );
  }, [t, success]);

  const renderActionBtn = useMemo(() => {
    if (!success) {
      return (
        <Button onClick={submit} disabled={loading || !isValid}>
          {t("common.confirm")}
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          closeModal();
          openSignIn();
        }}
        disabled={loading}
      >
        {t("signInDialog.signIn")}
      </Button>
    );
  }, [closeModal, loading, t, openSignIn, submit, success]);

  return (
    <AuthCard>
      <AuthCardContent>
        <FormLayout
          subTitle={
            success ? t("common.success") : t("newPasswordDialog.newPassword")
          }
          description={
            success
              ? t("newPasswordDialog.successText")
              : t("newPasswordDialog.description")
          }
        >
          {renderContent}
          {renderActionBtn}
          <Button onClick={closeModal} disabled={loading} variant={"secondary"}>
            {t("common.cancel")}
          </Button>
        </FormLayout>
      </AuthCardContent>
    </AuthCard>
  );
};

const NewPasswordDialog = ({
  isOpen,
  onClose,
  openSignIn,
}: {
  isOpen: boolean;
  onClose: () => void;
  openSignIn: () => void;
}) => {
  return (
    <ControlledForm schema={newPasswordValidationSchema}>
      <Modal isOpen={isOpen}>
        <NewPasswordForm
          isOpen={isOpen}
          onClose={onClose}
          openSignIn={openSignIn}
        />
      </Modal>
    </ControlledForm>
  );
};

export default NewPasswordDialog;
