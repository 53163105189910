import { UserOrderResponse } from "@ns/api";
import { Button } from "@ns/client-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BidTab } from "../pages/auth/user-page.tsx";

import FlatList from "./flat-list.tsx";
import MyBidsCard from "./ui/my-bids-card.tsx";
import WinningBidsCard from "./ui/winning-bids-card.tsx";

import stealDealPng from "/assets/no-data.jpeg";
import stealDealPngDark from "/assets/no-data-dark.jpeg";

interface Props {
  bidTab: BidTab;
  data: UserOrderResponse[];
  isFetching: boolean;
  lastItemRef: React.Ref<HTMLDivElement> | undefined;
}

const BidList = ({ bidTab, data, isFetching, lastItemRef }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {data.length === 0 ? (
        bidTab === BidTab.MY_BIDS ? (
          <div className="h-full flex flex-col items-center justify-center">
            <span className="text-[24px] avenir-semibold text-white">
              {t("userPage.noBids")}
            </span>
            <img
              className="hidden sm:block w-[200px] h-auto"
              src={stealDealPngDark}
              alt="logo"
            />
            <img
              className="sm:hidden w-[200px] h-auto"
              src={stealDealPng}
              alt="logo"
            />
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-[32px] max-w-[343px]">
              <span className="text-[16px] text-center text-white">
                {t("userPage.noBidsMsg")}
              </span>
              <div className="flex flex-col gap-[0rem] items-center">
                <span className="text-white font-bold">
                  {t("userPage.buyEthBtc")}
                </span>
                <span className="text-white">{t("userPage.forAmount")}</span>
              </div>
              <Button className="w-full" onClick={() => navigate("/")}>
                {t("common.placeBid")}
              </Button>
            </div>
          </div>
        )
      ) : (
        <FlatList<UserOrderResponse>
          data={data}
          isFetching={isFetching}
          keyExtractor={(item: UserOrderResponse) => item.id}
          ref={lastItemRef}
          renderItem={(item: UserOrderResponse) =>
            bidTab === BidTab.MY_BIDS ? (
              <MyBidsCard
                key={item.id}
                roundNumber={item.round.number}
                roundStatus={item.round.status}
                bid={item.bid}
                paymentStatus={item.status}
                // @ts-ignore
                currency={item.round.currency.code}
                reward={item?.reward}
              />
            ) : (
              <WinningBidsCard
                key={item.id}
                roundNumber={item.round.number}
                roundStatus={item.round.status}
                bid={item.bid}
                date={item.round.createdAt}
                // @ts-ignore
                currency={item.round.currency.code}
                reward={item.reward!}
              />
            )
          }
        />
      )}
    </>
  );
};

export default BidList;
