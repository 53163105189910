import { Card, CardContent } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { formatDate } from "../../helpers/helpers";

export type MyBidData = {
  roundNumber: string;
  roundStatus: string;
  bid: string;
  currency: "BTC" | "ETH";
  date: string;
  reward: string;
};

const WinningBidsCard = ({
  roundNumber,
  roundStatus,
  bid,
  currency,
  date,
  reward,
}: MyBidData) => {
  const { t } = useTranslation();
  const formatedDate = new Date(date);

  return (
    <Card className="bg-transparent h-fit w-full p-[16px] border-[1px] border-solid border-tabBorderColor text-bidCardText">
      <CardContent className="flex flex-col p-0">
        <div className={"hidden sm:block"}>
          <div className="flex justify-between text-[16px]">
            <span className="w-1/5 text-center">
              {`${t("bidCard.round")} ${roundNumber}`}
            </span>
            <span className="w-1/5 text-center">
              {t("myBidsCard.roundStatus")}
            </span>
            <span className="w-1/5 text-center">{t("myBidsCard.myBid")}</span>
            <span className="w-1/5 text-center">{t("myBidsCard.date")}</span>
            <span className="w-1/5 text-center">{t("myBidsCard.reward")}</span>
          </div>
          <div className="border-b border-tabBorderColor my-4"></div>
          <div className="flex justify-between text-[14px]">
            <div className="w-1/5 flex justify-center items-center gap-2">
              <img
                src={CURRENCY_IMAGE[currency as keyof typeof CURRENCY_IMAGE]}
                alt="Currency"
                className="w-[18px]"
              />
              <span className="w-1/5 text-center">{currency}</span>
            </div>
            <span className="w-1/5 text-center">
              {t(`common.status.${roundStatus}`)}
            </span>
            <span className="w-1/5 text-center">{`${bid} ${environmentVariables.fiatCurrency}`}</span>
            <span className="w-1/5 text-center">
              {formatDate(formatedDate)}
            </span>
            <span className="w-1/5 text-center">
              {reward} {environmentVariables.fiatCurrency}
            </span>
          </div>
        </div>

        {/*MOBILE CARD*/}
        <div className={"w-full h-full sm:hidden"}>
          <div className="flex w-full flex-col justify-between text-[16px]">
            <div className={"flex justify-between"}>
              <span>{`${t("bidCard.round")} ${roundNumber}`}</span>
              <div className="w-1/5 flex justify-center items-center gap-2">
                <img
                  src={CURRENCY_IMAGE[currency as keyof typeof CURRENCY_IMAGE]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span>{currency}</span>
              </div>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.roundStatus")}</span>
              <span>{t(`common.status.${roundStatus}`)}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.myBid")}</span>
              <span>{`${bid} ${environmentVariables.fiatCurrency}`}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.date")}</span>
              <span>{formatDate(formatedDate)}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.reward")}</span>
              <span>
                {reward} {environmentVariables.fiatCurrency}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WinningBidsCard;
