import type { ValidateForgottenCodeRequest } from "@ns/api";
import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import {
  VerificationCodeSchema,
  verificationCodeSchema,
} from "../../schemas/form/verification-code.schema.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import useUserStore from "../../store/user-store.ts";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledOtp from "../form/controlled-otp.tsx";

const VerifyPinCode = () => {
  const { t } = useTranslation();
  const { setActiveForgotPinStep } = useAuthContext();
  const { user } = useUserStore();
  const [seconds, setSeconds] = useState<number>(60);
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);

  const {
    handleSubmit,
    clearErrors,
    watch,
    setError,
    formState: { errors },
  } = useFormContext<VerificationCodeSchema>();

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (errors.code) {
        clearErrors(name);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, errors, clearErrors]);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [seconds]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const { mutate: verifyCode } = useMutation({
    mutationFn: (data: ValidateForgottenCodeRequest) =>
      api.forgottenCode.validateForgottenCode(data),
    onSuccess: (response) => {
      if (response.isValid) {
        setActiveForgotPinStep(3);
      } else {
        setError("code", { message: t("form.code") });
      }
    },
  });

  const submit = handleSubmit((data) => {
    verifyCode(data);
  });

  const { mutate: resend } = useMutation({
    mutationFn: () => api.forgottenCode.resendForgottenCodeEmail(),
    onSuccess: () => {
      setSeconds(0);
      setIsCodeResent(true);
    },
  });

  const renderAction = useCallback(() => {
    if (seconds > 0) {
      return (
        <div className={"flex flex-row gap-[1.5rem] justify-center"}>
          <span className={"text-[14px] avenir-semibold text-textGray"}>
            {t("enterCodeDialog.sendCode")}
          </span>
          <span className={"text-[14px] font-semibold text-textGray"}>
            {formatTime(seconds)}
          </span>
        </div>
      );
    }

    if (isCodeResent) {
      return (
        <div className={"flex flex-row gap-[1.5rem] justify-center"}>
          <span
            className={"text-[14px] font-semibold cursor-pointer text-textGray"}
          >
            {t("enterCodeDialog.codeResent")}
          </span>
        </div>
      );
    }

    return (
      <div className={"flex flex-row gap-[1.5rem] justify-center"}>
        <span
          className={"text-[14px] font-semibold cursor-pointer text-textGray"}
        >
          {t("enterCodeDialog.didntreceive")}
        </span>
        <span
          className={"text-[14px] font-semibold cursor-pointer text-white"}
          onClick={() => resend()}
        >
          {t("enterCodeDialog.resend")}
        </span>
      </div>
    );
  }, [seconds, t, isCodeResent]);

  return (
    <AuthCard>
      <AuthCardContent>
        <FormLayout
          subTitle={t("common.checkYourEmail")}
          description={`${t("common.securityPin")} ${user?.email}`}
        >
          <ControlledOtp name={"code"} />
          <Button className={"mt-[2rem]"} onClick={submit}>
            {t("common.submit")}
          </Button>
          {renderAction()}
        </FormLayout>
      </AuthCardContent>
    </AuthCard>
  );
};

const VerificationPinCodeForm = () => {
  return (
    <ControlledForm schema={verificationCodeSchema}>
      <VerifyPinCode />
    </ControlledForm>
  );
};

const VerifyPinCodeDialog = () => {
  const { isForgotPinModalOpen, activeForgotPinStep } = useAuthContext();

  return (
    <Modal isOpen={isForgotPinModalOpen && activeForgotPinStep === 2}>
      <VerificationPinCodeForm />
    </Modal>
  );
};

export default VerifyPinCodeDialog;
