export const BlueCircle = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"animate-spin"}
    >
      <g clipPath="url(#clip0_1939_31954)">
        <path
          d="M47.0806 107.686C46.9377 108.219 46.3889 108.537 45.8584 108.383C34.7631 105.171 25.1106 98.212 18.5564 88.6757C11.8133 78.8643 8.79223 66.9689 10.0367 55.1289C11.2811 43.289 16.7093 32.2816 25.345 24.0866C33.7387 16.1213 44.6271 11.3211 56.1478 10.4862C56.6986 10.4463 57.1694 10.871 57.1983 11.4225C57.2272 11.974 56.8033 12.4435 56.2525 12.4839C45.2074 13.2936 34.77 17.8999 26.7217 25.5374C18.4315 33.4045 13.2204 43.9716 12.0257 55.338C10.8311 66.7043 13.7313 78.1239 20.2047 87.5428C26.4891 96.6867 35.7408 103.362 46.3762 106.451C46.9066 106.605 47.2236 107.152 47.0806 107.686Z"
          fill="url(#paint0_linear_1939_31954)"
        />
        <path
          d="M72.445 13.0247C72.5879 12.4913 73.1367 12.1737 73.6672 12.3273C84.7626 15.5393 94.415 22.4984 100.969 32.0348C107.712 41.8461 110.733 53.7416 109.489 65.5815C108.245 77.4215 102.816 88.4289 94.1806 96.6238C85.787 104.589 74.8985 109.389 63.3778 110.224C62.827 110.264 62.3562 109.839 62.3273 109.288V109.288C62.2984 108.736 62.7223 108.267 63.2731 108.227C74.3182 107.417 84.7557 102.811 92.8039 95.1731C101.094 87.3059 106.305 76.7388 107.5 65.3725C108.695 54.0061 105.794 42.5865 99.3209 33.1676C93.0365 24.0237 83.7848 17.348 73.1494 14.2595C72.6191 14.1055 72.3021 13.5582 72.445 13.0247V13.0247Z"
          fill="url(#paint1_linear_1939_31954)"
        />
        <path
          d="M47.5494 105.446C47.299 102.847 45.0555 101.002 42.4506 101.252C39.8458 101.503 37.9959 103.741 38.2463 106.34C38.4968 108.939 40.7403 110.784 43.3452 110.533C45.9679 110.468 47.7999 108.044 47.5494 105.446Z"
          fill="#2C3D4C"
          stroke="url(#paint2_linear_1939_31954)"
          strokeMiterlimit="10"
        />
        <path
          d="M76.5494 12.4456C76.299 9.847 74.0555 8.00201 71.4506 8.25246C68.8458 8.50292 66.9959 10.7415 67.2463 13.3401C67.4968 15.9387 69.7403 17.7837 72.3452 17.5333C74.9679 17.4684 76.7999 15.0443 76.5494 12.4456Z"
          fill="#2C3D4C"
          stroke="url(#paint3_linear_1939_31954)"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1939_31954"
          x1="78.7627"
          y1="-33.6445"
          x2="100.12"
          y2="98.4705"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.303689" stopColor="#004DF4" stopOpacity="0" />
          <stop offset="0.674376" stopColor="#32FDE8" />
          <stop offset="0.89827" stopColor="#26BAD9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1939_31954"
          x1="40.7629"
          y1="154.355"
          x2="19.4057"
          y2="22.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.303689" stopColor="#004DF4" stopOpacity="0" />
          <stop offset="0.674376" stopColor="#32FDE8" />
          <stop offset="0.89827" stopColor="#26BAD9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1939_31954"
          x1="-21.2719"
          y1="132.693"
          x2="63.6648"
          y2="104.197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.657691" stopColor="#2ACFDE" />
          <stop offset="0.969401" stopColor="#07EAD3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1939_31954"
          x1="7.72813"
          y1="39.6928"
          x2="92.6648"
          y2="11.1973"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.657691" stopColor="#2ACFDE" />
          <stop offset="0.969401" stopColor="#07EAD3" />
        </linearGradient>
        <clipPath id="clip0_1939_31954">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
      <style>
        {`
        @keyframes spin {
          0% { transform: rotate(360deg); }
          100% { transform: rotate(0deg); }
        }
        .animate-spin {
          animation: spin 4s linear infinite;
          transform-origin: center;
        }
        @media screen and (max-width: 640px) {
          .animate-spin {
            width: 170px;
            height: 170px;
          }
        }
        `}
      </style>
    </svg>
  );
};
