import { Button } from "@ns/client-ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  getUnreadNotifications,
  readNotifications,
} from "../../graphql/api.ts";
import { useAuthContext } from "../store/auth-store.ts";
import { useDialogContext } from "../store/dialog-store.ts";
import { RegistrationSteps } from "../store/registration-steps.tsx";
import useUserStore from "../store/user-store";

import gitgtSvg from "/svg/gift.svg";

import AuthDialog from "./dialogs/auth-dialog";
import GiftDialog from "./dialogs/gift-dialog";
import { SuccessDialog } from "./dialogs/success-dialog.tsx";
import { LanguagePicker } from "./language-picker.tsx";
import BellButton from "./svg-components/bell-button.tsx";
import BurgerMenu from "./svg-components/burger-menu.tsx";

import logoButton from "/assets/a-logo.png";

interface Props {
  openMobileMenu: () => void;
}

const SubHeaderControls: FC<Props> = ({ openMobileMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    user,
    setSelectedItem,
    setHasUnreadNotifications,
    hasUnreadNotifications,
  } = useUserStore();
  const {
    setIsGiftDialogOpen,
    isGiftDialogOpen,
    setBellRef,
    toggleNotificationOpen,
  } = useDialogContext();
  const { setIsLoginModalOpen, isSuccessModalOpen, toggleSuccessModal } =
    useAuthContext();
  const bellRef = useRef<HTMLDivElement | null>(null);

  useQuery({
    queryKey: ["hasUnreadNotifications"],
    queryFn: () => getUnreadNotifications({ userId: user?.id }),
    onSuccess: (response) => {
      if (response?.getUnreadNotifications?.hasUnread) {
        setHasUnreadNotifications(true);
      }
    },
    enabled: !!user,
  });

  const { mutate } = useMutation({
    mutationFn: () => readNotifications({ userId: user?.id }),
    onSuccess: () => {
      setHasUnreadNotifications(false);
    },
  });

  useEffect(() => {
    if (bellRef?.current) {
      setBellRef(bellRef);
    }
  }, [bellRef?.current, setBellRef]);

  const toggleAuthDialog = () => {
    setIsLoginModalOpen(true);
  };

  const toggleGiftDialog = (val: boolean) => {
    setIsGiftDialogOpen(val);
  };

  const { activeRegistrationStep, isAuthModalOpen } = useAuthContext(
    (state) => state,
  );

  const Component = RegistrationSteps[activeRegistrationStep].component;
  const componentProps = {
    ...RegistrationSteps[activeRegistrationStep].props,
    toggleAuthDialog,
  };

  const handleClick = useCallback(() => {
    toggleNotificationOpen();
    mutate();
  }, [setHasUnreadNotifications, toggleNotificationOpen, mutate]);

  return (
    <>
      <div className="flex w-full justify-between px-[16px] gap-[16px] mb-[0.5rem] items-center pt-[5px] pb-[10px] xl:py-0 relative">
        <div
          className={`hidden sm:flex items-center justify-center gap-[16px] cursor-pointer border-[1px] border-tabBorderColor p-[8px] rounded-[8px] bg-tabsInactiveGradient w-fit xl:w-[210px] ${
            user ? "visible" : "invisible"
          }`}
          onClick={() => toggleGiftDialog(true)}
        >
          <img src={gitgtSvg} alt="gift" />
          <span className="text-white hidden xl:block">
            {t("common.claim")}
          </span>
        </div>
        <img
          src={logoButton}
          alt="logo button"
          className="z-10 top-[-5px] sm:top-0 lg:block w-14 h-14 lg:w-[89px] lg:h-[87px] cursor-pointer absolute left-1/2 transform -translate-x-1/2"
          onClick={() => {
            setSelectedItem(undefined);
            navigate("/");
          }}
        />
        <BurgerMenu onClick={openMobileMenu} className={"sm:hidden"} />
        {user ? (
          <div className={"flex items-center flex-row gap-[0.5rem]"}>
            <LanguagePicker />
            <div
              ref={bellRef}
              className={"hidden sm:flex sm:gap-2"}
              onClick={handleClick}
            >
              <span className="text-white hidden xl:block cursor-pointer">{`${user?.username}`}</span>
              <BellButton className={"cursor-pointer"} />
              {hasUnreadNotifications && (
                <div
                  className={
                    "w-[10px] h-[10px] bg-[#00fdf6] rounded-full absolute right-[2rem]"
                  }
                ></div>
              )}
            </div>
          </div>
        ) : (
          <div className={"flex flex-row gap-[0.5rem] w-fit"}>
            <LanguagePicker />
            <Button
              className={"ml-auto sm:ml-0 w-fit"}
              onClick={toggleAuthDialog}
            >
              {t("common.signIn")}
            </Button>
          </div>
        )}
      </div>
      <AuthDialog />
      {isAuthModalOpen && <Component {...componentProps} />}
      <GiftDialog
        isOpen={isGiftDialogOpen}
        onClose={() => toggleGiftDialog(false)}
      />
      <SuccessDialog
        isOpen={isSuccessModalOpen}
        onClose={() => {
          toggleSuccessModal();
          toggleAuthDialog();
        }}
      />
    </>
  );
};

export default SubHeaderControls;
