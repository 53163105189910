import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const countrySchema = (t: TFunction) =>
  z.object({
    country: z.string(),
    phoneNumber: z
      .string()
      .regex(/^[0-9]+$/, { message: t("form.invalidPhoneNumber") }),
  });

const schema = countrySchema(staticTranslationFunction);

export type CountryFormData = z.infer<typeof schema>;

export const registerFieldNames: FormNames<CountryFormData> = {
  country: "country",
  phoneNumber: "phoneNumber",
};
