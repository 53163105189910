import { MutableRefObject } from "react";
import { create } from "zustand";

export type DialogStore = {
  isAddReferralDialogOpen: boolean;
  isLanguageDialogOpen: boolean;
  isUploadImageDialogOpen: boolean;
  isChangePasswordDialogOpen: boolean;
  isGiftDialogOpen: boolean;
  isNotificationOpen: boolean;
  isLockScreenDialogOpen: boolean;
  isChangePinDialogOpen: boolean;
  isNewPinDialogOpen: boolean;
  isSuccessDialogOpen: boolean;
  isSuccessSetDialogOpen: boolean;
  isDeleteAccountDialogOpen: boolean;
  selectedTab?: string;
  setIsAddReferralDialogOpen: (val: boolean) => void;
  setIsLanguageDialogOpen: (val: boolean) => void;
  setIsUploadImageDialogOpen: (val: boolean) => void;
  setIsChangePasswordDialogOpen: (val: boolean) => void;
  setIsGiftDialogOpen: (val: boolean) => void;
  bellRef?: MutableRefObject<HTMLDivElement | null>;
  setBellRef: (ref: MutableRefObject<HTMLDivElement | null>) => void;
  toggleNotificationOpen: () => void;
  setIsLockScreenDialogOpen: (val: boolean) => void;
  setIsChangePinDialogOpen: (val: boolean) => void;
  setIsNewPinDialogOpen: (val: boolean) => void;
  setIsSuccessDialogOpen: (val: boolean) => void;
  setIsSuccessSetDialogOpen: (val: boolean) => void;
  setSelectedTab: (val?: string) => void;
  setIsDeleteAccountDialogOpen: (val: boolean) => void;
  toggleIsDeleteAccountDialogOpen: () => void;
};

export const useDialogContext = create<DialogStore>((set) => ({
  isAddReferralDialogOpen: false,
  isLanguageDialogOpen: false,
  isUploadImageDialogOpen: false,
  isChangePasswordDialogOpen: false,
  isGiftDialogOpen: false,
  isNotificationOpen: false,
  isLockScreenDialogOpen: false,
  isChangePinDialogOpen: false,
  isNewPinDialogOpen: false,
  isSuccessDialogOpen: false,
  isSuccessSetDialogOpen: false,
  isDeleteAccountDialogOpen: false,
  bellRef: undefined,
  selectedTab: undefined,
  setSelectedTab: (val?: string) => set(() => ({ selectedTab: val })),
  setIsAddReferralDialogOpen: (val: boolean) =>
    set(() => ({ isAddReferralDialogOpen: val })),
  setIsLanguageDialogOpen: (val: boolean) =>
    set(() => ({ isLanguageDialogOpen: val })),
  setIsUploadImageDialogOpen: (val: boolean) =>
    set(() => ({ isUploadImageDialogOpen: val })),
  setIsChangePasswordDialogOpen: (val: boolean) =>
    set(() => ({ isChangePasswordDialogOpen: val })),
  setIsGiftDialogOpen: (val: boolean) => set(() => ({ isGiftDialogOpen: val })),
  setBellRef: (val: MutableRefObject<HTMLDivElement | null>) =>
    set(() => ({ bellRef: val })),
  toggleNotificationOpen: () =>
    set(({ isNotificationOpen }) => ({
      isNotificationOpen: !isNotificationOpen,
    })),
  setIsLockScreenDialogOpen: (val: boolean) =>
    set(() => ({ isLockScreenDialogOpen: val })),
  setIsChangePinDialogOpen: (val: boolean) =>
    set(() => ({ isChangePinDialogOpen: val })),
  setIsNewPinDialogOpen: (val: boolean) =>
    set(() => ({ isNewPinDialogOpen: val })),
  setIsSuccessDialogOpen: (val: boolean) =>
    set(() => ({ isSuccessDialogOpen: val })),
  setIsSuccessSetDialogOpen: (val: boolean) =>
    set(() => ({ isSuccessSetDialogOpen: val })),
  setIsDeleteAccountDialogOpen: (val: boolean) =>
    set(() => ({ isDeleteAccountDialogOpen: val })),
  toggleIsDeleteAccountDialogOpen: () =>
    set((prev) => ({ isDeleteAccountDialogOpen: !prev })),
}));
