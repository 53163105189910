import { ReactNode } from 'react';

type PopupFormProps = {
  children: ReactNode;
};

const PopupOverlay = ({ children }: PopupFormProps) => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-[1000000001]">
      {children}
    </div>
  );
};

export default PopupOverlay;
