import ethSvgSmall from "/svg/eth-svg.svg";
import btcSvgSmall from "/svg/btc-svg.svg";

export type Currency = {
  name: string;
  id: string;
  code: "ETH" | "BTC"; // Add all possible currency codes here
};

export const CURRENCY_IMAGE: { [key in Currency["code"]]: string } = {
  ETH: ethSvgSmall,
  BTC: btcSvgSmall,
};
