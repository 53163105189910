import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const forgotPasswordValidationSchema = (t: TFunction) =>
  z.object({
    email: z
      .string({ message: t("form.required", { name: "form.email" }) })
      .email({
        message: t("form.authentication.invalidEmail", { name: "form.email" }),
      }),
  });

const schema = forgotPasswordValidationSchema(staticTranslationFunction);

export type ForgotPasswordData = z.infer<typeof schema>;

export const forgotPasswordFormFieldNames: FormNames<ForgotPasswordData> = {
  email: "email",
};
