import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { environmentVariables } from "../../env/enviroment-variables.ts";
import { forgotPasswordValidationSchema } from "../../schemas/form/forgot-password-schema.ts";
import useUserStore from "../../store/user-store.ts";
import ControlledForm from "../form/controlled-form";
import ControlledInput from "../form/controlled-input";

const ForgotPasswordForm = ({
  onClose,
  openEnterCode,
}: {
  isOpen: boolean;
  onClose: () => void;
  openEnterCode: () => void;
}) => {
  const { t } = useTranslation();
  const {
    reset,
    formState: { isValid },
    handleSubmit,
  } = useFormContext();
  const { setResetId, setResetEmail } = useUserStore();

  const closeModal = () => {
    onClose();
    reset();
  };

  const onSubmit = handleSubmit((data) =>
    axios
      .post(`${environmentVariables.authServiceUrl}/password/forgot/code`, {
        email: data.email,
      })
      .then((response) => {
        setResetId(response.data.reset_id);
        setResetEmail(data.email);
        closeModal();
        openEnterCode();
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setTag("forgot-password", "basic");
          scope.setExtra("data", data);
          Sentry.captureException(error);
        });
      }),
  );

  return (
    <AuthCard className="max-w-[37.5rem]">
      <AuthCardContent>
        <FormLayout
          subTitle={t("forgotPasswordDialog.forgotPassword")}
          description={t("forgotPasswordDialog.enterEmailAddressText")}
        >
          <ControlledInput
            name="email"
            placeholder={t("forgotPasswordDialog.enterEmailAddress")}
          />
          <Button
            className={"mt-[2rem]"}
            disabled={!isValid}
            onClick={onSubmit}
          >
            {t("forgotPasswordDialog.sendEmail")}
          </Button>
          <Button onClick={onClose} variant={"secondary"}>
            {t("common.cancel")}
          </Button>
        </FormLayout>
      </AuthCardContent>
    </AuthCard>
  );
};

const ForgotPasswordDialog = ({
  isOpen,
  openEnterCode,
  onClose,
}: {
  isOpen: boolean;
  openEnterCode: () => void;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={forgotPasswordValidationSchema}>
      <Modal isOpen={isOpen}>
        <ForgotPasswordForm
          isOpen={isOpen}
          onClose={onClose}
          openEnterCode={openEnterCode}
        />
      </Modal>
    </ControlledForm>
  );
};

export default ForgotPasswordDialog;
