import { useEffect } from "react";

import { environmentVariables } from "../env/enviroment-variables.ts";

export const TidioChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = environmentVariables.tidioChatKey;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};
