const SuccessButton = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.2677 2.22528L12.2502 1.21028C12.1819 1.13974 12.1001 1.08365 12.0097 1.04535C11.9193 1.00704 11.8221 0.987305 11.7239 0.987305C11.6257 0.987305 11.5286 1.00704 11.4382 1.04535C11.3478 1.08365 11.266 1.13974 11.1977 1.21028L5.22768 7.18278L2.80018 4.75028C2.65998 4.61286 2.4715 4.53589 2.27518 4.53589C2.07887 4.53589 1.89038 4.61286 1.75018 4.75028L0.73518 5.76778C0.664642 5.83607 0.608554 5.91786 0.57025 6.00826C0.531946 6.09866 0.512207 6.19584 0.512207 6.29403C0.512207 6.39221 0.531946 6.48939 0.57025 6.5798C0.608554 6.6702 0.664642 6.75198 0.73518 6.82028L4.70268 10.7903C4.77006 10.8616 4.8515 10.9183 4.94186 10.9565C5.03222 10.9948 5.12955 11.014 5.22768 11.0128C5.32533 11.0132 5.42204 10.9937 5.51188 10.9554C5.60172 10.9172 5.68281 10.861 5.75018 10.7903L13.2627 3.29028C13.3332 3.22198 13.3893 3.1402 13.4276 3.0498C13.4659 2.95939 13.4857 2.86221 13.4857 2.76403C13.4857 2.66584 13.4659 2.56866 13.4276 2.47826C13.3893 2.38786 13.3332 2.30607 13.2627 2.23778L13.2677 2.22528Z"
        fill="#4EEF38"
      />
    </svg>
  );
};

export default SuccessButton;
