import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

interface VideoPlayerProps {
  videoUrl: string;
  className?: string;
}

export const VideoPlayer = forwardRef(
  ({ videoUrl, className }: VideoPlayerProps, ref) => {
    const videoRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

    useImperativeHandle(ref, () => playerRef.current);

    const options = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: videoUrl,
          type: "video/mp4",
        },
      ],
    };

    useEffect(() => {
      if (!playerRef.current && videoRef.current) {
        const videoElement = document.createElement("video-js");

        videoElement.classList.add("vjs-big-play-centered");
        videoRef.current.appendChild(videoElement);

        const player = videojs(videoElement, options);

        playerRef.current = player;
      } else if (playerRef.current) {
        playerRef.current.src(options.sources);
        playerRef.current.autoplay(options.autoplay);
      }

      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }, [videoUrl]);

    return (
      <div className={`${className ?? "w-[300px] sm:w-[400px]"}`}>
        <div ref={videoRef} className={className} />
      </div>
    );
  },
);

VideoPlayer.displayName = "VideoPlayer";
