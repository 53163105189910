import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const newPasswordValidationSchema = (t: TFunction) =>
  z
    .object({
      newPassword: z
        .string({ message: t("form.authentication.passwordRequired") })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
      confirmNewPassword: z
        .string({
          message: t("form.authentication.passwordRequired"),
        })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: t("form.mustMatch", { name: t("form.passwords") }),
      path: ["confirmNewPassword"],
    });

const schema = newPasswordValidationSchema(staticTranslationFunction);

export type NewPasswordData = z.infer<typeof schema>;

export const forgotPasswordFormFieldNames: FormNames<NewPasswordData> = {
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword",
};
