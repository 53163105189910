import {
  GetReferralCodeResponse,
  ReferralResponse,
  WinningReferralResponse,
} from "@ns/api";
import { Button } from "@ns/client-ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReferralTab } from "../../pages/auth/user-page.tsx";
import FlatList from "../flat-list.tsx";
import ControlledInput from "../form/controlled-input.tsx";
import ControlledTab from "../form/controlled-tab.tsx";
import ReferralInvitePopup from "../popup/referral-invite-popup.tsx";
import SuccessButton from "../svg-components/success-button.tsx";

import ReferralCard from "./referral-card.tsx";
import WinningReferralCard from "./winning-referral-card.tsx";

interface Props {
  referralTab: ReferralTab;
  referrals: ReferralResponse[];
  winningReferrals: WinningReferralResponse[];
  isFetching: boolean;
  lastItemRef: React.Ref<HTMLDivElement>;
  referralCode?: GetReferralCodeResponse;
  isReferralInviteOpen: boolean;
  referralInviteHandler: () => void;
  handleCopy: () => void;
  isTextCopied: boolean;
  onTabChange: (tab: string) => void;
  formatCurrencies: { label: string; value: string; icon: string }[];
  setIsReferralInviteOpen: (val: boolean) => void;
}

const ReferralList = ({
  referralTab,
  referrals,
  winningReferrals,
  isFetching,
  lastItemRef,
  referralCode,
  isReferralInviteOpen,
  referralInviteHandler,
  handleCopy,
  isTextCopied,
  onTabChange,
  formatCurrencies,
  setIsReferralInviteOpen,
}: Props) => {
  const { t } = useTranslation();

  const noReferralMessage = () => (
    <div className="h-full flex flex-col items-center justify-center">
      <span className="text-[24px] text-disabledSecondaryText avenir-semibold">
        {t("userPage.noReferrals")}
      </span>
      <div className="flex flex-col w-[343px] mt-4 text-center">
        <ControlledTab
          tabs={formatCurrencies}
          name="currencyId"
          className="md:w-full"
          callback={onTabChange}
        />
        <span className="text-[16px] mt-4 text-disabledSecondaryText text-center">
          {referralCode?.code
            ? t("giftDialog.inviteStealDeal")
            : t("giftDialog.inOrder")}
          <span className="avenir-semibold">{t("giftDialog.placed")}</span>
        </span>
        <div
          onClick={referralInviteHandler}
          className="relative flex items-baseline mt-8 gap-[10px] w-full"
        >
          <ControlledInput
            placeholder={referralCode?.code ?? "*********"}
            name="code"
            disabled={true}
            hasCopyIcon={!!referralCode?.code}
          />
          <Button
            disabled={true}
            onClick={handleCopy}
            variant={!referralCode?.code ? "secondary" : "primary"}
            className={"min-w-[80px]"}
          >
            {isTextCopied ? <SuccessButton /> : t("common.copy")}
          </Button>
          {isReferralInviteOpen && (
            <ReferralInvitePopup
              onClose={() => setIsReferralInviteOpen(false)}
              className={"z-30 top-[-10rem]"}
            />
          )}
        </div>
      </div>
    </div>
  );

  const noWinningReferralMessage = () => (
    <div className="h-full flex flex-col items-center justify-center">
      <span className="text-[24px] text-disabledSecondaryText avenir-semibold">
        {t("userPage.noWinningBids")}
      </span>
      <div className="flex flex-col w-[343px] mt-4 text-center">
        <ControlledTab
          tabs={formatCurrencies}
          name="currencyId"
          className="md:w-full"
          callback={onTabChange}
        />
        <span className="text-[16px] mt-4 text-disabledSecondaryText text-center">
          {referralCode?.code
            ? t("giftDialog.inviteStealDeal")
            : t("giftDialog.inOrder")}
          <span className="avenir-semibold">{t("giftDialog.placed")}</span>
        </span>
        <div
          onClick={referralInviteHandler}
          className="relative flex items-baseline mt-8 gap-[10px] w-full"
        >
          <ControlledInput
            placeholder={referralCode?.code ?? "*********"}
            name="code"
            disabled
            hasCopyIcon={!!referralCode?.code}
          />
          <Button
            disabled={!referralCode?.code}
            onClick={handleCopy}
            variant={!referralCode?.code ? "secondary" : "primary"}
            className={"min-w-[80px]"}
          >
            {isTextCopied ? <SuccessButton /> : t("common.copy")}
          </Button>
          {isReferralInviteOpen && (
            <ReferralInvitePopup
              onClose={() => setIsReferralInviteOpen(false)}
              className={"z-30 top-[-10rem]"}
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {referralTab === ReferralTab.MY_REFERRALS ? (
        referrals.length === 0 ? (
          noReferralMessage()
        ) : (
          <FlatList<ReferralResponse>
            data={referrals}
            isFetching={isFetching}
            keyExtractor={(item: ReferralResponse) =>
              `${item.id}-${item.currency.code}`
            }
            ref={lastItemRef}
            renderItem={(referral: ReferralResponse) => (
              <ReferralCard
                id={`${referral.id}-${referral.currency.code}`}
                name={referral.name}
                currency={referral.currency}
                totalBids={referral.totalBids}
                date={referral.date}
                status={referral.status}
                imageUrl={referral?.imageUrl}
              />
            )}
          />
        )
      ) : winningReferrals.length === 0 ? (
        noWinningReferralMessage()
      ) : (
        <FlatList<WinningReferralResponse>
          data={winningReferrals}
          isFetching={isFetching}
          keyExtractor={(item: WinningReferralResponse) =>
            `${item.id}-${item.currency.code}`
          }
          ref={lastItemRef}
          renderItem={(referral: WinningReferralResponse) => (
            <WinningReferralCard
              id={`${referral.id}-${referral.currency.code}`}
              name={referral.name}
              currency={referral.currency}
              winningBid={referral.winningBid}
              date={referral.date}
              status={referral.status}
              imageUrl={referral?.imageUrl}
              reward={referral.reward}
            />
          )}
        />
      )}
    </>
  );
};

export default ReferralList;
