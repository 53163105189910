import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSearchParams } from "react-router-dom";

import { api } from "../../api/client-api";
import VerifyDialog from "../../components/dialogs/verify-dialog.tsx";
import FooterMenu from "../../components/footer-menu";
import ControlledForm from "../../components/form/controlled-form";
import OnboardWizard from "../../components/onboard-wizard/onboard-wizard";
import RoundControl from "../../components/round-control";
import { placeBidValidationSchema } from "../../schemas/form/place-bid-schema";
import useUserStore from "../../store/user-store.ts";

const Dashboard = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Dashboard" });
  }, []);
  const queryClient = useQueryClient();
  const { user, setUser } = useUserStore();

  const [searchParams] = useSearchParams();
  const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false);
  const verifyId = searchParams.get("verify-id");
  const registrationId = searchParams.get("hashed-registration-id");
  const [wizard, setWizard] = useState<boolean>(false);
  const toggleVerifyDialog = useCallback(() => {
    setIsVerifyOpen((isVerifyOpen) => !isVerifyOpen);
  }, [setIsVerifyOpen]);

  useEffect(() => {
    if (verifyId && registrationId && !isVerifyOpen) {
      toggleVerifyDialog();
    }
  }, [isVerifyOpen, verifyId, registrationId, toggleVerifyDialog]);

  const { refetch } = useQuery({
    queryKey: ["auth"],
    queryFn: () => api.auth.getAuthWhoAmI(),
    onSuccess: (response) => {
      setUser(response);
    },
  });

  const { mutate: completeOnboarding } = useMutation({
    mutationFn: () => api.user.completeOnboarding(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["auth"] });
      refetch();
    },
    onError: (error) => {
      Sentry.withScope((scope) => {
        scope.setTag("complete-onboarding", "basic");
        Sentry.captureException(error);
      });
    },
  });

  const onWizardClose = () => {
    setWizard(false);
    completeOnboarding();
  };

  useEffect(() => {
    if (user?.onboarding && user?.completed) {
      user.onboarding && setWizard(true);
    }
  }, [user, setWizard]);

  return (
    <>
      <div className="flex w-full items-center flex-col lg:pt-[40px] h-dashboardHeight lg:w-dashboardWidth gap-4">
        <ControlledForm schema={placeBidValidationSchema}>
          <RoundControl />
        </ControlledForm>
      </div>
      {user && <FooterMenu />}
      <OnboardWizard isOpen={wizard} onClose={onWizardClose} />
      {registrationId && verifyId && (
        <VerifyDialog
          firstParam={verifyId}
          secondParam={registrationId}
          onClose={toggleVerifyDialog}
          isOpen={isVerifyOpen}
        />
      )}
    </>
  );
};

export default Dashboard;
