import { UpdateUserRequest } from "@ns/api";
import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { countrySchema } from "../../schemas/form/country-schema.ts";
import ControlledDropdown from "../form/controlled-dropdown.tsx";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledInput from "../form/controlled-input.tsx";
import CloseBtn from "../svg-components/close-btn.tsx";

type CountryDialogType = {
  phoneNumber: string;
  country: string;
};

type CountryData = {
  label: string;
  value: string;
  icon: string;
};

const CompleteSignUpDialogForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState<string | undefined>("");
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState<string>("");
  const [search, setSearch] = useState("");
  const [countries, setCountries] = useState<CountryData[]>([]);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const {
    watch,
    reset,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useFormContext<CountryDialogType>();

  const { data: countriesData } = useQuery({
    queryKey: [`getCountries`],
    queryFn: () => api.country.getCountries(1000),
  });

  const formattedCountries = useMemo(() => {
    return countriesData?.items.map((country) => ({
      label: country.name,
      value: country.name,
      icon: country.flag,
    }));
  }, [countriesData]);

  const selectedCountry = watch("country");

  useEffect(() => {
    if (formattedCountries?.length) {
      const initialCountry = formattedCountries[0];
      setValue("country", initialCountry.value);
      setFlag(initialCountry.icon);
      setPhoneNumberPrefix(`${countriesData?.items[0].phonePrefix}`);
    }
  }, [countriesData, formattedCountries, setValue]);

  useEffect(() => {
    if (selectedCountry) {
      const country = countriesData?.items.find(
        (country) => country.name === selectedCountry,
      );

      setFlag(country?.flag);
      setPhoneNumberPrefix(`+${country?.phonePrefix}`);
    }
  }, [selectedCountry, countriesData?.items, setValue]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        phoneNumberRef.current &&
        phoneNumberRef.current.contains(document.activeElement)
      ) {
        return;
      }
      if (event.key.length === 1 && /^[a-zA-Z]$/.test(event.key)) {
        setSearch((prevTerm) => prevTerm + event.key);
      } else if (event.key === "Backspace") {
        setSearch((prevTerm) => prevTerm.slice(0, -1));
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const cnt = formattedCountries?.filter((country) =>
      country.value.toLowerCase().includes(search.toLowerCase()),
    );
    if (cnt) {
      setCountries(cnt);
    }
  }, [search, formattedCountries]);

  useEffect(() => {
    if (formattedCountries) {
      setCountries(formattedCountries);
    }
  }, [formattedCountries]);

  const { mutate } = useMutation({
    mutationFn: (data: UpdateUserRequest) => api.user.updateUser(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(["auth"]);
      onClose();
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("complete-sign-up", "basic");
        scope.setExtra("formData", variables);
        Sentry.captureException(error);
      });
    },
  });

  const updateUser = handleSubmit((data) => {
    const country = countriesData?.items.find(
      (country) => country.name === selectedCountry,
    );
    mutate({ countryId: country?.id, phoneNumber: data.phoneNumber });
  });

  const onCancelHandler = () => {
    onClose();
    reset();
  };

  const resetDropdown = () => {
    if (formattedCountries) {
      setCountries(formattedCountries);
    }
    setSearch("");
  };

  if (!countriesData) return <></>;

  return (
    <>
      <AuthCard className="min-h-[500px]">
        <AuthCardContent>
          <FormLayout
            subTitle={t("countryDialog.phoneNumber")}
            description={t("countryDialog.pleaseSelect")}
          >
            <CloseBtn
              className="absolute top-[30px] right-[16px]"
              onClick={onCancelHandler}
            />
            <ControlledDropdown
              resetDropdown={resetDropdown}
              name={"country"}
              options={countries!}
            />
            <ControlledInput
              name="phoneNumber"
              icon={flag}
              iconText={phoneNumberPrefix}
              ref={phoneNumberRef}
            />
            <Button onClick={updateUser} disabled={!isValid}>
              {t("common.completeSignUp")}
            </Button>
          </FormLayout>
        </AuthCardContent>
      </AuthCard>
    </>
  );
};

const CompleteSignUpDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={countrySchema}>
      <Modal isOpen={isOpen}>
        <CompleteSignUpDialogForm onClose={onClose} />
      </Modal>
    </ControlledForm>
  );
};

export default CompleteSignUpDialog;
