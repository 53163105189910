/* eslint-disable @typescript-eslint/naming-convention */
import { Dialog } from "@ns/client-ui";
import { useToast } from "@ns/styles/src/components/ui/use-toast.ts";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  ChangePasswordData,
  changePasswordValidationSchema,
} from "../../schemas/form/change-password-schema";
import ControlledForm from "../form/controlled-form";
import ControlledInput from "../form/controlled-input";

import { SuccessChangedPasswordDialog } from "./success-changed-password.tsx";

const ChangePasswordForm = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { handleSubmit, trigger, setError, clearErrors } =
    useFormContext<ChangePasswordData>();
  const { toast } = useToast();
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  const password = useWatch({ name: "newPassword" });
  const confirmNewPassword = useWatch({ name: "confirmNewPassword" });

  useEffect(() => {
    const validateConfirmPassword = async () => {
      if (confirmNewPassword) {
        const result = await trigger("confirmNewPassword");

        if (result) {
          if (password !== confirmNewPassword) {
            setError("confirmNewPassword", {
              message: "Passwords do not match",
            });
          } else {
            clearErrors("confirmNewPassword");
          }
        }
      }
    };

    validateConfirmPassword();
  }, [confirmNewPassword, password, trigger, setError, clearErrors]);

  const handleCloseModal = () => {
    setIsOpenSuccessModal(false);
  };

  const onSubmit = handleSubmit((data) =>
    axios
      .post(
        `${environmentVariables.authServiceUrl}/password/reset`,
        {
          new_password: data.newPassword,
          old_password: data.currentPassword,
          repeated_new_password: data.confirmNewPassword,
        },
        { withCredentials: true },
      )
      .then(() => {
        onClose();
        setIsOpenSuccessModal(true);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: t("common.errors.error"),
          description: t("form.authentication.toast.loginError.description"),
        });
        Sentry.withScope((scope) => {
          scope.setTag("change-password", "basic");
          scope.setExtra("data", data);
          Sentry.captureException(error);
        });
      }),
  );

  return (
    <>
      <Dialog
        title={t("profile.dialog.changePassword.changePassword")}
        subTitle={t("profile.dialog.changePassword.subtitle")}
        isOpen={isOpen}
        successBtnText={t("common.save")}
        closeBtnText={t("common.cancel")}
        onClose={onClose}
        onSuccess={onSubmit}
        showCancel
      >
        <div className="flex flex-col gap-[2rem] w-[343px] mb-[1rem]">
          <ControlledInput
            name="currentPassword"
            type="password"
            placeholder={t("profile.dialog.changePassword.oldPassword")}
          />
          <ControlledInput
            name="newPassword"
            type="password"
            placeholder={t("profile.dialog.changePassword.newPassword")}
          />
          <ControlledInput
            name="confirmNewPassword"
            type="password"
            placeholder={t("profile.dialog.changePassword.confirmPassword")}
          />
        </div>
      </Dialog>
      <SuccessChangedPasswordDialog
        isOpen={isOpenSuccessModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

const ChangePasswordDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={changePasswordValidationSchema}>
      <ChangePasswordForm isOpen={isOpen} onClose={onClose} />
    </ControlledForm>
  );
};

export default ChangePasswordDialog;
