import { Input } from "@ns/client-ui";
import { Controller, useFormContext } from "react-hook-form";

import checkSvg from "/svg/check.svg";
import pasteSvg from "/svg/paste.svg";
import closeSvg from "/svg/close.svg";
import exclamationSvg from "/svg/exclamation.svg";

const InputPromoCode = ({
  name,
  placeholder,
  disabled,
  codeCorrect,
  loaded,
  hasPasteIcon = false,
  showErrorMessage = false,
}: {
  name: string;
  placeholder: string;
  disabled?: boolean;
  codeCorrect?: boolean;
  loaded?: boolean;
  hasPasteIcon?: boolean;
  showErrorMessage?: boolean;
}) => {
  const { control, getValues, setValue } = useFormContext();
  const hasValue = getValues(name);

  const onPasteHandler = async () => {
    if (disabled) {
      return;
    }
    const data = await navigator.clipboard.readText();
    setValue(name, data);
  };

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className="flex flex-1 items-center gap-4 w-full relative">
          <Input
            type="text"
            name={name}
            className={`bg-transparent border text-white placeholder-gray-400 focus:outline-none flex-1 ${hasPasteIcon && "pr-10"} ${
              !codeCorrect && hasValue && loaded ? " border-destructive" : ""
            }`}
            placeholder={placeholder}
            value={field.value ?? ""}
            onChange={field.onChange}
            disabled={disabled}
          />
          {!hasValue && hasPasteIcon && (
            <img
              src={pasteSvg}
              className={`${disabled && "cursor-not-allowed"} absolute right-[15px] w-[16px] h-[16px]`}
              onClick={onPasteHandler}
            />
          )}
          {codeCorrect && hasValue ? (
            <img src={checkSvg} alt="check" className="absolute right-[15px]" />
          ) : (
            <>
              {loaded && hasValue && !showErrorMessage && (
                <>
                  <img
                    src={closeSvg}
                    alt="incorrect"
                    className="absolute right-[15px]"
                  />
                </>
              )}
            </>
          )}
          {error?.message && showErrorMessage && (
            <div className="absolute top-[40px] flex justify-start gap-[3px]">
              <img
                className={"self-baseline mt-[3px]"}
                src={exclamationSvg}
                alt="exclamation"
              />
              <span className="text-left text-red-600 text-[12px]">
                {error.message}
              </span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default InputPromoCode;
