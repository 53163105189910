import { Dialog } from "@ns/client-ui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { networkMapper } from "../../helpers/network.mapper.ts";
import { usePaymentStore } from "../../store/payment-store.ts";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledInput from "../form/controlled-input.tsx";

const CoinPaymentDialogForm = () => {
  const { t } = useTranslation();
  const {
    isCoinPaymentOpen,
    setIsCoinPaymentOpen,
    coinPaymentInfo,
    setIsPaymentProcessingOpen,
  } = usePaymentStore();

  const { setValue } = useFormContext();

  const onCloseHandler = () => {
    setIsCoinPaymentOpen(false);
    setIsPaymentProcessingOpen(true);
  };

  useEffect(() => {
    if (coinPaymentInfo) {
      setValue("amount", coinPaymentInfo.amount);
      setValue("network", coinPaymentInfo.receiveCurrency);
      setValue("wallet", coinPaymentInfo.walletAddress);
    }
  }, [coinPaymentInfo, setValue]);

  return (
    <Dialog
      className={"px-4"}
      isOpen={isCoinPaymentOpen}
      onClose={onCloseHandler}
    >
      <div
        className={
          "w-full text-center flex flex-col justify-center items-center text-white gap-4 px-8 padding-0"
        }
      >
        <div>{t("payment.finalizePayment")}</div>
        <div className={"w-[222px] h-[222px]"}>
          <img src={coinPaymentInfo.qrCodeUrl} alt={"Qr-code"} />
        </div>
        <div className={"w-full flex items-center gap-[1rem] justify-between"}>
          <div className={"flex flex-col gap-2 items-baseline"}>
            <div>{t("payment.value")}</div>
            <div>
              <ControlledInput
                placeholder={coinPaymentInfo.amount}
                name="amount"
                disabled
                hasCopyIcon
              />
            </div>
          </div>
          <img
            src={coinPaymentInfo.currencyIcon}
            className={"w-[48px] h-[48px]"}
          />
          <div className={"flex flex-col gap-2 items-end"}>
            <div>{t("payment.network")}</div>
            <div>
              <ControlledInput
                className={"text-right"}
                placeholder={coinPaymentInfo.amount}
                name="network"
                disabled
              />
            </div>
          </div>
        </div>
        <div className={"w-full divider-gold"} />
        <div className={"flex items-center justify-center gap-8"}>
          <img src={coinPaymentInfo.icon} className={"w-[48px] h-[48px]"} />
          <div className={"max-w-[250px] flex flex-col gap-2 items-baseline"}>
            <div className={"text-[18px] font-medium"}>
              {t("payment.networkFee")}
            </div>
            <div className={"text-xs text-left bidCardText opacity-[0.7]"}>
              {t("payment.toComplete")}{" "}
              <span className={"font-bold"}>
                {networkMapper(coinPaymentInfo!.receiveCurrency!)}
              </span>{" "}
              {t("payment.on")}{" "}
              <span className={"font-bold"}>
                {coinPaymentInfo?.receiveCurrency?.split(".")[1]}
              </span>{" "}
              {t("payment.toCover")}
            </div>
          </div>
        </div>
        <div className={"max-w-[400px] text-left w-full flex flex-col gap-2"}>
          <div>{t("payment.address")}</div>
          <ControlledInput
            placeholder={coinPaymentInfo.walletAddress}
            name="wallet"
            disabled
            hasCopyIcon
          />
        </div>
      </div>
    </Dialog>
  );
};

export const CoinPaymentDialog = () => {
  return (
    <ControlledForm schema={{}}>
      <CoinPaymentDialogForm />
    </ControlledForm>
  );
};

export default CoinPaymentDialog;
