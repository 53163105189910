import { BaseHttpRequest, CancelablePromise, OpenAPIConfig } from "@ns/api";
import { ApiRequestOptions } from "@ns/api/src/api/core/ApiRequestOptions.ts";
import axios from "axios";
import axiosRetry from "axios-retry";

import { request } from "./custom-axios-request.ts";

export class CustomAxiosHttpRequest extends BaseHttpRequest {
  axiosInstance = axios.create({
    withCredentials: true,
  });

  constructor(config: OpenAPIConfig) {
    super(config);
    axiosRetry(this.axiosInstance);
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return request(this.config, options, this.axiosInstance);
  }
}
