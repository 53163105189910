import { SendMessageToSupportRequest } from "@ns/api";
import { Button } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import ControlledForm from "../../components/form/controlled-form.tsx";
import ControlledInput from "../../components/form/controlled-input.tsx";
import {
  sendSupportMessageSchema,
  SendSupportMessageSchema,
} from "../../schemas/form/send-support-message.schema.ts";

const Support = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = useFormContext<SendSupportMessageSchema>();

  const { mutate } = useMutation({
    mutationFn: (data: SendMessageToSupportRequest) =>
      api.support.sendEmail(data),
    onSuccess: () => {
      reset();
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("send-support-message", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const onSubmit = handleSubmit((data) => mutate(data));

  return (
    <div className="flex flex-col w-full h-full px-4 sm:p-[0rem] lg:p-[3rem] overflow-y-auto">
      <div className="flex flex-col w-full items-center pt-[2rem] sm:pt-[5rem] justify-around">
        <div className="flex flex-col items-center w-full sm:w-[28rem] justify-around">
          <span className="text-white avenir-semibold text-[24px] text-center">
            {t("common.reachOut")}
          </span>
        </div>
        <div className="flex flex-col items-center pt-8 sm:pt-[4.75rem] w-[23.5rem] justify-around">
          <span className="text-white avenir-semibold text-[24px] pb-[2rem] text-center">
            {t("common.contactUs")}
          </span>
          <div className={"flex w-[21.5rem] flex-col gap-[0.5rem]"}>
            <ControlledInput name={"email"} placeholder={t("common.email")} />
            <ControlledInput name={"name"} placeholder={t("common.name")} />
            <ControlledInput
              name={"message"}
              placeholder={t("common.message")}
            />
            <div className={"pt-[2rem]"}>
              <Button
                className={"w-full"}
                variant={"primary"}
                disabled={!isValid}
                onClick={onSubmit}
              >
                {t("common.send")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SupportForm = () => {
  return (
    <ControlledForm schema={sendSupportMessageSchema}>
      <Support />
    </ControlledForm>
  );
};
