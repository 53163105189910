const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div onClick={onClick}>
      <svg
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.2754 10.0125L13.5379 15.75L19.2754 21.4875L17.5004 23.25L10.0004 15.75L17.5004 8.25L19.2754 10.0125Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default BackButton;
