import { useState } from "react";

import { useAuthContext } from "../../store/auth-store.ts";

import EnterCodeDialog from "./enter-code-dialog";
import EnterPinCodeDialog from "./enter-pin-code-dialog.tsx";
import ForgotPasswordDialog from "./forgot-password-dialog";
import ChangeForgotPinDialog from "./forgot-pin-change.tsx";
import ForgotPinDialog from "./forgot-pin-dialog.tsx";
import NewPasswordDialog from "./new-password-dialog";
import SignInDialog from "./sign-in-dialog";
import VerifyPinCodeDialog from "./verify-pin-code-forgot-dialog.tsx";

const AuthDialog = () => {
  useState<boolean>(false);
  const [forgotPasswordDialog, setForgotPasswordDialog] =
    useState<boolean>(false);
  const [newPasswordDialog, setNewPasswordDialog] = useState<boolean>(false);
  const [enterCodeDialog, setEnterCodeDialog] = useState<boolean>(false);

  const {
    setIsLoginModalOpen,
    isLoginModalOpen,
    isPinModalOpen,
    setIsPinModalOpen,
    activeForgotPinStep,
    isForgotPinModalOpen,
  } = useAuthContext();

  const toggleForgotPasswordDialog = () => {
    setForgotPasswordDialog((state) => !state);
  };

  const toggleNewPasswordDialog = () => {
    setNewPasswordDialog((state) => !state);
  };

  const toggleEnterCodeDialog = () => {
    setEnterCodeDialog((state) => !state);
  };

  return (
    <div>
      <SignInDialog
        onClose={() => setIsLoginModalOpen(false)}
        isOpen={isLoginModalOpen}
        openForgotPassword={toggleForgotPasswordDialog}
      />
      <ForgotPasswordDialog
        onClose={toggleForgotPasswordDialog}
        openEnterCode={toggleEnterCodeDialog}
        isOpen={forgotPasswordDialog}
      />
      <EnterCodeDialog
        onClose={toggleEnterCodeDialog}
        openNewPassword={toggleNewPasswordDialog}
        isOpen={enterCodeDialog}
      />
      <NewPasswordDialog
        onClose={toggleNewPasswordDialog}
        openSignIn={() => setIsLoginModalOpen(true)}
        isOpen={newPasswordDialog}
      />
      <EnterPinCodeDialog
        isOpen={isPinModalOpen}
        onClose={() => setIsPinModalOpen(false)}
      />
      <ForgotPinDialog
        isOpen={isForgotPinModalOpen && activeForgotPinStep === 1}
      />
      <VerifyPinCodeDialog />
      <ChangeForgotPinDialog />
    </div>
  );
};

export default AuthDialog;
