import { FC } from "react";

import { getCapitalizedFirstLetter } from "../../helpers/helpers.ts";

import btcCoin from "/assets/spining-btc.png";
import ethCoin from "/assets/spining-eth.png";

type Props = {
  name: string;
  type: string;
  isSpinning?: boolean;
  profileImage?: string;
  alt?: string;
};

const SpinningCoin: FC<Props> = ({
  name,
  type,
  isSpinning = false,
  profileImage,
  alt,
}) => {
  return (
    <>
      {isSpinning ? (
        <div className="relative h-16 w-16 sm:w-24 sm:h-24 mx-auto my-24">
          <div className="flip-coin">
            <div className="flip-coin-inner">
              <div className="flip-coin-front">
                <div className="flip-coin-context">
                  {profileImage ? (
                    <img src={profileImage} alt="btc coin" />
                  ) : (
                    <span className="text-black text-[50px]">
                      {getCapitalizedFirstLetter(name)}
                    </span>
                  )}
                </div>
              </div>
              <div className="flip-coin-thick"></div>
              <div className="flip-coin-back">
                <div className="flip-coin-context">
                  <img
                    src={type === "BTC" ? btcCoin : ethCoin}
                    alt="btc coin"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : profileImage ? (
        <img
          src={profileImage}
          alt={alt}
          className="w-full h-full object-cover rounded-full winnerImg"
        />
      ) : (
        <div className="rounded-full h-[120px] w-[120px] bg-white flex justify-center items-center">
          <span className="text-black text-[50px]">
            {getCapitalizedFirstLetter(name)}
          </span>
        </div>
      )}
    </>
  );
};

export default SpinningCoin;
