import axios, { CancelTokenSource } from "axios";
import { useEffect, useState } from "react";
import { environmentVariables } from "../src/env/enviroment-variables.ts";

export const useUpload = (folder: "users" | "chat") => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(-1);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | undefined>(
    undefined,
  );
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  const cancel = axios.CancelToken.source();

  useEffect(() => {
    setCancelToken(cancel);
  }, []);

  const upload = async (formData: FormData) => {
    return await axios
      .post(
        `${environmentVariables.baseApiUrl}/upload?type=${folder}`,
        formData,
        {
          cancelToken: cancel.token,
          withCredentials: true,
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Number(
              ((progressEvent.loaded / progressEvent.total!) * 100).toFixed(2),
            );
            setProgress(progress);
          },
        },
      )
      .then(async (response: { data: { key: string } }) => {
        setIsSuccess(true);
        setFileUrl(response.data.key);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          setProgress(-1);
        }
      });
  };

  return {
    upload,
    isSuccess,
    progress,
    setIsSuccess,
    setProgress,
    cancelToken,
    fileUrl,
  };
};
