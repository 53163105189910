import { ReferralResponse } from "@ns/api";
import { Card, CardContent } from "@ns/client-ui";
import { Avatar, AvatarFallback, AvatarImage } from "@ns/styles";
import { useTranslation } from "react-i18next";

import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { formatDate } from "../../helpers/helpers";

const ReferralCard = ({
  id,
  totalBids,
  name,
  status,
  currency,
  date,
  imageUrl,
}: ReferralResponse) => {
  const { t } = useTranslation();
  const formatedDate = new Date(date);

  return (
    <Card
      id={id}
      className="flex items-center h-fit gap-4 bg-transparent w-full p-[16px] border-[1px] border-solid border-tabBorderColor text-bidCardText"
    >
      <Avatar
        className={
          "self-baseline sm:self-center w-[50px] h-[50px] bg-placeholderBg"
        }
      >
        <AvatarImage src={`${imageUrl}`} />
        <AvatarFallback className="text-3xl">
          {`${name}}`[0].toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <CardContent className="w-full flex flex-col p-0">
        <div className={"hidden sm:block"}>
          <div className="flex justify-between text-[16px]">
            <span className="w-1/5 text-center">
              {`${t("referralCard.linkName")}`}
            </span>
            <span className="w-1/5 text-center">
              {t("referralCard.status")}
            </span>
            <span className="w-1/5 text-center">
              {t("referralCard.totalBid")}
            </span>
            <span className="w-1/5 text-center">{t("referralCard.date")}</span>
            <span className="w-1/5 text-center">
              {t("referralCard.currency")}
            </span>
          </div>
          <div className="border-b border-tabBorderColor my-4"></div>
          <div className="flex justify-between text-[14px]">
            <span className="w-1/5 text-center">{name}</span>
            <span className="w-1/5 text-center">
              {t(`common.status.${status}`)}
            </span>
            <span className="w-1/5 text-center">
              {totalBids > 0 ? totalBids : "-"}
            </span>
            <span className="w-1/5 text-center">
              {formatDate(formatedDate)}
            </span>
            <div className="w-1/5 flex justify-center items-center gap-2">
              <img
                src={
                  CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE]
                }
                alt="Currency"
                className="w-[18px]"
              />
              <span className="w-1/5 text-center">{currency.code}</span>
            </div>
          </div>
        </div>

        {/*Mobile card*/}
        <div className={"w-full h-full sm:hidden"}>
          <div className="flex w-full flex-col justify-between text-[16px]">
            <div className={"flex justify-between"}>
              <span>{`${t("referralCard.linkName")}`}</span>
              <span>{name}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("referralCard.status")}</span>
              <span>{t(`common.status.${status}`)}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("referralCard.totalBid")}</span>
              <span>{totalBids > 0 ? totalBids : "-"}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("referralCard.date")}</span>
              <span>{formatDate(formatedDate)}</span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("referralCard.currency")}</span>
              <div className="w-1/5 flex justify-center items-center gap-2">
                <img
                  src={
                    CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE]
                  }
                  alt="Currency"
                  className="w-[18px]"
                />
                <span>{currency.code}</span>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ReferralCard;
