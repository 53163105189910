export const IconPicture = () => {
  return (
    <div>
      <svg width="69" height="84" viewBox="0 0 69 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.2 4L67 30.6667V74.6667C67 79.8213 62.8004 84 57.62 84H9.38C4.19957 84 0 79.8213 0 74.6667V13.3333C0 8.17868 4.19957 4 9.38 4H40.2Z"
          fill="url(#paint0_linear_1551_2892)"
        />
        <g filter="url(#filter0_d_1551_2892)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67 30.667H50.0538C44.8733 30.667 40.2 26.0169 40.2 20.8623V4.00033L67 30.667Z"
            fill="white"
            fillOpacity="0.4"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.6904 37.333H14.8889C14.0666 37.333 13.4 37.9964 13.4 38.8148V62.5191C13.4 63.337 14.0666 63.9997 14.8889 63.9997H41.6904C42.5128 63.9997 43.1794 63.337 43.1794 62.5191V38.8148C43.1794 37.9964 42.5127 37.333 41.6904 37.333ZM38.7123 59.5555H17.867V52.1485L22.3338 47.7036L29.7787 55.1114L34.2454 50.6661L38.7123 55.1114V59.5555ZM32.7566 44.7408C32.7566 46.3772 34.0897 47.7036 35.7344 47.7036C37.3791 47.7036 38.7123 46.3772 38.7123 44.7408C38.7123 43.1038 37.3791 41.7778 35.7344 41.7778C34.0897 41.7778 32.7566 43.1037 32.7566 44.7408Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_1551_2892"
            x="38.2"
            y="0"
            width="30.8"
            height="30.667"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0909637 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1551_2892" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1551_2892" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1551_2892" x1="0" y1="4" x2="0" y2="84" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36D2AD" />
            <stop offset="1" stopColor="#2DBC9A" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
