import type { UserOrderResponse } from "@ns/api";
import { useMemo, useRef, useState } from "react";

import chevrons from "/svg/chevrons.svg";

import { useTranslation } from "react-i18next";

import { useOutsideClick } from "../../hooks/use-outside-click.ts";
import { usePagination } from "../../hooks/use-pagination.ts";
import { api } from "../api/client-api.ts";
import useUserStore from "../store/user-store.ts";

import FlatList from "./flat-list.tsx";
import BidCard from "./ui/bid-card";

import stealDealPng from "/assets/no-data.jpeg";

export type BidData = {
  roundNumber: string;
  value: string;
  entryFee: string;
  currency: string;
};

const FooterMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useUserStore();
  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const containerRef = useRef(null);
  useOutsideClick([containerRef], () => setIsOpen(false));

  const { data, lastItemRef, isFetchingNextPage } =
    usePagination<UserOrderResponse>({
      queryKey: ["activeOrders", user?.id || ""],
      queryFn: (offset: number, limit: number) =>
        api.orders.getAllOrders("ACTIVE", undefined, limit, offset),
      retry: false,
      refetchOnWindowFocus: false,
    });

  const renderData = useMemo(() => {
    if (!data?.length) {
      return (
        <div className="m-auto bg-backgroundDeepTeal flex flex-col items-center justify-center flex-1">
          <span className="text-[24px] avenir-semibold text-white">
            {t("footerMenu.noActiveBids")}
          </span>
          <img className={"w-[200px] h-auto"} src={stealDealPng} alt="logo" />
        </div>
      );
    }

    return (
      <FlatList<UserOrderResponse>
        data={data}
        isFetching={isFetchingNextPage}
        keyExtractor={(item: UserOrderResponse) => item.id}
        ref={lastItemRef}
        renderItem={(bid: UserOrderResponse) => (
          <BidCard
            key={bid.id}
            roundNumber={bid.round.number}
            currency={bid.round.currency.code}
            value={bid.bid}
            entryFee={bid.round.entryFee}
          />
        )}
      />
    );
  }, [t, data]);

  return (
    <div
      ref={containerRef}
      className="flex flex-1 flex-col items-end justify-end relative w-full sm:max-w-[450px] md:max-w-[550px] lg:max-w-[700px] xl:max-w-[900px] 2xl:max-w-[1096px] w-full"
    >
      <div className="flex w-full absolute bottom-0">
        <div
          className={`flex flex-1 w-full flex-col footer-menu ${
            isOpen
              ? "footer-menu-active translate-y-0"
              : "footer-menu translate-y-[430px] lg:translate-y-[440px]"
          } gap-[30px] rounded-tr-[30px] transition-transform duration-300 ease-in-out z-[100] h-[500px]`}
        >
          <div
            onClick={toggleMenu}
            className={`absolute cursor-pointer flex justify-center items-center w-[40px] h-[40px] top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full ${
              isOpen
                ? "bg-backgroundDeepTeal  border border-gray-700"
                : "bg-borderBlue"
            } z-20`}
          >
            <img
              src={chevrons}
              className={`transition-transform duration-300 ease-in-out ${
                isOpen ? "rotate-[0deg]" : "rotate-[180deg]"
              }`}
            />
          </div>

          <div className="bg-backgroundDeepTeal flex flex-row items-center justify-center overflow-hidden gap-[16px] mt-5 p-2 pt-0 flex-col z-10 rounded-tr-[30px] rounded-tr-[30px]">
            <span className="text-base text-white avenir-semibold self-start px-[16px]">
              {t("footerMenu.myActiveBids")}
            </span>
            <div
              className={`max-h-[500px] flex flex-col lg:flex-row justify-start overflow-auto gap-[16px] ${
                Array.isArray(renderData) && renderData.length > 1
                  ? "lg:grid lg:grid-cols-2 lg:gap-4"
                  : "lg:flex"
              } lg:flex-wrap h-full items-start w-full bg-backgroundDeepTeal`}
            >
              {renderData}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMenu;
