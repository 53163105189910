export const BtcClosed = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.730591" width="48" height="48" rx="24" fill="#001214" />
      <g clip-path="url(#clip0_4876_9334)">
        <path
          d="M42.2306 24C42.2306 27.4612 41.2042 30.8446 39.2813 33.7225C37.3584 36.6003 34.6253 38.8434 31.4276 40.1679C28.2299 41.4924 24.7112 41.839 21.3165 41.1637C17.9219 40.4885 14.8036 38.8218 12.3562 36.3744C9.90881 33.927 8.2421 30.8087 7.56686 27.4141C6.89161 24.0194 7.23817 20.5007 8.56271 17.303C9.88724 14.1053 12.1303 11.3722 15.0081 9.44928C17.886 7.52636 21.2694 6.5 24.7306 6.5C29.3719 6.5 33.8231 8.34374 37.105 11.6256C40.3869 14.9075 42.2306 19.3587 42.2306 24Z"
          fill="#F7931A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.6683 14.4912L23.6901 15.5682L24.589 12.2178L26.5999 12.7635L25.736 15.9755L27.3762 16.4162L28.2417 13.1691L30.2876 13.7164L29.4062 16.981C29.4062 16.981 32.7471 17.7207 33.533 20.438C34.319 23.1553 31.8053 24.5823 31.029 24.6364C31.029 24.6364 33.9562 26.2416 32.9508 29.3996C31.9453 32.5575 28.859 33.1223 25.6119 32.3985L24.7305 35.7839L22.6846 35.2366L23.5835 31.9037L21.9608 31.4614L21.0619 34.8182L19.0319 34.2725L19.9324 30.9316L15.804 29.818L16.8444 27.508C16.8444 27.508 18.009 27.8262 18.4496 27.9312C18.8903 28.0362 19.1735 27.578 19.2976 27.1198C19.4217 26.6616 21.291 19.0682 21.4676 18.443C21.6442 17.8178 21.5726 17.3294 20.8312 17.1369C20.0899 16.9444 19.0812 16.6437 19.0812 16.6437L19.6683 14.4912ZM23.7251 24.5473L22.6115 28.9748C22.6115 28.9748 28.1335 30.9682 28.8399 28.1635C29.5462 25.3587 23.7251 24.5473 23.7251 24.5473ZM24.2374 22.4473L25.3303 18.3905C25.3303 18.3905 30.0585 19.2369 29.4762 21.4944C28.894 23.7519 26.1067 22.8864 24.2374 22.4473Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4876_9334">
          <rect
            width="35"
            height="35"
            fill="white"
            transform="translate(7.23059 6.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
