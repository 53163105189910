/* eslint-disable @typescript-eslint/naming-convention */
import { WhoAmIClientResponse } from "@ns/api";
import {
  AccountNav,
  AuthCard,
  AuthCardContent,
  Button,
  Divider,
  FormLayout,
  Modal,
  SocialsLayout,
} from "@ns/client-ui";
import { useToast } from "@ns/styles";
import * as Sentry from "@sentry/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import googleSvg from "/svg/google.svg";
import logoButton from "/assets/logo-button.png";

import { useNavigate } from "react-router-dom";

import { api } from "../../api/client-api.ts";
import { getSessionToken, setCodeVerified } from "../../api/token-helper.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  LoginFormData,
  loginValidationSchema,
} from "../../schemas/form/login-schema";
import { useAuthContext } from "../../store/auth-store.ts";
import useUserStore from "../../store/user-store.ts";
import ControlledForm from "../form/controlled-form";
import CloseBtn from "../svg-components/close-btn.tsx";
import ControlledInput from "../form/controlled-input";

import facebookSvg from "/svg/facebook.svg";

const SignInForm = ({
  onClose,
  openForgotPassword,
}: {
  isOpen: boolean;
  onClose: () => void;
  openForgotPassword: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setUser } = useUserStore();

  const socials = [
    {
      onClick: () =>
        axios
          .post(
            `${environmentVariables.authServiceUrl}/oidc/google`,
            {
              redirect_url: environmentVariables.baseUrl,
              server: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            },
          )
          .then((res) => {
            if (res.data) {
              window.location.href = res.data;
            } else {
              console.error("No redirect URL in response");
            }
          })
          .catch((error: unknown) =>
            console.error("Error:", (error as Error).message),
          ),
      src: googleSvg,
    },
    {
      onClick: () =>
        axios
          .post(
            `${environmentVariables.authServiceUrl}/oidc/facebook`,
            {
              redirect_url: environmentVariables.baseUrl,
              server: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            },
          )
          .then((res) => {
            if (res.data) {
              window.location.href = res.data;
            } else {
              console.error("No redirect URL in response");
            }
          })
          .catch((error: unknown) => {
            console.error("Error:", (error as Error).message);
            Sentry.withScope((scope) => {
              scope.setTag("sign-in-social", "basic");
              Sentry.captureException(error);
            });
          }),
      src: facebookSvg,
    },
  ];

  const {
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    reset,
  } = useFormContext<LoginFormData>();
  const { toggleAuthModal, setActiveRegistrationStep, setUserDetails } =
    useAuthContext((state) => state);
  const { toast } = useToast();

  const closeModal = () => {
    onClose();
    reset();
  };

  const { setVerificationId } = useAuthContext();

  const { refetch } = useQuery({
    queryKey: ["auth"],
    queryFn: () => api.auth.getAuthWhoAmI(),
    onSuccess: (response: WhoAmIClientResponse) => {
      setUser(response);
    },
    enabled: !!getSessionToken(),
  });

  const { refetch: refetchPinCode } = useQuery({
    queryKey: ["checkPinCode"],
    queryFn: () => api.pinCode.getPinCode(),
    enabled: !!getSessionToken(),
  });

  const resendCode = () => {
    axios
      .post(
        `${environmentVariables.authServiceUrl}/verification`,
        {
          email: getValues().email,
        },
        { withCredentials: true },
      )
      .then((response) => {
        setVerificationId(response.data.verificationId);
        setActiveRegistrationStep(2);
        toggleAuthModal();
        setUserDetails({ email: getValues().email });
        closeModal();
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setTag("resend-verification-code-dialog", "basic");
          Sentry.captureException(error);
        });
      });
  };

  const login = async (data: LoginFormData) => {
    try {
      const { email, password } = data;
      await axios.post(
        `${environmentVariables.authServiceUrl}/basic/login`,
        {
          email,
          password,
        },
        { withCredentials: true },
      );
      navigate("/");
      queryClient.invalidateQueries(["auth"]);
      await refetch();
      const response = await refetchPinCode();

      if (response?.data?.isActive) {
        setCodeVerified(false);
      }

      closeModal();
    } catch (error: unknown) {
      Sentry.withScope((scope) => {
        scope.setTag("sign-in", "basic");
        scope.setExtra("data", data);
        Sentry.captureException(error);
      });
      if (
        // @ts-ignore
        (error as AxiosError).response?.data?.message ===
        "account is not verified"
      ) {
        resendCode();
        return;
      }
      toast({
        variant: "destructive",
        title: t("common.errors.error"),
        description: t("form.authentication.toast.loginError.description"),
      });
    }
  };

  const handleModalChange = (setterFn?: () => void) => {
    if (setterFn) {
      setterFn();
    } else {
      toggleAuthModal();
    }
    closeModal();
  };

  return (
    <AuthCard>
      <CloseBtn
        className="absolute top-[30px] right-[16px]"
        onClick={closeModal}
      />
      <AuthCardContent>
        <div className={"w-full flex justify-center"}>
          <img
            src={logoButton}
            alt="logo button"
            className="sm:hidden w-16 h-16"
          />
        </div>
        <FormLayout
          subTitle={t("signInDialog.signIn")}
          description={t("signInDialog.enterEmailPasswordToUseApp")}
        >
          <ControlledInput
            name={"email"}
            placeholder={t("common.enterEmailAddress")}
            type="email"
          />
          <ControlledInput
            name={"password"}
            placeholder={t("common.password")}
            type="password"
          />
          <div className={`flex justify-end flex-wrap mb-[20px]`}>
            <span
              className={
                "text-sm text-white font-medium underline cursor-pointer"
              }
              onClick={() => handleModalChange(openForgotPassword)}
            >
              {t("signInDialog.forgotPassword")}
            </span>
          </div>
          <Button
            onClick={handleSubmit(login)}
            disabled={!isValid || Object.keys(errors).length !== 0}
          >
            {t("signInDialog.signIn")}
          </Button>
        </FormLayout>
        {environmentVariables.socialLogin === "true" && (
          <>
            <Divider text={t("common.or")} />
            <SocialsLayout socialArr={socials} />
          </>
        )}
        <AccountNav
          description={t("signInDialog.dontHaveAnAccount")}
          actionText={t("signInDialog.createAccount")}
          action={() => handleModalChange()}
        />
      </AuthCardContent>
    </AuthCard>
  );
};

const SignInDialog = ({
  isOpen,
  onClose,
  openForgotPassword,
}: {
  isOpen: boolean;
  onClose: () => void;
  openForgotPassword: () => void;
}) => {
  return (
    <ControlledForm schema={loginValidationSchema}>
      <Modal isOpen={isOpen}>
        <SignInForm
          isOpen={isOpen}
          onClose={onClose}
          openForgotPassword={openForgotPassword}
        />
      </Modal>
    </ControlledForm>
  );
};

export default SignInDialog;
