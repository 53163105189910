import { Navigate, Outlet } from "react-router-dom";

import { TidioChat } from "../../components/tidio-chat.tsx";
import useUserStore from "../../store/user-store";

const ProtectedRoute = () => {
  const { user } = useUserStore();

  return (
    <>
      {!user ? (
        <Navigate to="/" />
      ) : (
        <>
          <TidioChat />
          <Outlet />
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
