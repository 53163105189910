import { Checkbox } from "@ns/client-ui";
import { Controller, useFormContext } from "react-hook-form";

const ControlledCheckbox = ({
  name,
  label,
  link,
}: {
  name: string;
  label?: string;
  link?: string;
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <div className="flex flex-row gap-[10px] items-center">
          <Checkbox
            checked={value}
            onCheckedChange={onChange}
            onBlur={onBlur}
          />
          {label && (
            <span
              className={`text-white ${link && "underline cursor-pointer"}`}
              onClick={link ? () => window.open(link) : () => {}}
            >
              {label}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default ControlledCheckbox;
