import { Button, Card, CardContent, CardHeader } from '@ns/client-ui';
import { ReactNode, useEffect, useState } from 'react';

import CloseBtn from '../../../../../apps/steal-deal-frontend/src/components/svg-components/close-btn';

import { CardFooter } from './card';
import Modal from './modal';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  title?: string;
  subTitle?: string;
  showCancel?: boolean;
  hideHeaderBtn?: boolean;
  closeBtnText?: string;
  children: ReactNode;
  smallDeviceHeight?: string;
  successBtnText?: string;
  onClose?: () => void;
  onSuccess?: () => void;
  isError?: boolean;
  titleIcon?: string;
  isValid?: boolean;
  className?: string;
  isTitleBold?: boolean;
  disableHeader?: boolean;
};

const Dialog = ({
  title,
  subTitle,
  isOpen,
  children,
  onClose,
  onSuccess,
  successBtnText,
  isLoading = false,
  showCancel,
  hideHeaderBtn,
  closeBtnText,
  titleIcon,
  className,
  smallDeviceHeight = 'h-[90%]',
  isError = false,
  isValid = true,
  isTitleBold = false,
  disableHeader = false,
}: Props) => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal isOpen={isOpen}>
      <Card
        className={`${screenSize.width < 640 ? 'h-full' : screenSize.height < 770 ? `sm:${smallDeviceHeight}` : 'h-fit'} relative border-none w-full sm:w-[560px] flex flex-col sm:rounded-2xl sm:max-h-[770px]`}
      >
        <div className="rounded-lg sm:rounded-2xl absolute inset-0 card-border z-10 h-full w-full" />
        <div
          className={`${className} overflow-y-auto hide-scrollbar rounded-none h-full relative sm:rounded-2xl z-20 bg-background`}
        >
          {!disableHeader && (
            <CardHeader>
              {!hideHeaderBtn && (
                <div className="flex items-center justify-end">
                  <CloseBtn onClick={onClose ? onClose : () => {}} />
                </div>
              )}
              <div className="flex flex-col gap-[10px]">
                <div className="flex flex-row justify-center items-center gap-[0.5rem]">
                  {titleIcon && <img className="w-[1.75rem] h-[1.75rem]" src={titleIcon} />}
                  <span
                    className={`text-white text-center ${isTitleBold ? 'font-semibold text-[16px]' : 'text-[24px]'}`}
                  >
                    {title}
                  </span>
                </div>
                {subTitle && <span className="text-[16px] text-textGray self-center text-center">{subTitle}</span>}
              </div>
            </CardHeader>
          )}

          <CardContent className="flex justify-center items-center">{children}</CardContent>
          <CardFooter className="gap-[10px] flex-1 items-end justify-center">
            <div className="flex flex-col justify-center items-center w-[350px] gap-[16px]">
              {onSuccess && (
                <Button onClick={onSuccess} className="flex-1 w-full" disabled={isLoading || isError || !isValid}>
                  <span>{successBtnText}</span>
                </Button>
              )}
              {showCancel && (
                <Button onClick={onClose} className="flex-1 w-full" disabled={isLoading} variant={'secondary'}>
                  <span>{closeBtnText}</span>
                </Button>
              )}
            </div>
          </CardFooter>
        </div>
      </Card>
    </Modal>
  );
};

export default Dialog;
