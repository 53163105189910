import { Card } from "@ns/client-ui";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useOutsideClick } from "../../../hooks/use-outside-click.ts";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import useUserStore from "../../store/user-store.ts";

interface Props {
  text: string;
  currency: "BTC" | "ETH";
  setIsEditMode: (val: boolean) => void;
  onDelete: () => void;
}

const WalletCard: FC<Props> = ({ text, currency, setIsEditMode, onDelete }) => {
  const { t } = useTranslation();
  const { user } = useUserStore();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const walletCardRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick([walletCardRef], () => setIsEditOpen(false));

  return (
    <Card
      className={
        "w-full text-white p-4 flex justify-between max-w-[350px] border-inputBorderIdle"
      }
      ref={walletCardRef}
    >
      <div className={"w-full flex flex-col"}>
        <div className={"w-full flex items-center gap-2 mb-4"}>
          <img src={CURRENCY_IMAGE[currency]} alt="image" />
          <div className={"text-textGray7"}>{text}</div>
          <div className={"ml-auto relative"}>
            <div
              onClick={() => setIsEditOpen(!isEditOpen)}
              className={"cursor-pointer flex text-sm self-baseline"}
            >
              <div>.</div>
              <div>.</div>
              <div>.</div>
            </div>
            {isEditOpen && (
              <div
                className={
                  "bg-background rounded-sm min-w-[80px] h-auto p-2 absolute top-[20px] right-0 border border-inputBorderIdle"
                }
              >
                <div
                  className={"cursor-pointer"}
                  onClick={() => setIsEditMode(true)}
                >
                  {t("common.edit")}
                </div>
                <div className={"cursor-pointer"} onClick={onDelete}>
                  {t("common.delete")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"text-sm"}>
          {currency === "BTC" ? user?.btcWallet : user?.ethWallet}
        </div>
      </div>
    </Card>
  );
};

export default WalletCard;
