import { AuthCard, AuthCardContent, Button, Modal } from "@ns/client-ui";
import { useMutation } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import {
  CodeData,
  codeValidationSchema,
} from "../../schemas/form/code-schema.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledOtp from "../form/controlled-otp.tsx";
import Lock from "../svg-components/lock.tsx";

const ChangeForgotPinDialogForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useFormContext<CodeData>();
  const { setActiveForgotPinStep, setIsForgotPinModalOpen } = useAuthContext();

  const { mutate } = useMutation({
    mutationFn: (data: { pin: string }) =>
      api.pinCode.updatePinCode({ pin: data.pin, isForgotPin: true }),
    onSuccess: () => {
      reset();
      setIsForgotPinModalOpen(false);
      setActiveForgotPinStep(0);
    },
  });

  const onSubmitHandler = handleSubmit((data) => {
    mutate(data);
  });

  return (
    <AuthCard className="min-h-[31.5rem] max-w-[37.5rem] items-center">
      <AuthCardContent>
        <div
          className={
            "text-white flex flex-col gap-4 justify-center items-center mb-[35px]"
          }
        >
          <Lock />
          <div className="text-white text-2xl">
            {t("screenLock.enterNewPin")}
          </div>
        </div>
        <ControlledOtp name={"pin"} />
        <div className={"flex flex-col gap-4 w-full mt-8"}>
          <Button disabled={!isValid} onClick={onSubmitHandler}>
            {t("common.continue")}
          </Button>
        </div>
      </AuthCardContent>
    </AuthCard>
  );
};

const ChangeForgotPinDialog = () => {
  const { isForgotPinModalOpen, activeForgotPinStep } = useAuthContext();

  return (
    <ControlledForm schema={codeValidationSchema}>
      <Modal isOpen={isForgotPinModalOpen && activeForgotPinStep === 3}>
        <ChangeForgotPinDialogForm />
      </Modal>
    </ControlledForm>
  );
};

export default ChangeForgotPinDialog;
