import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { AddReferral } from "../../components/dialogs/bid-dialog.tsx";
import ControlledForm from "../../components/form/controlled-form.tsx";
import ReferralCodeCard from "../../components/ui/referral-code-card.tsx";
import { addReferralSchema } from "../../schemas/form/add-referral-schema.ts";

const Referral = () => {
  const { t } = useTranslation();

  const { data: currencies } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => api.resources.getCurrencies(),
  });

  const getCurrencyByCode = (code: string) => {
    if (currencies && currencies.items.length === 0) {
      return null;
    }

    return currencies?.items.find((currency) => currency.code === code)?.id;
  };

  const { data: btcCode } = useQuery({
    queryKey: ["referralCode", getCurrencyByCode("BTC")],
    queryFn: () => {
      const btcId = getCurrencyByCode("BTC");
      return api.referralCode.getReferralCodeByCurrencyId(btcId!);
    },
    enabled: !!currencies,
  });

  const { data: ethCode } = useQuery({
    queryKey: ["referralCode", getCurrencyByCode("ETH")],
    queryFn: () => {
      const ethId = getCurrencyByCode("ETH");
      return api.referralCode.getReferralCodeByCurrencyId(ethId!);
    },
    enabled: !!currencies,
  });

  const isReferralDisabled = useMemo(() => {
    return !ethCode?.code && !btcCode?.code;
  }, [ethCode, btcCode]);

  return (
    <div
      className={
        "overflow-y-auto pb-2 sm:pb-0 w-full h-full p-[0rem] lg:p-[3rem] flex justify-center"
      }
    >
      <div
        className={
          "flex flex-col items-center self-baseline w-[85%] sm:w-[calc(100%-144px)] max-w-[600px] self-end pt-6 sm:pt-[3rem]"
        }
      >
        <div className={"text-xl text-white avenir-semibold mb-4 text-center"}>
          {t("referral.shareCode")}
        </div>
        {isReferralDisabled ? (
          <div className={"text-xs text-textGray6 text-center"}>
            <span>{t("giftDialog.toUnlock")}</span>
          </div>
        ) : (
          <div className={"text-xs text-textGray6 text-center"}>
            <span>{t("referral.ifYourFriend")}</span>
            <span className={"avenir-semibold"}>
              {t("referral.ofTheMarket")}
            </span>
          </div>
        )}
        <div className={"w-full max-w-[350px] mt-10 flex flex-col gap-4"}>
          {btcCode && btcCode.code && (
            <ReferralCodeCard
              text={t("referral.yourBitcoin")}
              code={btcCode.code}
              currency={"BTC"}
            />
          )}
          {ethCode && ethCode.code && (
            <ReferralCodeCard
              text={t("referral.yourEthereum")}
              code={ethCode.code}
              currency={"ETH"}
            />
          )}
        </div>
        <div
          className={
            "w-full flex flex-col justify-center items-center gap-4 max-w-[350px]"
          }
        >
          <div className={"text-white text-xl mt-[40px] avenir-semibold"}>
            {t("referral.addNewReferral")}
          </div>
          <ControlledForm schema={addReferralSchema}>
            <AddReferral />
          </ControlledForm>
        </div>
      </div>
    </div>
  );
};

export default Referral;
