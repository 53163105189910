import { InputOtp, InputOtpGroup, InputOtpSlot } from "@ns/client-ui";
import { Controller, useFormContext } from "react-hook-form";

import exclamationSvg from "/svg/exclamation.svg";

const ControlledOtp = ({ name }: { name: string }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputOtp maxLength={4} value={value} onChange={onChange}>
          <InputOtpGroup>
            {[0, 1, 2, 3].map((index) => (
              <InputOtpSlot
                key={index}
                index={index}
                className={error && "border-red-600"}
              />
            ))}
          </InputOtpGroup>
          {error?.message && (
            <div className="absolute top-[3.75rem] flex items-center justify-start gap-[3px]">
              <img src={exclamationSvg} alt="exclamation" />
              <span className="text-red-600 text-[12px]">{error.message}</span>
            </div>
          )}
        </InputOtp>
      )}
    />
  );
};

export default ControlledOtp;
