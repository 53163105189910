import { AuthCard, AuthCardContent, Button, Modal } from "@ns/client-ui";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import Lock from "../svg-components/lock.tsx";

const ForgotPinDialog = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation();
  const { setActiveForgotPinStep, setIsPinModalOpen, setIsForgotPinModalOpen } =
    useAuthContext();

  const [hasError, setHasError] = useState(false);

  const { mutate } = useMutation({
    mutationFn: () => api.forgottenCode.sendForgottenCodeEmail(),
    onSuccess: () => {
      setActiveForgotPinStep(2);
    },
    onError: () => {
      setHasError(true);
    },
  });

  const onSubmitHandler = () => {
    mutate();
  };

  return (
    <Modal isOpen={isOpen}>
      <AuthCard className="min-h-[31.5rem] max-w-[37.5rem] items-center">
        <AuthCardContent>
          <div
            className={
              "text-white flex flex-col gap-4 justify-center items-center mb-[35px]"
            }
          >
            <Lock />
            <div className="text-white text-2xl">
              {t("screenLock.forgotPin")}
            </div>
            <div className={"text-textGray max-w-[220px] text-center"}>
              {t("screenLock.enterEmail")}
            </div>
            {hasError && (
              <div className="top-[40px] flex justify-center gap-[3px]">
                <span className="text-center text-red-600 text-[14px]">
                  {t("screenLock.wait")}
                </span>
              </div>
            )}
          </div>
          <div className={"flex flex-col gap-4 w-full"}>
            <Button onClick={onSubmitHandler}>{t("common.sendEmail")}</Button>
            <Button
              variant={"secondary"}
              onClick={() => {
                setIsPinModalOpen(true);
                setIsForgotPinModalOpen(false);
              }}
            >
              {t("common.back")}
            </Button>
          </div>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};

export default ForgotPinDialog;
