import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const loginValidationSchema = (t: TFunction) =>
  z.object({
    email: z
      .string()
      .min(1, { message: t("signInDialog.auth.emailRequired") })
      .email({ message: t("form.authentication.invalidEmail") }),
    password: z
      .string({ message: t("form.authentication.passwordRequired") })
      .min(8, { message: t("form.authentication.passwordAtLeast") })
      .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
      .regex(/[A-Z]/, {
        message: t("form.authentication.atLeastOneUppercase"),
      })
      .regex(/[a-z]/, {
        message: t("form.authentication.atLeastOneLowercase"),
      })
      .regex(/[^a-zA-Z0-9\s]/, {
        message: t("form.authentication.atLeastOneSpecial"),
      }),
  });

const schema = loginValidationSchema(staticTranslationFunction);

export type LoginFormData = z.infer<typeof schema>;

export const loginFormFieldNames: FormNames<LoginFormData> = {
  email: "email",
  password: "password",
};
