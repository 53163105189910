import { WhoAmIClientResponse } from "@ns/api";
import { create } from "zustand";

export type Auth = {
  languageId: string;
  user: WhoAmIClientResponse | null;
  setUser: (whoAmIResponse: WhoAmIClientResponse | null) => void;
  setLanguageId: (id: string) => void;
  imageUrl?: string | null;
  setImageUrl: (imageUrl?: string | null) => void;
  chatImageUrl?: string | null;
  setChatImageUrl: (imageUrl?: string | null) => void;
  resetId?: string;
  setResetId: (resetId?: string) => void;
  resetEmail?: string;
  setResetEmail: (resetEmail?: string) => void;
  hasUnreadNotifications: boolean;
  setHasUnreadNotifications: (hasUnreadNotifications: boolean) => void;
  selectedItem?: string | null;
  setSelectedItem: (selectedItem?: string | null) => void;
  hasUnreadMessages: boolean;
  setHasUnreadMessages: (hasUnreadMessages: boolean) => void;
  isChatOpen: boolean;
  toggleIsChatOpen: () => void;
};

const useUserStore = create<Auth>((set) => ({
  user: null,
  languageId: "",
  imageUrl: null,
  resetId: undefined,
  resetEmail: undefined,
  chatImageUrl: null,
  hasUnreadNotifications: false,
  selectedItem: null,
  hasUnreadMessages: false,
  isChatOpen: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  setUser: (whoAmIResponse: WhoAmIClientResponse | null) =>
    set({ user: whoAmIResponse }),
  setLanguageId: (id) => set({ languageId: id }),
  setImageUrl: (imageUrl?: string | null) => set({ imageUrl: imageUrl }),
  setResetId: (resetId?: string) => set({ resetId }),
  setResetEmail: (resetEmail?: string) => set({ resetEmail }),
  setChatImageUrl: (chatImageUrl?: string | null) =>
    set({ chatImageUrl: chatImageUrl }),
  setHasUnreadNotifications: (hasUnreadNotifications: boolean) =>
    set({ hasUnreadNotifications: hasUnreadNotifications }),
  setSelectedItem: (selectedItem?: string | null) =>
    set({ selectedItem: selectedItem }),
  setHasUnreadMessages: (hasUnreadMessages: boolean) =>
    set({ hasUnreadMessages: hasUnreadMessages }),
  toggleIsChatOpen: () => set((state) => ({ isChatOpen: !state.isChatOpen })),
}));

export default useUserStore;
