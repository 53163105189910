import {
  CheckReferralCodeResponse,
  CreateReferralRequest,
  UseCouponRequest,
} from "@ns/api";
import { Button, Dialog } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dollarGoldSvg from "/svg/dollarGold.svg";
import giftSvg from "/svg/gift.svg";
import logoSvg from "/svg/small-logo.svg";

import { api } from "../../api/client-api.ts";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { addReferralSchema } from "../../schemas/form/add-referral-schema.ts";
import { placeBidFormNames } from "../../schemas/form/place-bid-schema.ts";
import { featureFreeBid } from "../../utils/feature-flags/feature-flags.ts";
import ControlledDropdown from "../form/controlled-dropdown.tsx";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledPlaceABidInput from "../form/controlled-place-a-bid-input.tsx";
import InputPromoCode from "../form/input-promo-code";
import AddReferralButton from "../svg-components/add-referral-button.tsx";

const BidDialog = ({
  isOpen,
  onClose,
  onSuccess,
  roundId,
  entryFee,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  roundId?: string;
  entryFee: number;
}) => {
  const {
    getValues,
    watch,
    reset,
    setValue,
    formState: { isValid },
  } = useFormContext();

  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isDropdownInputDisplayed, setIsDropdownInputDisplayed] =
    useState<boolean>(false);

  const toggleIsUpdated = () => {
    setIsUpdated((isUpdated) => !isUpdated);
  };
  const { data: round } = useQuery({
    queryKey: ["round", roundId],
    queryFn: () => api.round.getRoundById(roundId!),
    enabled: !!roundId,
  });

  const { data: freeRound } = useQuery({
    queryKey: ["freeRound", round?.currency?.id],
    queryFn: () => api.orders.checkFreeRound(round!.currency!.id!),
    enabled: !!round?.currency?.id && featureFreeBid,
  });

  const { data: availableCoupons } = useQuery({
    queryKey: ["availableCoupons"],
    queryFn: () => api.coupons.checkAvailableCouponsExist(),
    enabled: !!round?.currency?.id,
  });

  const { data: referredBy, refetch } = useQuery({
    queryKey: ["referredBy", round?.currency?.id],
    queryFn: () => api.referral.getReferredBy(round!.currency!.id!),
    enabled: !!round?.currency?.id,
  });

  useEffect(() => {
    if (isUpdated) {
      refetch();
      setIsUpdated(false);
    }
  }, [isUpdated, refetch, setIsUpdated]);

  const selectedReferral = watch("referralId");

  useEffect(() => {
    if (selectedReferral !== "1") {
      setValue("referralId", selectedReferral);
    } else {
      setValue("referralId", undefined);
    }
  }, [setValue, selectedReferral]);

  const {
    mutate: checkPromoCode,
    data: discountData,
    isIdle,
  } = useMutation({
    mutationFn: (data: UseCouponRequest) => api.payment.useCoupon(data),
  });

  const promoCode = useWatch({ name: "promoCode" });
  const bidValue = useWatch({ name: "bid" });

  const { t } = useTranslation();
  const { discountedPrice, discount } = useMemo(() => {
    const referralDiscount = !selectedReferral ? 0 : 5;

    const promoDiscount = discountData?.discount ?? 0;

    const discount = promoDiscount + referralDiscount;

    return {
      discountedPrice: entryFee - (entryFee * discount) / 100,
      discount,
    };
  }, [discountData, entryFee, selectedReferral]);

  useEffect(() => {
    if (!roundId || !promoCode) return;
    const timeout = setTimeout(() => {
      checkPromoCode({ roundId, promoCode });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [roundId, checkPromoCode, promoCode]);

  useEffect(() => {
    setValue("currencyBid", getValues("currency"));
  }, [getValues, setValue]);

  const isFree = useMemo(() => {
    return freeRound?.isFree ?? false;
  }, [freeRound]);

  const formatedReferredBy = useMemo(() => {
    const formatedRefferedBy = (referredBy?.items || []).map((referredBy) => {
      return {
        value: referredBy.id,
        label: referredBy.name,
      };
    });
    return [
      ...formatedRefferedBy,
      {
        value: "1",
        label: t("dashboard.dialog.addNew"),
        icon: <AddReferralButton />,
      },
    ];
  }, [referredBy]);

  const isFreeRoundAvailable = useMemo(() => {
    return featureFreeBid && isFree;
  }, [isFree]);

  if (!round) {
    return <></>;
  }

  return (
    <Dialog
      title={t("dashboard.dialog.placeBid")}
      isOpen={isOpen}
      successBtnText={t("common.placeBid")}
      onClose={() => {
        onClose();
        reset();
      }}
      onSuccess={() => {
        onSuccess();
        reset();
      }}
      titleIcon={CURRENCY_IMAGE[round.currency.code as "ETH" | "BTC"]}
      isValid={isValid}
    >
      <div className="flex flex-col items-center justify-start w-[343px] gap-[16px] container-90">
        <div className="flex flex-col items-start justify-center pb-[16px] w-full">
          {isFreeRoundAvailable && (
            <span
              className={
                "text-white text-[20px] avenir-semibold text-center pb-[25px]"
              }
            >
              {t("dashboard.dialog.useFree")}
            </span>
          )}
          <div className="entryFeeCard flex items-center h-[44px] justify-between w-full p-[20px] entry-fee-container">
            <div className="flex items-center gap-[16px]">
              {!isFreeRoundAvailable ? (
                <img src={dollarGoldSvg} alt="dollar" />
              ) : (
                <img src={giftSvg} alt="gift" />
              )}
              <span className="text-white text-[20px] avenir-semibold">
                {!isFreeRoundAvailable
                  ? t("dashboard.dialog.entryFee")
                  : t("dashboard.dialog.freeRound")}
              </span>
            </div>
            <span className="text-[20px] text-priceColor avenir-semibold">
              {!isFreeRoundAvailable ? round.entryFee : 0}{" "}
              {environmentVariables.fiatCurrency}
            </span>
          </div>
        </div>
        {!isFreeRoundAvailable && (
          <div className="flex flex-col items-start justify-center w-full gap-[15px]">
            {availableCoupons?.isAvailable && (
              <>
                <span className="text-white text-[16px] avenir-semibold">
                  {t("dashboard.dialog.promoCode")}:
                </span>
                <div className={"flex w-full flex-col gap-[6px]"}>
                  <InputPromoCode
                    name={"promoCode"}
                    placeholder={t("dashboard.dialog.enterPromoCode")}
                    codeCorrect={!!discountData}
                    loaded={!isIdle}
                  />
                </div>
              </>
            )}
            <div className="flex flex-col items-start justify-center w-full gap-[15px]">
              <span className="text-white text-[16px] avenir-semibold">
                {t("dashboard.dialog.referralProgram")}:
              </span>
              {formatedReferredBy && !isDropdownInputDisplayed ? (
                <div className="flex flex-row justify-between w-full">
                  <div className={"flex w-[260px]"}>
                    <span className="text-[16px] avenir-semibold text-textGray self-center">
                      <span className={"gold-text-clear"}>{`5% ${t(
                        "dashboard.dialog.off",
                      )}`}</span>{" "}
                      <span className="text-white avenir-semibold text-[16px]">
                        {t("dashboard.dialog.fromReferral")}
                      </span>
                    </span>
                  </div>
                  <ControlledDropdown
                    name={"referralId"}
                    options={formatedReferredBy}
                    showInput={setIsDropdownInputDisplayed}
                  />
                </div>
              ) : (
                <ControlledForm schema={addReferralSchema}>
                  <AddReferral
                    onCancel={() => {
                      setValue("referralId", undefined);
                      setIsDropdownInputDisplayed(false);
                    }}
                    setIsUpdated={toggleIsUpdated}
                    currencyId={round.currency.id}
                  />
                </ControlledForm>
              )}
            </div>
          </div>
        )}
        {(!discountData || !selectedReferral) && discount === 0 && (
          <img src={logoSvg} alt="dolar" />
        )}
        {(discountData || selectedReferral) && selectedReferral !== "1" && (
          <div className="flex flex-col w-full border-t border-t-solid border-t-borderBlue border-b border-b-solid pb-[15px] pt-[15px] gap-[6px] border-borderBlue items-center text-white">
            <div className="flex flex-row w-full justify-between">
              <span>{`${t("dashboard.dialog.subtotal")}:`}</span>
              <span>{`${round?.entryFee} ${environmentVariables.fiatCurrency}`}</span>
            </div>
            <div className="flex flex-row w-full justify-between">
              <span>{`${t("dashboard.dialog.discount")}:`}</span>
              <span>{discount}%</span>
            </div>
          </div>
        )}
        {(discountData || selectedReferral) && selectedReferral !== "1" && (
          <div className="flex flex-col items-start justify-center pb-[16px] w-full gap-[6px]">
            <div className="flex justify-between w-full items-center">
              <span className="text-white text-[16px] avenir-semibold">
                {t("dashboard.dialog.total")}:
              </span>
              <span className="text-red-400 line-through text-[16px] avenir-semibold">
                {entryFee} {environmentVariables.fiatCurrency}
              </span>
            </div>
            <span className="text-white text-[18px] avenir-semibold self-end">
              {discountedPrice} {environmentVariables.fiatCurrency}
            </span>
          </div>
        )}
        {selectedReferral && <img src={logoSvg} alt="dolar" />}
        <div className="w-full flex flex-col gap-[16px]">
          <div className="w-full flex flex-col gap-[6px]">
            <ControlledPlaceABidInput
              name={placeBidFormNames.bid}
              id={placeBidFormNames.bid}
              placeholder={t("dashboard.dialog.enterBidValue")}
              currency={round.currency.code}
            />
            <span className="text-[12px] text-center text-textGray self-center">
              {t("dashboard.dialog.noteBid", { entryFee: round?.entryFee })}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="gold-text-clear avenir-semibold">
              {t("dashboard.dialog.bid")}:
            </span>
            <span className="gold-text-clear avenir-semibold">
              {bidValue || 0} {environmentVariables.fiatCurrency}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const AddReferral = ({
  setIsUpdated,
  disabled,
  onCancel,
  currencyId,
}: {
  setIsUpdated?: () => void;
  disabled?: boolean;
  onCancel?: () => void;
  currencyId?: string;
}) => {
  const { setError, handleSubmit, setValue } = useFormContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: checkReferralCodeMutation,
    isIdle,
    data: checkReferralCode,
  } = useMutation({
    mutationFn: (data: string) =>
      api.referralCode.checkReferralCode(data, currencyId),
    onSuccess: (response: CheckReferralCodeResponse) => {
      if (!response.isValid) {
        setError("code", {
          message: t("profile.dialog.referral.invalidCode"),
        });
      }
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("check-referral-code-mutation", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
      setError("code", { message: t("profile.dialog.referral.invalidCode") });
    },
  });

  const { mutate: addReferralCodeMutation } = useMutation({
    mutationFn: (data: CreateReferralRequest) =>
      api.referral.createReferral(data),
    onSuccess: async () => {
      setValue("code", "");
      if (setIsUpdated) {
        setIsUpdated();
      }
      await queryClient.invalidateQueries({ queryKey: ["referredBy"] });
      if (onCancel) {
        onCancel();
      }
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("add-referral-code", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const referralCode = useWatch({ name: "code" });

  useEffect(() => {
    if (!referralCode) {
      return;
    }

    const timeout = setTimeout(() => {
      checkReferralCodeMutation(referralCode);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [referralCode, checkReferralCodeMutation]);

  const onSubmit = handleSubmit((data) =>
    addReferralCodeMutation({ code: data.code }),
  );

  return (
    <div className={"flex w-full flex-row gap-[0.25rem] relative"}>
      {onCancel && (
        <div
          onClick={onCancel}
          className={
            "cursor-pointer underline text-white absolute top-[-26px] right-[5px]"
          }
        >
          {t("common.cancel")}
        </div>
      )}
      <InputPromoCode
        name={"code"}
        placeholder={t("dashboard.dialog.enterReferralCode")}
        codeCorrect={checkReferralCode?.isValid ?? false}
        loaded={!isIdle}
        hasPasteIcon={true}
        showErrorMessage={true}
        disabled={disabled}
      />
      <Button onClick={onSubmit} disabled={!checkReferralCode?.isValid}>
        <span>{t("dashboard.dialog.add")}</span>
      </Button>
    </div>
  );
};

export default BidDialog;
