import { useTranslation } from "react-i18next";
import { AuthCard, AuthCardContent, FormLayout, Modal } from "@ns/client-ui";

import CloseBtn from "../svg-components/close-btn.tsx";

import tickCircleSvg from "/svg/tick-circle.svg";

export const SuccessChangedPasswordDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen}>
      <AuthCard>
        <AuthCardContent>
          <FormLayout
            subTitle={t("common.success")}
            description={t("common.successfullyChanged")}
          >
            <CloseBtn
              className="absolute top-[30px] right-[16px]"
              onClick={onClose}
            />
            <div className="flex flex-col items-center justify-center w-full">
              <img src={tickCircleSvg} alt={"tick-circle"} />
            </div>
          </FormLayout>
        </AuthCardContent>
      </AuthCard>
    </Modal>
  );
};
