import type { CreatePinCodeRequest } from "@ns/api";
import { AuthCard, AuthCardContent, Button, Modal } from "@ns/client-ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import {
  CodeData,
  codeValidationSchema,
} from "../../schemas/form/code-schema.ts";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledOtp from "../form/controlled-otp.tsx";
import CloseBtn from "../svg-components/close-btn.tsx";
import Lock from "../svg-components/lock.tsx";

const LockScreenForm = ({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useFormContext<CodeData>();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (data: CreatePinCodeRequest) => api.pinCode.createPinCode(data),
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(["pinCode"]);
      reset();
    },
  });

  const onSubmitHandler = handleSubmit((data) => {
    mutate(data);
  });

  return (
    <AuthCard className="min-h-[31.5rem] max-w-[37.5rem] items-center">
      <AuthCardContent>
        <CloseBtn
          onClick={() => {
            onClose();
            reset();
          }}
          className="absolute right-[32px] top-[32px] w-6 h-6"
        />
        <div
          className={
            "text-white flex flex-col gap-4 justify-center items-center mb-[35px]"
          }
        >
          <Lock />
          <div className="text-white text-2xl">
            {t("screenLock.screenLock")}
          </div>
          <div className={"text-textGray max-w-[220px] text-center"}>
            {t("screenLock.forSecurity")}
          </div>
        </div>
        <div>
          <ControlledOtp name={"pin"} />
        </div>
        <Button
          className={"mt-[2rem]"}
          disabled={!isValid}
          onClick={() => onSubmitHandler()}
        >
          {t("common.continue")}
        </Button>
      </AuthCardContent>
    </AuthCard>
  );
};

const LockScreenDialog = ({
  isOpen,
  onClose,
  onSuccess,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  return (
    <ControlledForm schema={codeValidationSchema}>
      <Modal isOpen={isOpen}>
        <LockScreenForm onClose={onClose} onSuccess={onSuccess} />
      </Modal>
    </ControlledForm>
  );
};

export default LockScreenDialog;
