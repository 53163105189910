import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";

export const changePasswordValidationSchema = (t: TFunction) =>
  z
    .object({
      currentPassword: z
        .string({ message: t("form.authentication.passwordRequired") })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
      newPassword: z
        .string({ message: t("form.authentication.passwordRequired") })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
      confirmNewPassword: z
        .string({
          message: t("form.authentication.passwordRequired"),
        })
        .min(8, { message: t("form.authentication.passwordAtLeast") })
        .regex(/\d/, { message: t("form.authentication.atLeastOneDigit") })
        .regex(/[A-Z]/, {
          message: t("form.authentication.atLeastOneUppercase"),
        })
        .regex(/[a-z]/, {
          message: t("form.authentication.atLeastOneLowercase"),
        })
        .regex(/[^a-zA-Z0-9\s]/, {
          message: t("form.authentication.atLeastOneSpecial"),
        }),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: t("form.authentication.passwordsDoNotMatch"),
      path: ["confirmNewPassword"],
    });

export const schema = changePasswordValidationSchema(staticTranslationFunction);

export type ChangePasswordData = z.infer<typeof schema>;
