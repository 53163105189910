import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { Suspense } from "react";
import ReactGA from "react-ga4";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import MainLayout from "./components/layout/main-layout";
import { TidioChat } from "./components/tidio-chat.tsx";
import { environmentVariables } from "./env/enviroment-variables.ts";
import { AboutUs } from "./pages/auth/about-us.tsx";
import Dashboard from "./pages/auth/dashboard.tsx";
import Maintenance from "./pages/auth/maintenance.tsx";
import Referral from "./pages/auth/referral.tsx";
import ReferralsPage from "./pages/auth/referrals.tsx";
import { SupportForm } from "./pages/auth/support.tsx";
import UserPage from "./pages/auth/user-page.tsx";
import { WalletForm } from "./pages/auth/wallet.tsx";
import { WatchDemo } from "./pages/auth/watch-demo.tsx";
import WinnersPage from "./pages/auth/winners-page.tsx";
import NotFound from "./pages/errors/not-found";
import { useAuthContext } from "./store/auth-store.ts";
import ProtectedRoute from "./utils/protected-route/protected-route";

ReactGA.initialize(environmentVariables.analyticsId);

Amplify.configure({
  API: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_region: "eu-central-1",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_graphqlEndpoint: environmentVariables.graphqlUrl,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_apiKey: environmentVariables.graphqlApiKey,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_authenticationType: "API_KEY",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GraphQL: {
      endpoint: environmentVariables.graphqlUrl,
      region: "eu-central-1",
      defaultAuthMode: "apiKey",
      apiKey: environmentVariables.graphqlApiKey,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: Infinity },
  },
});

function App() {
  const { isMaintenanceMode } = useAuthContext();

  const router = createBrowserRouter([
    {
      path: "*",
      element: <NotFound />,
    },
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "/winners",
          element: <WinnersPage />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/demo",
          element: <WatchDemo />,
        },
        {
          path: "/support",
          element: <SupportForm />,
        },
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "/bids",
              element: <UserPage />,
            },
          ],
        },
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "/wallet",
              element: <WalletForm />,
            },
          ],
        },
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "/referral",
              element: <Referral />,
            },
          ],
        },
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "/referrals",
              element: <ReferralsPage />,
            },
          ],
        },
      ],
    },
  ]);

  const routerMaintenance = createBrowserRouter([
    {
      path: "*",
      element: <Maintenance />,
    },
  ]);

  return (
    <Suspense fallback={<></>}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider
          router={isMaintenanceMode ? routerMaintenance : router}
        />
        <TidioChat />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
