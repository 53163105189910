export const environmentVariables = {
  baseApiUrl: import.meta.env.VITE_BASE_API_URL,
  graphqlUrl: import.meta.env.VITE_GRAPHQL_API_URL,
  graphqlApiKey: import.meta.env.VITE_GRAPHQL_API_KEY,
  tidioChatKey: import.meta.env.VITE_TIDIO_CHAT_KEY,
  authServiceUrl: import.meta.env.VITE_AUTH_SERVICE_URL,
  baseUrl: import.meta.env.VITE_BASE_URL,
  sentryDsn: import.meta.env.VITE_CLIENT_SENTRY_DSN,
  nodeEnv: import.meta.env.VITE_NODE_ENV,
  sentryAppUrl: import.meta.env.VITE_CLIENT_SENTRY_APP_URL,
  sentryAuthToken: import.meta.env.VITE_CLIENT_SENTRY_AUTH_TOKEN,
  fiatCurrency: import.meta.env.VITE_FIAT_CURRENCY,
  languagesFlag: import.meta.env.VITE_LANGUAGES_FLAG,
  websocketUrl: import.meta.env.VITE_WEBSOCKET_URL,
  analyticsId: import.meta.env.VITE_ANALYTICS_ID,
  socialLogin: import.meta.env.VITE_SOCIAL_LOGIN,
  freeBidFeature: import.meta.env.VITE_FREE_BID_FEATURE,
  betaVersion: import.meta.env.VITE_FEATURE_BETA_VERSION,
  walletFeature: import.meta.env.VITE_WALLET_FEATURE,
};
