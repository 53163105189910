import { CountriesResponse, CountryResponse } from "@ns/api";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import chevronSvg from "/svg/chevron.svg";

import { useTranslation } from "react-i18next";

import { useOutsideClick } from "../../hooks/use-outside-click";
import { api } from "../api/client-api";

const CountryDropdown = ({
  setPhoneData,
  flag,
}: {
  setPhoneData: (data: { prefix: string; flag: string }) => void;
  flag: string;
}) => {
  const { t } = useTranslation();

  const { data: countriesData, isLoading } = useQuery<CountriesResponse>({
    queryKey: [`getCountries`],
    queryFn: () => api.country.getCountries(1000),
  });
  const { setValue } = useFormContext();
  const [countries, setCountries] = useState<CountryResponse[]>([]);

  const dropdownRef = useRef(null);

  const [opened, setOpened] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  useOutsideClick([dropdownRef], () => setOpened(false));

  const onChangeHandler = useCallback(
    (value: string) => {
      setOpened(true);
      setInputValue(value);
      if (value) {
        const filtered = countriesData?.items?.filter((item) => {
          return item.name.toLowerCase().includes(value.toLowerCase());
        });
        setCountries(filtered ?? []);
      } else {
        setOpened(false);
      }
    },
    [setInputValue, setOpened, countriesData?.items],
  );

  const onSelect = (name: string, prefix: string, flag: string) => {
    setOpened((prev) => !prev);
    setValue("country", name);
    setInputValue(name);
    setPhoneData({ prefix, flag });
  };

  useEffect(() => {
    if (!countriesData?.items.length) return;
    setCountries(countriesData.items ?? []);
    setPhoneData({
      prefix: countriesData.items[0].phonePrefix,
      flag: countriesData.items[0].flag,
    });
  }, [isLoading, setCountries, countriesData?.items, setPhoneData]);

  return (
    <div className="relative flex-col" ref={dropdownRef}>
      <img
        className="absolute left-[16px] top-[8px] w-[25px] h-[25px] "
        src={flag}
      />
      <input
        onChange={(event) => onChangeHandler(event.target.value)}
        placeholder={t("common.selectCountry")}
        value={inputValue}
        className="outline-none bg-inherit text-white placeholder:text-textGray2 border border-inputBorderIdle rounded-[8px] py-[8px] pl-[50px] w-full text-sm"
      />
      <img
        src={chevronSvg}
        alt="Chevron"
        className="absolute top-[8px] right-[16px]"
        onClick={() => setOpened((prev) => !prev)}
      />

      {opened && countries?.length ? (
        <div className="w-full max-h-[256px] overflow-auto absolute left-0 top-[46px] p-[16px] rounded-[16px] bg-countryDropdownBg z-10 flex flex-col gap-[16px]">
          {countries?.map((country) => (
            <div
              className="flex flex-row items-center gap-[16px] cursor-pointer"
              onClick={() =>
                onSelect(country.name, country.phonePrefix, country.flag)
              }
            >
              <img src={country?.flag} className="w-[25px] h-[25px]" />
              <div className="flex flex-col flex-1 items-start">
                <span className="text-white text-[16px]">{country.name}</span>
                <div className={"fade-away w-full mt-1"} />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CountryDropdown;
