import React, { forwardRef, useDeferredValue } from "react";

interface FlatListProps<T> {
  data: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  keyExtractor: (item: T) => string;
  isFetching?: boolean;
  fetchingTriggerIndex?: number;
  numColumns?: number;
  numberOfColumnsOnSmallerDevices?: number;
}

const FlatList = <T,>(
  props: FlatListProps<T>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const {
    data,
    renderItem,
    isFetching,
    keyExtractor,
    fetchingTriggerIndex = 3,
    numColumns = 1,
  } = props;

  const deferredData = useDeferredValue(data);

  return (
    <div
      className="grid auto-rows-max gap-4 w-full"
      style={{
        gridTemplateColumns: `repeat(${numColumns}, minmax(11rem, 1fr))`,
      }}
    >
      {deferredData?.map((item, index) => {
        const isFetchingTrigger =
          index === deferredData.length - fetchingTriggerIndex;

        return (
          <div
            ref={ref && isFetchingTrigger ? ref : undefined}
            key={keyExtractor(item)}
          >
            {renderItem(item, index)}
          </div>
        );
      })}
      {isFetching && (
        <div className="grid gap-4 col-span-full">
          <div>LOADING....</div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(FlatList) as <T>(
  props: FlatListProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => ReturnType<typeof FlatList>;
