import {
  AuthCard,
  AuthCardContent,
  Button,
  FormLayout,
  Modal,
} from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  CountryFormData,
  countrySchema,
} from "../../schemas/form/country-schema.ts";
import { useAuthContext } from "../../store/auth-store.ts";
import useUserStore from "../../store/user-store.ts";
import CountryDropdown from "../country-dropdown.tsx";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledInput from "../form/controlled-input.tsx";
import CloseBtn from "../svg-components/close-btn.tsx";

const CountryDialogForm = () => {
  const { t } = useTranslation();
  const [phoneData, setPhoneData] = useState({ prefix: "", flag: "" });
  // const [search, setSearch] = useState("");
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const { watch, reset, handleSubmit } = useFormContext<CountryFormData>();
  const { userDetails, setActiveRegistrationStep, setVerificationId } =
    useAuthContext((state) => state);

  const { data: countriesData } = useQuery({
    queryKey: [`getCountries`],
    queryFn: () => api.country.getCountries(1000),
  });

  const selectedCountry = watch("country");
  const phoneNumber = watch("phoneNumber");
  const { languageId } = useUserStore();

  const { data: languages } = useQuery({
    queryKey: [`languages`],
    queryFn: () => api.resources.getLanguages(),
  });

  const register = handleSubmit(async (data) => {
    if (userDetails) {
      const country = countriesData?.items.find(
        (country) => country.name === selectedCountry,
      );
      const language = languages?.items.find(
        (language) => language.id === languageId,
      );
      await axios
        .post(`${environmentVariables.authServiceUrl}/basic/registration`, {
          email: userDetails.email,
          password: userDetails.password,
          meta: {
            username: userDetails.username,
            countryId: country?.id,
            phoneNumber: data.phoneNumber,
            languageId: languageId,
            language: language?.code ?? "en",
          },
        })
        .then((response) => {
          setVerificationId(response.data["verification_id"]);
          setActiveRegistrationStep(2);
        })
        .catch((error) => {
          Sentry.withScope((scope) => {
            scope.setTag("country-dialog", "basic");
            scope.setExtra("userDetails", userDetails);
            scope.setExtra("formData", data);
            Sentry.captureException(error);
          });
        });
    }
  });

  const onCancelHandler = () => {
    setActiveRegistrationStep(0);
    reset();
  };

  if (!countriesData) return <></>;

  return (
    <>
      <AuthCard>
        <AuthCardContent>
          <FormLayout
            subTitle={t("countryDialog.phoneNumber")}
            description={t("countryDialog.pleaseSelect")}
          >
            <CloseBtn
              className="absolute top-[30px] right-[16px]"
              onClick={onCancelHandler}
            />
            <CountryDropdown
              setPhoneData={setPhoneData}
              flag={phoneData.flag}
            />
            <ControlledInput
              name="phoneNumber"
              icon={phoneData?.flag}
              iconText={phoneData?.prefix}
              ref={phoneNumberRef}
            />
            <Button
              variant={"primary"}
              onClick={register}
              disabled={!phoneNumber || !selectedCountry}
            >
              {t("common.continue")}
            </Button>
            <Button variant={"secondary"} onClick={onCancelHandler}>
              {t("common.back")}
            </Button>
          </FormLayout>
        </AuthCardContent>
      </AuthCard>
    </>
  );
};

const CountryDialog = () => {
  const { isAuthModalOpen, activeRegistrationStep } = useAuthContext();
  return (
    <ControlledForm schema={countrySchema}>
      <Modal isOpen={isAuthModalOpen && activeRegistrationStep === 1}>
        <CountryDialogForm />
      </Modal>
    </ControlledForm>
  );
};

export default CountryDialog;
