import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from './../../lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        primary:
          'bg-gradientGold hover:bg-gradientGoldShade text-black disabled:bg-gray-700 disabled:text-white disabled:bg-none hover:disabled:cursor-not-allowed',
        secondary:
          'bg-secondary disabled:border disabled:border-disabledSecondaryBorder disabled:text-disabledSecondaryText text-white hover:bg-secondaryHover',
        error: 'bg-destructive text-destructive-foreground',
        errorOutline: 'border border-borderError text-destructive bg-inherit',
        gradientBlue: 'bg-gradientBlueBtn text-white',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const combinedClasses = cn(
      buttonVariants({ variant, size }),
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'bg-disabledPrimary': disabled && variant === 'primary',
      },
      className
    );

    return <Comp className={combinedClasses} ref={ref} disabled={disabled} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
