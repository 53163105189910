import { Dialog } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import useUserStore from "../../store/user-store.ts";
import { ControlledImageUpload } from "../form/controlled-image-upload.tsx";

const UploadImageDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { imageUrl } = useUserStore();
  const queryClient = useQueryClient();
  const {
    formState: { isValid },
  } = useFormContext();

  const { mutate } = useMutation({
    mutationFn: (key: string) => api.user.updateUser({ key }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(["auth"]);
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("upload-image-dialog", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  return (
    <Dialog
      isOpen={isOpen}
      title={t("dashboard.dialog.uploadImage")}
      onClose={onClose}
      successBtnText={t("dashboard.dialog.save")}
      showCancel={true}
      closeBtnText={t("dashboard.dialog.cancel")}
      isValid={isValid}
      onSuccess={() => {
        mutate(imageUrl!);
        onClose();
      }}
    >
      <div className="w-full">
        <ControlledImageUpload
          name={"file"}
          id={"file"}
          folder={"users"}
          uploadFileHeading={t("dashboard.dialog.uploadImage")}
          maxFileSizeText={t("dashboard.dialog.fileMust")}
          browseText={t("dashboard.dialog.browse")}
          errorText={t("dashboard.dialog.failedToUpload")}
          maxFileSize={"15MB"}
          dragAndDropText={t("dashboard.dialog.dragAndDrop")}
          uploadAgainText={t("dashboard.dialog.uploadAgain")}
        />
      </div>
    </Dialog>
  );
};

export default UploadImageDialog;
