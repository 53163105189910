import { z } from "zod";

import { FormNames } from "../name-generator";

export const addWalletSchema = z.object({
  ethWallet: z.string().or(z.any().optional()).optional(),
  btcWallet: z.string().or(z.any().optional()).optional(),
});

export type AddWalletData = z.infer<typeof addWalletSchema>;

export const addWalletForm: FormNames<AddWalletData> = {
  ethWallet: "ethWallet",
  btcWallet: "btcWallet",
};
