import { getCapitalizedFirstLetter } from "../helpers/helpers";

const ExpandableUserProfile = ({
  shouldExpand,
  imgSrc,
  onClick,
  username,
}: {
  shouldExpand: boolean;
  imgSrc?: string;
  onClick?: () => void;
  username: string;
}) => {
  return (
    <div
      className="hover:bg-gray-500
  relative flex items-center py-2 px-3 my-1
  font-medium rounded-md cursor-pointer
  transition-colors group
  "
      onClick={onClick}
    >
      {imgSrc ? (
        <img src={imgSrc} className={"w-14 h-14 rounded-full"} />
      ) : (
        <div className="rounded-full bg-placeholderBg  w-[56px] h-[56px] flex justify-center items-center">
          <span className="text-black text-[24px]">
            {getCapitalizedFirstLetter(username)}
          </span>
        </div>
      )}

      <div className="flex flex-col">
        <span
          className={`overflow-hidden text-nowrap transition-all text-[16px] text-white ${
            shouldExpand ? "w-52 ml-3" : "w-0"
          }`}
        >
          {username}
        </span>
      </div>
    </div>
  );
};

export default ExpandableUserProfile;
