import { Button, Modal } from "@ns/client-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useScreenSize from "../../../hooks/use-screen-size.ts";
import WizzardTabs from "../ui/wizzard-tabs";

import {
  FifthPage,
  FirstPage,
  FourthPage,
  SecondPage,
  ThirdPage,
} from "./pages";

const OnboardWizard = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const [index, setIndex] = useState<number>(0);
  const { height } = useScreenSize();

  const pages = [
    <FirstPage />,
    <SecondPage />,
    <ThirdPage />,
    <FourthPage />,
    <FifthPage />,
  ];

  const handleNextScreen = () => {
    if (index >= 4) return;
    setIndex((prev) => prev + 1);
  };

  const onCloseHandler = () => {
    setIndex(0);
    onClose();
  };

  const handleBackScreen = () => {
    if (index <= 0) return;
    setIndex((prev) => prev - 1);
  };

  return (
    <Modal isOpen={isOpen}>
      <div
        className={`overflow-y-auto items-center px-4 bg-background border-none rounded-none sm:rounded-2xl flex flex-col w-full h-full sm:w-[650px] ${height < 730 ? "sm:h-[90%]" : "sm:h-[720px]"} sm:px-[155px] py-[32px] relative gap-[32px]`}
      >
        <div
          className={`hidden sm:block rounded-2xl absolute border-gradient-blue w-full h-full top-0 left-0`}
        />
        <div className={"relative z-[2] w-full h-full flex flex-col"}>
          {index < 3 && (
            <div className="flex flex-col w-full">
              <WizzardTabs tabsNumber={3} index={index} />
            </div>
          )}
          <div className={"h-full flex flex-col justify-center"}>
            <div className={"flex flex-col justify-center items-center gap-8"}>
              {pages[index]}
              <div className="h-full w-full max-w-[350px] flex-col gap-[1rem] flex items-end">
                {index < 4 ? (
                  <Button onClick={handleNextScreen} className="w-full">
                    {t("common.continue")}
                  </Button>
                ) : (
                  <Button onClick={onCloseHandler} className="w-full">
                    {t("onboardWizard.startBidding")}
                  </Button>
                )}
                {index !== 0 && index !== 4 && (
                  <Button
                    variant={"secondary"}
                    onClick={handleBackScreen}
                    className={"w-full"}
                  >
                    {t("common.back")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OnboardWizard;
